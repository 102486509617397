
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import ReactTooltip from 'react-tooltip';


class TopMenu extends Component {

    constructor() {
        super();
        this.state = {
            responseValue: [],
            cart_count: 0,
            search_key: '',
            Redirecturl: false,
            userRedirect: true,
            Predetive_search: [],
            offer_alert_details:[],
            user_log_status: false
        };
        this.getMainDepartments = this.getMainDepartments.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    componentDidMount() {

        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');
        // let user_auth_key = cookies.get('user_auth_key');
        // alert(user_auth_key);


        // console.log(cookies.get('user_auth_key'))
        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }

        let ele = document.getElementsByClassName('display_searchdiv');
        for (let i = 0; i < ele.length; i++) {
            ele[i].classList.add("d-none");
            ele[i].classList.remove("d-block");
        }

        // document.getElementsByClassName('display_searchdiv')[0].style.visibility='hidden';
        window.scrollTo(0, 0)
        this.getMainDepartments();
        var url = window.location.href;
        var parts = url.split('/');
        var lastSegment = parts.pop() || parts.pop();
        if (parts.length > 5) {
            if (parts[5] === "relatedproduct") {
                this.setState({ search_key: decodeURI(lastSegment) });
            }
        }
        else {
            this.setState({ search_key: '' });
        }
        this.props.onIncrement();
        var elements = document.getElementsByClassName('removerightarrowws');
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.remove('sf-with-ul');
        }


        // window.addEventListener('load', 
        // function() { 
        //     this.getas();
        // }.bind(this), false);


        var myElementToCheckIfClicksAreInsideOf = document.getElementById('search_div_showhide_script');
        // Listen for click events on body
        document.body.addEventListener('click', function (event) {
            if (myElementToCheckIfClicksAreInsideOf.contains(event.target)) {
                // console.log('clicked inside');
            }
            else {
                let ele = document.getElementsByClassName('display_searchdiv');
                for (let i = 0; i < ele.length; i++) {
                    ele[i].classList.add("d-none");
                    ele[i].classList.remove("d-block");
                }
            }
        });

        var mobilesearhindex = document.getElementById('search_mobilediv_showhide_script');
        // Listen for click events on body
        document.body.addEventListener('click', function (event) {
            if (mobilesearhindex.contains(event.target)) {
                // console.log('clicked inside');
            }
            else {
                let ele = document.getElementsByClassName('display_searchdiv');
                for (let i = 0; i < ele.length; i++) {
                    ele[i].classList.add("d-none");
                    ele[i].classList.remove("d-block");
                }
            }
        });
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    async getMainDepartments() {
        const mainjsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        await axiosInstance.post(`getMainDepartments`, mainjsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') {
                    let response = res.data.main_departments;
                    let user_log = res.data.user_log;
                    // let ProductDetails = ProductValues.map(product_response => { return product_response; });
                    let Details = response.map(values_response => { return values_response; });

                    this.setState({
                        responseValue: Details, user_log_status: user_log
                    }, () => {
                        localStorage.setItem('user_log_status', this.state.user_log_status);
                        if (this.state.user_log_status === false) {
                            localStorage.removeItem('yallabasketuser');
                        }
                    });

                    // this.setState({ responseValue: Details,user_log_status:user_log });

                    // localStorage.setItem('user_log_status', this.state.user_log_status);
                    // if(this.state.user_log_status===false)
                    // {
                    //     localStorage.removeItem('yallabasketuser');
                    // }

                }
            })
            .catch(err => console.log('Error: ', err));

        // const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        // await axiosInstance.post(`getUserCartDetails`, jsonValue)
        //     .then((res) => {
        //         if (res.data.status === "success" && res.data.statusCode === "200") {
        //             let cart_count = res.data.count;
        //             this.setState({ cart_count: cart_count });
        //         }
        //     })
        //     .catch((err) => console.log("Error: ", err));
        // // console.log(localStorage.getItem('user_log_status'));
    }

    logout() {

        // window.location.href = "https://yallabasket.appteq.in/";
        // window.location.href = "https://www.appteqdemo.in/";
        localStorage.removeItem('yallabasketuser');
        localStorage.removeItem('user_auth_key');
        localStorage.setItem('user_log_status', "false");

        // cookies.remove('yallabasketuser');
        // cookies.remove('user_auth_key');
        window.location.href = "https://yallabasket.com/";
        // window.location.reload();
        // window.location.href = "https://yallabasket.com/";
        localStorage.removeItem('yallabasketuser');
        localStorage.removeItem('user_auth_key');
        // this.setState({Redirecturl :true});
    }

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            // alert(this.state.search_key);
            if (this.state.search_key !== '' && this.state.search_key !== ' ' && this.state.search_key !== '  ' && this.state.search_key !== '   ') {
                var url = window.location.href;
                var parts = url.split('/');
                // console.log(parts);
                // alert(parts.length);
                if (parts.length > 6) {
                    if (parts[5] === "relatedproduct") {
                        window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + this.state.search_key;
                        // window.location.href = 'https://yallabasket.com/#/product/searchdetails/relatedproduct/'+this.state.search_key;
                        // window.location.reload();
                    }
                    else {
                        window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + this.state.search_key;
                        // window.location.href = 'https://yallabasket.com/#/product/searchdetails/relatedproduct/'+this.state.search_key;
                    }
                }
                else {
                    window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + this.state.search_key;
                    // window.location.href = 'https://yallabasket.com/#/product/searchdetails/relatedproduct/'+this.state.search_key;
                }
            }
        }
    }

    onFocusOutsearchbtn = (e) => {
        if (e.target.value === '' && e.target.value === null) {
            let ele = document.getElementsByClassName('display_searchdiv');
            for (let i = 0; i < ele.length; i++) {
                ele[i].classList.add("d-none");
                ele[i].classList.remove("d-block");
            }
        }
    }

    onPressgetsearchint = (e) => {
        if (e.target.value !== '' && e.target.value !== null) {
            const jsonValue = {
                user_unique_id: localStorage.getItem('user_auth_key'), getkeyrealated: e.target.value
            };
            axiosInstance
                .post(`getpredetivesearch`, jsonValue)
                .then(res => {
                    if (res.data.status === 'success' && res.data.statusCode === '200') {
                        let Predetive_search_values = res.data.category_details;
                        let Predetive_search_details = Predetive_search_values.map(subcat_response => { return subcat_response; });

                        this.setState({
                            Predetive_search: Predetive_search_details
                        }, () => {
                            if (this.state.Predetive_search.length > 0) {
                                let ele = document.getElementsByClassName('display_searchdiv');
                                for (let i = 0; i < ele.length; i++) {
                                    ele[i].classList.remove("d-none");
                                    ele[i].classList.add("d-block");
                                }
                            }
                            else {
                                let ele = document.getElementsByClassName('display_searchdiv');
                                for (let i = 0; i < ele.length; i++) {
                                    ele[i].classList.add("d-none");
                                    ele[i].classList.remove("d-block");
                                }
                            }
                        });
                    }
                })
                .catch(err => console.log('Error: ', err));
        }
        if (e.target.value === '' || e.target.value === null) {
            let ele = document.getElementsByClassName('display_searchdiv');
            for (let i = 0; i < ele.length; i++) {
                ele[i].classList.add("d-none");
                ele[i].classList.remove("d-block");
            }
            this.setState({
                Predetive_search: []
            });
        }

        if (e.keyCode === 13 && e.shiftKey === false) {
            // alert(this.state.search_key);
            if (this.state.search_key !== '' && this.state.search_key !== ' ' && this.state.search_key !== '  ' && this.state.search_key !== '   ') {
                var url = window.location.href;
                var parts = url.split('/');
                // console.log(parts);
                // alert(parts.length);
                if (parts.length > 6) {
                    if (parts[5] === "relatedproduct") {
                        window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + this.state.search_key;
                        // window.location.href = 'https://yallabasket.com/#/product/searchdetails/relatedproduct/'+this.state.search_key;
                        // window.location.reload();
                    }
                    else {
                        window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + this.state.search_key;
                        // window.location.href = 'https://yallabasket.com/#/product/searchdetails/relatedproduct/'+this.state.search_key;
                    }
                }
                else {
                    window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + this.state.search_key;
                    // window.location.href = 'https://yallabasket.com/#/product/searchdetails/relatedproduct/'+this.state.search_key;
                }
            }
        }
    }

    search_products() {
        if (this.state.search_key !== '' && this.state.search_key !== ' ' && this.state.search_key !== '  ' && this.state.search_key !== '   ') {
            var url = window.location.href;
            var parts = url.split('/');
            // console.log(parts);
            if (parts.length > 6) {
                if (parts[5] === "relatedproduct") {
                    window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + this.state.search_key;
                    // window.location.href = 'https://yallabasket.com/#/product/searchdetails/relatedproduct/'+this.state.search_key;
                    // window.location.reload();
                }
                else {
                    window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + this.state.search_key;
                    // window.location.href = 'https://yallabasket.com/#/product/searchdetails/relatedproduct/'+this.state.search_key;
                }
            }
            else {
                window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + this.state.search_key;
                // window.location.href = 'https://yallabasket.com/#/product/searchdetails/relatedproduct/'+this.state.search_key;
            }
        }
    }


    Click_Predetive_search(values) {
        if (values !== '' && values !== null) {
            window.location.href = 'https://yallabasket.com/product/searchdetails/relatedproduct/' + values;
        }
    }


    hovermouse(id) {
        var div = document.getElementsByClassName('subcategory');
        for (let i = 0; i < div.length; i++) {
            div[i].classList.add("hide");
            div[i].classList.remove("cat-show");
            div[i].classList.remove("cat-active");
        }

        var n = document.getElementById('mainmenu' + id);
        // console.log(n);
        n.classList.remove("hide");
        n.classList.add("cat-show");
        n.classList.add("cat-active");
    }
    hidearrowsfrommenu() {
        var div = document.getElementsByClassName('removerightarrowws');

        for (let i = 0; i < div.length; i++) {
            div[i].classList.remove("sf-with-ul");
        }
    }
    closeofferalert()
    {
        localStorage.setItem('sowofferalert', "false");
        this.props.onIncrement();
    }

    render() {

        return (
            <header className="header">
                {this.props.showactiveoffer !== 0 ? (
                    <>
                        {localStorage.getItem('sowofferalert') === "true" ? (
                            <>
                                {
                                    this.props.offeralert.map(values => {
                                        return (
                                            <>
                                                <div className="ele">
                                                    <div className="topofferalert row">
                                                        <div className="col-lg-11 col-9">
                                                            <p className="cust-print">{values.offer_detsils}</p>
                                                        </div>
                                                        <div className="col-lg-1 col-3">
                                                            <p className="text-right" onClick={()=>this.closeofferalert()}>Dismiss <i class="fa fa-times-circle" aria-hidden="true"></i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                } 
                            </>
                        ) : (
                            <></>
                        )}

                    </>
                ) : (
                    <></>
                )}

                <ReactTooltip effect="solid" place="bottom" multiline={true} isCapture={true} wrapper="span" offset={{ top: 10, left: 10 }} />
                <div className="header-middle">
                    <div className="custom-top cust-top-img">
                        <div className="top-menu">
                            <span className="custom-top-span pad-l-45" data-tip="All orders received before 11 am will be delivered same day for free" ><i className="fa fa-shipping-fast"></i>&nbsp;Free Delivery</span>
                            <span className="custom-top-span bl-line" data-tip="We let you inspect the quality of the product before you pay for it"><i className="fa fa-credit-card"></i>&nbsp;No Pre-Payment</span>
                            <span className="custom-top-span bl-line" data-tip="You are free to order as you like"><i className="icon-mini-cart"></i>&nbsp;No Minimum Order</span>
                            <span className="custom-top-span bl-line" data-tip="No stress ! We deliver when you are back home from work "><i className="fa fa-clock"></i>&nbsp;Delivery Time : 6 - 11 PM</span>
                        </div>
                    </div>
                    <div className="container">
                        <div className="header-left">
                            <button className="mobile-menu-toggler" type="button">
                                <i className="icon-menu"></i>
                            </button>
                            <Link to="/" className="logo">
                                <img className="custom-logo" src="/assets/img/logo.png" alt="" />
                            </Link>
                        </div>

                        <div className="header-center">
                            <div className="header-search mobile-hide" id="search_div_showhide_script">
                                <span href="#" className="search-toggle" role="button"><i className="icon-magnifier"></i></span>
                                <div className="header-search-wrapper">
                                    <input type="text" id="search_key_web" className="form-control" onChange={this.onChangeHandler} onBlur={() => this.onFocusOutsearchbtn} name="search_key" value={this.state.search_key} placeholder="What are you looking for?" onKeyDown={this.onEnterPress} onKeyUp={this.onPressgetsearchint} />
                                    <button className="btn" onClick={() => this.search_products()}><i className="icon-magnifier"></i></button>
                                    <div className="display_searchdiv">
                                        <div id="search-result">
                                            {this.state.Predetive_search.length > 0 ? (
                                                <>
                                                    {
                                                        this.state.Predetive_search.map(values => {
                                                            return (
                                                                <p onClick={() => this.Click_Predetive_search(values)}>{values}</p>
                                                            )
                                                        })
                                                    }
                                                </>
                                            ) : (
                                                    null
                                                )}
                                        </div>
                                        <div className="searchviweallbtn" onClick={() => this.Click_Predetive_search(this.state.search_key)}>view all</div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="header-right">
                            <div className="header-account">
                                {localStorage.getItem('yallabasketuser') !== undefined
                                    && localStorage.getItem('yallabasketuser') !== null
                                    && localStorage.getItem('yallabasketuser') !== '' ? (
                                        <>
                                            {this.state.user_log_status === true || this.state.user_log_status === "true" ? (
                                                <div className="header-user">
                                                    <i className="icon-user-2"></i>
                                                    <div className="header-userinfo mobile-hide">
                                                        <span className="c-pointer" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">Hello!</span>
                                                        <h4>{localStorage.getItem('yallabasketuser')}</h4>
                                                    </div>
                                                    <div id="custom-logout" className="custom-logout">
                                                        <div className="account-profile">
                                                            <Link to={{ pathname: '/Accountdetails/pgtype?=myaccount' }}><p><i className="custom-account-profile-icon fa fa-user-alt"></i>Profile</p></Link>
                                                            <Link to="/Orders" ><p><i className="custom-account-profile-icon fa fa-shopping-bag"></i>Orders</p></Link>
                                                            <Link to="/Subscription" ><p><i className="custom-account-profile-icon fa fa-calendar"></i>My Subscription</p></Link>
                                                            <p onClick={() => this.logout()} ><i className="custom-account-profile-icon fa fa-sign-out-alt"></i>Logout</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                    <div className="header-user">
                                                        <button id="login-modal" className="sdsdsdsds btn custom-login-btn login-modal">Login </button>
                                                    </div>
                                                )}
                                        </>
                                    ) : (
                                        <div className="header-user">
                                            <button id="login-modal" className="sdsdsdsds btn custom-login-btn login-modal">Login </button>
                                        </div>
                                    )}
                            </div>
                            <div className="dropdown cart-dropdown">
                                {this.props.counter !== 0 ? (
                                    <Link to="/Cart" data-tip="My Basket">
                                        <span className="dropdown-toggle c-pointer" role="button">
                                            <img className="cust_basket_width_siz" src="/assets/img/basket icon_2.png" alt="" />
                                            <span className="cart-count">{this.props.counter}</span>
                                        </span>
                                    </Link>
                                ) : (
                                        <Link to="/Cart"  data-tip="My Basket">
                                            <span className="dropdown-toggle c-pointer" role="button">
                                                <img className="cust_basket_width_siz" src="/assets/img/basket icon_1.png" alt="" />
                                                <span className="cart-count">{this.props.counter}</span>
                                            </span>
                                        </Link>
                                    )}

                            </div>
                            <div className="dropdown cart-dropdown ml-2" >
                                <Link to="/Subscription" data-tip="My Subscription" >
                                    <span className="dropdown-toggle c-pointer" role="button">
                                        <img className="cust_basket_width_siz" src="/assets/img/subscription.png" alt="" />
                                        {/* <span className="cart-count">0</span> */}
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="row res-search" id="search_mobilediv_showhide_script">
                        <input type="text" className="form-control res-input-search" onChange={this.onChangeHandler} onBlur={() => this.onFocusOutsearchbtn} name="search_key" value={this.state.search_key} placeholder="What are you looking for?" onKeyDown={this.onEnterPress} onKeyUp={this.onPressgetsearchint} />
                        <button className="btn res-btn" onClick={() => this.search_products()}><i className="icon-magnifier"></i></button>
                        <div className="display_searchdiv">
                            <div id="search-result">
                                {this.state.Predetive_search.length > 0 ? (
                                    <>
                                        {
                                            this.state.Predetive_search.map(values => {
                                                return (
                                                    <p onClick={() => this.Click_Predetive_search(values)}>{values}</p>
                                                )
                                            })
                                        }
                                    </>
                                ) : (
                                        null
                                    )}
                            </div>
                            <div className="searchviweallbtn" onClick={() => this.Click_Predetive_search(this.state.search_key)}>view all</div>
                        </div>
                    </div>
                </div>

                <div className="header-bottom">
                    <div className="container">
                        <nav className="main-nav">
                            <div className="menu-depart">
                                <span style={{ fontWeight: 600 }}><i className="icon-menu"></i>All Category</span>
                                <div className="submenu">
                                    {
                                        this.state.responseValue.map(maindepartment => {
                                            return (
                                                <Link key={"allcategory" + maindepartment.mdId} to={{ pathname: '/' + maindepartment.md_url_code + '/' + maindepartment.mdId }}>
                                                    {/* <input type="hiden" name="all-main-menu" value={maindepartment.md_code} /> */}
                                                    <span id="all-main-menu-temi" onMouseEnter={() => this.hovermouse(maindepartment.md_code)} className='all-main-menu' data-id={maindepartment.md_code} >
                                                        {maindepartment.mdName}
                                                    </span>
                                                </Link>
                                            )
                                        })
                                    }
                                    {/* <span>VIEW ALL <i className="icon-angle-right"></i></span> */}
                                </div>
                                {
                                    this.state.responseValue.map((main_dep) => (
                                        <>
                                            <div key={"allcategorymain" + main_dep.mdId} id={"mainmenu" + main_dep.md_code} className="subcategory hide">
                                                <div className="custom-container">
                                                    <header className="custom-cat-header">
                                                        <Link className="color-b" to={{ pathname: '/' + main_dep.md_url_code + '/' + main_dep.mdId }}><p className="cat-p">{main_dep.mdName}</p></Link>
                                                    </header>
                                                    {
                                                        main_dep.sub_departments.length === 0 ? (
                                                            null
                                                        ) : (
                                                                <div className="row cat-scroll">
                                                                    {main_dep.sub_departments.map(sub_dep => (
                                                                        <div key={"allcategorysub" + sub_dep.sdId} className="col-lg-4 cat-main-content">
                                                                            <div className="cat-products">
                                                                                <Link className="color-b" to={{ pathname: '/' + main_dep.md_url_code + '/' + sub_dep.sd_url_code + '/' + sub_dep.sdId }}><p>{sub_dep.sdName}</p></Link>
                                                                                {
                                                                                    sub_dep.main_category.length === 0 ? (
                                                                                        null
                                                                                    ) : (
                                                                                            <ul className="cat-products-ul">
                                                                                                {
                                                                                                    sub_dep.main_category.map(main_cat => (
                                                                                                        <li key={"allcategorymaincat" + main_cat.mcId}>
                                                                                                            <Link to={{ pathname: '/'+ main_dep.md_url_code + '/' + sub_dep.sd_url_code + '/' + main_cat.mc_url_code + '/'  + main_cat.mcId }}><span className="cat-products-a">{main_cat.mcName}</span></Link>
                                                                                                            {
                                                                                                                main_cat.sub_category.length === 0 ? (
                                                                                                                    null
                                                                                                                ) : (
                                                                                                                        <ul className="sub-cat-ul">
                                                                                                                            {
                                                                                                                                main_cat.sub_category.map(sub_cat => (
                                                                                                                                    <Link key={"allcategorysubcat" + sub_cat.scId} to={{ pathname: '/' + main_dep.md_url_code + '/' + sub_dep.sd_url_code + '/' + main_cat.mc_url_code + '/' + sub_cat.sc_url_code + '/' + sub_cat.scId }}><li className="sub-cat-products-a">{sub_cat.scName}</li></Link>
                                                                                                                                ))}
                                                                                                                        </ul>
                                                                                                                    )
                                                                                                            }
                                                                                                        </li>
                                                                                                    ))}
                                                                                            </ul>
                                                                                        )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ))}
                            </div>
                            <p className="span_flex"></p>
                            <ul className="menu res-d-none">
                                <li className="sf-with-ul">
                                    <Link className="removerightarrowws" to={{ pathname: '/' }}>
                                        <span className="main-dep-name">Home</span></Link>
                                </li>
                                {
                                    this.state.responseValue.map((main_dep) => (
                                        <li key={"maindeptt" + main_dep.mdId} className="sf-with-ul">
                                            <Link className="removerightarrowws" to={{ pathname: '/' + main_dep.md_url_code + '/' + main_dep.mdId }}>
                                                <span className="main-dep-name" onMouseEnter={() => this.hidearrowsfrommenu()}>{main_dep.mdName}</span></Link>
                                            {
                                                // console.log(main_dep.sub_departments.length),
                                                main_dep.sub_departments.length === 0 ? (
                                                    null
                                                ) : (
                                                        <ul>
                                                            {main_dep.sub_departments.map(sub_dep => (
                                                                <li key={"subdeptt" + sub_dep.sdId}>
                                                                    <Link className="removerightarrowws" to={{ pathname: '/' + main_dep.md_url_code + '/'  + sub_dep.sd_url_code + '/' + sub_dep.sdId }}>
                                                                        <span className="sf-with-ul cat-name">{sub_dep.sdName}</span></Link>
                                                                    {
                                                                        // console.log(main_dep.sub_departments.length),
                                                                        sub_dep.main_category.length === 0 ? (
                                                                            null
                                                                        ) : (
                                                                                <ul>
                                                                                    {
                                                                                        sub_dep.main_category.map(main_cat => (
                                                                                            <li key={"maincatt" + main_cat.mcId}>
                                                                                                <Link className="removerightarrowws" to={{ pathname: '/' + main_dep.md_url_code + '/' + sub_dep.sd_url_code + '/' + main_cat.mc_url_code + '/' + main_cat.mcId }}>
                                                                                                    <span className="cat-name">{main_cat.mcName}</span></Link>
                                                                                            </li>
                                                                                        ))}
                                                                                </ul>
                                                                            )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )
                                            }
                                        </li>
                                    ))}
                            </ul>
                            <p className="span_flex"></p>
                        </nav>
                    </div>
                </div>

            </header>
        )
    }
}

export default TopMenu