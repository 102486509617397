import React, { Component } from 'react';
// import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
// import Cookies from 'universal-cookie';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Select from 'react-select';
// const cookies = new Cookies();


export const uom_list = [
    { key: "Grams", value: 'Grams', label: 'Grams', },
    { key: "Kilogram", value: 'Kilogram', label: 'Kilogram', },
    { key: "Liter", value: 'Liter', label: 'Liter', },
    { key: "Box", value: 'Box', label: 'Box', },
    { key: "Piece", value: 'Piece', label: 'Piece', },
    { key: "Bunch", value: 'Bunch', label: 'Bunch', },
    { key: "Pack", value: 'Pack', label: 'Pack', },
];


class Category extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryList: [],
            offset: 0,
            elements: [],
            perPage: 28,
            currentPage: 0,
            mainvaluelist: [],
            subvaluelist: [],
            brandvaluelist: [],
            dietvaluelist: [],
            mainDepartment: "",
            subDepartment: "",
            mainCategory: "",
            product_name: "",
            quantity: "",
            prod_unit: "",
            prod_unit_id: "",
            prod_unit_qty: "",
            requestusername: "",
            request_mobile_no: "",
            productDetails_popup: [],
            clicks: 1,
            show: false,
            total_weight: '',
            total_price: '',
            mobile_no_code: '+971',
            spinner: 'loading-overlay',
            small_spinner: 'ajax-overlay hide-content',
            value: { min: 0, max: 0 },
            md_url_param: "",
            sd_url_param: "",
            mc_url_param: "",
            sc_url_param: "",
            md_url_code: "",
            sd_url_code: "",
            mc_url_code: "",
            sc_url_code: "",
            visible: 28,
            myerrorpage: false,
            seotitle: "",
            seodescription: "",
            category_custfilter: "POPULARITY",
            showfiltervalues: "",
            category_custfilter_mobile: "POPULARITY",
            subscriptionopen: false,
            subscription_weekly: [],
            subscription_biweekly: [],
            subscription_monthly: [],
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            showsubscriptionerrormessage: false,
            subscription_errormsg: "",
            subscription_prod_id: "",
            subscription_prod_code: "",
            Sun_quantity: 0,
            Mon_quantity: 0,
            Tue_quantity: 0,
            Wed_quantity: 0,
            Thu_quantity: 0,
            Fri_quantity: 0,
            Sat_quantity: 0,
            subscription_produ_imgurl: "",
            subscription_produ_category: "",
            subscription_produ_name: "",
            subscription_produ_brand: "",
            subscription_produ_umo: "",
            addresflagopenpopup: false
        };
        this.getCategoryDetails = this.getCategoryDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getweeklysubscriptionvalues = this.getweeklysubscriptionvalues.bind(this);
        this.getbiweeklysubscriptionvalues = this.getbiweeklysubscriptionvalues.bind(this);
        this.getmonthlysubscriptionvalues = this.getmonthlysubscriptionvalues.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
        let spinner = 'loading-overlay';
        var mdCode = "";
        var sdCode = "";
        var mcCode = "";
        this.setState({ spinner });
        var chks = document.getElementsByName('sc_checked_name');
        var chks_brand = document.getElementsByName('brand_checked_name');
        var chks_diet = document.getElementsByName('diet_type_checked_name');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            chks[i].checked = false;
        }

        for (var j = 0; j < chks_brand.length; j++) {
            chks_brand[j].checked = false;
        }

        if (chks_diet !== null && chks_diet !== undefined) {
            for (var k = 0; k < chks_diet.length; k++) {
                chks_diet[k].checked = false;
            }
        }

        this.setState({
            mainDepartment: "", subDepartment: "", mainCategory: "", currentPage: 0, offset: 0, md_url_param: "", sd_url_param: "", mc_url_param: "",
            sc_url_param: "", md_url_code: "", sd_url_code: "", mc_url_code: "", sc_url_code: ""
        });
        var url = window.location.href;
        var parts = url.split('/');

        var lastSegment = parts.pop() || parts.pop();
        let searchkeyword = lastSegment.replace('-', ' ');

        this.setState({
            category_custfilter: "POPULARITY"
        }, () => {
            if (parts.length === 4) {
                localStorage.removeItem('prevsdCode');
                localStorage.removeItem('prevmcCode');
                localStorage.setItem('prevmdCode', lastSegment);
                if (lastSegment !== "") {
                    this.getCategoryDetails(lastSegment);
                }
            }
            if (parts.length === 5) {
                if (parts[3] === "ProductDetails") {
                    window.location.href = 'http://yallabasket.com:3002/product/searchdetails/relatedproduct/' + searchkeyword;
                }
                else {
                    mdCode = parts[4];
                    localStorage.removeItem('prevmdCode');
                    localStorage.removeItem('prevmcCode');
                    localStorage.setItem('prevsdCode', lastSegment);
                    if (lastSegment !== "") {
                        this.getCategoryDetails(mdCode, lastSegment);
                    }
                }
            }
            if (parts.length === 6) {
                mdCode = parts[4];
                sdCode = parts[5];
                localStorage.removeItem('prevmdCode');
                localStorage.removeItem('prevsdCode');
                localStorage.setItem('prevmcCode', lastSegment);
                if (lastSegment !== "") {
                    this.getCategoryDetails(mdCode, sdCode, lastSegment);
                }
            }
            if (parts.length === 7) {
                mdCode = parts[4];
                sdCode = parts[5];
                mcCode = parts[6];
                localStorage.removeItem('prevmdCode');
                localStorage.removeItem('prevsdCode');
                localStorage.removeItem('prevmcCode');
                localStorage.setItem('prevscCode', lastSegment);
                if (lastSegment !== "") {
                    this.getCategoryDetails(mdCode, sdCode, mcCode, lastSegment);
                }
            }
        });

    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    componentDidUpdate(prevProps, prevState) {
        var url = window.location.href;
        var parts = url.split('/');
        var lastSegment = parts.pop() || parts.pop();

        if (prevState.spinner === 'loading-overlay hide-content') {
            if (parts.length === 4) {
                if (localStorage.getItem('prevmdCode') !== lastSegment) 
                {
                    localStorage.setItem('prevmdCode', lastSegment);
                    this.reload();
                }
            }
            if (parts.length === 5) {
                if (localStorage.getItem('prevsdCode') !== lastSegment) 
                {
                    localStorage.setItem('prevsdCode', lastSegment);
                    this.reload();
                }
            }
            if (parts.length === 6) {
                if (localStorage.getItem('prevmcCode') !== lastSegment) 
                {
                    localStorage.setItem('prevmcCode', lastSegment);
                    this.reload();
                }
            }
            if (parts.length === 7) {
                if (localStorage.getItem('prevscCode') !== lastSegment) 
                {
                    localStorage.setItem('prevscCode', lastSegment);
                    this.reload();
                }
            }
        }
    }

    reload = () => {
        this.componentDidMount();
    }

    loadMore() {
        this.setState((prev) => {
            return { visible: prev.visible + 28 };
        });
    }


    getCategoryDetails(mdCode, sdCode, mcCode, scCode) {
        var url = window.location.href;
        var parts = url.split('/');
        this.setState({ myerrorpage: false });
        if (parts.length === 5) {
            if (mdCode === undefined) {
                mdCode = 0;
            }
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetails`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                        let maincatvalues = res.data.category_list;
                        let mainvaluedetails = maincatvalues.map(maincat_response => { return maincat_response; });

                        let subcatvalues = res.data.compatible_with;
                        let subvaluedetails = subcatvalues.map(subcat_response => { return subcat_response; });

                        let brandfiltervalues = res.data.brand_filter;
                        let brandvaluedetails = brandfiltervalues.map(subcat_response => { return subcat_response; });

                        let dietfiltervalues = res.data.diet_filter;
                        let dietvaluedetails = dietfiltervalues.map(subcat_response => { return subcat_response; });

                        let subscription_weeklyvalues = res.data.subscription_details["subscription_weekly"];
                        let subscription_weeklydetails = subscription_weeklyvalues.map(subcat_response => { return subcat_response; });

                        let subscription_biweeklyvalues = res.data.subscription_details["subscription_biweekly"];
                        let subscription_biweeklydetails = subscription_biweeklyvalues.map(subcat_response => { return subcat_response; });

                        let subscription_monthlyvalues = res.data.subscription_details["subscription_monthly"];
                        let subscription_monthlydetails = subscription_monthlyvalues.map(subcat_response => { return subcat_response; });


                        let price_filtervalues = res.data.price_filter;
                        let seotitle = res.data.mdTitle;
                        let seodescription = res.data.mdDes;
                        //   let brandvaluedetails = price_filtervalues.map(subcat_response => { return subcat_response; });

                        // console.log(mainvaluedetails);
                        // console.log(subvaluedetails);

                        this.setState({
                            categoryList: CategoryDetails, visible: 28, mainvaluelist: mainvaluedetails,
                            subvaluelist: subvaluedetails, brandvaluelist: brandvaluedetails, dietvaluelist: dietvaluedetails, mainDepartment: res.data.mdName,
                            mdCode: res.data.mdCode, listcount: res.data.count,
                            value: { min: price_filtervalues[0].min_price, max: price_filtervalues[0].max_price },
                            price_valueaa_min: price_filtervalues[0].min_price, price_valueaa_max: price_filtervalues[0].max_price,
                            md_url_param: res.data.md_url_param, md_url_code: res.data.md_url_code, seotitle: seotitle,
                            seodescription: seodescription,
                            subscription_weekly: subscription_weeklydetails,
                            subscription_biweekly: subscription_biweeklydetails,
                            subscription_monthly: subscription_monthlydetails
                        });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "202") {
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner, myerrorpage: true });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Error: ", err));
        }
        if (parts.length === 6) {
            if (sdCode === undefined) {
                sdCode = 0;
            }

            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, sd_code: sdCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetails`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                        let maincatvalues = res.data.category_list;
                        let mainvaluedetails = maincatvalues.map(maincat_response => { return maincat_response; });

                        let subcatvalues = res.data.compatible_with;
                        let subvaluedetails = subcatvalues.map(subcat_response => { return subcat_response; });

                        let brandfiltervalues = res.data.brand_filter;
                        let brandvaluedetails = brandfiltervalues.map(subcat_response => { return subcat_response; });

                        let dietfiltervalues = res.data.diet_filter;
                        let dietvaluedetails = dietfiltervalues.map(subcat_response => { return subcat_response; });

                        let subscription_weeklyvalues = res.data.subscription_details["subscription_weekly"];
                        let subscription_weeklydetails = subscription_weeklyvalues.map(subcat_response => { return subcat_response; });

                        let subscription_biweeklyvalues = res.data.subscription_details["subscription_biweekly"];
                        let subscription_biweeklydetails = subscription_biweeklyvalues.map(subcat_response => { return subcat_response; });

                        let subscription_monthlyvalues = res.data.subscription_details["subscription_monthly"];
                        let subscription_monthlydetails = subscription_monthlyvalues.map(subcat_response => { return subcat_response; });

                        let price_filtervalues = res.data.price_filter;
                        let seotitle = res.data.sdTitle;
                        let seodescription = res.data.sdDes;

                        this.setState({
                            categoryList: CategoryDetails, visible: 28, mainvaluelist: mainvaluedetails,
                            subvaluelist: subvaluedetails, brandvaluelist: brandvaluedetails, dietvaluelist: dietvaluedetails, mainDepartment: res.data.mdName, mdCode: res.data.mdCode, subDepartment: res.data.sdName, sdCode: res.data.sdCode, listcount: res.data.count,
                            value: { min: price_filtervalues[0].min_price, max: price_filtervalues[0].max_price },
                            price_valueaa_min: price_filtervalues[0].min_price, price_valueaa_max: price_filtervalues[0].max_price,
                            md_url_param: res.data.md_url_param, md_url_code: res.data.md_url_code, sd_url_param: res.data.sd_url_param,
                            sd_url_code: res.data.sd_url_code, seotitle: seotitle, seodescription: seodescription,
                            subscription_weekly: subscription_weeklydetails,
                            subscription_biweekly: subscription_biweeklydetails,
                            subscription_monthly: subscription_monthlydetails
                        });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "202") {
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner, myerrorpage: true });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Error: ", err));
        }
        if (parts.length === 7) {
            if (mcCode === undefined) {
                mcCode = 0;
            }
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, sd_code: sdCode, mc_code: mcCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetails`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                        let maincatvalues = res.data.category_list;
                        let mainvaluedetails = maincatvalues.map(maincat_response => { return maincat_response; });

                        let subcatvalues = res.data.compatible_with;
                        let subvaluedetails = subcatvalues.map(subcat_response => { return subcat_response; });

                        let brandfiltervalues = res.data.brand_filter;
                        let brandvaluedetails = brandfiltervalues.map(subcat_response => { return subcat_response; });

                        let dietfiltervalues = res.data.diet_filter;
                        let dietvaluedetails = dietfiltervalues.map(subcat_response => { return subcat_response; });

                        let subscription_weeklyvalues = res.data.subscription_details["subscription_weekly"];
                        let subscription_weeklydetails = subscription_weeklyvalues.map(subcat_response => { return subcat_response; });

                        let subscription_biweeklyvalues = res.data.subscription_details["subscription_biweekly"];
                        let subscription_biweeklydetails = subscription_biweeklyvalues.map(subcat_response => { return subcat_response; });

                        let subscription_monthlyvalues = res.data.subscription_details["subscription_monthly"];
                        let subscription_monthlydetails = subscription_monthlyvalues.map(subcat_response => { return subcat_response; });


                        let price_filtervalues = res.data.price_filter;
                        let seotitle = res.data.mcTitle;
                        let seodescription = res.data.mcDes;

                        this.setState({
                            categoryList: CategoryDetails, visible: 28, mainvaluelist: mainvaluedetails,
                            subvaluelist: subvaluedetails, brandvaluelist: brandvaluedetails, dietvaluelist: dietvaluedetails, mainDepartment: res.data.mdName, mdCode: res.data.mdCode, subDepartment: res.data.sdName, sdCode: res.data.sdCode, mainCategory: res.data.mcName, mcCode: res.data.mcCode, listcount: res.data.count,
                            value: { min: price_filtervalues[0].min_price, max: price_filtervalues[0].max_price },
                            price_valueaa_min: price_filtervalues[0].min_price, price_valueaa_max: price_filtervalues[0].max_price,
                            md_url_param: res.data.md_url_param, md_url_code: res.data.md_url_code, sd_url_param: res.data.sd_url_param,
                            sd_url_code: res.data.sd_url_code, mc_url_param: res.data.mc_url_param, mc_url_code: res.data.mc_url_code, seotitle: seotitle, seodescription: seodescription,
                            subscription_weekly: subscription_weeklydetails,
                            subscription_biweekly: subscription_biweeklydetails,
                            subscription_monthly: subscription_monthlydetails
                        });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "202") {
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner, myerrorpage: true });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Error: ", err));
        }
        if (parts.length === 8) {
            if (scCode === undefined) {
                scCode = 0;
            }
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, sd_code: sdCode, mc_code: mcCode, sc_code: scCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetails`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                        let maincatvalues = res.data.category_list;
                        let mainvaluedetails = maincatvalues.map(maincat_response => { return maincat_response; });

                        let subcatvalues = res.data.compatible_with;
                        let subvaluedetails = subcatvalues.map(subcat_response => { return subcat_response; });

                        let brandfiltervalues = res.data.brand_filter;
                        let brandvaluedetails = brandfiltervalues.map(subcat_response => { return subcat_response; });

                        let dietfiltervalues = res.data.diet_filter;
                        let dietvaluedetails = dietfiltervalues.map(subcat_response => { return subcat_response; });

                        let subscription_weeklyvalues = res.data.subscription_details["subscription_weekly"];
                        let subscription_weeklydetails = subscription_weeklyvalues.map(subcat_response => { return subcat_response; });

                        let subscription_biweeklyvalues = res.data.subscription_details["subscription_biweekly"];
                        let subscription_biweeklydetails = subscription_biweeklyvalues.map(subcat_response => { return subcat_response; });

                        let subscription_monthlyvalues = res.data.subscription_details["subscription_monthly"];
                        let subscription_monthlydetails = subscription_monthlyvalues.map(subcat_response => { return subcat_response; });



                        let price_filtervalues = res.data.price_filter;

                        let seotitle = res.data.scTitle;
                        let seodescription = res.data.scDes;

                        this.setState({
                            categoryList: CategoryDetails, visible: 28, mainvaluelist: mainvaluedetails,
                            subvaluelist: subvaluedetails, brandvaluelist: brandvaluedetails, dietvaluelist: dietvaluedetails, mainDepartment: res.data.mdName, mdCode: res.data.mdCode, subDepartment: res.data.sdName, sdCode: res.data.sdCode, mainCategory: res.data.mcName, mcCode: res.data.mcCode, listcount: res.data.count,
                            value: { min: price_filtervalues[0].min_price, max: price_filtervalues[0].max_price },
                            price_valueaa_min: price_filtervalues[0].min_price, price_valueaa_max: price_filtervalues[0].max_price,
                            md_url_param: res.data.md_url_param, md_url_code: res.data.md_url_code,
                            sd_url_param: res.data.sd_url_param, sd_url_code: res.data.sd_url_code,
                            mc_url_param: res.data.mc_url_param, mc_url_code: res.data.mc_url_code,
                            sc_url_param: res.data.sc_url_param, sc_url_code: res.data.sc_url_code, seotitle: seotitle, seodescription: seodescription,
                            subscription_weekly: subscription_weeklydetails,
                            subscription_biweekly: subscription_biweeklydetails,
                            subscription_monthly: subscription_monthlydetails
                        });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "202") {
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner, myerrorpage: true });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Error: ", err));
        }
    }

    onOpenModal = (id) => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = { pId: id };
        axiosInstance
            .post(`getindividualProductDetails`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let ProductValues = res.data.product_details;
                    let selling_price = res.data.selling_price;
                    let prod_quantity = res.data.prod_quantity;
                    let prod_idd = res.data.prod_id;
                    //   let prod_dtl_nutrition_info = res.data.prod_dtl_nutrition_info;
                    let ProductDetails = ProductValues.map(product_response => { return product_response; });
                    //   let RelatedDetails = RelatedValues.map(related_response => { return related_response; });
                    this.setState({ productDetails_popup: ProductDetails });
                    this.setState({ prod_quantity, selling_price, prod_idd })

                    this.setState({ open: true });
                }
            })
            .catch((err) => console.log("Error: ", err));


    };

    onCloseModal = () => {
        this.setState({ open: false, clicks: 1, total_weight: '', total_price: '' });
        this.setState({ show: false });
    };

    IncrementItem = () => {
        this.setState({ clicks: this.state.clicks + 1 });
        // alert('hii');

        var total_clicks = this.state.clicks + 1
        if (total_clicks > 1) {
            var total_weight = total_clicks * this.state.prod_quantity;
            var total_price = this.state.selling_price * total_clicks;
            var final_total = parseFloat(total_price).toFixed(2);
            this.setState({ show: true, total_weight: total_weight, total_price: final_total, total_clicks: total_clicks });
        }
        else {
            this.setState({ show: false });
        }


    }

    DecreaseItem = () => {
        if (this.state.clicks > 1) {
            this.setState({ clicks: this.state.clicks - 1 });
            var total_clicks = this.state.clicks - 1;
            if (total_clicks > 1) {
                var total_weight = total_clicks * this.state.prod_quantity;
                var total_price = this.state.selling_price * total_clicks;
                var final_total = parseFloat(total_price).toFixed(2);
                this.setState({ show: true, total_weight: total_weight, total_price: final_total, total_clicks: total_clicks });
            }
            else {
                this.setState({ show: false });
            }
        }
        else {
            this.setState({ show: false });
        }
    }

    ToggleClick = () => {
        this.setState({ show: !this.state.show });
        // console.log(this.state.clicks);
    }

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === "category_custfilter") {
            this.setState({
                category_custfilter: e.target.value
            }, () => {
                this.handleChange(e);
            });
        }
    }


    async callbackgetCategoryDetailsonselectedfilter(value) {
        var mdCode = "";
        var sdCode = "";
        var mcCode = "";
        var url = window.location.href;
        var parts = url.split('/');
        var lastSegment = parts.pop() || parts.pop();
        this.setState({
            category_custfilter: value
        }, () => {
            if (parts.length === 4) {
                localStorage.removeItem('prevsdCode');
                localStorage.removeItem('prevmcCode');
                localStorage.setItem('prevmdCode', lastSegment);
                if (lastSegment !== "") {
                    let small_spinner = 'ajax-overlay';
                    this.setState({ small_spinner });
                    this.getCategoryDetailsonselectedfilter(lastSegment);
                }
            }
            else if (parts.length === 5) {
                // console.log(parts[5]);
                mdCode = parts[4];
                localStorage.removeItem('prevmdCode');
                localStorage.removeItem('prevmcCode');
                localStorage.setItem('prevsdCode', lastSegment);
                if (lastSegment !== "") {
                    let small_spinner = 'ajax-overlay';
                    this.setState({ small_spinner });
                    this.getCategoryDetailsonselectedfilter(mdCode, lastSegment);
                }
            }
            else if (parts.length === 6) {
                mdCode = parts[4];
                sdCode = parts[5];
                localStorage.removeItem('prevmdCode');
                localStorage.removeItem('prevsdCode');
                localStorage.setItem('prevmcCode', lastSegment);
                if (lastSegment !== "") {
                    let small_spinner = 'ajax-overlay';
                    this.setState({ small_spinner });
                    this.getCategoryDetailsonselectedfilter(mdCode, sdCode, lastSegment);
                }
            }
            else if (parts.length === 7) {
                mdCode = parts[4];
                sdCode = parts[5];
                mcCode = parts[6];
                localStorage.removeItem('prevmdCode');
                localStorage.removeItem('prevsdCode');
                localStorage.removeItem('prevmcCode');
                localStorage.setItem('prevscCode', lastSegment);
                if (lastSegment !== "") {
                    let small_spinner = 'ajax-overlay';
                    this.setState({ small_spinner });
                    this.getCategoryDetailsonselectedfilter(mdCode, sdCode, mcCode, lastSegment);
                }
            }
        });
    }

    async getCategoryDetailsonselectedfilter(mdCode, sdCode, mcCode, scCode) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        var url = window.location.href;
        var parts = url.split('/');
        this.setState({ myerrorpage: false });
        if (parts.length === 5) {
            if (mdCode === undefined) {
                mdCode = 0;
            }
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetailsonselectedfilter`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                        this.setState({ categoryList: CategoryDetails, listcount: res.data.count });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Error: ", err));
        }
        else if (parts.length === 6) {
            if (sdCode === undefined) {
                sdCode = 0;
            }

            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, sd_code: sdCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetailsonselectedfilter`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                        this.setState({ categoryList: CategoryDetails, listcount: res.data.count });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Error: ", err));
        }
        else if (parts.length === 7) {
            if (mcCode === undefined) {
                mcCode = 0;
            }
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, sd_code: sdCode, mc_code: mcCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetailsonselectedfilter`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                        this.setState({ categoryList: CategoryDetails, listcount: res.data.count });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Error: ", err));
        }
        else if (parts.length === 8) {
            if (scCode === undefined) {
                scCode = 0;
            }
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, sd_code: sdCode, mc_code: mcCode, sc_code: scCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetailsonselectedfilter`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
                        this.setState({ categoryList: CategoryDetails, listcount: res.data.count });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Error: ", err));
        }
    }

    handleChange(e) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const value = [];
        const brandvalue = [];
        const diettyprvalue = [];
        var chks = document.getElementsByName('sc_checked_name');
        var chks_brand = document.getElementsByName('brand_checked_name');
        var chks_diet = document.getElementsByName('diet_type_checked_name');

        if (chks_diet !== null && chks_diet !== undefined) {
            for (var k = 0; k < chks_diet.length; k++) {
                if (chks_diet[k].checked) {
                    diettyprvalue.push(chks_diet[k].value);
                }
            }
        }

        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                value.push(chks[i].value);
            }
        }

        for (var j = 0; j < chks_brand.length; j++) {
            if (chks_brand[j].checked) {
                brandvalue.push(chks_brand[j].value);
            }
        }

        var url = window.location.href;
        var parts = url.split('/');
        var lastSegment = parts.pop() || parts.pop();
        var md_code_pr = 0;
        var sd_code_pr = 0;
        var mc_code_pr = 0;
        var sc_code_pr = 0;
        if (parts.length === 4) {
            md_code_pr = lastSegment;
        }
        if (parts.length === 5) {
            // md_code_pr = parts[4];
            sd_code_pr = lastSegment;
        }
        if (parts.length === 6) {
            // md_code_pr = parts[4];
            // sd_code_pr = parts[5];
            mc_code_pr = lastSegment;
        }
        if (parts.length === 7) {
            // md_code_pr = parts[4];
            // sd_code_pr = parts[5];
            // mc_code_pr = parts[6];
            sc_code_pr = lastSegment;
        }

        // componentDidMount()
        if (value.length > 0 || brandvalue.length > 0 || diettyprvalue.length > 0) {
            const jsonValue = {
                category_custfilter: this.state.category_custfilter,
                scId: value, brand_name: brandvalue, diet_type: diettyprvalue, user_unique_id: localStorage.getItem('user_auth_key'),
                min_price: this.state.value.min, max_price: this.state.value.max, md_code_pr: md_code_pr,
                sd_code_pr: sd_code_pr, mc_code_pr: mc_code_pr, sc_code_pr: sc_code_pr
            };
            axiosInstance
                .post(`getCompatibleProduct`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                        this.setState({ categoryList: CategoryDetails, listcount: res.data.count });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });

                })
                .catch((err) => console.log("Error: ", err));
        }

        else if (value.length === 0 && brandvalue.length === 0 && diettyprvalue.length === 0) {
            if (this.state.value.min !== this.state.price_valueaa_min || this.state.value.max !== this.state.price_valueaa_max) {
                const jsonValue = {
                    category_custfilter: this.state.category_custfilter,
                    scId: [], brand_name: [], diet_type: [], user_unique_id: localStorage.getItem('user_auth_key'),
                    min_price: this.state.value.min, max_price: this.state.value.max, md_code_pr: md_code_pr,
                    sd_code_pr: sd_code_pr, mc_code_pr: mc_code_pr, sc_code_pr: sc_code_pr
                };
                axiosInstance
                    .post(`getCompatibleProduct`, jsonValue)
                    .then((res) => {
                        if (res.data.status === "success" && res.data.statusCode === "200") {
                            let CategoryValues = res.data.category_details;
                            let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                            this.setState({ categoryList: CategoryDetails, listcount: res.data.count });
                            let spinner = 'loading-overlay hide-content';
                            this.setState({ spinner });
                        }
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });

                    })
                    .catch((err) => console.log("Error: ", err));
            }
            else {
                // this.componentDidMount();
                this.callbackgetCategoryDetailsonselectedfilter(this.state.category_custfilter);
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            }
        }
        else {
            this.callbackgetCategoryDetailsonselectedfilter(this.state.category_custfilter);
        }
    }

    movetobasket = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        var total_weight = '';
        var total_price = '';
        var total_clicks = '';
        if (this.state.total_weight === '') {
            total_weight = this.state.prod_quantity;
        }
        else {
            total_weight = this.state.total_weight;
        }
        if (this.state.total_price === '') {
            total_price = this.state.selling_price;
        }
        else {
            total_price = this.state.total_price;
        }
        if (this.state.total_clicks === undefined) {
            total_clicks = 1;
        }
        else {
            total_clicks = this.state.total_clicks;
        }
        const jsonValue = {
            prod_id: this.state.prod_idd,
            prod_quantity: total_clicks,
            prod_price: this.state.selling_price,
            prod_weight: this.state.prod_quantity,
            prod_totalweight: total_weight,
            prod_totalprice: total_price,
            // prod_totalprice:total_price,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`inserttobasket`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.props.onIncrement();
                    let cart_count = res.data.cart_count;
                    this.setState({ cart_count: cart_count });
                    NotificationManager.success(res.data.quantity + ' X ' + res.data.weight + ' ' + res.data.uom + ' added sucessfully', res.data.pname, 200);
                    this.onCloseModal();
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
            })
            .catch((err) => console.log("Login: ", err));
    }

    basketIncrementItem(prod_id, prodcode, prodprice, prodweight) {
        this.productaddtobasket(prod_id, prodcode, prodprice, prodweight);
    }

    basketDecreaseItem(prod_id, prodcode, prodprice, prodweight, cart_id) {
        let qty_value = document.getElementById(prodcode).value;
        if (qty_value > 1) {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            var quantity = 1;
            var total_weight = quantity * prodweight;
            var total_price = prodprice * quantity;
            var final_total = parseFloat(total_price).toFixed(2);
            const jsonValue = {
                prod_id: prod_id,
                prod_quantity: 1,
                prod_price: prodprice,
                prod_weight: prodweight,
                prod_totalweight: total_weight,
                prod_totalprice: final_total,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
                .post(`removebasketqty`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        this.addtobasketrefresh();
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
        else {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            const jsonValue = {
                cart_id: cart_id,
                user_unique_id: localStorage.getItem('user_auth_key')
            };

            axiosInstance
                .post(`removeCartDetails`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        this.props.onIncrement();
                        this.addtobasketrefresh();
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
    }

    productaddtobasket(prod_id, prodcode, prodprice, prodweight) {
        let prod_quantity = 1;
        let tol_prod_quantity = prod_quantity * prodweight;
        let tol_selling_price = prod_quantity * prodprice;
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            prod_id: prod_id,
            prod_price: prodprice,
            prod_quantity: prod_quantity,
            prod_weight: prodweight,
            prod_totalweight: tol_prod_quantity,
            prod_totalprice: tol_selling_price,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`inserttobasket`, jsonValue)
            .then((res) => {

                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.props.onIncrement();
                    this.addtobasketrefresh();
                    NotificationManager.success(res.data.quantity + ' X ' + res.data.weight + ' ' + res.data.uom + ' added sucessfully', res.data.pname, 200);
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
            })
            .catch((err) => console.log("Login: ", err));
    }

    addtobasketrefresh() {
        const value = [];
        const brandvalue = [];
        const diettyprvalue = [];
        var chks = document.getElementsByName('sc_checked_name');
        var chks_brand = document.getElementsByName('brand_checked_name');
        var chks_diet = document.getElementsByName('diet_type_checked_name');

        if (chks_diet !== null && chks_diet !== undefined) {
            for (var k = 0; k < chks_diet.length; k++) {
                if (chks_diet[k].checked) {
                    diettyprvalue.push(chks_diet[k].value);
                }
            }
        }
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                value.push(chks[i].value);
            }
        }
        for (var j = 0; j < chks_brand.length; j++) {
            if (chks_brand[j].checked) {
                brandvalue.push(chks_brand[j].value);
            }
        }

        var url = window.location.href;
        var parts = url.split('/');
        var lastSegment = parts.pop() || parts.pop();
        var md_code_pr = 0;
        var sd_code_pr = 0;
        var mc_code_pr = 0;
        var sc_code_pr = 0;
        var mdCode = '';
        var sdCode = '';
        var mcCode = '';
        if (parts.length === 4) {
            md_code_pr = lastSegment;
        }
        if (parts.length === 5) {
            md_code_pr = parts[4];
            sd_code_pr = lastSegment;
        }
        if (parts.length === 6) {
            md_code_pr = parts[4];
            sd_code_pr = parts[5];
            mc_code_pr = lastSegment;
        }
        if (parts.length === 7) {
            md_code_pr = parts[4];
            sd_code_pr = parts[5];
            mc_code_pr = parts[6];
            sc_code_pr = lastSegment;
        }

        // componentDidMount()
        if (value.length > 0 || brandvalue.length > 0 || diettyprvalue.length > 0) {
            const jsonValue = {
                category_custfilter: this.state.category_custfilter,
                scId: value, brand_name: brandvalue, diet_type: diettyprvalue, user_unique_id: localStorage.getItem('user_auth_key'),
                min_price: this.state.value.min, max_price: this.state.value.max, md_code_pr: md_code_pr,
                sd_code_pr: sd_code_pr, mc_code_pr: mc_code_pr, sc_code_pr: sc_code_pr
            };
            axiosInstance
                .post(`getCompatibleProduct`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });

                        this.setState({ categoryList: CategoryDetails, listcount: res.data.count });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });

                })
                .catch((err) => console.log("Error: ", err));
        }

        else if (value.length === 0 && brandvalue.length === 0 && diettyprvalue === 0) {
            if (this.state.value.min !== this.state.price_valueaa_min || this.state.value.max !== this.state.price_valueaa_max) {
                const jsonValue = {
                    category_custfilter: this.state.category_custfilter,
                    scId: [], brand_name: [], diet_type: [], user_unique_id: localStorage.getItem('user_auth_key'),
                    min_price: this.state.value.min, max_price: this.state.value.max, md_code_pr: md_code_pr,
                    sd_code_pr: sd_code_pr, mc_code_pr: mc_code_pr, sc_code_pr: sc_code_pr
                };
                axiosInstance
                    .post(`getCompatibleProduct`, jsonValue)
                    .then((res) => {
                        if (res.data.status === "success" && res.data.statusCode === "200") {
                            let CategoryValues = res.data.category_details;
                            let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
                            this.setState({ categoryList: CategoryDetails, listcount: res.data.count });
                        }
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });

                    })
                    .catch((err) => console.log("Error: ", err));
            }
            else {
                this.setState({
                    mainDepartment: "", subDepartment: "", mainCategory: "", md_url_param: "", sd_url_param: "", mc_url_param: "",
                    sc_url_param: "", md_url_code: "", sd_url_code: "", mc_url_code: "", sc_url_code: ""
                });

                if (parts.length === 4) {
                    localStorage.removeItem('prevsdCode');
                    localStorage.removeItem('prevmcCode');
                    localStorage.setItem('prevmdCode', lastSegment);
                    if (lastSegment !== "") {
                        this.getCategoryDetailsrefresh(lastSegment);
                    }
                }
                else if (parts.length === 5) {
                    mdCode = parts[4];
                    localStorage.removeItem('prevmdCode');
                    localStorage.removeItem('prevmcCode');
                    localStorage.setItem('prevsdCode', lastSegment);
                    if (lastSegment !== "") {
                        this.getCategoryDetailsrefresh(mdCode, lastSegment);
                    }
                }
                else if (parts.length === 6) {
                    mdCode = parts[4];
                    sdCode = parts[5];
                    localStorage.removeItem('prevmdCode');
                    localStorage.removeItem('prevsdCode');
                    localStorage.setItem('prevmcCode', lastSegment);
                    if (lastSegment !== "") {
                        this.getCategoryDetailsrefresh(mdCode, sdCode, lastSegment);
                    }
                }
                else if (parts.length === 7) {
                    mdCode = parts[4];
                    sdCode = parts[5];
                    mcCode = parts[6];
                    localStorage.removeItem('prevmdCode');
                    localStorage.removeItem('prevsdCode');
                    localStorage.removeItem('prevmcCode');
                    localStorage.setItem('prevscCode', lastSegment);
                    if (lastSegment !== "") {
                        this.getCategoryDetailsrefresh(mdCode, sdCode, mcCode, lastSegment);
                    }
                }
            }
        }

        else {
            this.setState({
                mainDepartment: "", subDepartment: "", mainCategory: "", md_url_param: "", sd_url_param: "", mc_url_param: "",
                sc_url_param: "", md_url_code: "", sd_url_code: "", mc_url_code: "", sc_url_code: ""
            });

            if (parts.length === 4) {
                localStorage.removeItem('prevsdCode');
                localStorage.removeItem('prevmcCode');
                localStorage.setItem('prevmdCode', lastSegment);
                if (lastSegment !== "") {
                    this.getCategoryDetailsrefresh(lastSegment);
                }
            }
            else if (parts.length === 5) {
                // console.log(parts[5]);
                mdCode = parts[4];
                localStorage.removeItem('prevmdCode');
                localStorage.removeItem('prevmcCode');
                localStorage.setItem('prevsdCode', lastSegment);
                if (lastSegment !== "") {
                    this.getCategoryDetailsrefresh(mdCode, lastSegment);
                }
            }
            else if (parts.length === 6) {
                mdCode = parts[4];
                sdCode = parts[5];
                localStorage.removeItem('prevmdCode');
                localStorage.removeItem('prevsdCode');
                localStorage.setItem('prevmcCode', lastSegment);
                if (lastSegment !== "") {
                    this.getCategoryDetailsrefresh(mdCode, sdCode, lastSegment);
                }
            }
            else if (parts.length === 7) {
                mdCode = parts[4];
                sdCode = parts[5];
                mcCode = parts[6];
                localStorage.removeItem('prevmdCode');
                localStorage.removeItem('prevsdCode');
                localStorage.removeItem('prevmcCode');
                localStorage.setItem('prevscCode', lastSegment);
                if (lastSegment !== "") {
                    this.getCategoryDetailsrefresh(mdCode, sdCode, mcCode, lastSegment);
                }
            }
        }
    }


    getCategoryDetailsrefresh(mdCode, sdCode, mcCode, scCode) {
        var url = window.location.href;
        var parts = url.split('/');
        if (parts.length === 5) {
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetails`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
                        let seotitle = res.data.mdTitle;
                        let seodescription = res.data.mdDes;

                        this.setState({
                            categoryList: CategoryDetails, mainDepartment: res.data.mdName, mdCode: res.data.mdCode,
                            listcount: res.data.count,
                            md_url_param: res.data.md_url_param, md_url_code: res.data.md_url_code, seotitle: seotitle, seodescription: seodescription
                        });
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
        else if (parts.length === 6) {
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, sd_code: sdCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetails`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
                        let seotitle = res.data.sdTitle;
                        let seodescription = res.data.sdDes;

                        this.setState({
                            categoryList: CategoryDetails, mainDepartment: res.data.mdName, mdCode: res.data.mdCode, subDepartment: res.data.sdName, sdCode: res.data.sdCode, listcount: res.data.count,
                            md_url_param: res.data.md_url_param, md_url_code: res.data.md_url_code,
                            sd_url_param: res.data.sd_url_param, sd_url_code: res.data.sd_url_code, seotitle: seotitle, seodescription: seodescription
                        });
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
        else if (parts.length === 7) {
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, sd_code: sdCode, mc_code: mcCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetails`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
                        let seotitle = res.data.mcTitle;
                        let seodescription = res.data.mcDes;

                        this.setState({
                            categoryList: CategoryDetails, mainDepartment: res.data.mdName, mdCode: res.data.mdCode, subDepartment: res.data.sdName, sdCode: res.data.sdCode, mainCategory: res.data.mcName, mcCode: res.data.mcCode, listcount: res.data.count,
                            md_url_param: res.data.md_url_param, md_url_code: res.data.md_url_code,
                            sd_url_param: res.data.sd_url_param, sd_url_code: res.data.sd_url_code,
                            mc_url_param: res.data.mc_url_param, mc_url_code: res.data.mc_url_code, seotitle: seotitle, seodescription: seodescription
                        });
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
        else if (parts.length === 8) {
            const jsonValue = { category_custfilter: this.state.category_custfilter, md_code: mdCode, sd_code: sdCode, mc_code: mcCode, sc_code: scCode, urllength: parts.length, user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
                .post(`getCategoryDetails`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let CategoryValues = res.data.category_details;
                        let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
                        let seotitle = res.data.scTitle;
                        let seodescription = res.data.scDes;

                        this.setState({
                            categoryList: CategoryDetails, mainDepartment: res.data.mdName, mdCode: res.data.mdCode, subDepartment: res.data.sdName, sdCode: res.data.sdCode, mainCategory: res.data.mcName, mcCode: res.data.mcCode, listcount: res.data.count,
                            pageCount: Math.ceil(CategoryDetails.length / this.state.perPage),
                            md_url_param: res.data.md_url_param, md_url_code: res.data.md_url_code,
                            sd_url_param: res.data.sd_url_param, sd_url_code: res.data.sd_url_code,
                            mc_url_param: res.data.mc_url_param, mc_url_code: res.data.mc_url_code,
                            sc_url_param: res.data.sc_url_param, sc_url_code: res.data.sc_url_code, seotitle: seotitle, seodescription: seodescription
                        });
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
    }



    onOpenRequestModal = () => {
        this.setState({
            product_name: '',
            quantity: 1,
            prod_unit: '',
            prod_unit_id: '',
            prod_unit_qty: '',
            requestusername: '',
            request_mobile_no: '',
            requestopen: true
        });

    };

    onCloseRequestModal = () => {
        this.setState({ requestopen: false });
    };

    validateFunction() {
        let product_nameError = "";
        let quantityError = "";
        let prod_unit_idError = "";
        let prod_unit_qtyError = "";
        let requestusernameError = "";
        let request_mobileError = "";

        if (!this.state.product_name) {
            product_nameError = "Product Name required";
        }

        if (!this.state.quantity) {
            quantityError = "Enter Quantity required";
        }

        if (!this.state.prod_unit_id) {
            prod_unit_idError = "Select the UOM";
        }

        if (!this.state.prod_unit_qty) {
            prod_unit_qtyError = "Enter Size of the product required";
        }

        if (localStorage.getItem('user_log_status') === "false") {
            if (!this.state.requestusername) {
                requestusernameError = "Username required";
            }

            if (!this.state.request_mobile_no) {
                request_mobileError = "Mobile Number Required";
            }
            if (this.state.request_mobile_no.length !== 9) {
                request_mobileError = "Your mobile number should be 9 Digit";
            }
        }

        if (product_nameError || request_mobileError || requestusernameError || quantityError || prod_unit_idError || prod_unit_qtyError) {
            this.setState({
                product_nameError,
                request_mobileError,
                requestusernameError,
                quantityError,
                prod_unit_idError,
                prod_unit_qtyError,
            });
            return false;
        }
        return true;
    }

    productrequestsubmitForm = () => {
        const isValid = this.validateFunction();
        if (isValid) {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });

            if (localStorage.getItem('user_log_status') === "false") {
                const jsonValue = {
                    product_name: this.state.product_name,
                    quantity: this.state.quantity,
                    prod_unit: this.state.prod_unit_qty + ' ' + this.state.prod_unit_id,
                    prod_unit_id: this.state.prod_unit_id,
                    prod_unit_qty: this.state.prod_unit_qty,
                    mobile_no: this.state.request_mobile_no,
                    name: this.state.requestusername,
                    yb_user_id: localStorage.getItem('user_auth_key')
                };
                axiosInstance
                    .post(`insertrequestproduct`, jsonValue)
                    .then((res) => {
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                        if (res.data.status === "success" && res.data.statusCode === "200") {
                            this.props.onIncrement();
                            let cart_count = res.data.cart_count;
                            this.setState({ cart_count: cart_count });
                            NotificationManager.success('Request Sent Sucessfully', 'Success', 200);
                            this.onCloseRequestModal();
                        }
                        else if (res.data.status === "fail" && res.data.statusCode === "201") {
                            NotificationManager.error('Something went wrong', 'Error', 500);
                        }
                    })
                    .catch((err) => console.log("Login: ", err));
            }
            else {
                const jsonValue = {
                    product_name: this.state.product_name,
                    quantity: this.state.quantity,
                    prod_unit: this.state.prod_unit_qty + ' ' + this.state.prod_unit_id,
                    prod_unit_id: this.state.prod_unit_id,
                    prod_unit_qty: this.state.prod_unit_qty,
                    yb_user_id: localStorage.getItem('user_auth_key'),
                };
                axiosInstance
                    .post(`insertrequestproduct`, jsonValue)
                    .then((res) => {
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                        if (res.data.status === "success" && res.data.statusCode === "200") {
                            this.props.onIncrement();
                            let cart_count = res.data.cart_count;
                            this.setState({ cart_count: cart_count });
                            NotificationManager.success('Request Sent Sucessfully', 'Success', 200);
                            this.onCloseRequestModal();
                        }
                        else if (res.data.status === "fail" && res.data.statusCode === "201") {
                            NotificationManager.error('Something went wrong', 'Error', 500);
                        }
                    })
                    .catch((err) => console.log("Login: ", err));
            }
        }
    }


    pricefun_filter = async (ttt) => {
        // console.log(ttt);
        this.setState({ value: ttt })
        let min_prz = parseFloat(ttt.min).toFixed(2);
        let max_prz = parseFloat(ttt.max).toFixed(2);

        if (max_prz >= this.state.price_valueaa_max) {
            max_prz = this.state.price_valueaa_max;
        }
        if (min_prz <= this.state.price_valueaa_min) {
            min_prz = this.state.price_valueaa_min;
        }
        let prizee = {
            min: parseFloat(min_prz),
            max: parseFloat(max_prz)
        };
        await this.setState({ value: prizee })
    }

    clearpricezfilter = () => {
        let prizee = {
            min: parseFloat(this.state.price_valueaa_min),
            max: parseFloat(this.state.price_valueaa_max)
        };
        this.setState({ value: prizee })
        setTimeout(function () {
            this.handleChange(prizee);
        }.bind(this), 100);
        // await this.handleChange(prizee);
    }

    closefilterr() {
        var elements = document.getElementsByClassName('main');
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.remove('sidebar-opened');
        }
    }

    opensidebarhandel = (sssss) => {

        this.setState({
            showfiltervalues: sssss
        }, () => {
            if (this.state.showfiltervalues === "filter") {
                let ele = document.getElementsByClassName('allfiltershow')[0];
                ele.classList.add("d-block");
                ele.classList.remove("d-none");
                let ele2 = document.getElementsByClassName('sortfiltershow')[0];
                ele2.classList.add("d-none");
                ele2.classList.remove("d-block");
                // hide-content
            }
            if (this.state.showfiltervalues === "sort") {
                let ele = document.getElementsByClassName('sortfiltershow')[0];
                ele.classList.add("d-block");
                ele.classList.remove("d-none");
                let ele2 = document.getElementsByClassName('allfiltershow')[0];
                ele2.classList.add("d-none");
                ele2.classList.remove("d-block");
            }
        });
    }


    // subscription model start here

    opensubscriptionmodel = (prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces, subscribe_offer_flag, subscribe_offer_type, subscribe_offer_value) => {
        var produ_umo_cust = "" + produ_umo + "";
        if (no_of_prod_pisces !== '' && no_of_prod_pisces !== null && no_of_prod_pisces !== ' ' && no_of_prod_pisces !== undefined) {
            produ_umo_cust = "" + produ_umo + " ( approx " + no_of_prod_pisces + ")";
        }
        var subscribe_offer_text = "";
        if (subscribe_offer_flag !== 0 && subscribe_offer_flag !== null && subscribe_offer_flag !== '' && subscribe_offer_flag !== undefined) {
            if (subscribe_offer_type === 'P') {
                subscribe_offer_text = "Subscribe & save " + subscribe_offer_value + " %";
            }
            if (subscribe_offer_type === 'A') {
                subscribe_offer_text = "Subscribe & save AED " + subscribe_offer_value + "";
            }
        }

        this.setState({
            subscriptionopen: true,
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            subscription_prod_id: pId,
            subscription_prod_code: prod_code,
            Sun_quantity: 0,
            Mon_quantity: 0,
            Tue_quantity: 0,
            Wed_quantity: 0,
            Thu_quantity: 0,
            Fri_quantity: 0,
            Sat_quantity: 0,
            subscription_produ_imgurl: produ_imgurl,
            subscription_produ_category: produ_category,
            subscription_produ_name: produ_name,
            subscription_produ_brand: produ_brand,
            subscription_produ_umo: produ_umo_cust,
            subscribe_offer_text: subscribe_offer_text

        });
        setTimeout(function () {
            var tab1 = document.getElementById('subscription_tab_one');
            if (tab1 !== null && tab1 !== undefined) {
                tab1.classList.add("subscription_tab_active");
            }
            else {
                this.closesubscriptionmodel();
                this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces, subscribe_offer_flag, subscribe_offer_type, subscribe_offer_value);
            }
            var tab2 = document.getElementById('subscription_tab_two_content');
            if (tab2 !== null && tab2 !== undefined) {
                tab2.classList.add("d-none");
            }
            else {
                this.closesubscriptionmodel();
                this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces, subscribe_offer_flag, subscribe_offer_type, subscribe_offer_value);
            }
            var tab3 = document.getElementById('subscription_tab_three_content');
            if (tab3 !== null && tab3 !== undefined) {
                tab3.classList.add("d-none");
            }
            else {
                this.closesubscriptionmodel();
                this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces, subscribe_offer_flag, subscribe_offer_type, subscribe_offer_value);
            }

        }, 100);
    };

    showtabone = () => {
        document.getElementById('subscription_tab_one').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.add("d-none");
        document.getElementById('subscription_tab_three_content').classList.add("d-none");
        if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length > 0) {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }
    };
    showtabtwo = () => {
        document.getElementById('subscription_tab_one').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.add("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.add("d-none");
        if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }

    };
    showtabthree = () => {
        document.getElementById('subscription_tab_one').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.add("d-none");
        document.getElementById('subscription_tab_two_content').classList.add("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }
    };



    closesubscriptionmodel = () => {
        this.setState({
            subscriptionopen: false,
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            Sun_quantity: 0,
            Mon_quantity: 0,
            Tue_quantity: 0,
            Wed_quantity: 0,
            Thu_quantity: 0,
            Fri_quantity: 0,
            Sat_quantity: 0,
            subscription_produ_imgurl: "",
            subscription_produ_category: "",
            subscription_produ_name: "",
            subscription_produ_brand: "",
            subscription_produ_umo: "",
            subscribe_offer_text: "",
        });

    };

    closesubscriptionaddressmodel = () => {
        this.setState({
            addresflagopenpopup: false
        });
    }

    subscription_quantity = (name, value, type, id) => {

        if (type === "add") {
            let addvalue = this.state[name] + 1;
            this.setState({
                [name]: addvalue
            }, () => {
                if (name !== "biweekly_quantity" && name !== "monthly_quantity") {
                    if (this.state[name] > 0) {
                        document.getElementById(id).parentElement.classList.add("subscription_day_lable_selected");
                        document.getElementById(id).checked = true;
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        document.getElementById(id).parentElement.classList.remove("subscription_day_lable_selected");
                        document.getElementById(id).checked = false;
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }

                    if (this.state.Sun_quantity > 0 || this.state.Mon_quantity > 0 || this.state.Tue_quantity > 0 || this.state.Wed_quantity > 0
                        || this.state.Thu_quantity > 0 || this.state.Fri_quantity > 0 || this.state.Sat_quantity > 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                    this.getweeklysubscriptionvalues();
                }
                if (name === "biweekly_quantity") {
                    if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                }
                if (name === "monthly_quantity") {
                    if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                }
            });
        }
        if (type === "minus") {
            let minusvalue = this.state[name];
            if (minusvalue !== 0) {
                this.setState({
                    [name]: minusvalue - 1
                }, () => {
                    if (name !== "biweekly_quantity" && name !== "monthly_quantity") {
                        if (this.state[name] > 0) {
                            document.getElementById(id).parentElement.classList.add("subscription_day_lable_selected");
                            document.getElementById(id).checked = true;
                        }
                        else {
                            document.getElementById(id).parentElement.classList.remove("subscription_day_lable_selected");
                            document.getElementById(id).checked = false;
                        }

                        if (this.state.Sun_quantity > 0 || this.state.Mon_quantity > 0 || this.state.Tue_quantity > 0 || this.state.Wed_quantity > 0
                            || this.state.Thu_quantity > 0 || this.state.Fri_quantity > 0 || this.state.Sat_quantity > 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                        this.getweeklysubscriptionvalues();
                    }
                    if (name === "biweekly_quantity") {
                        if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                    }
                    if (name === "monthly_quantity") {
                        if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                    }
                });
            }
        }
    };

    getweeklysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.classList.remove("subscription_day_lable_selected")
            }
        }

        var quantity_values = document.getElementsByClassName('subscription_weekly_quantity_value');
        const weekly_quantity = [];

        for (let j = 0; j < quantity_values.length; j++) {
            if (quantity_values[j].value > 0) {
                // console.log(quantity_values[j].value);
                weekly_quantity.push(quantity_values[j].value);
            }
        }

        this.setState({
            weekly_selected_date: value, weekly_selected_quntity: weekly_quantity
        }, () => {
            // console.log(this.state.biweekly_selected_date.length);
            if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length > 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });

    }

    getbiweeklysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable-biweekly');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.firstChild.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            }
        }

        this.setState({
            biweekly_selected_date: value
        }, () => {
            if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });
    }

    getmonthlysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable-monthly');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.firstChild.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            }
        }

        this.setState({
            monthly_selected_date: value
        }, () => {
            if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });
    }

    clearselectedsubscription = (value) => {
        // alert(value);
        var chks = document.getElementsByClassName('' + value + '');
        // console.log(chks);
        for (var i = 0; i < chks.length; i++) {
            chks[i].checked = false;
            chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            // console.log(chks[i].checked)
        }
        this.setState({
            showsubscriptionerrormessage: false,
            subscription_errormsg: ""
        });
        if (value === "visually-hidden-lable-biweekly") {
            this.getbiweeklysubscriptionvalues();
        }
        else if (value === "visually-hidden-lable-monthly") {
            this.getmonthlysubscriptionvalues();
        }

    }


    submit_subscription = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({
            showsubscriptionerrormessage: false,
            subscription_errormsg: ""
        });

        if (this.state.weekly_selected_date.length === 0 && this.state.weekly_selected_quntity.length !== 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabone();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.biweekly_selected_date.length === 0 && this.state.biweekly_quantity !== 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabtwo();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.monthly_selected_date.length === 0 && this.state.monthly_quantity !== 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabthree();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length === 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabone();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity === 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabtwo();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity === 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabthree();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else {
            const jsonValue = {
                weekly_selected_date: this.state.weekly_selected_date,
                weekly_quantity: this.state.weekly_selected_quntity,
                biweekly_selected_date: this.state.biweekly_selected_date,
                biweekly_quantity: this.state.biweekly_quantity,
                monthly_selected_date: this.state.monthly_selected_date,
                monthly_quantity: this.state.monthly_quantity,
                subscription_prod_id: this.state.prod_unit,
                subscription_prod_code: this.state.subscription_prod_code,
                user_unique_id: localStorage.getItem('user_auth_key'),
            };
            axiosInstance
                .post(`insersubscriptionproduct`, jsonValue)
                .then((res) => {

                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Product Subscribed Sucessfully', 'Success', 200);
                        this.closesubscriptionmodel();
                        this.addtobasketrefresh();
                        if (res.data.address_flag === 0) {
                            this.setState({ addresflagopenpopup: true });
                        }
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "201") {
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                        NotificationManager.error('Something went wrong', 'Error', 500);
                    }
                })
                .catch((err) => console.log("Login: ", err));
        }
    }

    handleSelectChange = (option, action) => {
        let inputName = action.name;
        let inputValue = option.value;
        if (inputValue === 'Grams') {
            this.setState({ prod_unit_qty: "500" });
        }
        else {
            this.setState({ prod_unit_qty: "1" });
        }

        this.setState({ [inputName]: inputValue });
    };

    render() {

        if (this.state.myerrorpage) {
            return (
                <main className="main">
                    <Helmet>
                        <title>Free Online Grocery delivery at best Price in dubai | Yalla Basket</title>
                        <meta name="description" content="Yalla Basket is an online grocery delivery service. Fresh fruits, vegetables and groceries are delivered to your doorstep. We stand for the best customer service. Try us now" />
                    </Helmet>
                    {/* <MetaTags>
                    <title>Free Online Grocery delivery at best Price in dubai | Yalla Basket</title>
                    <meta name="description" content="Yalla Basket is an online grocery delivery service. Fresh fruits, vegetables and groceries are delivered to your doorstep. We stand for the best customer service. Try us now" />
                    <meta property="og:title" content="Yalla Basket" />
                    <meta property="og:image" content="/assets/img/symbol.png" />
                </MetaTags> */}
                    <div className="container">
                        <div className="row text-center min_hg_500">
                            <div className="col-lg-12 card-text">
                                <h3>Looking for something?</h3>
                                <h5 className="errormsg_dip_est">We're sorry. The Web address you entered is not a functioning page on our site. <br></br>
                                Go to <Link to="/"> Home</Link> Page
                            </h5>

                            </div>
                        </div>
                    </div>
                </main>
            )
        }
        else {
            return (
                <main className="main">
                    <Helmet>
                        {/* { this.state.seotitle === "" || this.state.seotitle === null ? ( 
                    <>
                        <title>Free Online Grocery delivery at best Price in dubai | Yalla Basket</title>
                        <meta name="description" content="Yalla Basket is an online grocery delivery service. Fresh fruits, vegetables and groceries are delivered to your doorstep. We stand for the best customer service. Try us now" />
                    </>
                    ) : (
                    <>
                        <title>{this.state.seotitle}</title>
                        <meta name="description" content={this.state.seodescription} />
                    </>
                    ) } */}
                        <title>{this.state.seotitle}</title>
                        <meta name="description" content={this.state.seodescription} />
                    </Helmet>
                    {/* <MetaTags>
                    { this.state.seotitle === "" || this.state.seotitle === null ? ( 
                    <>
                        <title>Free Online Grocery delivery at best Price in dubai | Yalla Basket</title>
                        <meta name="description" content="Yalla Basket is an online grocery delivery service. Fresh fruits, vegetables and groceries are delivered to your doorstep. We stand for the best customer service. Try us now" />
                        <meta property="og:title" content="Yalla Basket" />
                        <meta property="og:image" content="/assets/img/symbol.png" />
                    </>
                    ) : (
                    <>
                        <title>{this.state.seotitle}</title>
                        <meta name="description" content={this.state.seodescription} />
                        <meta property="og:title" content="Yalla Basket" />
                        <meta property="og:image" content="/assets/img/symbol.png" />
                    </>
                    ) }
                </MetaTags> */}
                    <NotificationContainer />

                    <div className={this.state.small_spinner}>
                        <i className="porto-loading-icon"></i>
                    </div>
                    <div className={this.state.spinner}>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>

                    <nav aria-label="breadcrumb" className="breadcrumb-nav">

                        <div className="container">
                            <div className="hide_ipad_filter">
                                <p className="sidebar-toggle" onClick={() => this.opensidebarhandel('filter')}> <img className="m_filter_img" src="/assets/img/m-filter.png" alt="" />&nbsp; &nbsp;  Filter</p>
                                <p className="sidebar-toggle short-product-toggle" onClick={() => this.opensidebarhandel('sort')}> <img className="m_filter_img" src="/assets/img/m-sort.png" alt="" />&nbsp; &nbsp;  Sort</p>
                            </div>

                            <ol className="breadcrumb">
                                {this.state.mainDepartment !== "" && this.state.subDepartment === "" ? (
                                    <>
                                        <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                                        <li className="breadcrumb-item active"><Link to={{ pathname: '/' + this.state.md_url_code + '/' + this.state.md_url_param }} ><span>{this.state.mainDepartment}</span></Link></li>
                                    </>
                                ) : this.state.mainDepartment !== "" && this.state.subDepartment !== "" && this.state.mainCategory === "" ? (
                                    <>
                                        <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                                        <li className="breadcrumb-item"><Link to={{ pathname: '/' + this.state.md_url_code + '/' + this.state.md_url_param }} ><span>{this.state.mainDepartment}</span></Link></li>
                                        <li className="breadcrumb-item active"><Link to={{ pathname: '/' + this.state.md_url_code + '/' + this.state.sd_url_code + '/' + this.state.sd_url_param }} ><span>{this.state.subDepartment}</span></Link></li>
                                    </>
                                ) : this.state.mainDepartment !== "" && this.state.subDepartment !== "" && this.state.mainCategory !== "" ? (
                                    <>
                                        <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                                        <li className="breadcrumb-item"><Link to={{ pathname: '/' + this.state.md_url_code + '/' + this.state.md_url_param }} ><span>{this.state.mainDepartment}</span></Link></li>
                                        <li className="breadcrumb-item"><Link to={{ pathname: '/' + this.state.md_url_code + '/' + this.state.sd_url_code + '/' + this.state.sd_url_param }} ><span>{this.state.subDepartment}</span></Link></li>
                                        <li className="breadcrumb-item active"><Link to={{ pathname: '/' + this.state.md_url_code + '/' + this.state.sd_url_code + '/' + this.state.mc_url_code + '/' + this.state.mc_url_param }} ><span>{this.state.mainCategory}</span></Link></li>
                                    </>
                                ) : (
                                    <>
                                        <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                                    </>
                                )}
                            </ol>
                        </div>

                        <div className="hide_mobile_filter">
                            <p className="sidebar-toggle" onClick={() => this.opensidebarhandel('filter')}> <img className="m_filter_img" src="/assets/img/m-filter.png" alt="" />&nbsp; &nbsp;  Filter</p>
                            <p className="sidebar-toggle short-product-toggle" onClick={() => this.opensidebarhandel('sort')}> <img className="m_filter_img" src="/assets/img/m-sort.png" alt="" />&nbsp; &nbsp;  Sort</p>
                        </div>


                    </nav>
                    <div className="container">

                        <div className="row">
                            {this.state.listcount !== 0 ? (
                                <div className="col-lg-9 col-xl-5col-4 bg-white">
                                    <nav className="toolbox mb-1 mt-2">
                                        <div className="toolbox-item toolbox-show filtershort_margright res-d-none">
                                            <select onChange={this.onChangeHandler} name="category_custfilter" className="form-control form-control-sm category_custfilter" id="category_custfilter" value={this.state.category_custfilter}>
                                                <option value="POPULARITY"> Popularity </option>
                                                <option value="LTH"> Price - Low to High </option>
                                                <option value="HTL"> Price - High to Low </option>
                                                <option value="ATZ"> Alphabetical ( A - Z )</option>
                                                <option value="ZTA"> Alphabetical ( Z - A )</option>
                                                <option value="OFFPROD"> % Offer - Products </option>
                                            </select>

                                        </div>
                                        <div className="toolbox-item toolbox-show">
                                            {this.state.listcount < this.state.visible ? (
                                                <label>Showing {this.state.listcount} of {this.state.listcount} results</label>
                                            ) : (
                                                <label>Showing {this.state.visible} of {this.state.listcount} results</label>
                                            )}

                                        </div>
                                    </nav>

                                    <div className="cus-product-row">
                                        {/* {paginationElement} */}
                                        {/* {this.state.elements} */}


                                        {
                                            this.state.categoryList.map(value => {
                                                return (
                                                    <div key={value.prod_id} className="cus-div-lay-four">
                                                        <a className="cus-div-a" href="#">
                                                            <div className="cus-div-sty">
                                                                <div className="cus-head-div">
                                                                    <div className="cus-img-div">
                                                                        <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                                            {value.outof_stock_flag === '1' ? (
                                                                                <img className="cus-product-img" src={value.produ_imgurl} alt={value.prod_name} />
                                                                            ) : (
                                                                                <img className="cus-product-img outofstockflagon" src={value.produ_imgurl} alt={value.prod_name} />
                                                                            )}
                                                                        </Link>
                                                                    </div>

                                                                    {value.subacribed_prod === true || value.subacribed_prod === "true" ? (
                                                                        <div class="cus-subscribed-sty cus-subscribed-sty-two">
                                                                            <div class="cus-subscribed-txt">Subscribed</div>
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )}

                                                                    <div className="cus-title-div">
                                                                        <div className="cus-title-main">
                                                                            <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                                                {value.prod_name}
                                                                            </Link>
                                                                        </div>
                                                                        <div className="cus-title-secondary">{value.scName}</div>
                                                                    </div>
                                                                    <div className="cus-price">
                                                                        <span> UOM : {value.prod_quantity} {value.unit_id}</span>
                                                                        <div>
                                                                            {(value.offer_flag === "1" && value.redeemed_offers_flag === 1) || (value.offer_flag === "0" && value.redeemed_offers_flag === 1) ? (
                                                                                <>
                                                                                    <span className="old-price">Rs. {value.original_amt}</span>
                                                                                    <span className="product-price">Rs. {value.selling_price}</span>
                                                                                </>
                                                                            ) : (value.offer_flag === "1" && value.redeemed_offers_flag === 0) ? (
                                                                                <>
                                                                                    <span className="old-price">Rs. {value.original_amt}</span>
                                                                                    <span className="product-price">Rs. {value.selling_price}</span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {value.market_price !== "0.00" ? (
                                                                                        <span className="old-price">Rs. {value.market_price}</span>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                    <span className="product-price">Rs. {value.selling_price}</span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    {value.outof_stock_flag === '1' ? (
                                                                        <>
                                                                            {value.cart_count > 0 ? (
                                                                                <>
                                                                                
                                                                                    <div className="buttons_slots mt-1">
                                                                                        <div className="input-group mob-p-l">
                                                                                            <span className="input-group-btn">
                                                                                                <button type="button" className="btn btn-number product-custom-qty-m" onClick={() => this.basketDecreaseItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity, value.cart_id)} aria-label="Add one" data-direction="1">
                                                                                                    <span className="fa fa-minus"></span>
                                                                                                </button>
                                                                                            </span>
                                                                                            <input type="text" id={value.prod_code} className="form-control product-input-number" name="quantity" value={value.cart_count} readOnly={true} />
                                                                                            <span className="input-group-btn">
                                                                                                <button type="button" className="btn btn-number product-custom-qty-a" onClick={() => this.basketIncrementItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} aria-label="Subtract one" data-direction="-1">
                                                                                                    <span className="fa fa-plus"></span>
                                                                                                </button>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="cus-subscribe">
                                                                                        {localStorage.getItem('user_log_status') === "true" ? (
                                                                                            <>
                                                                                                {value.subacribed_prod === "true" ? (
                                                                                                    <Link to="/Subscription"> <span className="product-subscribe" > View Subscription  </span></Link>
                                                                                                ) : (
                                                                                                    <span className="product-subscribe"
                                                                                                        onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                            value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <span className="product-subscribe" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            ) :
                                                                                (
                                                                                    <>

                                                                                        <div className="buttons_slots mt-1">
                                                                                            <button onClick={() => this.productaddtobasket(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} className="cart-btn">Add +</button>
                                                                                        </div>
                                                                                        <div className="cus-subscribe">
                                                                                            {localStorage.getItem('user_log_status') === "true" ? (
                                                                                                <>
                                                                                                    {value.subacribed_prod === "true" ? (
                                                                                                        <Link to="/Subscription"> <span className="product-subscribe" > View Subscription  </span></Link>
                                                                                                    ) : (
                                                                                                        <span className="product-subscribe"
                                                                                                            onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                                value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <span className="product-subscribe" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                        </>
                                                                    ) : (
                                                                        <div className="buttons_slots mt-1">
                                                                            <span className="outofstockflagcolor">Currently Out Of Stock</span>
                                                                        </div>

                                                                    )}
                                                                        
                                                                    { (value.offer_flag === '1' && value.redeemed_offers_flag === 1) || (value.offer_flag === '0' && value.redeemed_offers_flag === 1) ? (
                                                                        <div className="cus-dis-div">
                                                                            {value.redeemed_offers_type === 'A' ? (
                                                                                <span className="cus-dis-span-two">{value.redeemed_offers_value} off | Use STEALDEAL</span>
                                                                            ) : (
                                                                                <span className="cus-dis-span-two">{value.redeemed_offers_value}% off | Use STEALDEAL</span>
                                                                            )}
                                                                        </div>
                                                                    ) : (value.offer_flag === '1' && value.redeemed_offers_flag === 0) ? (
                                                                        <div className="cus-dis-div">
                                                                            {value.redeemed_offers_type === 'A' ? (
                                                                                <span className="cus-dis-span-two">{value.offer_value} off | Use STEALDEAL</span>
                                                                            ) : (
                                                                                <span className="cus-dis-span-two">{value.offer_value}% off | Use STEALDEAL</span>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                            <>
                                                                            </>
                                                                        )}
                                                                </div>
                                                                <div className="cus-div-btn-div">
                                                                    <span role="button" aria-label="Open" className="cus-hov-btn cus-hov-sty" onClick={() => this.onOpenModal(value.prod_id)} data-toggle="modal" data-target="#addCartModal">Quick View</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }


                                        {this.state.visible < this.state.categoryList.length &&
                                            // <button onClick={this.loadMore} type="button" className="load-more">Load more</button>
                                            <div className="loadmorebtn_cust_div" id="hotseller_loadmore" >
                                                <button className="loadmorecustbtn btn mb-1" onClick={() => this.loadMore()}>load more... </button>
                                            </div>
                                        }
                                        {/* <div className="col-12">
                                                    <div className="cust_stype_paginate">                                                
                                                            {paginationElement}
                                                    </div>
                                                </div> */}
                                    </div>
                                </div>
                            ) : (
                                <div className="col-lg-9 col-xl-5col-4 bg-white">
                                    <nav className="toolbox mb-1 mt-2">
                                        <div className="toolbox-item toolbox-show filtershort_margright res-d-none">
                                            <select onChange={this.onChangeHandler} name="category_custfilter" className="form-control form-control-sm category_custfilter" id="category_custfilter" value={this.state.category_custfilter}>
                                                <option value="POPULARITY"> Popularity </option>
                                                <option value="LTH"> Price - Low to High </option>
                                                <option value="HTL"> Price - High to Low </option>
                                                <option value="ATZ"> Alphabetical ( A - Z )</option>
                                                <option value="ZTA"> Alphabetical ( Z - A )</option>
                                                <option value="OFFPROD"> % Offer - Products </option>
                                            </select>
                                        </div>
                                        <div className="toolbox-item toolbox-show">
                                            {this.state.listcount < this.state.visible ? (
                                                <label>Showing {this.state.listcount} of {this.state.listcount} results</label>
                                            ) : (
                                                <label>Showing {this.state.visible} of {this.state.listcount} results</label>
                                            )}
                                        </div>
                                    </nav>
                                    <div className="row row-sm text-center cust-max-height">
                                        <div className="custom-no-product">
                                            <button onClick={() => this.onOpenRequestModal()} type="button" className="guest-shopping-btn">Request a Product</button>
                                            <p> </p>
                                            <img src="/assets/img/Spaceship-New_1_Animation_1.gif" alt="" />
                                        </div>
                                    </div>
                                </div>
                            )}


                            <aside className="sidebar-shop mb-2 col-lg-3 col-xl-5col-1 order-lg-first res-d-none">
                                <div className="sidebar-wrapper">

                                    {/* Main Category Filter */}

                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">Category</a>
                                        </h3>

                                        <div className="collapse show" id="widget-body-2">
                                            <div className="widget-body">
                                                <ul className="cat-list sidecat-lister">
                                                    {
                                                        this.state.mainvaluelist.map(values => {
                                                            return (
                                                                // <>
                                                                <li key={values.mcId}>
                                                                    <Link to={{ pathname: '/' + values.md_url_code + '/' + values.sd_url_code + '/' + values.mc_url_code + '/' + values.mcId }}>
                                                                        <span>{values.mcName} ({values.prod_total_count})</span> </Link>
                                                                </li>
                                                                // </>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Sub Category Filter */}

                                    <div className="widget cust_sub_category_widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-5" role="button" aria-expanded="true" aria-controls="widget-body-5">Shop by Category</a>
                                        </h3>

                                        <div className="collapse show" id="widget-body-5">
                                            <div className="widget-body cust_sub_category_widgetbody">
                                                <ul className="cat-list ">
                                                    {
                                                        this.state.subvaluelist.map(values => {
                                                            return (
                                                                <li key={values.scId} className="checkbox">
                                                                    <label><input type="checkbox" name="sc_checked_name" value={values.scId} onClick={this.handleChange} />{values.scName}  ({values.prod_total_count})</label>
                                                                </li>
                                                            )
                                                        })
                                                    }

                                                    {/* <li  key={values.scId} className="checkbox">
                                                            <label><input type="checkbox" value="" />{values.scName}</label>
                                                        </li> */}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Price Filter */}

                                    <div className="widget cust_sub_category_widget ">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Price Filter ( AED )</a>
                                        </h3>

                                        <div className="collapse show" id="widget-body-3">
                                            <div className="widget-body widget-body-price cust-padd_price">
                                                <InputRange
                                                    // draggableTrack
                                                    step={0.01}
                                                    maxValue={this.state.price_valueaa_max}
                                                    minValue={this.state.price_valueaa_min}
                                                    value={this.state.value}
                                                    allowSameValues={false}
                                                    onChange={value =>
                                                        this.pricefun_filter(value)}
                                                    onChangeComplete={value =>
                                                        this.handleChange(value)}
                                                />
                                            </div>
                                            <p className="clear_prz_btn" onClick={() => this.clearpricezfilter()} >Clear Price </p>
                                        </div>

                                    </div>

                                    {/* Diet type filter */}

                                    {this.state.dietvaluelist.length > 0 ? (
                                        <div className="widget cust_sub_category_widget">
                                            <h3 className="widget-title">
                                                <a data-toggle="collapse" href="#widget-body-9" role="button" aria-expanded="true" aria-controls="widget-body-6">Diet type </a>
                                            </h3>

                                            <div className="collapse show" id="widget-body-9">
                                                <div className="widget-body">
                                                    <ul className="cat-list">

                                                        {
                                                            this.state.dietvaluelist.map(values => {
                                                                return (
                                                                    <li key={values.diet_type_id} className="checkbox">
                                                                        <label><input type="checkbox" name="diet_type_checked_name" value={values.diet_type_name} onClick={this.handleChange} />{values.diet_type_name} </label>
                                                                    </li>
                                                                )
                                                            })
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <> </>
                                    )}



                                    {/* Brand Filter */}

                                    <div className="widget cust_sub_category_widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-6" role="button" aria-expanded="true" aria-controls="widget-body-6">Brand</a>
                                        </h3>

                                        <div className="collapse show" id="widget-body-6">
                                            <div className="widget-body">
                                                <ul className="cat-list">

                                                    {
                                                        this.state.brandvaluelist.map(values => {
                                                            return (
                                                                <li key={values.prod_id} className="checkbox">
                                                                    <label><input type="checkbox" name="brand_checked_name" value={values.brand_name} onClick={this.handleChange} />{values.brand_name}  ({values.prod_total_count})</label>
                                                                </li>
                                                            )
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>

                        </div>
                    </div>

                    <div>
                        {/* <button >Open modal</button> */}
                        <Modal open={this.state.requestopen} onClose={this.onCloseRequestModal} center id="requestmodel_cust">
                            <div className="container cust_stype_modal">
                                <div className="row row-sm">
                                    <form method="post" id="ProductRequestForm" className="mb-0 w-100">
                                        <div className="form-group">
                                            <label htmlFor="product_name">Product Name</label>
                                            <input type="text" onChange={this.onChangeHandler} name="product_name" value={this.state.product_name} className="form-control form-control-sm" id="product_name" placeholder="Enter Product Name" />
                                            <span className="error">
                                                {this.state.product_nameError}
                                            </span>
                                        </div>
                                        <div className="row row-sm">
                                            <div className="form-group col-6">
                                                <label htmlFor="prod_unit">Select UOM</label>
                                                {/* <input type="text" onChange={this.onChangeHandler} name="prod_unit" value={this.state.prod_unit} className="form-control form-control-sm" id="prod_unit" placeholder="Enter Size Eg.(large, small, medium... etc)" /> */}
                                                <Select
                                                    options={uom_list}
                                                    name="prod_unit_id"
                                                    onChange={this.handleSelectChange}
                                                />
                                                <span className="error">
                                                    {this.state.prod_unit_idError}
                                                </span>
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="prod_unit">Measurement</label>
                                                <input type="text" onChange={this.onChangeHandler} name="prod_unit_qty" value={this.state.prod_unit_qty} readOnly className="form-control form-control-sm" id="prod_unit_qty" placeholder="Enter Measurement Eg.(1, 100, 500)" />
                                                <span className="error">
                                                    {this.state.prod_unit_qtyError}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="quantity">Quantity</label>
                                            <input type="number" onChange={this.onChangeHandler} name="quantity" value={this.state.quantity} className="form-control form-control-sm" id="quantity" placeholder="Enter Quantity" />
                                            <span className="error">
                                                {this.state.quantityError}
                                            </span>
                                        </div>
                                        {localStorage.getItem('user_log_status') === "false" ? (
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="requestusername">Full Name</label>
                                                    <input type="text" onChange={this.onChangeHandler} name="requestusername" value={this.state.requestusername} className="form-control form-control-sm" id="requestusername" placeholder="Enter Username" />
                                                    <span className="error">
                                                        {this.state.requestusernameError}
                                                    </span>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form_mobile_num_code_lable" htmlFor="request_mobile_no">Mobile Number</label>
                                                    <input type="text" onChange={this.onChangeHandler} name="mobile_no_code" className="form-control form-control-sm form_mobile_num_code" id="mobile_no_code" value={this.state.mobile_no_code} readOnly />
                                                    <input type="text" onChange={this.onChangeHandler} name="request_mobile_no" value={this.state.request_mobile_no}
                                                        className="form-control form-control-sm form_mobile_num" id="request_mobile_no" placeholder="Enter Mobile Number"
                                                        x />
                                                    <span className="error">
                                                        {this.state.request_mobileError}
                                                    </span>
                                                </div>
                                            </>
                                        ) : (
                                            null
                                        )}
                                        <div className="form-group">
                                            <button type="button" onClick={() => this.productrequestsubmitForm()} id="submitFormHandler" className="btn btn-primary btn-block">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    <div>
                        {/* <button >Open modal</button> */}
                        <Modal open={this.state.open} onClose={this.onCloseModal} center>
                            <div className="container cust_stype_modal">
                                <div className="row row-sm">
                                    <div className="col-lg-12 col-xl-12">
                                        {this.state.productDetails_popup.map(values => (
                                            <div key={values.prod_id}>
                                                <div className="product-single-container product-single-default">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-8 product-single-gallery">
                                                            <div className="product-slider owl-carousel owl-theme" data-toggle="owl" data-owl-options="{
                                                        'items': 1,
                                                        'loop': false,
                                                        'autoplay': false,
                                                        'autoplayHoverPause': true
                                                    }">
                                                                <div className="product-slide">
                                                                    <div className="product-default">
                                                                        <figure>
                                                                            {values.outof_stock_flag === '1' ? (
                                                                                <img src={values.produ_imgurl} alt={values.prod_name} />
                                                                            ) : (
                                                                                <img className="outofstockflagon" src={values.produ_imgurl} alt="" />
                                                                            )}
                                                                            {/* <img src={values.produ_imgurl} alt="" /> */}
                                                                            {/* </a> */}
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="product-single-details">
                                                                <h1 className="product-title">{values.prod_name}</h1>
                                                                <p className="mb-1">Category: {values.scName} </p>
                                                                <p className="mb-1">UOM: {values.prod_quantity} {values.unit_id} </p>
                                                                <p className="mb-1">Brand : {values.brand_name}</p>
                                                                {values.offer_flag === '1' ? (
                                                                    <>
                                                                        <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                                            {values.market_price !== "0.00" ? (
                                                                                <>( AED {values.original_amt} )</>
                                                                            ) : (
                                                                                <> </>
                                                                            )}
                                                                        </s> <br />
                                                                            <span className="product-save-discount">You Save :&nbsp; {values.offer_value}   {values.offer_type === 'P' ? (
                                                                                <> % </>
                                                                            ) : (
                                                                                <> AED </>
                                                                            )} OFF</span>
                                                                        </p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                                            {values.market_price !== "0.00" ? (
                                                                                <>( Avg. Market Price AED {values.market_price} )</>
                                                                            ) : (
                                                                                <> </>
                                                                            )}
                                                                        </s> <br />
                                                                        </p>
                                                                    </>
                                                                )}
                                                                {/* <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                            
                                                            {values.market_price!="0.00" ? (
                                                                     <>( Avg. Market Price AED {values.market_price} )</>
                                                                ):(
                                                                    <> </>
                                                                )}
                                                            
                                                            </s> <br />
                                                        </p> */}

                                                                <div className="product-action product-all-icons mt-2">
                                                                    {values.outof_stock_flag === '1' ? (
                                                                        <>
                                                                            <div className="product-quantity">
                                                                                <input onChange={this.onChangeHandler} className="custom-quantity" data-min="1" data-max="0" type="text" name="quantity" value={this.state.clicks} readOnly={true} /><div className="quantity-selectors-container">
                                                                                    <div className="quantity-selectors">
                                                                                        <button type="button" onClick={this.IncrementItem} aria-label="Add one" data-direction="1"><span>&#43;</span></button>
                                                                                        <button type="button" onClick={this.DecreaseItem} aria-label="Subtract one" data-direction="-1"><span>&#8722;</span></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <span className="paction add-cart" onClick={this.movetobasket} title="Add to Cart">
                                                                                Add to Basket
                                                                    </span>
                                                                        </>
                                                                    ) : (
                                                                        <div className="col-lg-12 custom-s-pc-res col-md-12 col-12">
                                                                            {/* <button onClick={ () => this.productaddtobasket(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity) } className="cart-btn">Add to Basket +</button> */}
                                                                            <h4 className="outofstockflagcolor">Currently Out Of Stock</h4>

                                                                        </div>
                                                                    )}

                                                                </div>
                                                                {this.state.show === true ? (
                                                                    <div className="autocalculate">
                                                                        <p>Total UOM:&nbsp;{this.state.total_weight} {values.unit_id}</p>
                                                                        <p>Total Price :&nbsp;AED {this.state.total_price}</p>
                                                                        {/* <h3> Total UOM: {this.state.total_weight}</h3> */}<br />
                                                                        {/* <h3> Total Price : {this.state.total_price}</h3> */}
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}

                                                                <div className="product-desc">
                                                                    <p>{values.prod_info}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Popup
                            open={this.state.subscriptionopen}
                            modal
                            nested
                            closeOnDocumentClick={false}
                        >
                            {close => (
                                <div className="subsription_modal">
                                    <button className="subsription_close" onClick={() => this.closesubscriptionmodel()}>
                                        &times;
                                    </button>
                                    <div className="subsription_header"> Subscribe
                                    </div>
                                    <div className="subsription_content">
                                        {' '}
                                        <div className="ml-3 subsription_product_details_display">
                                            <div className="subsription_product_img">
                                                <img src={this.state.subscription_produ_imgurl} alt="" />
                                            </div>
                                            <div className="subsription_product_info">
                                                <span className="font-size-14"><b>{this.state.subscription_produ_name}</b></span>
                                                <span> UOM  : {this.state.subscription_produ_umo} </span>
                                                <span className="product-save-discount"> {this.state.subscribe_offer_text} </span>

                                            </div>
                                        </div>

                                        <div className="subscription_header_tab">
                                            <div className="subscription_tab" id="subscription_tab_one" onClick={() => this.showtabone()}>
                                                <p className="m-0">Weekly</p>
                                            </div>
                                            <div className="subscription_tab" id="subscription_tab_two" onClick={() => this.showtabtwo()}>
                                                <p className="m-0">Bi-Weekly</p>
                                            </div>
                                            <div className="subscription_tab" id="subscription_tab_three" onClick={() => this.showtabthree()}>
                                                <p className="m-0">Monthly</p>
                                            </div>
                                        </div>

                                        <div id="subscription_tab_one_content">
                                            <div className="display_weekly_days mb-1">
                                                <div className="subscription_name_lable_div subscription_name_lable_popup mb-1">
                                                    <span className="mr-2 subscription_name_lable"> Which Days ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup mb-1">
                                                    {
                                                        this.state.subscription_weekly.map(values => {
                                                            return (
                                                                <span key={values.subscription_weekly_id} className="subscription_weekly_days subscription_weekly_days_popup">
                                                                    <div className="subscription_day_lable_popup">
                                                                        <label for={values.subscription_weekly_id + "toggle"}>{values.week_name}</label>
                                                                        <input type="checkbox" name="subscription_weekly" id={values.subscription_weekly_id + "toggle"} value={values.subscription_weekly_id} class="visually-hidden-lable" />
                                                                    </div>
                                                                    <div className="subscription_weekly_add_minus" onClick={() => this.subscription_quantity(values.week_name + "_quantity", "1", "add", values.subscription_weekly_id + "toggle")}>
                                                                        <span className="fa fa-plus"></span>
                                                                    </div>
                                                                    <div className="subscription_weekly_quantity">
                                                                        <input readOnly className="subscription_weekly_quantity_value" type="text" name={values.week_name + "_quantity"} id={values.week_name + "_quantity"} value={this.state[values.week_name + "_quantity"]} />
                                                                    </div>
                                                                    <div className="subscription_weekly_add_minus" onClick={() => this.subscription_quantity(values.week_name + "_quantity", "1", "minus", values.subscription_weekly_id + "toggle")}>
                                                                        <span className="fa fa-minus"></span>
                                                                    </div>

                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {/* <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                    <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup">
                                                    <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                        <div className="input-group ">
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("weekly_quantity", "1", "minus")} aria-label="Add one" data-direction="1">
                                                                    <span className="fa fa-minus"></span>
                                                                </button>
                                                            </span>
                                                            <input type="text" className="form-control product-input-number product-input-number-custsty" name="weekly_quantity" value={this.state.weekly_quantity} readOnly={true} />
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("weekly_quantity", "1", "add")} aria-label="Subtract one" data-direction="-1">
                                                                    <span className="fa fa-plus"></span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div id="subscription_tab_two_content">
                                            <div className="display_weekly_days mb-1">
                                                <div className="subscription_name_lable_div subscription_name_lable_popup mb-2">
                                                    <span className="subscription_name_lable"> Which Dates ? </span>
                                                    <span className="subscription_name_lable cust_text_clear float-right text-right" onClick={() => this.clearselectedsubscription("visually-hidden-lable-biweekly")}> Clear selected </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup subscription_biweekly_content_popup mb-1">
                                                    {
                                                        this.state.subscription_biweekly.map(values => {
                                                            return (
                                                                // <span key={values.subscription_biweekly_id} className="display_biweekly_popup_content"> {values.biweekly_dates} </span>
                                                                <span key={values.subscription_biweekly_id} className="subscription_biweekly_days_popup">
                                                                    <div key={values.subscription_biweekly_id}>
                                                                        <label className="display_biweekly_popup_content" for={values.subscription_biweekly_id + "toggle_biweekly"}>{values.biweekly_dates}</label>
                                                                        <input type="radio" name="subscription_biweekly" id={values.subscription_biweekly_id + "toggle_biweekly"} value={values.subscription_biweekly_id} class="visually-hidden-lable-biweekly" onClick={this.getbiweeklysubscriptionvalues} />
                                                                    </div>
                                                                </span>

                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                    <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup">
                                                    <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                        <div className="input-group ">
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("biweekly_quantity", "1", "minus", "dummy")} aria-label="Add one" data-direction="1">
                                                                    <span className="fa fa-minus"></span>
                                                                </button>
                                                            </span>
                                                            <input type="text" className="form-control product-input-number product-input-number-custsty" name="biweekly_quantity" value={this.state.biweekly_quantity} readOnly={true} />
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("biweekly_quantity", "1", "add", "dummy")} aria-label="Subtract one" data-direction="-1">
                                                                    <span className="fa fa-plus"></span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="subscription_tab_three_content">
                                            <div className="display_weekly_days mb-1">
                                                <div className="subscription_name_lable_div subscription_name_lable_popup mb-2">
                                                    <span className="subscription_name_lable"> Which Date ? </span>
                                                    <span className="subscription_name_lable cust_text_clear float-right text-right" onClick={() => this.clearselectedsubscription("visually-hidden-lable-monthly")}> Clear selected </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup subscription_biweekly_content_popup mb-1">
                                                    {
                                                        this.state.subscription_monthly.map(values => {
                                                            return (
                                                                // <span key={values.subscription_monthly_id} className="display_monthly_popup_content"> {values.monthly_date}  </span>
                                                                <span key={values.subscription_monthly_id} className="subscription_monthly_days_popup">
                                                                    <div >
                                                                        <label className="display_monthly_popup_content" for={values.subscription_monthly_id + "toggle_monthly"}>{values.monthly_date}</label>
                                                                        <input type="radio" name="subscription_biweekly" id={values.subscription_monthly_id + "toggle_monthly"} value={values.subscription_monthly_id} class="visually-hidden-lable-monthly" onClick={this.getmonthlysubscriptionvalues} />
                                                                    </div>
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                    <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup">
                                                    <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                        <div className="input-group ">
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("monthly_quantity", "1", "minus", "dummy")} aria-label="Add one" data-direction="1">
                                                                    <span className="fa fa-minus"></span>
                                                                </button>
                                                            </span>
                                                            <input type="text" className="form-control product-input-number product-input-number-custsty" name="monthly_quantity" value={this.state.monthly_quantity} readOnly={true} />
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("monthly_quantity", "1", "add", "dummy")} aria-label="Subtract one" data-direction="-1">
                                                                    <span className="fa fa-plus"></span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.showsubscriptionerrormessage === true ? (
                                            <p className="error ml-4"> {this.state.subscription_errormsg} </p>
                                        ) : (
                                            <> </>
                                        )}
                                        <input type="hidden" value={this.subscription_prod_id} name="subscription_prod_id" />
                                        <input type="hidden" value={this.subscription_prod_code} name="subscription_prod_code" />

                                        {this.state.addtosubscribebtn === true ? (
                                            <button className="paction add-subscription float-left add-cart add-cart-custsty" title="Add to subscription"
                                                onClick={() => this.submit_subscription()}> Add to subscription </button>
                                        ) : (
                                            <button className="paction add-subscription float-left add-subscription-disabled add-cart add-cart-custsty" title="Add to subscription"
                                                disabled="true" > Add to subscription </button>
                                        )}
                                        <span className="float-right view_subscriotion_popup" title="View subscription"
                                        > <Link to="/Subscription"> View Subscription </Link> </span>
                                    </div>

                                </div>
                            )}
                        </Popup>

                        <Popup
                            open={this.state.addresflagopenpopup}
                            modal
                            nested
                            closeOnDocumentClick={false}
                        >
                            {close => (
                                <div className="subsription_modal Add_address_modal">
                                    <button className="subsription_close" onClick={() => this.closesubscriptionaddressmodel()}>
                                        &times;
                                    </button>
                                    <div className="subsription_header"> Add address info
                                    </div>
                                    <div className="subsription_content">
                                        {' '}
                                        <p className="text-center add_subscribe_address">Click the Add address button to add the address for subscription Order</p>
                                        <Link to={{ pathname: '/Ac-addressDetails' }}>
                                            <button className="btn add_subscribed_address_btn" title="Add Address">Add Address</button>
                                        </Link>
                                    </div>

                                </div>
                            )}
                        </Popup>

                    </div>

                    <div className="sidebar-overlay"></div>
                    <aside className="sidebar-product col-lg-3 col-xl-2 padding-left-lg mobile-sidebar">

                        <div className="allfiltershow">
                            <div>
                                <h4 className="close_filter" onClick={() => this.closefilterr()}>Close <i className="icon-retweet"></i></h4>
                            </div>
                            <div className="sidebar-wrapper mt-3">
                                {/* Main Category Filter */}

                                <div className="widget">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">Category</a>
                                    </h3>

                                    <div className="collapse show" id="widget-body-2">
                                        <div className="widget-body">
                                            <ul className="cat-list sidecat-lister">
                                                {
                                                    this.state.mainvaluelist.map(values => {
                                                        return (
                                                            // <>
                                                            <li key={values.mcId}>
                                                                <Link to={{ pathname: '/' + values.mc_url_code + '/' + values.md_url_param + '/' + values.sd_url_param + '/' + values.mc_url_param }}>
                                                                    <span>{values.mcName} ({values.prod_total_count})</span> </Link>
                                                            </li>
                                                            // </>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* Sub Category Filter */}

                                <div className="widget cust_sub_category_widget">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-5" role="button" aria-expanded="true" aria-controls="widget-body-5">Shop by Category</a>
                                    </h3>

                                    <div className="collapse show" id="widget-body-5">
                                        <div className="widget-body cust_sub_category_widgetbody">
                                            <ul className="cat-list">
                                                {
                                                    this.state.subvaluelist.map(values => {
                                                        return (
                                                            <li key={values.scId} className="checkbox">
                                                                <label><input type="checkbox" name="sc_checked_name" value={values.scId} onClick={this.handleChange} />{values.scName}  ({values.prod_total_count})</label>
                                                            </li>
                                                        )
                                                    })
                                                }

                                                {/* <li  key={values.scId} className="checkbox">
                                                                        <label><input type="checkbox" value="" />{values.scName}</label>
                                                                    </li> */}

                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                {/* Price Filter */}

                                <div className="widget cust_sub_category_widget ">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-4" role="button" aria-expanded="true" aria-controls="widget-body-4">Price Filter ( AED )</a>
                                    </h3>

                                    <div className="collapse show" id="widget-body-4">
                                        <div className="widget-body widget-body-price cust-padd_price">
                                            <InputRange
                                                // draggableTrack
                                                step={0.01}
                                                maxValue={this.state.price_valueaa_max}
                                                minValue={this.state.price_valueaa_min}
                                                value={this.state.value}
                                                allowSameValues={false}
                                                onChange={value =>
                                                    this.pricefun_filter(value)}
                                                onChangeComplete={value =>
                                                    this.handleChange(value)}
                                            />
                                            {/* <p className="clear_prz_btn" onClick={ () => this.clearpricezfilter() } >Clear Price </p>  */}
                                        </div>
                                        <h3 className="clearprice_style">
                                            <span role="button" onClick={() => this.clearpricezfilter()} >Clear Price</span>
                                        </h3>
                                    </div>

                                </div>

                                {/* Diet type filter */}

                                {this.state.dietvaluelist.length > 0 ? (
                                    <div className="widget cust_sub_category_widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-9" role="button" aria-expanded="true" aria-controls="widget-body-6">Diet type </a>
                                        </h3>

                                        <div className="collapse show" id="widget-body-9">
                                            <div className="widget-body">
                                                <ul className="cat-list">

                                                    {
                                                        this.state.dietvaluelist.map(values => {
                                                            return (
                                                                <li key={values.diet_type_id} className="checkbox">
                                                                    <label><input type="checkbox" name="diet_type_checked_name" value={values.diet_type_name} onClick={this.handleChange} />{values.diet_type_name} </label>
                                                                </li>
                                                            )
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <> </>
                                )}

                                {/* Brand Filter */}

                                <div className="widget cust_sub_category_widget">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Brand</a>
                                    </h3>

                                    <div className="collapse show" id="widget-body-3">
                                        <div className="widget-body">
                                            <ul className="cat-list">

                                                {
                                                    this.state.brandvaluelist.map(values => {
                                                        return (
                                                            <li key={values.prod_id} className="checkbox">
                                                                <label><input type="checkbox" name="brand_checked_name" value={values.brand_name} onClick={this.handleChange} />{values.brand_name}  ({values.prod_total_count})</label>
                                                            </li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sortfiltershow">
                            <div>
                                <h4 className="close_filter" onClick={() => this.closefilterr()}>Close <i className="icon-retweet"></i></h4>
                            </div>
                            <div className="sidebar-wrapper mt-3">
                                <div className="widget">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">Sort By Default</a>
                                    </h3>
                                    <div className="collapse show" id="widget-body-2">
                                        <div className="widget-body">
                                            <div className="filterdata_bysort">
                                                <input type="hidden" name="focus_input" id="focus_input" />
                                                <label className="filterdata_bysort_label schedule-date-label">
                                                    <input onChange={this.onChangeHandler} type="radio" id="POPULARITY" name="category_custfilter" className="custom-radio mr-2" value="POPULARITY" />
                                                    <div className="schedule-date-selector filterdata_bysort_selector">
                                                        <h4 className="mb-0 font-12 ml-4">Popularity</h4>
                                                    </div>
                                                </label>
                                                <label className="filterdata_bysort_label schedule-date-label">
                                                    <input onChange={this.onChangeHandler} type="radio" id="LTH" name="category_custfilter" className="custom-radio mr-2" value="LTH" />
                                                    <div className="schedule-date-selector filterdata_bysort_selector">
                                                        <h4 className="mb-0 font-12  ml-4">Price - Low to High</h4>
                                                    </div>
                                                </label>
                                                <label className="filterdata_bysort_label schedule-date-label">
                                                    <input onChange={this.onChangeHandler} type="radio" id="HTL" name="category_custfilter" className="custom-radio mr-2" value="HTL" />
                                                    <div className="schedule-date-selector filterdata_bysort_selector">
                                                        <h4 className="mb-0 font-12 ml-4">Price - High to Low</h4>
                                                    </div>
                                                </label>
                                                <label className="filterdata_bysort_label schedule-date-label">
                                                    <input onChange={this.onChangeHandler} type="radio" id="ATZ" name="category_custfilter" className="custom-radio mr-2" value="ATZ" />
                                                    <div className="schedule-date-selector filterdata_bysort_selector">
                                                        <h4 className="mb-0 font-12 ml-4">Alphabetical ( A - Z ) </h4>
                                                    </div>
                                                </label>
                                                <label className="filterdata_bysort_label schedule-date-label">
                                                    <input onChange={this.onChangeHandler} type="radio" id="ZTA" name="category_custfilter" className="custom-radio mr-2" value="ZTA" />
                                                    <div className="schedule-date-selector filterdata_bysort_selector">
                                                        <h4 className="mb-0 font-12 ml-4">Alphabetical ( Z - A )</h4>
                                                    </div>
                                                </label>
                                                <label className="filterdata_bysort_label schedule-date-label">
                                                    <input onChange={this.onChangeHandler} type="radio" id="OFFPROD" name="category_custfilter" className="custom-radio mr-2" value="OFFPROD" />
                                                    <div className="schedule-date-selector filterdata_bysort_selector">
                                                        <h4 className="mb-0 font-12 ml-4">% Offer - Products</h4>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </aside>
                </main>
            )
        }
    }
}

export default Category