//@types/babel-polyfill
// import 'babel-polyfill';
import React from 'react';
// import 'script!react-polyfill';
import { BrowserRouter, Route, Switch  } from "react-router-dom";
// import { browserHistory } from 'react-router';
import HomePage from './Pages/HomePage/HomePage';
import Sitemap from './Pages/Sitemap/SitemapDetails';
import ProductDetails from './Pages/ProductDetails/ProductDetails';
import CategoryDetails from './Pages/CategoryDetails/CategoryDetails';
import CartDetails from './Pages/CartDetails/CartDetails';
import SubscriptionDetails from './Pages/SubscriptionDetails/SubscriptionDetails';
import CheckoutDetails from './Pages/CheckoutDetails/CheckoutDetails';
import PlaceOrder from './Pages/CheckoutDetails/PlaceOrder';
import OrderDetails from './Pages/OrderDetails/OrderDetails';
import Orderdetailspage from './Pages/OrderDetails/Orderdetailspage';
import SearchDetails from './Pages/SearchDetails/SearchDetails';
import AcaccountDetails from './Pages/AccountDetails/Ac-accountDetails';
import AcaddressDetails from './Pages/AccountDetails/Ac-addressDetails';
import AboutusDetails from './Pages/AboutusDetails/AboutusDetails';
import AboutusDetailsphone from './Pages/AboutusDetails/AboutusDetailsphone';
import BannerproductDetails from './Pages/BannerproductDetails/BannerproductDetails';
import Terms_and_conditionsDetails from './Pages/Terms_and_conditionsDetails/Terms_and_conditionsDetails';
import PrivacypolicyDetails from './Pages/PrivacypolicyDetails/PrivacypolicyDetails';
import FAQsDetails from './Pages/FAQsDetails/FAQsDetails';
import Terms_and_conditionsDetailsphone from './Pages/Terms_and_conditionsDetails/Terms_and_conditionsDetailsphone';
import ErrorPage from './Pages/ErrorPage/Errorpage';

function App() {
  return (
    <BrowserRouter >
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/Orderdetails/:orderno" component={Orderdetailspage} />        
        <Route exact path="/Accountdetails/:pgtype" component={AcaccountDetails} />
        <Route exact path="/banneroffers/searchdetails/:bannername/:bannerid/getyallaproduct/bannersproduct" component={BannerproductDetails} />
        <Route exact path="/:mdCode/:sdCode/:mcCode/:scCode/:ProductId/:ProdName" component={ProductDetails} />
        <Route exact path="/product/searchdetails/relatedproduct/:keyword" component={SearchDetails} />
        <Route exact path="/Cart" component={CartDetails} />
        <Route exact path="/Sitemap" component={Sitemap} />
        <Route exact path="/Checkout" component={CheckoutDetails} />
        <Route exact path="/Subscription" component={SubscriptionDetails} />
        <Route exact path="/Orders" component={OrderDetails} />
        <Route exact path="/OrderPlaced" component={PlaceOrder} />
        <Route exact path="/Ac-addressDetails" component={AcaddressDetails} />
        <Route exact path="/Aboutus" component={AboutusDetails} />
        <Route exact path="/AboutusDetailsphone" component={AboutusDetailsphone} />
        <Route exact path="/Terms-and-conditions" component={Terms_and_conditionsDetails} />
        <Route exact path="/privacy-policy" component={PrivacypolicyDetails} />
        <Route exact path="/faq" component={FAQsDetails} />
        <Route exact path="/Terms_and_conditionsDetailsphone" component={Terms_and_conditionsDetailsphone} />
        <Route exact path="/:name/:mdCode" component={CategoryDetails} />
        <Route exact path="/:name/:mdCode/:sdCode" component={CategoryDetails} />
        <Route exact path="/:name/:mdCode/:sdCode/:mcCode" component={CategoryDetails} />
        <Route exact path="/:name/:mdCode/:sdCode/:mcCode/:scCode" component={CategoryDetails} />   
        <Route  component={ErrorPage} />  
               
      </Switch>
    </BrowserRouter>
  )
}

export default App;
