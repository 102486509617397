import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
// import Cookies from 'universal-cookie';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import styles from './Footer.module.css';
// const cookies = new Cookies();

const options = {
    margin: 10,
    responsiveClass: true,
    autoplayHoverPause:true,
    slideBy: 1,
    nav: true,
    autoplay: true,
    smartSpeed: 1000,
    dots: true,
    loop:true,
    autoplayTimeout:10000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};


class Footer extends Component {

    constructor(){
        super();
        this.state = {
            review_banner_details: [],
            lastSegment:'',
        };
        this.getreview_banner_details = this.getreview_banner_details.bind(this);
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' || 
            user_auth_key === "undefined" || user_auth_key === "null") 
        {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }

        this.getreview_banner_details();
        var url = window.location.href;
        var parts = url.split('/');
        var lastSegment = parts.pop() || parts.pop();
        this.setState({lastSegment:lastSegment});
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    async getreview_banner_details() {
        await axiosInstance.get(`getreview_banner_details`)
            .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let rievwbannerResponse = res.data.review_banner_details;
                    let RievwBanner = rievwbannerResponse.map(rievwbanner_response => { return rievwbanner_response });
                    this.setState({ review_banner_details:RievwBanner });
                }
            })
            .catch(err => console.log('Error: ', err));
    }

    render() {
        return (
            <footer className={`footer ${styles.footer_cus_js}`}>
                {/* <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <span className="widget-newsletter-title">Sign Up to Newsletter</span>
                            </div>
                            <div className="col-lg-4">
                                <p className="widget-newsletter-content"><br />
                                    <span className="widget-newsletter-content"></span>
                                </p>
                            </div>
                            <div className="col-lg-5">
                                <form action="#">
                                    <div className="footer-submit-wrapper">
                                        <input type="email" className="form-control" placeholder="Enter your Email address" required />
                                        <button type="submit" className="btn">Subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}

                    {/* review section */}
                    {/* {  this.state.lastSegment!=='Checkout' && this.state.lastSegment!=='OrderPlaced' && this.state.lastSegment!=='AccountDetails'
                     && this.state.lastSegment!=='Aboutus'  && this.state.lastSegment!=='Terms_and_conditions' ? (
                                <>
                                    <section className="product-panel pad-lr-50">
                                        <div className="section-title-custm section-title mb-2 ">
                                            <h2 className="footer-middle">Customer reviews</h2>
                                        </div>
                                    </section>
                                    <div id="web-view-brand" className="row row-sm">
                                        <div className="col-lg-12">
                                        {this.state.review_banner_details.length && (
                                            <OwlCarousel
                                            {...options}
                                            className="home-slider owl-carousel owl-theme"
                                            >
                                            {
                                                this.state.review_banner_details.map(brandbanner => {
                                                   

                                                    const brandweb_imageurl = brandbanner.web_imageurl;
                                                    return (
                                                        <a href="https://www.google.ae/search?ibp=gwp;0,7&q=Yalla+Basket&ludocid=18168362174652240133&lsig=AB86z5VgS8T-K2IpDssUDxNbzh9c#lkt=LocalPoiReviews" target="_blank" rel="noopener noreferrer">
                                                            <div key={brandbanner.banner_id} className="brand-slide" style={{backgroundImage: "url("+brandweb_imageurl+")" }}>
                                                            </div>
                                                        </a> 
                                                        
                                                )} 
                                            )}
                                            </OwlCarousel>
                                        )}
                                        </div>
                                    </div>

                                    <div id="mobile-view-brand" className="row row-sm">
                                        <div className="col-lg-12">
                                        {this.state.review_banner_details.length && (
                                        <OwlCarousel
                                        {...options}
                                        className="home-slider owl-carousel owl-theme"
                                        >
                                            {
                                                this.state.review_banner_details.map(brandbanner => {
                                                    const brandmobile_imageurl = brandbanner.mobile_imageurl;
                                                    return (
                                                        <a href="https://www.google.ae/search?ibp=gwp;0,7&q=Yalla+Basket&ludocid=18168362174652240133&lsig=AB86z5VgS8T-K2IpDssUDxNbzh9c#lkt=LocalPoiReviews" target="_blank" rel="noopener noreferrer">
                                                            <div key={brandbanner.banner_id} className="brand-slide item" style={{backgroundImage: "url("+brandmobile_imageurl+")" }}>
                                                            </div>
                                                        </a>
                                                    
                                                )} 
                                            )}
                                            </OwlCarousel>
                                        )}
                                        </div>
                                    </div>
                                    <br></br>
                                    <br></br> 
                                </>
                    ) : ( 
                        <></>
                )} */}


                
                <div className="container">
                    <div className="footer-middle">
                        <div className="row row-sm">
                            <div className="col-md-4 col-lg-2 col-xl-3 col-12 res-mb-1">
                                <div className="row row-sm">
                                    <div className="col-sm-12 text-center">
                                        <Link to="/">
                                            <img className="footer-img" src="/assets/img/logo.png" alt="Logo" />
                                        </Link> 

                                        {/* <div className="contact-widget text-left">
                                            <h4 className="widget-title">Follow us on </h4>
                                        <div className="social-icons mb-2 text-center">
                                            <a href="https://www.facebook.com/yallabasketuae"  rel="noopener noreferrer nofollow" target="_blank"><span className="social-icon" > <img src="/assets/img/facebook.png"  alt=""/> </span></a>
                                            <a href="https://www.instagram.com/yallabasket"  rel="noopener noreferrer nofollow" target="_blank"><span className="social-icon" ><img src="/assets/img/instagram.png" alt="" /></span></a>
                                        </div>

                                        </div>  */}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-2 col-xl-3 col-6">
                                <div className="widget">
                                    {/* <h4 className="widget-title">Contact</h4> */}
                                    {/* <ul className="links">
                                        <li><span>About Yallabasket</span></li>
                                        <li><span>Our Guarantees</span></li>
                                        <li><span>Terms And Conditions</span></li>
                                        <li><span>Privacy Policy</span></li>
                                    </ul> */}
                                    <div className="col-sm-12 col-12">
                                        <div className="contact-widget email">
                                            <h4 className="widget-title">EMAIL</h4>
                                            <span>hello@yallabasket.com</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-12">
                                        <div className="contact-widget">
                                            <h4 className="widget-title">PHONE</h4>
                                            <a href="https://wa.me/message/MY2RPGA2B7BDJ1" target="_blank"  rel="noopener noreferrer nofollow" ><p className="md-0-cust" ><img className="what_logo_style" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6207179</p></a>
                                            <a href="https://wa.me/message/BKO5NZ5MJOH6A1" target="_blank"  rel="noopener noreferrer nofollow" ><p className="md-0-cust"><img className="what_logo_style" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6339179</p></a>
                                            {/* <Link to="https://wa.me/message/MY2RPGA2B7BDJ1"><p className="md-0-cust">+971 56 6207179</p></Link>
                                            <Link to="https://wa.me/message/BKO5NZ5MJOH6A1"><p className="md-0-cust">+971 56 6339179</p></Link> */}
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12">

                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4 col-lg-2 col-xl-3 col-6">
                                <div className="widget">
                                    <h4 className="widget-title">&nbsp;</h4>
                                    <ul className="links link-parts">
                                        <div className="link-part">
                                            {  localStorage.getItem('user_log_status') === "true" ? (
                                                    <li> <Link to="/AccountDetails"><span>My Account</span></Link></li>
                                            ) : ( 
                                                <li> <span  id="login-modal" className="login-modal c-pointer">My Account</span></li>
                                            ) }
                                            <li> <Link to="/Aboutus"><span>About us</span></Link></li>
                                            <li> <Link to="/Aboutus"><span>Payment Methods</span></Link></li>
                                            <li> <Link to="/Aboutus"><span>Delivery Policy/fees</span></Link></li>
                                            <li> <Link to="/Aboutus"><span>Returns Policy</span></Link></li>
                                        </div>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-2 col-xl-2 col-6">
                                <div className="widget">
                                    <h4 className="widget-title">&nbsp;</h4>
                                    <ul className="links">
                                        <li><Link to="/Terms-and-conditions"><span>Terms and Condtions</span></Link></li>
                                        <li><Link to="/privacy-policy"><span>Privacy policy</span></Link></li>
                                        <li><Link to="/faq"><span>FAQ's</span></Link></li>
                                        <li><Link to="/Aboutus"><span>Contact us</span></Link></li>
                                        {/* <li><span>Best Selling Performance</span></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom">
                        <p className="footer-copyright">Appteq &copy; 2021.  All Rights Reserved</p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer