import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
// import Cookies from 'universal-cookie';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// const cookies = new Cookies();
// const current = new Date();
// const nextYear = new Date();
// nextYear.setFullYear(current.getFullYear() + 1);

class Subscription extends Component {

    constructor() {
        super();
        this.state = {
            CartDetails: [],
            Subscription_weekly_Details: [],
            Subscription_biweekly_Details: [],
            Subscription_monthly_Details: [],
            avaliable_coupones: [],
            upcoming_order: [],
            todays_order: [],
            spinner: 'loading-overlay',
            small_spinner: 'ajax-overlay hide-content',
            applycoupontext: "",
            couponerror: false,
            couponerror_textmsg: '',
            coupon_count: 0,
            coupon_discount_amt: "",
            final_amount: "",
            total_without_coupon: "",
            Coupon_code_appliedd: "",
            promocode_discount_value: "",
            promocode_discount_type: "",
            Coupon_code_appliedd_id: "",
            promocode_selected_flag: "0",
            outofstock_product_count: 0,
            pricerange_percentage: 0,
            pricerange_final_amt: 0,
            outofstock_product_errortest: false,
            avaliable_requested_product: 0,
            slotproduct_errortest: false,
            show_weekly_subscription: "Sun",
            bagsavings:"0.00",
            prod_subtotal:"0.00",
            total_price:"0.00",
            upcoming_bagsavings:"0.00",
            upcoming_prod_subtotal:"0.00",
            upcoming_total_price:"0.00",


            subscriptionopen: false,
            subscription_weekly: [],
            subscription_biweekly: [],
            subscription_monthly: [],
            delivery_address: [],
            upcoming_delivery_address: [],
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            showsubscriptionerrormessage:false,
            subscription_errormsg:"",
            subscription_prod_id:"",
            subscription_prod_code:"",
            Sun_quantity:0,
            Mon_quantity:0,
            Tue_quantity:0,
            Wed_quantity:0,
            Thu_quantity:0,
            Fri_quantity:0,
            Sat_quantity:0,
            subscription_produ_imgurl:"",
            subscription_produ_category:"",
            subscription_produ_name:"",
            subscription_produ_brand:"",
            subscription_produ_umo:"",
            Predetive_search:[],
            useraddressDetails:[],
            subscription_searched_prod_code:"",
            show_subscription_prod_details:false,
            address_id:"",
            radioflatnoError:""
        };
        this.getsubscribedproducts = this.getsubscribedproducts.bind(this);
        this.getupcomingsubscribedorders = this.getupcomingsubscribedorders.bind(this);
        this.showweeklysubscription = this.showweeklysubscription.bind(this);
        this.getbiweeklysubscriptionvalues = this.getbiweeklysubscriptionvalues.bind(this);
        this.getmonthlysubscriptionvalues = this.getmonthlysubscriptionvalues.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    componentDidMount() {
        this.getupcomingsubscribedorders();
        this.getsubscribedproducts();
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
        }



        var myElementToCheckIfClicksAreInsideOf = document.getElementById('subscription_product_search');        
        console.log(myElementToCheckIfClicksAreInsideOf);
        // Listen for click events on body
        if(myElementToCheckIfClicksAreInsideOf!==null && myElementToCheckIfClicksAreInsideOf!== undefined)
        {
            document.body.addEventListener('click', function (event) {
                if (myElementToCheckIfClicksAreInsideOf.contains(event.target)) 
                {
                    // console.log('clicked inside');
                } 
                else 
                {
                    let ele = document.getElementsByClassName('subscription_display_searchdiv');
                    for (let i = 0; i <  ele.length; i++) 
                    {            
                        ele[i].classList.add("d-none");
                        ele[i].classList.remove("d-block");   
                    }  
                }
            });
        }
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ couponerror: false, couponerror_textmsg: '' });
    }


    getupcomingsubscribedorders() {
        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
            .post(`getupcomingsubscribedorders`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let subscription_weeklyvalues = res.data.subscription_details["subscription_weekly"];
                    let subscription_weeklydetails = subscription_weeklyvalues.map(subcat_response => { return subcat_response; });

                    let subscription_biweeklyvalues = res.data.subscription_details["subscription_biweekly"];
                    let subscription_biweeklydetails = subscription_biweeklyvalues.map(subcat_response => { return subcat_response; });

                    let subscription_monthlyvalues = res.data.subscription_details["subscription_monthly"];
                    let subscription_monthlydetails = subscription_monthlyvalues.map(subcat_response => { return subcat_response; });

                    let delivery_address_values = res.data.delivery_address;
                    let delivery_addressdetails = delivery_address_values.map(subcat_response => { return subcat_response; });

                    let upcoming_delivery_address_values = res.data.upcoming_delivery_address;
                    let upcoming_delivery_addressdetails = upcoming_delivery_address_values.map(subcat_response => { return subcat_response; });

                    let user_address_details_values = res.data.user_address_details;
                    let user_address_details = user_address_details_values.map(subcat_response => { return subcat_response; });

                    let upcoming_order_details = res.data.upcoming_order;
                    let todays_order_details = res.data.todays_order;
                    let bagsavings = 0.00;
                    let prod_subtotal = 0.00;
                    let total_price = 0.00;
                    if(res.data.todays_order.length>0)
                    {
                        bagsavings = res.data.todays_order[0]["discount_price"];
                        prod_subtotal = res.data.todays_order[0]["prod_subtotal"];
                        total_price = res.data.todays_order[0]["total_price"];
                    }
                    let upcoming_bagsavings = 0.00;
                    let upcoming_prod_subtotal = 0.00;
                    let upcoming_total_price = 0.00;
                    
                    if(res.data.upcoming_order.length>0)
                    {
                        upcoming_bagsavings = res.data.upcoming_order[0]["discount_price"];
                        upcoming_prod_subtotal = res.data.upcoming_order[0]["prod_subtotal"];
                        upcoming_total_price = res.data.upcoming_order[0]["total_price"];
                    }
                    

                    let upcoming_order = upcoming_order_details.map(product_response => { return product_response; });
                    let todays_order = todays_order_details.map(product_response => { return product_response; });

                    this.setState({
                        upcoming_order: upcoming_order,
                        todays_order: todays_order,
                        bagsavings:bagsavings,
                        prod_subtotal:prod_subtotal,
                        total_price:total_price,
                        upcoming_bagsavings:upcoming_bagsavings,
                        upcoming_prod_subtotal:upcoming_prod_subtotal,
                        upcoming_total_price:upcoming_total_price,
                        subscription_weekly: subscription_weeklydetails,
                        subscription_biweekly: subscription_biweeklydetails,
                        subscription_monthly: subscription_monthlydetails,
                        delivery_address :delivery_addressdetails,
                        upcoming_delivery_address :upcoming_delivery_addressdetails,
                        useraddressDetails:user_address_details
                    });
                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner });
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                }
            })
            .catch((err) => console.log("Error: ", err));
    }

    getsubscribedproducts() {
        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
            .post(`getsubscribedproducts`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let Subscription_weekly = res.data.weekly_subscribed_products;
                    let Subscription_biweekly = res.data.biweekly_subscribed_products;
                    let Subscription_monthly = res.data.monthly_subscribed_products;

                    let Subscription_weekly_Details = Subscription_weekly.map(product_response => { return product_response; });
                    let Subscription_biweekly_Details = Subscription_biweekly.map(product_response => { return product_response; });
                    let Subscription_monthly_Details = Subscription_monthly.map(product_response => { return product_response; });

                    this.setState({
                        Subscription_weekly_Details: Subscription_weekly_Details,
                        Subscription_biweekly_Details: Subscription_biweekly_Details,
                        Subscription_monthly_Details: Subscription_monthly_Details
                    });
                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner });

                }
            })
            .catch((err) => console.log("Error: ", err));
    }



    onOpenModal = () => {
        this.setState({ applycoupontext: "", open: true });



    };

    onCloseModal = () => {
        //   alert('hii');
        this.setState({ open: false, clicks: 1 });
        this.setState({ show: false });
        this.setState({ couponerror: false });
        // this.setState({ applycoupontext:"" });
        // this.reload();
        // alert(this.state.open);
    };

    removeappliedcoupon = () => {
        //   alert('hii');
        this.setState({
            Subtotal: this.state.total_without_coupon,
            couponerror: false, promocode_selected_flag: "0", coupon_discount_amt: "", couponerror_textmsg: '',
            final_amount: "",
            total_without_coupon: "",
            Coupon_code_appliedd: "",
            promocode_discount_value: "",
            promocode_discount_type: "",
            Coupon_code_appliedd_id: "",
            applycoupontext: "",
        });
        localStorage.removeItem('coupon_discount_amt');
        localStorage.removeItem('final_amount');
        localStorage.removeItem('total_without_coupon');
        localStorage.removeItem('Coupon_code_appliedd');
        localStorage.removeItem('promocode_discount_value');
        localStorage.removeItem('promocode_discount_type');
        localStorage.removeItem('Coupon_code_appliedd_id');
        localStorage.removeItem('promocode_selected_flag');
        // this.reload();
        // alert(this.state.open);
    };

    removeoutofstockprod() {
        this.setState({ outofstock_product_errortest: true });
    }

    showsloterror() {
        this.setState({ slotproduct_errortest: true });
    }
    showweeklysubscription = (values) => {
        var chks = document.getElementsByClassName('visually-hidden-lable');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.firstChild.classList.add("subscription_day_lable_selected")
                this.setState({ show_weekly_subscription: chks[i].value });
            }
            else {
                chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            }
        }
    }

    IncrementsubscriptionItem(prod_code,prod_quantity,subscribed_products_id)
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });

        const jsonValue = { 
            prod_code:prod_code,
            quantity: parseInt(prod_quantity)+1,
            subscribed_products_id:subscribed_products_id,
            user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
          .post(`updatesubscriptionquantity`, jsonValue)
          .then((res) => {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            if (res.data.status === "success" && res.data.statusCode === "200")
            {
                this.getupcomingsubscribedorders();
                this.getsubscribedproducts();
            }
          })
          .catch((err) => console.log("Error: ", err));
    }
    
    DecrementsubscriptionItem(prod_code,prod_quantity,subscribed_products_id) 
    {
        if(parseInt(prod_quantity)>1)
        {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });    
            const jsonValue = { 
                prod_code:prod_code,
                quantity: parseInt(prod_quantity)-1,
                subscribed_products_id:subscribed_products_id,
                user_unique_id: localStorage.getItem('user_auth_key') };
            axiosInstance
              .post(`updatesubscriptionquantity`, jsonValue)
              .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200")
                {
                    this.getupcomingsubscribedorders();
                    this.getsubscribedproducts();
                }
              })
              .catch((err) => console.log("Error: ", err));
        }
    }
    
    removesubscriptionprod(prod_code,subscribed_products_id) 
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });    
        const jsonValue = { 
            prod_code:prod_code,
            subscribed_products_id:subscribed_products_id,
            user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
          .post(`removesubscriptionprod`, jsonValue)
          .then((res) => {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            if (res.data.status === "success" && res.data.statusCode === "200")
            {
                this.getupcomingsubscribedorders();
                this.getsubscribedproducts();
            }
          })
          .catch((err) => console.log("Error: ", err));
    }
    
    updateupcomingorderquantity(type,quantity,id,prod_code) 
    {
        if(type==="add")
        {
            quantity = parseInt(quantity)+1;
        }
        else if(type==="minus")
        {
            if(parseInt(quantity)>0)
            {
                quantity = parseInt(quantity)-1;
            }
        }

        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });    
        const jsonValue = { 
            prod_code:prod_code,
            quantity:quantity,
            subscription_order_id:id,
            user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
          .post(`updateupcomingorderquantity`, jsonValue)
          .then((res) => {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            if (res.data.status === "success" && res.data.statusCode === "200")
            {
                this.getupcomingsubscribedorders();
                this.getsubscribedproducts();
            }
          })
          .catch((err) => console.log("Error: ", err));
    }

    removeupcomingorderquantity(id,prod_code)
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });    
        const jsonValue = { 
            prod_code:prod_code,
            subscription_order_id:id,
            user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
          .post(`removeupcomingorderquantity`, jsonValue)
          .then((res) => {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            if (res.data.status === "success" && res.data.statusCode === "200")
            {
                this.getupcomingsubscribedorders();
                this.getsubscribedproducts();
            }
          })
          .catch((err) => console.log("Error: ", err));
    }

    // subscription model start here

    opensubscriptionmodel = () => {

        this.setState({
            subscriptionopen: true,
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            Predetive_search:[],
            addtosubscribebtn: false,
            product_code_searchkey:"",
            // subscription_prod_id:pId,
            // subscription_prod_code:prod_code,
            Sun_quantity:0,
            Mon_quantity:0,
            Tue_quantity:0,
            Wed_quantity:0,
            Thu_quantity:0,
            Fri_quantity:0,
            Sat_quantity:0,
            show_subscription_prod_details:false,
            subscription_produ_imgurl:"",
            subscription_produ_name:"",
            subscription_produ_umo:"",
            subscription_errormsg:""

        });
        setTimeout(function () {
            document.getElementById('subscription_tab_one').classList.add("subscription_tab_active");
            document.getElementById('subscription_tab_two_content').classList.add("d-none");
            document.getElementById('subscription_tab_three_content').classList.add("d-none");

            let ele = document.getElementsByClassName('subscription_display_searchdiv');
            for (let i = 0; i <  ele.length; i++) 
            {            
                ele[i].classList.add("d-none");
                ele[i].classList.remove("d-block");   
            }

        }, 100);
    };

    showtabone = () => {
        document.getElementById('subscription_tab_one').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.add("d-none");
        document.getElementById('subscription_tab_three_content').classList.add("d-none");
        if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length> 0 && this.state.subscription_searched_prod_code !== "") {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }
    };
    showtabtwo = () => {
        document.getElementById('subscription_tab_one').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.add("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.add("d-none");
        if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0 && this.state.subscription_searched_prod_code !== "") {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }

    };
    showtabthree = () => {
        document.getElementById('subscription_tab_one').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.add("d-none");
        document.getElementById('subscription_tab_two_content').classList.add("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0 && this.state.subscription_searched_prod_code !== "") {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }
    };



    closesubscriptionmodel = () => {
        this.setState({
            subscriptionopen: false,
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            Sun_quantity:0,
            Mon_quantity:0,
            Tue_quantity:0,
            Wed_quantity:0,
            Thu_quantity:0,
            Fri_quantity:0,
            Sat_quantity:0,
            subscription_produ_imgurl:"",
            subscription_produ_category:"",
            subscription_produ_name:"",
            subscription_produ_brand:"",
            subscription_produ_umo:"",
        });

    };

    subscription_quantity = (name, value, type,id) => {



        if (type === "add") {
            let addvalue = this.state[name] + 1;
            this.setState({
                [name]: addvalue
            }, () => {
                if (name !== "biweekly_quantity" && name !== "monthly_quantity") 
                {
                    if(this.state[name]>0)
                    {
                        document.getElementById(id).parentElement.classList.add("subscription_day_lable_selected");
                        document.getElementById(id).checked=true;
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else
                    {
                        document.getElementById(id).parentElement.classList.remove("subscription_day_lable_selected");
                        document.getElementById(id).checked=false; 
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }

                    if(this.state.Sun_quantity>0 || this.state.Mon_quantity>0  || this.state.Tue_quantity>0  || this.state.Wed_quantity>0
                        || this.state.Thu_quantity>0  || this.state.Fri_quantity>0 || this.state.Sat_quantity>0)
                    {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else
                    {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                    this.getweeklysubscriptionvalues();
                }
                if (name === "biweekly_quantity") {
                    if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                }
                if (name === "monthly_quantity") {
                    if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                }
            });
        }
        if (type === "minus") {
            let minusvalue = this.state[name];
            if (minusvalue !== 0) {
                this.setState({
                    [name]: minusvalue - 1
                }, () => {
                    if (name !== "biweekly_quantity" && name !== "monthly_quantity") 
                    {
                        if(this.state[name]>0)
                        {
                            document.getElementById(id).parentElement.classList.add("subscription_day_lable_selected");
                            document.getElementById(id).checked=true;
                        }
                        else
                        {
                            document.getElementById(id).parentElement.classList.remove("subscription_day_lable_selected");
                            document.getElementById(id).checked=false; 
                        }

                        if(this.state.Sun_quantity>0 || this.state.Mon_quantity>0  || this.state.Tue_quantity>0  || this.state.Wed_quantity>0
                            || this.state.Thu_quantity>0  || this.state.Fri_quantity>0 || this.state.Sat_quantity>0)
                        {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else
                        {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                        this.getweeklysubscriptionvalues();
                    }
                    if (name === "biweekly_quantity") {
                        if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                    }
                    if (name === "monthly_quantity") {
                        if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                    }
                });
            }
        }
    };



    getweeklysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable-weeklydt');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.classList.remove("subscription_day_lable_selected")
            }
        }
        
        var quantity_values = document.getElementsByClassName('subscription_weekly_quantity_value');
        const weekly_quantity = [];

        for (let j = 0; j < quantity_values.length; j++) 
        {
            if(quantity_values[j].value>0)
            {
                // console.log(quantity_values[j].value);
                weekly_quantity.push(quantity_values[j].value);
            }            
        }

        this.setState({
            weekly_selected_date: value,weekly_selected_quntity:weekly_quantity
        }, () => {
            // console.log(this.state.biweekly_selected_date.length);
            if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length> 0) 
            {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });

    }

    getbiweeklysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable-biweekly');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.firstChild.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            }
        }

        this.setState({
            biweekly_selected_date: value
        }, () => {
            if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });
    }

    getmonthlysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable-monthly');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.firstChild.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            }
        }

        this.setState({
            monthly_selected_date: value
        }, () => {
            if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });
    }

    clearselectedsubscription = (value) =>  
    {
        // alert(value);
        var chks = document.getElementsByClassName(''+value+'');
        // console.log(chks);
        for (var i = 0; i < chks.length; i++) {
            chks[i].checked = false;
            chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            // console.log(chks[i].checked)
        }
        this.setState({
            showsubscriptionerrormessage: false,
            subscription_errormsg:""
        });
        if(value==="visually-hidden-lable-biweekly")
        {
            this.getbiweeklysubscriptionvalues();
        }
        else if(value==="visually-hidden-lable-monthly")
        {
            this.getmonthlysubscriptionvalues();
        }

    }


    submit_subscription = () =>  {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({
            showsubscriptionerrormessage: false,
            subscription_errormsg:""
        });

        if(this.state.subscription_searched_prod_code==="" )
        {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg:"Select the product to proceed next"
            }, () => {
                this.showtabone();
            }); 
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if(this.state.weekly_selected_date.length===0 && this.state.weekly_selected_quntity.length!==0)
        {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg:"Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabone();
            }); 
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if(this.state.biweekly_selected_date.length===0 && this.state.biweekly_quantity!==0)
        {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg:"Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabtwo();
            }); 
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if(this.state.monthly_selected_date.length===0 && this.state.monthly_quantity!==0)
        {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg:"Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabthree();
            }); 
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else if(this.state.weekly_selected_date.length>0 && this.state.weekly_selected_quntity.length===0)
        {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg:"Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabone();
            }); 
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if(this.state.biweekly_selected_date.length>0 && this.state.biweekly_quantity===0)
        {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg:"Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabtwo();
            }); 
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if(this.state.monthly_selected_date.length>0 && this.state.monthly_quantity===0)
        {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg:"Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabthree();
            }); 
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else
        {
            const jsonValue = {
                weekly_selected_date: this.state.weekly_selected_date,
                weekly_quantity: this.state.weekly_selected_quntity,
                biweekly_selected_date: this.state.biweekly_selected_date,
                biweekly_quantity: this.state.biweekly_quantity,
                monthly_selected_date: this.state.monthly_selected_date,
                monthly_quantity: this.state.monthly_quantity,
                subscription_prod_id: this.state.prod_unit,
                subscription_prod_code: this.state.subscription_searched_prod_code,
                user_unique_id: localStorage.getItem('user_auth_key'),
            };
            axiosInstance
                .post(`insersubscriptionproduct`, jsonValue)
                .then((res) => {
                    
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Product Subscribed Sucessfully', 'Success', 200);
                        this.getupcomingsubscribedorders();
                        this.getsubscribedproducts();
                        this.closesubscriptionmodel();
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "201") 
                    {
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                        NotificationManager.error('Something went wrong', 'Error', 500);
                    }                    
                })
                .catch((err) => console.log("Login: ", err));
        }
    }

    onFocusOutsearchbtn = (e)=>
    {
        if(e.target.value==='' && e.target.value===null)
        {
            let ele = document.getElementsByClassName('subscription_display_searchdiv');
            for (let i = 0; i <  ele.length; i++) 
            {            
                ele[i].classList.add("d-none");
                ele[i].classList.remove("d-block");   
            }
        }   
    }

    onPressgetsearchint = (e) => {
        if(e.target.value!=='' && e.target.value!==null)
        {
            const jsonValue = {
                user_unique_id: localStorage.getItem('user_auth_key'),search_key: e.target.value
            };
            axiosInstance
            .post(`getallproductnamelist`, jsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') {
                    let Predetive_search_values = res.data.category_details;
                    let Predetive_search_details = Predetive_search_values.map(subcat_response => { return subcat_response; });
                    
                    this.setState({
                        Predetive_search:Predetive_search_details,
                        subscription_errormsg:""
                    }, () => {
                        if(this.state.Predetive_search.length>0)
                        {
                            let ele = document.getElementsByClassName('subscription_display_searchdiv');
                            for (let i = 0; i <  ele.length; i++) 
                            {            
                                ele[i].classList.remove("d-none");
                                ele[i].classList.add("d-block");   
                            }
                        } 
                        else
                        {
                            let ele = document.getElementsByClassName('subscription_display_searchdiv');
                            for (let i = 0; i <  ele.length; i++) 
                            {            
                                ele[i].classList.add("d-none");
                                ele[i].classList.remove("d-block");   
                            }
                        }                       
                    });
                }
            })
            .catch(err => console.log('Error: ', err));
        }
        if(e.target.value==='' || e.target.value===null){
            let ele = document.getElementsByClassName('subscription_display_searchdiv');
            for (let i = 0; i <  ele.length; i++) 
            {            
                ele[i].classList.add("d-none");
                ele[i].classList.remove("d-block");   
            }
            this.setState({
                Predetive_search:[],
                subscription_errormsg:""
            });
        }
    }

    
    Click_Predetive_search = (prod_code,prod_name,produ_imgurl,prod_quantity,unit_id) => 
    {
        this.setState({
            subscription_searched_prod_code:prod_code,
            subscription_errormsg:"",
            product_code_searchkey:prod_name,
            show_subscription_prod_details:true,
            subscription_produ_imgurl:produ_imgurl,
            subscription_produ_name:prod_name,
            subscription_produ_umo:""+prod_quantity+" "+unit_id+"",
        }, () => {
            console.log(this.state.subscription_searched_prod_code);
            let ele = document.getElementsByClassName('subscription_display_searchdiv');
            for (let i = 0; i <  ele.length; i++) 
            {            
                ele[i].classList.add("d-none");
                ele[i].classList.remove("d-block");   
            }
        }); 

        
    }

    updatesubscription_address =() =>
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner ,  radioflatnoError:""});

        if(this.state.address_id==='')
        {
            this.setState({
                radioflatnoError:"Please Select the Address to Update"
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else
        {
            const jsonValue = {
                user_unique_id: localStorage.getItem('user_auth_key'),subscription_address_id: this.state.address_id
            };
            axiosInstance
            .post(`updateusersubscriptionaddress`, jsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') 
                {
                    NotificationManager.success("Address Updated sucessfully", 'Success', 200);
                    this.onCloseModal();
                    this.getupcomingsubscribedorders();
                }
                else
                {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch(err => console.log('Error: ', err));
        }
        

    }


    render() {
        // console.log(this.state.promocode_selected_flag);
        // console.log(localStorage.getItem('promocode_selected_flag'));
        return (
            <main className="main setminheight">
                  <NotificationContainer />
                <div className={this.state.small_spinner}>
                    <i className="porto-loading-icon"></i>
                </div>
                <div className={this.state.spinner}>
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                            <li className="breadcrumb-item"><span>Subscription </span></li>
                        </ol>
                    </div>
                </nav>

                <div className="container">
                    <div className="row mb-2 min_hg_500">
                        <div className="col-lg-9">
                            <Tabs defaultTab="one" >
                                <TabList className="custom_subscription_view" >
                                    <Tab tabFor="one">Upcoming Orders</Tab>
                                    <Tab tabFor="two">Weekly Subscriptions</Tab>
                                    <Tab tabFor="three">Bi-Weekly Subscriptions</Tab>
                                    <Tab tabFor="four">Monthly Subscriptions</Tab>
                                </TabList>

                                <TabPanel tabId="one">
                                    <p className="display_weekly_days mb-1">
                                        {this.state.todays_order.length > 0 ? (
                                            <>
                                                <span className="h4 d-block mb-1">Todays Order</span>
                                                <div className="cart-items">
                                                    {
                                                        this.state.todays_order.map(value => {
                                                            return (
                                                                <div className="row cart-bottom subscription-bottom-cust">
                                                                    <div className="col-lg-1 col-3">
                                                                        <img className="cart-img" src={value.produ_imgurl} alt={value.prod_name} />
                                                                    </div>
                                                                    <div className="col-lg-11 col-9">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-12">
                                                                                <div className="cart-details">
                                                                                    <p className="cart-name p-0 m-0">{value.prod_name}</p>
                                                                                    <p className="cart-total-weight">UOM : {value.prod_quantity} {value.unit_id} </p>
                                                                                    <p className="cart-total-weight">
                                                                                    { (value.prod_originalprice!==value.selling_price)? (
                                                                                        <>
                                                                                            <span className="old-price">AED {value.prod_originalprice}</span> AED {value.selling_price}
                                                                                        </>
                                                                                        ) : (
                                                                                        <>
                                                                                                AED {value.selling_price}
                                                                                            </>                                                                                                
                                                                                        ) }
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-4 col-12">
                                                                                <p className="cart-total-weight">Quantity : {value.subscribe_quantity} X {value.prod_quantity} {value.unit_id} </p>
                                                                                <p className="cart-total-weight">Total Price : AED {value.prod_total_price} </p>
                                                                            </div>
                                                                            <div className="col-lg-4 col-12 mob-d-none">
                                                                                <p className="cart-total-weight"><b> Delivery date :  {value.delivery_date_words}</b>
                                                                                </p>
                                                                                <p className="cart-total-weight"> Slot : Eve 6PM to 11PM
                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                </div>
                                            </>
                                        ) : (
                                            <> 
                                                {this.state.todays_order.length <= 0 && this.state.upcoming_order.length <= 0 ? (  
                                                    <>
                                                        <div className="cart-items mb-2">
                                                            No Orders found
                                                        </div>
                                                    </>
                                                ) : (
                                                    <> </>
                                                )}
                                            
                                            </>
                                        )}

                                        {/* upcoming orders */}

                                        {this.state.upcoming_order.length > 0 ? (
                                            <>
                                                <span className="h4 d-block mb-1">Upcoming Order</span>
                                                <p className="display_weekly_days mb-1">
                                                    <div className="cart-items">
                                                        {
                                                            this.state.upcoming_order.map(value => {
                                                                return (
                                                                    <div className="row cart-bottom subscription-bottom-cust">
                                                                        <div className="col-lg-1 col-3">
                                                                            <img className="cart-img" src={value.produ_imgurl} alt={value.prod_name} />
                                                                        </div>
                                                                        <div className="col-lg-11 col-9">
                                                                            <div className="row">
                                                                                <div className="col-lg-4 col-12">
                                                                                    <div className="cart-details mt-1">
                                                                                        <p className="cart-name p-0 m-0">{value.prod_name}</p>
                                                                                        <p className="cart-total-weight">UOM : {value.prod_quantity} {value.unit_id} </p>
                                                                                        <p className="cart-total-weight">Price : 
                                                                                        
                                                                                            { (value.prod_originalprice!==value.selling_price)? (
                                                                                            <>
                                                                                                <span className="old-price">AED {value.prod_originalprice}</span> AED {value.selling_price}
                                                                                            </>
                                                                                            ) : (
                                                                                            <>
                                                                                                 AED {value.selling_price}
                                                                                             </>                                                                                                
                                                                                            ) } 
                                                                                      </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-lg-3 col-12">
                                                                                <p className="cart-total-weight">Quantity : {value.subscribe_quantity} X {value.prod_quantity} {value.unit_id} </p>
                                                                                <p className="cart-total-weight">Total Price : AED {value.prod_total_price} </p>
                                                                                    <p className="cart-total-weight"><b> Date :
                                                                        {value.delivery_date_words}</b>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-lg-2 col-6">
                                                                                    <div className="input-group mob-p-l mt-1">
                                                                                        <span className="input-group-btn">
                                                                                            <button type="button" className="btn btn-number custom-qty-m" aria-label="Add one" data-direction="1" onClick={() => this.updateupcomingorderquantity("minus",""+value.subscribe_quantity+"",""+value.subscription_order_id+"",""+value.prod_code+"")}>
                                                                                                <span className="fa fa-minus"></span>
                                                                                            </button>
                                                                                        </span>
                                                                                        <input type="text" className="form-control input-number" name="quantity" value={value.subscribe_quantity} readOnly={true} />
                                                                                        <span className="input-group-btn">
                                                                                            <button type="button" className="btn btn-number custom-qty-a" aria-label="Subtract one" data-direction="-1" onClick={() => this.updateupcomingorderquantity("add",""+value.subscribe_quantity+"",""+value.subscription_order_id+"",""+value.prod_code+"")}>
                                                                                                <span className="fa fa-plus"></span>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-2 col-6">
                                                                                    <span className="cart-remove" onClick={() => this.removeupcomingorderquantity(""+value.subscription_order_id+"",""+value.prod_code+"")}> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                )
                                                            }
                                                            )}
                                                    </div>
                                                </p>
                                            </>
                                        ) : (
                                                <> </>
                                            )}
                                    </p>
                                </TabPanel>
                                <TabPanel tabId="two">
                                    <div className="container no-pad-res mt-2">

                                        <span className="subscription_days_view">
                                            <div >
                                                <label className="display_biweekly_popup_content subscription_day_lable_selected" for="toggle_sun">Sun</label>
                                                <input type="radio" name="selected_weekly_day" id="toggle_sun" value="Sun" class="visually-hidden-lable" onClick={() => this.showweeklysubscription("Sun")} />
                                            </div>
                                        </span>

                                        <span className="subscription_days_view">
                                            <div >
                                                <label className="display_biweekly_popup_content" for="toggle_mon">Mon</label>
                                                <input type="radio" name="selected_weekly_day" id="toggle_mon" value="Mon" class="visually-hidden-lable" onClick={() => this.showweeklysubscription("Mon")} />
                                            </div>
                                        </span>
                                        <span className="subscription_days_view">
                                            <div >
                                                <label className="display_biweekly_popup_content" for="toggle_tue">Tue</label>
                                                <input type="radio" name="selected_weekly_day" id="toggle_tue" value="Tue" class="visually-hidden-lable" onClick={() => this.showweeklysubscription("Tue")} />
                                            </div>
                                        </span>
                                        <span className="subscription_days_view">
                                            <div >
                                                <label className="display_biweekly_popup_content" for="toggle_wed">Wed</label>
                                                <input type="radio" name="selected_weekly_day" id="toggle_wed" value="Wed" class="visually-hidden-lable" onClick={() => this.showweeklysubscription("Wed")} />
                                            </div>
                                        </span>
                                        <span className="subscription_days_view">
                                            <div >
                                                <label className="display_biweekly_popup_content" for="toggle_thu">Thu</label>
                                                <input type="radio" name="selected_weekly_day" id="toggle_thu" value="Thu" class="visually-hidden-lable" onClick={() => this.showweeklysubscription("Thu")} />
                                            </div>
                                        </span>
                                        <span className="subscription_days_view">
                                            <div >
                                                <label className="display_biweekly_popup_content" for="toggle_fri">Fri</label>
                                                <input type="radio" name="selected_weekly_day" id="toggle_fri" value="Fri" class="visually-hidden-lable" onClick={() => this.showweeklysubscription("Fri")} />
                                            </div>
                                        </span>
                                        <span className="subscription_days_view">
                                            <div >
                                                <label className="display_biweekly_popup_content" for="toggle_sat">Sat</label>
                                                <input type="radio" name="selected_weekly_day" id="toggle_sat" value="Sat" class="visually-hidden-lable" onClick={() => this.showweeklysubscription("Sat")} />
                                            </div>
                                        </span>
                                        <div className="col-sm-12">
                                            <span className="addproductstosubscription_cust" onClick={() => this.opensubscriptionmodel()} > + Add Products </span>
                                        </div>

                                        <div className="cart-items  mb-2">
                                            {
                                                this.state.Subscription_weekly_Details.map(values => {
                                                    return (
                                                        <>
                                                            {values[this.state.show_weekly_subscription].length > 0 ? (
                                                                <>
                                                                    {
                                                                        values[this.state.show_weekly_subscription].map(value => {
                                                                            return (
                                                                                <>
                                                                                    <div className="row cart-bottom subscription-bottom-cust">
                                                                                        <div className="col-lg-1 col-3">
                                                                                            <img className="cart-img" src={value.produ_imgurl} alt={value.prod_name} />
                                                                                        </div>
                                                                                        <div className="col-lg-11 col-9">
                                                                                            <div className="row">
                                                                                                <div className="col-lg-4 col-12">
                                                                                                    <div className="cart-details mt-1">
                                                                                                        <p className="cart-name p-0 m-0">{value.prod_name}</p>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-lg-3 col-12">
                                                                                                    <p className="cart-total-weight">UOM : {value.prod_quantity} {value.unit_id} </p>
                                                                                                    <p className="cart-total-weight"><b> Day :
                                                                                                    {value.subscribe_date === 'Sun' ? (
                                                                                                        <> Sunday  </>
                                                                                                        ) : value.subscribe_date === 'Mon' ? (
                                                                                                            <> Monday  </>
                                                                                                        ) : value.subscribe_date === 'Tue' ? (
                                                                                                            <> Tuesday  </>
                                                                                                        ) : value.subscribe_date === 'Wed' ? (
                                                                                                            <> Wednesday  </>
                                                                                                        ) : value.subscribe_date === 'Thu' ? (
                                                                                                            <> Thursday  </>
                                                                                                        ) : value.subscribe_date === 'Fri' ? (
                                                                                                            <> Friday  </>
                                                                                                        ) : value.subscribe_date === 'Sat' ? (
                                                                                                            <> Saturday  </>
                                                                                                        ) : (
                                                                                                        <></>
                                                                                                    )}</b>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="col-lg-2 col-6">
                                                                                                    <div className="input-group mob-p-l mt-1">
                                                                                                        <span className="input-group-btn">
                                                                                                            <button type="button" className="btn btn-number custom-qty-m" aria-label="Add one" data-direction="1" onClick={() =>this.DecrementsubscriptionItem(value.prod_code,value.subscribe_quantity,value.subscribed_products_id)}>
                                                                                                                <span className="fa fa-minus"></span>
                                                                                                            </button>
                                                                                                        </span>
                                                                                                        <input type="text" className="form-control input-number" name="quantity" value={value.subscribe_quantity} readOnly={true} />
                                                                                                        <span className="input-group-btn">
                                                                                                            <button type="button" className="btn btn-number custom-qty-a" aria-label="Subtract one" data-direction="-1" onClick={() =>this.IncrementsubscriptionItem(value.prod_code,value.subscribe_quantity,value.subscribed_products_id)}>
                                                                                                                <span className="fa fa-plus"></span>
                                                                                                            </button>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-2 col-6">
                                                                                                    <span className="cart-remove" onClick={() =>this.removesubscriptionprod(value.prod_code,value.subscribed_products_id)}> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            ) : (
                                                                    <>
                                                                        <p>no data found</p>
                                                                    </>
                                                                )}
                                                        </>
                                                    )
                                                }
                                                )}
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel tabId="three">
                                    <p className="display_weekly_days mb-1">
                                        <div className="col-sm-12">
                                            <span className="addproductstosubscription_cust" onClick={() => this.opensubscriptionmodel()} > + Add Products </span>
                                        </div>
                                        <div className="cart-items  mb-2">
                                        {this.state.Subscription_biweekly_Details.length > 0 ? (  
                                            <>
                                                {
                                                    this.state.Subscription_biweekly_Details.map(value => {
                                                        return (
                                                            <div className="row cart-bottom subscription-bottom-cust">
                                                                <div className="col-lg-1 col-3">
                                                                    <img className="cart-img" src={value.produ_imgurl} alt={value.prod_name} />
                                                                </div>
                                                                <div className="col-lg-11 col-9">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-12">
                                                                            <div className="cart-details mt-1">
                                                                                <p className="cart-name p-0 m-0">{value.prod_name}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-3 col-12">
                                                                            <p className="cart-total-weight">UOM : {value.prod_quantity} {value.unit_id} </p>
                                                                            <p className="cart-total-weight">Brand : {value.brand_name} </p>
                                                                            <p className="cart-total-weight"><b> Date :
                                                                    {value.subscribe_date}</b>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-lg-2 col-6">
                                                                            <div className="input-group mob-p-l mt-1">
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number custom-qty-m" aria-label="Add one" data-direction="1" onClick={() =>this.DecrementsubscriptionItem(value.prod_code,value.subscribe_quantity,value.subscribed_products_id)}>
                                                                                        <span className="fa fa-minus"></span>
                                                                                    </button>
                                                                                </span>
                                                                                <input type="text" className="form-control input-number" name="quantity" value={value.subscribe_quantity} readOnly={true} />
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number custom-qty-a" aria-label="Subtract one" data-direction="-1" onClick={() =>this.IncrementsubscriptionItem(value.prod_code,value.subscribe_quantity,value.subscribed_products_id)}>
                                                                                        <span className="fa fa-plus"></span>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2 col-6">
                                                                            <span className="cart-remove" onClick={() =>this.removesubscriptionprod(value.prod_code,value.subscribed_products_id)}> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </>
                                        ) : (
                                            <>  no data found</>
                                        )}
                                        </div>
                                    </p>
                                </TabPanel>
                                <TabPanel tabId="four">
                                    <p className="display_weekly_days mb-1">
                                         <div className="col-sm-12">
                                            <span className="addproductstosubscription_cust" onClick={() => this.opensubscriptionmodel()} > + Add Products </span>
                                        </div>
                                        <div className="cart-items  mb-2">
                                            {this.state.Subscription_monthly_Details.length > 0 ? (  
                                                <>
                                                    {
                                                        this.state.Subscription_monthly_Details.map(value => {
                                                        return (
                                                            <div className="row cart-bottom subscription-bottom-cust">
                                                                <div className="col-lg-1 col-3">
                                                                    <img className="cart-img" src={value.produ_imgurl} alt={value.prod_name} />
                                                                </div>
                                                                <div className="col-lg-11 col-9">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-12">
                                                                            <div className="cart-details mt-1">
                                                                                <p className="cart-name p-0 m-0">{value.prod_name}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-3 col-12">
                                                                            <p className="cart-total-weight">UOM : {value.prod_quantity} {value.unit_id} </p>
                                                                            <p className="cart-total-weight">Brand : {value.brand_name} </p>
                                                                            <p className="cart-total-weight"><b> Date :
                                                                        {value.subscribe_date}</b>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-lg-2 col-6">
                                                                            <div className="input-group mob-p-l mt-1">
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number custom-qty-m" aria-label="Add one" data-direction="1" onClick={() =>this.DecrementsubscriptionItem(value.prod_code,value.subscribe_quantity,value.subscribed_products_id)}>
                                                                                        <span className="fa fa-minus"></span>
                                                                                    </button>
                                                                                </span>
                                                                                <input type="text" className="form-control input-number" name="quantity" value={value.subscribe_quantity} readOnly={true} />
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number custom-qty-a" aria-label="Subtract one" data-direction="-1" onClick={() =>this.IncrementsubscriptionItem(value.prod_code,value.subscribe_quantity,value.subscribed_products_id)}>
                                                                                        <span className="fa fa-plus"></span>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2 col-6">
                                                                            <span className="cart-remove" onClick={() =>this.removesubscriptionprod(value.prod_code,value.subscribed_products_id)}> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                </>
                                            ) : (
                                                <> no data found </>
                                            )}

                                        </div>
                                    </p>
                                </TabPanel>
                            </Tabs>
                        </div>

                        <div className="col-lg-3 ">
                            { (this.state.todays_order.length>0)? (
                            <>
                                <div className="cart-summary">
                                    <h3>Todays Order Summary</h3>

                                    <table className="table table-totals table-totals-subscription">
                                        <tbody>
                                            {
                                            this.state.delivery_address.map(value => {
                                                return (  
                                                    <>
                                                    <tr>
                                                        <td className="subscription_order_summary_head">
                                                            <p className="subscription_head_p p-0 m-0">Delivery Address</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="subscription_address_td">
                                                            <p className="subscription_address"><b>{value.username}</b>, </p>
                                                            <p className="subscription_address">{value.flat_no} {value.building_name} ,{value.landmark}</p>
                                                            <p className="subscription_address">{value.area} ,{value.city}.</p>
                                                        </td>
                                                        {/* <td className="subscription_address subscription_address_edit" onClick={() => this.onOpenModal()} > <i className="fas fa-edit"></i>&nbsp; Change</td> */}
                                                    </tr>
                                                    </>
                                                )
                                            }
                                            )}

                                            <tr>
                                                <td className="subscription_order_summary_head">
                                                    <p className="subscription_head_p p-0 m-0">Order Summary</p></td>
                                            </tr>
                                            <tr>
                                                <td className="subscription_address_td">Bag total</td>
                                                <td className="subscription_address_td">AED {this.state.prod_subtotal}</td>
                                            </tr>
                                            { (this.state.bagsavings!=="0.00")? (
                                            <>
                                                <tr>
                                                    <td className="subscription_address_td">Bag savings</td>
                                                    <td className="offeramountflag subscription_address_td">AED -{this.state.bagsavings}</td>
                                                </tr>
                                            </>
                                            ) : (
                                                <> </>
                                            ) } 


                                            <tr>
                                                <td className="subscription_address_td">Shipping</td>
                                                <td className="f-600 subscription_address_td">FREE</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>

                                                <td className="f-600 cart-total-width">Total</td>

                                                <td className="f-600">AED {this.state.total_price}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </>
                            ) : (
                                <> </>
                            ) }                             

                            <div className="cart-summary">
                                <h3>Upcoming Order Summary</h3>

                                <table className="table table-totals table-totals-subscription">
                                    <tbody>
                                        { (this.state.upcoming_order.length>0)? (
                                            <>
                                                { (this.state.upcoming_delivery_address.length>0)? (
                                                <>
                                                    {
                                                        this.state.upcoming_delivery_address.map(value => {
                                                            return (  
                                                                <>
                                                                <tr>
                                                                    <td className="subscription_order_summary_head">
                                                                        <p className="subscription_head_p p-0 m-0">Delivery Address</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="subscription_address_td">
                                                                        <p className="subscription_address"><b>{value.username}</b>, </p>
                                                                        <p className="subscription_address">{value.flat_no} {value.building_name} ,{value.landmark}</p>
                                                                        <p className="subscription_address">{value.area} ,{value.city}.</p>
                                                                    </td>
                                                                    <td className="subscription_address subscription_address_edit" onClick={() => this.onOpenModal()} > <i className="fas fa-edit"></i>&nbsp; Change</td>
                                                                </tr>
                                                                </>
                                                            )
                                                        }
                                                    )}
                                                </>
                                                ) : (
                                                    <> 
                                                        <tr>
                                                            <td className="subscription_order_summary_head">
                                                                <p className="subscription_head_p p-0 m-0">Delivery Address</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="subscription_address_td"> 
                                                                <Link to="/Ac-addressDetails"> 
                                                                    <p className="subscription_address address_link">
                                                                        <i className="fas fa-plus"></i>&nbsp; Add Address
                                                                    </p> 
                                                                </Link> 
                                                                {/* <p>Note : Add the Address to </p>                                                        */}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) }
                                            </>
                                        ) : ( 
                                            <></>
                                        ) }                                         

                                        <tr>
                                            <td className="subscription_order_summary_head">
                                                <p className="subscription_head_p p-0 m-0">Order Summary</p></td>
                                        </tr>
                                         <tr>
                                            <td className="subscription_address_td">Bag total</td>
                                            <td className="subscription_address_td">AED {this.state.upcoming_prod_subtotal}</td>
                                        </tr>
                                        { (this.state.upcoming_bagsavings!=="0.00")? (
                                        <>
                                            <tr>
                                                <td className="subscription_address_td">Bag savings</td>
                                                <td className="offeramountflag subscription_address_td">AED -{this.state.upcoming_bagsavings}</td>
                                            </tr>
                                        </>
                                        ) : (
                                            <> </>
                                        ) } 


                                        <tr>
                                            <td className="subscription_address_td">Shipping</td>
                                            <td className="f-600 subscription_address_td">FREE</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>

                                            <td className="f-600 cart-total-width">Total</td>

                                            <td className="f-600">AED {this.state.upcoming_total_price}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

                <div>
                    {/* <button >Open modal</button> */}
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="container cust_stype_modal">
                            <h2 className="product-title offers_img_displytypee">
                                 Address List
                        </h2>
                        {
                            this.state.useraddressDetails.map(useraddress => {
                                // console.log(this.state.address_status)
                            return (
                                <>
                                    <div key={useraddress.address_id} className="custom-user-address" onChange={this.onChangeValue}>
                                        { this.state.useraddressDetails.length === 1 ? (
                                            <input type="radio"
                                            id={useraddress.address_id}
                                            onChange={this.onChangeHandler}
                                            value={useraddress.address_id}
                                            name="address_id" className="custom-radio mr-2" />
                                            ) : (
                                            <input type="radio"
                                            id={useraddress.address_id}
                                            onChange={this.onChangeHandler}
                                            value={useraddress.address_id}
                                            name="address_id" className="custom-radio mr-2" />
                                            )
                                        }
                                        <label htmlFor={useraddress.address_id}>
                                            <p><b>{useraddress.address_type}</b></p>
                                            <p>{useraddress.username},
                                            +971 {useraddress.mobile_no},<br />
                                        {useraddress.flat_no}, {useraddress.building_name} {useraddress.landmark}, {useraddress.area}, {useraddress.city}.</p>
                                        </label>
                                    </div>
                                    
                                </>
                                )}
                            )
                        }
                            <span className="error">
                                {this.state.radioflatnoError}
                            </span>
                            {/* <p>Note : If you need to change the todays order address contact out cust</p> */}
                            <div className="custom-order-btn col-lg-12 mt-1 ">
                                <button type="button" onClick={() =>this.updatesubscription_address()} id="guestloginbtndisbale" className="btn btn-primary custom-nxt-btn  custom-updatesubscription-btn m-l-5">Update Address</button>
                            </div>

                        </div>
                    </Modal>
                    <Popup
                        open={this.state.subscriptionopen}
                        modal
                        nested
                        closeOnDocumentClick={false}
                    >
                        {close => (
                            <div className="subsription_modal">
                                <button className="subsription_close" onClick={() => this.closesubscriptionmodel()}>
                                    &times;
                                </button>
                                <div className="subsription_header"> Add Product to Subscription
                                </div>
                                <div className="subsription_content">
                                    {' '}
                                    <div className="col-sm-12 mb-1">
                                        <div className="subscription_product_search" id="subscription_product_search">
                                            <label>Select Product</label>
                                            <input type="text" id="subscription_search_key_web" class="form-control" name="product_code_searchkey" 
                                            placeholder="What are you looking for?" onBlur={() => this.onFocusOutsearchbtn} 
                                            onKeyUp={this.onPressgetsearchint} onChange={this.onChangeHandler} value={this.state.product_code_searchkey} />
                                            <div className="subscription_display_searchdiv">                                        
                                                <div id="subscription_search-result">
                                                {this.state.Predetive_search.length>0 ? (
                                                    <>
                                                        {
                                                            this.state.Predetive_search.map(values => {
                                                                return (
                                                                    <p onClick={() => this.Click_Predetive_search(values.prod_code,values.prod_name,values.produ_imgurl,values.prod_quantity,values.unit_id)}>{values.prod_name}</p>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    ) : (
                                                        null
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.show_subscription_prod_details === true ? (
                                        <div className="col-sm-12">
                                            <div className="ml-3 subsription_product_details_display">
                                                <div className="subsription_product_img">
                                                    <img src={this.state.subscription_produ_imgurl} alt="" />
                                                </div>
                                                <div className="subsription_product_info">
                                                    <span className="font-size-14"><b>{this.state.subscription_produ_name}</b></span>
                                                    <span> UOM  : {this.state.subscription_produ_umo} </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <> </>
                                    )}
                                    <div className="col-sm-12">
                                        <div className="subscription_header_tab">
                                            <div className="subscription_tab" id="subscription_tab_one" onClick={() => this.showtabone()}>
                                                <p className="m-0">Weekly</p>
                                            </div>
                                            <div className="subscription_tab" id="subscription_tab_two" onClick={() => this.showtabtwo()}>
                                                <p className="m-0">Bi-Weekly</p>
                                            </div>
                                            <div className="subscription_tab" id="subscription_tab_three" onClick={() => this.showtabthree()}>
                                                <p className="m-0">Monthly</p>
                                            </div>
                                        </div>

                                        <div id="subscription_tab_one_content">
                                            <div className="display_weekly_days mb-1">
                                                <div className="subscription_name_lable_div subscription_name_lable_popup mb-1">
                                                    <span className="mr-2 subscription_name_lable"> Which Days ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup mb-1">
                                                    {
                                                        this.state.subscription_weekly.map(values => {
                                                            return (
                                                                <span key={values.subscription_weekly_id} className="subscription_weekly_days subscription_weekly_days_popup">
                                                                    <div className="subscription_day_lable_popup">
                                                                        
                                                                        <label  for={values.subscription_weekly_id + "toggle"}>{values.week_name}</label>
                                                                        <input type="checkbox" name="subscription_weekly" id={values.subscription_weekly_id + "toggle"} value={values.subscription_weekly_id} class="visually-hidden-lable-weeklydt" />
                                                                    </div>
                                                                    <div className="subscription_weekly_add_minus" onClick={() => this.subscription_quantity(values.week_name+"_quantity", "1", "add",values.subscription_weekly_id + "toggle")}>
                                                                        <span className="fa fa-plus"></span>
                                                                    </div>
                                                                    <div className="subscription_weekly_quantity">
                                                                        <input readOnly className="subscription_weekly_quantity_value" type="text" name={values.week_name+"_quantity" } id={values.week_name+"_quantity" } value={this.state[values.week_name+"_quantity"]}  />
                                                                    </div>
                                                                    <div className="subscription_weekly_add_minus" onClick={() => this.subscription_quantity(values.week_name+"_quantity", "1", "minus",values.subscription_weekly_id + "toggle")}>
                                                                        <span className="fa fa-minus"></span>
                                                                    </div>
                                                                    
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {/* <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                    <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup">
                                                    <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                        <div className="input-group ">
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("weekly_quantity", "1", "minus")} aria-label="Add one" data-direction="1">
                                                                    <span className="fa fa-minus"></span>
                                                                </button>
                                                            </span>
                                                            <input type="text" className="form-control product-input-number product-input-number-custsty" name="weekly_quantity" value={this.state.weekly_quantity} readOnly={true} />
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("weekly_quantity", "1", "add")} aria-label="Subtract one" data-direction="-1">
                                                                    <span className="fa fa-plus"></span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div id="subscription_tab_two_content">
                                            <div className="display_weekly_days mb-1">
                                                <div className="subscription_name_lable_div subscription_name_lable_popup mb-2">
                                                    <span className="subscription_name_lable"> Which Dates ? </span>
                                                    <span className="subscription_name_lable cust_text_clear float-right text-right" onClick={() => this.clearselectedsubscription("visually-hidden-lable-biweekly")}> Clear selected </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup subscription_biweekly_content_popup mb-1">
                                                    {
                                                        this.state.subscription_biweekly.map(values => {
                                                            return (
                                                                // <span key={values.subscription_biweekly_id} className="display_biweekly_popup_content"> {values.biweekly_dates} </span>
                                                                <span key={values.subscription_biweekly_id} className="subscription_biweekly_days_popup">
                                                                    <div key={values.subscription_biweekly_id}>
                                                                        <label className="display_biweekly_popup_content" for={values.subscription_biweekly_id + "toggle_biweekly"}>{values.biweekly_dates}</label>
                                                                        <input type="radio" name="subscription_biweekly" id={values.subscription_biweekly_id + "toggle_biweekly"} value={values.subscription_biweekly_id} class="visually-hidden-lable-biweekly" onClick={this.getbiweeklysubscriptionvalues} />
                                                                    </div>
                                                                </span>

                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                    <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup">
                                                    <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                        <div className="input-group ">
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("biweekly_quantity", "1", "minus","dummy")} aria-label="Add one" data-direction="1">
                                                                    <span className="fa fa-minus"></span>
                                                                </button>
                                                            </span>
                                                            <input type="text" className="form-control product-input-number product-input-number-custsty" name="biweekly_quantity" value={this.state.biweekly_quantity} readOnly={true} />
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("biweekly_quantity", "1", "add","dummy")} aria-label="Subtract one" data-direction="-1">
                                                                    <span className="fa fa-plus"></span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="subscription_tab_three_content">
                                            <div className="display_weekly_days mb-1">
                                                <div className="subscription_name_lable_div subscription_name_lable_popup mb-2">
                                                    <span className="subscription_name_lable"> Which Date ? </span>
                                                    <span className="subscription_name_lable cust_text_clear float-right text-right" onClick={() => this.clearselectedsubscription("visually-hidden-lable-monthly")}> Clear selected </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup subscription_biweekly_content_popup mb-1">
                                                    {
                                                        this.state.subscription_monthly.map(values => {
                                                            return (
                                                                // <span key={values.subscription_monthly_id} className="display_monthly_popup_content"> {values.monthly_date}  </span>
                                                                <span key={values.subscription_monthly_id} className="subscription_monthly_days_popup">
                                                                    <div >
                                                                        <label className="display_monthly_popup_content" for={values.subscription_monthly_id + "toggle_monthly"}>{values.monthly_date}</label>
                                                                        <input type="radio" name="subscription_biweekly" id={values.subscription_monthly_id + "toggle_monthly"} value={values.subscription_monthly_id} class="visually-hidden-lable-monthly" onClick={this.getmonthlysubscriptionvalues} />
                                                                    </div>
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                    <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup">
                                                    <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                        <div className="input-group ">
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("monthly_quantity", "1", "minus","dummy")} aria-label="Add one" data-direction="1">
                                                                    <span className="fa fa-minus"></span>
                                                                </button>
                                                            </span>
                                                            <input type="text" className="form-control product-input-number product-input-number-custsty" name="monthly_quantity" value={this.state.monthly_quantity} readOnly={true} />
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("monthly_quantity", "1", "add","dummy")} aria-label="Subtract one" data-direction="-1">
                                                                    <span className="fa fa-plus"></span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {this.state.showsubscriptionerrormessage === true ? (
                                            <p className="error ml-4"> {this.state.subscription_errormsg} </p>
                                        ) : (
                                            <> </>
                                        )}

                                        <input type="hidden" value={this.subscription_prod_id} name="subscription_prod_id" />
                                        <input type="hidden" value={this.subscription_prod_code} name="subscription_prod_code" />

                                        {this.state.addtosubscribebtn === true ? (
                                            <button className="paction add-subscription float-left add-cart add-cart-custsty" title="Add to subscription"
                                                 onClick={() => this.submit_subscription()}> Add to subscription </button>
                                        ) : (
                                            <button className="paction add-subscription float-left add-subscription-disabled add-cart add-cart-custsty" title="Add to subscription"
                                                disabled="true" > Add to subscription </button>
                                        )}
                                            <span className="float-right view_subscriotion_popup" title="View subscription"
                                                > <Link to="/Subscription"> View Subscription </Link> </span>

                                    </div>                                   

                                </div>

                            </div>
                        )}
                    </Popup>


                </div>



            </main>
        )
    }
}

export default Subscription