import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import ScriptTag from 'react-script-tag';
import TopMenu from '../../Component/TopMenu/TopMenu'
import Account from '../../Component/Account/Ac-Account'
import LoginModal from '../../Component/LoginModal/LoginModal'
import Footer from '../../Component/Footer/Footer'
import MobileMenu from '../../Component/TopMenu/MobileMenu'
import { axiosInstance } from '../../Services/PostData';

class AccountDetails extends Component {

    state = { cart_count: 0 , showactiveoffer:0, offeralert:[]}
    incrementState () 
    { 
        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance.post(`getuseryalladetails`, jsonValue)
        .then((res) => {
            if (res.data.status === "success" && res.data.statusCode === "200")
            {
                let clicks = res.data.count;
                let showactiveoffer = res.data.offer_detsils;
                if(showactiveoffer.length>0)
                {
                    this.setState({ cart_count:clicks, showactiveoffer:1, offeralert:showactiveoffer});
                }
                else
                {
                    this.setState({ cart_count:clicks, showactiveoffer:0, offeralert:[]});
                }
                
            }
        })
        .catch((err) => console.log("Error: ", err));
    }
  
    render() {
      return [
        <>
            <div className="page-wrapper">
                <Helmet>
                    <title>Yalla Basket | My Profile</title>
                    <meta name="description" content="We provide an online grocery service in dubai with more customer-centered & quality-oriented. Our handpicked products help you find the best quality at the guaranteed lowest prices." />
                </Helmet>
                <TopMenu counter={this.state.cart_count} showactiveoffer={this.state.showactiveoffer}
                offeralert={this.state.offeralert} onIncrement={() => this.incrementState()}/>
                <Account onIncrement={() => this.incrementState()}  />
                <LoginModal />
                <Footer />
            </div>
            <div className="mobile-menu-overlay"></div>
            <MobileMenu />
            <ScriptTag type="text/javascript" src="/assets/js/plugins.min.js" />
            <ScriptTag type="text/javascript" src="/assets/js/plugins/isotope-docs.min.js" /> 
            <ScriptTag type="text/javascript" src="/assets/js/main.min.js" />
            <ScriptTag type="text/javascript" src="/assets/js/custom.js" />
            <a id="scroll-top" href="#top" title="Top" role="button"><i className="icon-angle-up"></i></a>
        </>
  
      ]; 
    }
}

export default AccountDetails

