import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';


class Sitemap extends Component {

    constructor() {
        super();
        this.state = {
            responseValue: [],
        };
        this.getMainDepartments = this.getMainDepartments.bind(this);
    }


    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
        }
        this.getMainDepartments();
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }
    async getMainDepartments() {
        const mainjsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        await axiosInstance.post(`getMainDepartments`, mainjsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') {
                    let response = res.data.main_departments;
                    console.log(response);
                    let Details = response.map(values_response => { return values_response; });
                    this.setState({ responseValue: Details });
                }
            })
            .catch(err => console.log('Error: ', err));

    }

    render() {
        return (
            <main className="main home">
                <div className="container">
                    <div className="sitemap_pg_hed mt-3">
                        <h3>Yalla Basket Site Map </h3>
                    </div>
                    <div className="sitemap_pg_bdy mt-2">
                        <div class="section_pg group row">
                        <div class="col-6 col-lg-3">
                            <Link to='/'  className="color-b" ><h4 className="sitemap_mb15"> Home</h4> </Link>
                        </div>
                        <div class="col-6 col-lg-3">
                            <Link to='/Aboutus' className="color-b" ><h4 className="sitemap_mb15"> About us</h4> </Link>
                        </div>
                        <div class="col-6 col-lg-3">
                            <Link to='/Aboutus' className="color-b" ><h4 className="sitemap_mb15"> Payment Methods</h4> </Link>
                        </div>
                        <div class="col-6 col-lg-3">
                            <Link to='/Aboutus' className="color-b" ><h4 className="sitemap_mb15"> Delivery Policy/fees</h4> </Link>
                        </div>
                        <div class="col-6 col-lg-3">
                            <Link to='/Aboutus' className="color-b" ><h4 className="sitemap_mb15"> Returns Policy</h4> </Link>
                        </div>
                        <div class="col-6 col-lg-3">
                            <Link to='/Aboutus' className="color-b" ><h4 className="sitemap_mb15"> Terms and Condtions</h4> </Link>
                        </div>
                        <div class="col-6 col-lg-3">
                            <Link to='/Aboutus' className="color-b" ><h4 className="sitemap_mb15"> Privacy policy</h4> </Link>
                        </div>
                        <div class="col-6 col-lg-3">
                            <Link to='/Aboutus' className="color-b" ><h4 className="sitemap_mb15"> Contact us</h4> </Link>
                        </div>
                        
                        {
                            this.state.responseValue.map((main_dep) => (
                                <>
                                    <div class="col-6 col-lg-3">
                                        <Link className="color-b" to={{ pathname: '/' + main_dep.md_url_code + '/' + main_dep.mdId }}><h2 className="sitemap_mb10  border-b1"> {main_dep.mdName}</h2> </Link>
                                        {
                                            main_dep.sub_departments.length === 0 ? (
                                                null
                                            ) : (
                                                <>
                                                {main_dep.sub_departments.map(sub_dep => (
                                                    <>
                                                    <Link className="color-b" to={{ pathname: '/' + main_dep.md_url_code + '/' + sub_dep.sd_url_code + '/' + sub_dep.sdId }}><h3  className="sitemap_mb10 border-b1"> {sub_dep.sdName}</h3> </Link>
                                                     {
                                                        sub_dep.main_category.length === 0 ? (
                                                            null
                                                        ) : (
                                                            <>
                                                            {sub_dep.main_category.map(main_cat => (
                                                                <>
                                                                <Link className="color-b" to={{ pathname: '/'+ main_dep.md_url_code + '/' + sub_dep.sd_url_code + '/' + main_cat.mc_url_code + '/'  + main_cat.mcId }}>
                                                                    <h4  className="sitemap_mb10"> {main_cat.mcName}</h4></Link>
                                                                    {
                                                                        main_cat.sub_category.length === 0 ? (
                                                                            null
                                                                        ) : (
                                                                            <>
                                                                                {main_cat.sub_category.map(sub_cat => (
                                                                                    <>
                                                                                    <Link key={"allcategorysubcat" + sub_cat.scId} to={{ pathname: '/' + main_dep.md_url_code + '/' + sub_dep.sd_url_code + '/' + main_cat.mc_url_code + '/' + sub_cat.sc_url_code + '/' + sub_cat.scId }}><p className="sitemap_mb10">{sub_cat.scName}</p></Link>
                                                                                </>                                                                          
                                                                                ))}
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            
                                                            ))}
                                                            </>
                                                        )
                                                    }
                                                    </>
                                                
                                                ))}
                                                </>
                                            )
                                        }
                                    </div>
                                </>
                            ))
                        }

                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default Sitemap