import React, { Component } from 'react';
// import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import ScriptTag from 'react-script-tag';
import TopMenu from '../../Component/TopMenu/TopMenu'
import Home from '../../Component/Home/Home'
import LoginModal from '../../Component/LoginModal/LoginModal'
import Footer from '../../Component/Footer/Footer'
import MobileMenu from '../../Component/TopMenu/MobileMenu'
import { axiosInstance } from '../../Services/PostData';
// import JsonLd from 'jsonld';
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

class HomePage extends Component {

  state = { cart_count: 0 , showactiveoffer:0, offeralert:[]}
  incrementState () 
  { 
      const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
      axiosInstance.post(`getuseryalladetails`, jsonValue)
      .then((res) => {
          if (res.data.status === "success" && res.data.statusCode === "200")
          {
              let clicks = res.data.count;
              let showactiveoffer = res.data.offer_detsils;
              if(showactiveoffer.length>0)
              {
                  this.setState({ cart_count:clicks, showactiveoffer:1, offeralert:showactiveoffer});
              }
              else
              {
                  this.setState({ cart_count:clicks, showactiveoffer:0, offeralert:[]});
              }
              
          }
      })
      .catch((err) => console.log("Error: ", err));
  }
  
    render() {
        let Orgschema = {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Yalla Basket",
          "url": "https://yallabasket.com",
          "logo": "https://yallabasket.com/assets/img/logo.png",
          "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+971566339179",
            "contactType": "customer service",
            "areaServed": "AE",
            "availableLanguage": "en"
          },{
            "@type": "ContactPoint",
            "telephone": "+971566207179",
            "contactType": "customer service",
            "areaServed": "AE",
            "availableLanguage": "en"
          }],
          "sameAs": [
            "https://www.facebook.com/yallabasketuae",
            "https://www.instagram.com/yallabasket/"
          ]
        }

        let LocalBusinessdata = {
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "Yalla Basket",
          "image": "https://yallabasket.com/assets/img/logo.png",
          "@id": "",
          "url": "https://yallabasket.com",
          "telephone": "+971566339179",
          "priceRange": "0.5د.إ -100د.إ",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Al QuozAl - 19 16 St",
            "addressLocality": "Dubai",
            "postalCode": "",
            "addressCountry": "AE"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 25.1740344,
            "longitude": 55.2533682
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday"
            ],
            "opens": "08:00",
            "closes": "24:00"
          },
          "sameAs": [
            "https://www.facebook.com/yallabasketuae",
            "https://www.instagram.com/yallabasket/"
          ] 
        }

        let SearchSchemadata={
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Yalla Basket",
          "url": "https://yallabasket.com",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://yallabasket.com/product/searchdetails/relatedproduct/{search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }

      return [
        <>
            <div className="page-wrapper">

                <Helmet>
                    <title>Free Online Grocery delivery at best Price in dubai | Yalla Basket</title>
                    <meta name="description" content="Yalla Basket is an online grocery delivery service. Fresh fruits, vegetables and groceries are delivered to your doorstep. We stand for the best customer service. Try us now" />
                    <script type="application/ld+json">
                      {JSON.stringify(Orgschema)}
                    </script>
                    <script type="application/ld+json">
                      {JSON.stringify(LocalBusinessdata)}
                    </script>
                    <script type="application/ld+json">
                      {JSON.stringify(SearchSchemadata)}
                    </script>
                </Helmet>
                {/* <MetaTags>
                    <title>Free Online Grocery delivery at best Price in dubai | Yalla Basket</title>
                    <meta name="description" content="Yalla Basket is an online grocery delivery service. Fresh fruits, vegetables and groceries are delivered to your doorstep. We stand for the best customer service. Try us now" />
                    <meta property="og:title" content="Yalla Basket" />
                    <meta property="og:image" content="/assets/img/symbol.png" />
                    <JsonLd data={LocalBusinessdata} />
                    <JsonLd data={SearchSchemadata} />
                </MetaTags> */}
                
                <TopMenu counter={this.state.cart_count} showactiveoffer={this.state.showactiveoffer} offeralert={this.state.offeralert} onIncrement={() => this.incrementState()}/>
                <Home onIncrement={() => this.incrementState()} />
                <LoginModal />
                <Footer />
            </div>
            <div className="mobile-menu-overlay"></div>
            <MobileMenu />
            {/* <ScriptTag type="text/javascript" src="/assets/js/jquery.min.js" />
            <ScriptTag type="text/javascript" src="/assets/js/bootstrap.bundle.min.js" /> */}
            <ScriptTag type="text/javascript" src="/assets/js/plugins.min.js" />
            <ScriptTag type="text/javascript" src="/assets/js/plugins/isotope-docs.min.js" /> 
            <ScriptTag type="text/javascript" src="/assets/js/main.min.js" />
            <ScriptTag type="text/javascript" src="/assets/js/custom.js" />
            <a id="scroll-top" href="#top" title="Top" role="button"><i className="icon-angle-up"></i></a>
        </>


  
      ]; 
    }
}

export default HomePage
