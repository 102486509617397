import React from 'react';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import styles from './Home.module.css'

// **************************** IMPORT IMAGES HERE **************
import img_vegetables from '../../assets/img/category/vegetables.jpg';

function ProductCategory(props) {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 5
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3
        }
      };

      const imageData = [
          {image: img_vegetables, alternative: "vegetable"},
          {image: img_vegetables, alternative: "vegetable"},
          {image: img_vegetables, alternative: "vegetable"},
          {image: img_vegetables, alternative: "vegetable"},
          {image: img_vegetables, alternative: "vegetable"},
          {image: img_vegetables, alternative: "vegetable"},
          {image: img_vegetables, alternative: "vegetable"},
        ]

    return (
        <>
        </>
        // <Carousel responsive={responsive}
        // itemClass={styles.item_class}>
        //     {imageData.map((items, index) => (
        //         <div key={index}>
        //             <img src={items.image} alt={items.alternative} />
        //         </div>
        //     ))}
        // </Carousel>
    );
}

export default ProductCategory;