import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
// import Cookies from 'universal-cookie';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// const cookies = new Cookies();

class Account extends Component {

    constructor() {
        super();
        this.state = {
            orderDetails: [],
            username: "",
            email: "",
            mobile_no: "",
            address: "",
            state: "",
            city: "Dubai",
            pincode: "",
            useraddressDetails: [],
            userDetails: [],
            ybarealist: [],
            spinner: 'loading-overlay',
            small_spinner: 'ajax-overlay hide-content',
            address_status: false,
            profile_firstname: "",
            profile_lastname: "",
            profile_email: "",
            profile_mobile_no: "",
            profile_dateofbirth:"",
            profile_nationality:"",
            profile_anniversary:"",
            currentpassword: "",
            newpassword: "",
            updateaddressstatus: false,
            address_id: "",
            show_forgetpassword_otps: false
        };
        this.getordersdetails = this.getordersdetails.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.addresssubmitForm = this.addresssubmitForm.bind(this);
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' || 
            user_auth_key === "undefined" || user_auth_key === "null") 
        {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
        this.getordersdetails();
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }


    async getordersdetails() {
        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
            .post(`getUserOrderDetails`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let order_details = res.data.order_details;
                    let OrderDetails = order_details.map(order_response => { return order_response; });
                    this.setState({ orderDetails: OrderDetails });
                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner });
                }
            })
            .catch((err) => console.log("Error: ", err));

        // const jsonValue = { user_unique_id: cookies.get('user_auth_key') };
        axiosInstance
            .post(`getuseraddres`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let useraddressResponse = res.data.user_address_details;
                    let userResponse = res.data.user_details;
                    let arealistResponse = res.data.area_list;
                    let UserDetails = userResponse.map(user_response => { return user_response });
                    let UserAddressDetails = useraddressResponse.map(useraddress_response => { return useraddress_response });
                    let arealists = arealistResponse.map(order_response => { return order_response });
                    this.setState({ userDetails: UserDetails, useraddressDetails: UserAddressDetails, ybarealist: arealists });
                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner });
                    if (this.state.userDetails.length === 0) {
                        this.setState({ spinner, username: '', email: '', mobile_no: '' });
                    }
                    else {
                        this.setState({ spinner, username: res.data.user_details[0].username, email: res.data.user_details[0].email, mobile_no: res.data.user_details[0].mobile_no });
                        this.setState({
                            profile_firstname: res.data.user_details[0].username,
                            profile_lastname: res.data.user_details[0].lastname,
                            profile_email: res.data.user_details[0].email,
                            profile_mobile_no: res.data.user_details[0].mobile_no,
                            profile_dateofbirth: res.data.user_details[0].dateofbirth,
                            profile_nationality: res.data.user_details[0].nationality,
                            profile_anniversary: res.data.user_details[0].anniversary,
                        });
                    }
                    if (UserAddressDetails.length === 0) {
                        this.setState({ address_status: true });
                    }

                }
            })
            .catch((err) => console.log("Error: ", err));
    }

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    addresssubmitForm() {
        let small_spinner = 'ajax-overlay ';
        this.setState({ small_spinner });
        const jsonValue = {
            username: this.state.username,
            mobile_no: this.state.mobile_no,
            landmark: this.state.landmark,
            email: this.state.email,
            city: "Dubai",
            building_name: this.state.building_name,
            flat_no: this.state.flat_no,
            area: this.state.area,
            address_type: this.state.address_type,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`InsertAddress`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    NotificationManager.success('Address Added', 'Success', 200);
                    this.getordersdetails();
                    this.setState({ address_status: false, updateaddressstatus: false });
                    this.setState({ Preview: "Basket Info", selectedIndex: 1, address_id: res.data.address_id, username: "", mobile_no: "", address: "", state: "", city: "", pincode: "" });
                    // window.location.reload();
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
    }

    addressForm(value, username, mobile_no, address, state, city, pincode, email, address_type) {

        let usernameError = "";
        let mobile_noError = "";
        let email_noError = "";
        let flatnoError = "";
        let buildingnameError = "";
        let areaError = "";
        let addresstypeError = "";

        if (!username) {
            usernameError = "Username cannot be blank";
        }
        if (!mobile_no) {
            mobile_noError = "Enter Mobile Number";
        }
        if (!email) {
            email_noError = "Enter Mobile Number";
        }
        else if (mobile_no.length !== 9) {
            mobile_noError = "Your mobile number should be 9 Digit";
        }

        if (!this.state.flat_no) {
            flatnoError = "Flat No cannot be blank";
        }

        if (!this.state.building_name) {
            buildingnameError = "Building name cannot be blank";
        }
        if (!this.state.area) {
            areaError = "Area cannot be blank";
        }
        if (!this.state.address_type) {
            addresstypeError = "Address type cannot be blank";
        }

        if (usernameError || mobile_noError || flatnoError || buildingnameError || areaError || email_noError || addresstypeError) {
            this.setState({
                usernameError,
                mobile_noError,
                email_noError,
                flatnoError,
                buildingnameError,
                areaError,
                addresstypeError
            });
            return false;
        }
        else {
            this.addresssubmitForm();
            return true;
        }
    }

    showaddressform() {
        // alert('hai');
        let usernameError = "";
        let mobile_noError = "";
        let email_noError = "";
        let flatnoError = "";
        let buildingnameError = "";
        let areaError = "";
        this.setState({
            usernameError,
            mobile_noError,
            email_noError,
            flatnoError,
            buildingnameError,
            areaError
        });
        this.setState({ address_status: true, updateaddressstatus: false, address_id: "" });
    }

    hideaddresshorm() {
        let usernameError = "";
        let mobile_noError = "";
        let email_noError = "";
        let flatnoError = "";
        let buildingnameError = "";
        let areaError = "";
        this.setState({
            usernameError,
            mobile_noError,
            email_noError,
            flatnoError,
            buildingnameError,
            areaError
        });
        this.setState({ address_status: false, updateaddressstatus: false });
        this.setState({ address_id: "", username: "", mobile_no: "", flat_no: "", building_name: "", landmark: "", area: "" });
    }

    submitprofileform() {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        if (this.state.profile_email === '') {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            this.setState({ profile_emailError: "Email Cannot Be Empty" });
        }

        if (this.state.profile_firstname === '') {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            this.setState({ profile_firstnameError: "First Name Cannot Be Empty" });
        }

        if (this.state.profile_mobile_no === '') {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            this.setState({ profile_mobile_noError: "First Name Cannot Be Empty" });
        }

        else if (this.state.profile_email !== '' && this.state.profile_firstname !== '' && this.state.profile_mobile_no !== '') {
            const jsonValue = {
                user_unique_id: localStorage.getItem('user_auth_key'),
                email: this.state.profile_email,
                username: this.state.profile_firstname,
                lastname: this.state.profile_lastname,
                mobile_no: this.state.profile_mobile_no,
                dateofbirth: this.state.profile_dateofbirth,
                nationality: this.state.profile_nationality,
                anniversary: this.state.profile_anniversary,
            };

            axiosInstance
                .post(`updateuserprofile`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Details Updated Succesfully', 'Success', 200);
                        window.location.reload();
                    }
                    else {
                        NotificationManager.error('Something went wrong', 'Error', 500);
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }

    }

    changepassword() {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        if (this.state.currentpassword === '') {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            this.setState({ currentpasswordError: "Current Password Cannot Be Empty" });
        }

        if (this.state.newpassword === '') {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            this.setState({ newpasswordError: "New Password Cannot Be Empty" });
        }

        if (this.state.newpassword.length <= 8) {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            this.setState({ newpasswordError: "Password should have atlease 8 character" });
        }
        else if (this.state.currentpassword !== '' && this.state.newpassword !== '') {
            this.setState({ currentpasswordError: "", newpasswordError: "" });
            const jsonValue = {
                user_unique_id: localStorage.getItem('user_auth_key'),
                current_password: this.state.currentpassword,
                new_password: this.state.newpassword
            };

            axiosInstance
                .post(`changepassword`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Password Updated Succesfully', 'Success', 200);
                        window.location.reload();
                    }
                    else {
                        this.setState({ currentpasswordError: res.data.msg });
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }

    }
    reorder(id, code) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            order_id: id,
            orderno: code,
            user_unique_id: localStorage.getItem('user_auth_key')
        };
        axiosInstance
            .post(`reordercustomer`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.setState({ userRedirect: true });
                    //   let order_details = res.data.order_details;
                    //   let OrderDetails = order_details.map(order_response => { return order_response; });
                    //   this.setState({ orderDetails: OrderDetails});
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                }
            })
            .catch((err) => console.log("Error: ", err));

    }



    editaddresssingle(id, username, mobile_no, flat_no, building_name, landmark, area, city, address_type) {
        let usernameError = "";
        let mobile_noError = "";
        let email_noError = "";
        let flatnoError = "";
        let buildingnameError = "";
        let areaError = "";
        this.setState({
            usernameError,
            mobile_noError,
            email_noError,
            flatnoError,
            buildingnameError,
            areaError
        });

        this.setState({ address_id: id, address_status: true, updateaddressstatus: true, username: username, mobile_no: mobile_no, flat_no: flat_no, building_name: building_name, landmark: landmark, area: area, address_type: address_type });
    }
    deleteaddresssingle(id) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            address_id: id,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`deleteAddressnew`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    NotificationManager.success('Address Deleted ', 'Success', 200);
                    this.getordersdetails();
                    this.setState({ address_status: false, updateaddressstatus: false });
                    this.setState({ Preview: "Basket Info", selectedIndex: 1, address_id: res.data.address_id, username: "", mobile_no: "", address: "", state: "", city: "", pincode: "" });
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
    }
    updateaddressnew(ides, username, mobile_no, flat_no, building_name, landmark, area, city, email) {
        let usernameError = "";
        let mobile_noError = "";
        let email_noError = "";
        let flatnoError = "";
        let buildingnameError = "";
        let areaError = "";
        let addresstypeError = "";

        if (!username) {
            usernameError = "Username cannot be blank";
        }
        if (!mobile_no) {
            mobile_noError = "Enter Mobile Number";
        }
        if (!email) {
            email_noError = "Enter Mobile Number";
        }
        else if (mobile_no.length !== 9) {
            mobile_noError = "Your mobile number should be 9 Digit";
        }

        if (!this.state.flat_no) {
            flatnoError = "Flat No cannot be blank";
        }

        if (!this.state.building_name) {
            buildingnameError = "Building name cannot be blank";
        }
        if (!this.state.area) {
            areaError = "Area cannot be blank";
        }
        if (!this.state.address_type) {
            addresstypeError = "Address type cannot be blank";
        }

        if (usernameError || mobile_noError || flatnoError || buildingnameError || areaError || email_noError || addresstypeError) {
            this.setState({
                usernameError,
                mobile_noError,
                email_noError,
                flatnoError,
                buildingnameError,
                areaError,
                addresstypeError
            });
            return false;
        }
        else {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            const jsonValue = {
                username: this.state.username,
                mobile_no: this.state.mobile_no,
                landmark: this.state.landmark,
                email: this.state.email,
                city: "Dubai",
                building_name: this.state.building_name,
                flat_no: this.state.flat_no,
                area: this.state.area,
                address_id: this.state.address_id,
                address_type: this.state.address_type,
                user_unique_id: localStorage.getItem('user_auth_key'),
            };
            axiosInstance
                .post(`updateAddress`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Address Updateded', 'Success', 200);
                        this.getordersdetails();
                        this.setState({ address_status: false, updateaddressstatus: false });
                        this.setState({ Preview: "Basket Info", selectedIndex: 1, address_id: res.data.address_id, username: "", mobile_no: "", address: "", state: "", city: "", pincode: "" });
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "400") {
                        NotificationManager.error('Something went wrong', 'Error', 500);
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Login: ", err));
        }
    }


    generatecurrentpasswordotp = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({ show_loginerror_msg: false, currentpasswordError: "" });
        let currentpasswordError = '';
        if (!this.state.currentpassword_otp) {
            let small_spinner = 'ajax-overlay hide-content';
            currentpasswordError = "Please enter valid Email ID/Mobile number";
            this.setState({
                currentpasswordError,
                small_spinner
            });
        }
        else {
            let currentpasswordError = "";
            this.setState({
                currentpasswordError,
            });

            const jsonValue = {
                mobile_no: this.state.currentpassword_otp,
                mobile_no_code: "+971",
            };
            axiosInstance
                .post(`checkuserandsendotp`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        this.setState({ show_forgetpassword_otps: true });
                        this.setState({ passwordotpcode: '', setpasswordnew: '' });
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "400") {
                        this.setState({ currentpasswordError: res.data.msg });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Login: ", err));
        }
    }



    updatepasswordusingotp() {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({ show_passwordlogerror_msg: false, loginpassworderror: "" });
        let passwordotpcodeError = '';
        let setpasswordnewError = '';

        if (!this.state.passwordotpcode) {
            let small_spinner = 'ajax-overlay hide-content';
            passwordotpcodeError = "Please enter valid OTP";
            this.setState({
                passwordotpcodeError,
                setpasswordnewError,
                small_spinner
            });
        }
        else if (!this.state.setpasswordnew) {
            let small_spinner = 'ajax-overlay hide-content';
            setpasswordnewError = "Please enter Password";
            this.setState({
                setpasswordnewError,
                passwordotpcodeError,
                small_spinner
            });
        }
        else if (this.state.setpasswordnew.length <= 7) {
            let small_spinner = 'ajax-overlay hide-content';
            setpasswordnewError = "Password must contain 8 characters";
            this.setState({
                setpasswordnewError,
                passwordotpcodeError,
                small_spinner
            });
        }
        else {
            let small_spinner = 'ajax-overlay';
            this.setState({
                passwordotpcodeError,
                setpasswordnewError,
                small_spinner,
            });
            const jsonValue = {
                username: this.state.currentpassword_otp,
                otp: this.state.passwordotpcode,
                user_unique_id: localStorage.getItem('user_auth_key'),
                updatepassword: this.state.setpasswordnew,
            };
            axiosInstance
                .post(`updatepasswordusingotp`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Password updated Successfully', 'Success', 500);
                        //   window.location.reload();
                        this.setState({ show_forgetpassword_otps: false, currentpassword_otp: "", passwordotpcode: "", setpasswordnew: "" });
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "201") {
                        //   NotificationManager.error('Something went wrong', 'Error',500);
                        this.setState({ show_logingerror_msg: true });
                        this.setState({ loginnerror: res.data.errorStatus })
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Login: ", err));
        }
    }

    hidechangepasswordotomodel = () => {
        this.setState({ show_forgetpassword_otps: false });
    }

    render() {

        if (this.state.userRedirect) {
            return <Redirect to="/Cart" />;
        }

        return (
            <main className="main home">
                <NotificationContainer />
                <div className={this.state.small_spinner}>
                    <i className="porto-loading-icon"></i>
                </div>
                <div className={this.state.spinner}>
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>

                <div className="container minheight-80vh">
                    <div className="row pad-50 pad-50-sm">
                        <div className="col-lg-3 col-md-10 col-12">
                            <div className="tab-leftside-head">
                                <h2>Hello {this.state.profile_firstname}</h2>
                                <p>Sign out</p>
                            </div>


                            <ul className="nav nav-tabs tabs-left cust-tablist" role="tablist">
                                <li className="profile_activepanel">
                                    <Link className=" menuactive" to={{ pathname: '/Ac-accountDetails' }}> 
                                    <img src="/assets/img/user-ico.png" alt="" />&nbsp; &nbsp; Profile</Link>
                                </li>
                                <li className="profile_activepanel">
                                    <Link className=" menuactive" to={{ pathname: '/Orders' }}> 
                                    <img src="/assets/img/order-ico.png" alt="" />&nbsp; &nbsp; Orders</Link>
                                </li>
                                <li className="profile_activepanel">
                                    <a className="menuactive active show" href="#profile" aria-controls="profile" role="tab" data-toggle="tab">
                                        <img src="/assets/img/address-ico.png" alt="" />&nbsp; &nbsp; Addresses</a>
                                </li>
                                <li className="profile_activepanel">
                                    <a className="menuactive" href="#messages" aria-controls="messages" role="tab" data-toggle="tab">
                                        <img src="/assets/img/return-ico.png" alt="" />&nbsp; &nbsp; Returns</a>
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-9 col-md-12 col-12 sm-padding">
                            <div className="tab-content tab-content-cust">

                                <div className="tab-pane" id="settings">
                                    <div className="tab-leftside-head">
                                        <h2 className="color-b">Profile</h2>
                                        <p>Manage your profile details</p>
                                    </div>
                                    <div className="card custprof-bordernone">
                                        <div className="profile-card-body profile-card-body ">
                                            <h5 className="card-title profile-card-title">General Information</h5>
                                            <div className="card-text profile-card-text">
                                                <div className="row">
                                                    <div className="col-sm-6 custprof-padding10">
                                                        <label className="custprof-lable">First Name</label>
                                                        <input type="text" className="form-control" value={this.state.profile_firstname} name="profile_firstname" onChange={this.onChangeHandler} placeholder="First Name" required="" />
                                                        <span className="error">
                                                            {this.state.profile_firstnameError}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 custprof-padding10">
                                                        <label className="custprof-lable">Last Name</label>
                                                        <input type="text" className="form-control" value={this.state.profile_lastname} name="profile_lastname" onChange={this.onChangeHandler} placeholder="Last Name" required="" />
                                                    </div>
                                                    <div className="col-sm-6 custprof-padding10">
                                                        <label className="custprof-lable">Email </label>
                                                        <input type="text" className="form-control" value={this.state.profile_email} name="profile_email" onChange={this.onChangeHandler} placeholder="Email Address" required="" readOnly />
                                                        <span className="error">
                                                            {this.state.profile_emailError}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className="custprof-lable">Mobile number </label>
                                                        <input type="text" className="form-control" value={this.state.profile_mobile_no} name="profile_mobile_no" onChange={this.onChangeHandler} placeholder="Mobile Number" required="" readOnly />
                                                        <span className="error">
                                                            {this.state.profile_mobile_noError}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 custprof-padding10">
                                                        <label className="custprof-lable">D.O.B</label>
                                                        <input type="text" className="form-control" value={this.state.profile_dateofbirth || "" } name="profile_dateofbirth" onChange={this.onChangeHandler} placeholder="Enter D.O.B" required=""  />
                                                    </div>
                                                    <div className="col-sm-6 custprof-padding10">
                                                        <label className="custprof-lable">Nationality</label>
                                                        <input type="text" className="form-control" value={this.state.profile_nationality || "" } name="profile_nationality" onChange={this.onChangeHandler} placeholder="Enter Your Nationality" required=""  />
                                                    </div>
                                                    <div className="col-sm-6 custprof-padding10">
                                                        <label className="custprof-lable">Anniversary</label>
                                                        <input type="text" className="form-control" value={this.state.profile_anniversary || "" } name="profile_anniversary" onChange={this.onChangeHandler} placeholder="Enter Your Anniversary Date" required=""  />
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <button type="submit" onClick={() => this.submitprofileform()} className="reorder-btn btn btn-primary profile-save-btn">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card custprof-bordernone">
                                        <div className="profile-card-body profile-card-body ">
                                            <h5 className="card-title profile-card-title">Change Password Using OTP</h5>
                                            <div className="card-text profile-card-text">
                                                <div className="row">
                                                    {this.state.show_forgetpassword_otps === true ? (
                                                        <div className="col-sm-8">
                                                            <>
                                                                <div className="form-group form-group-custstyler">
                                                                    <label className="custprof-lable">Email / Mobile number</label>
                                                                    <span onClick={() => this.hidechangepasswordotomodel()} className="forget-text"> Change ?</span>
                                                                    <input type="text" readOnly className="form-control form-control-sm" value={this.state.currentpassword_otp} />
                                                                </div>
                                                                <ul className="nav nav-tabs nav-tabs-custstylere">
                                                                    <li className="nav-item nave-item-custstylers">
                                                                        Not received your code? <span className="nav-link nav-link-cust-styler" onClick={() => this.generatecurrentpasswordotp()} > Resend code</span>
                                                                    </li>
                                                                </ul>
                                                                <div className="form-group form-group-custstyler">
                                                                    <label htmlFor="passwordotpcode">OTP</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="passwordotpcode" className="form-control form-control-sm"
                                                                        value={this.state.passwordotpcode} id="passwordotpcode" placeholder="Enter OTP" />
                                                                    <span className="error">
                                                                        {this.state.passwordotpcodeError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group form-group-custstyler">
                                                                    <label htmlFor="setpasswordnew">Set Password</label>
                                                                    <input type="password" onChange={this.onChangeHandler} name="setpasswordnew" className="form-control form-control-sm"
                                                                        value={this.state.setpasswordnew} id="setpasswordnew" placeholder="Enter Password" />
                                                                    <span className="error">
                                                                        {this.state.setpasswordnewError}
                                                                    </span>
                                                                </div>
                                                                <button type="button" onClick={() => this.updatepasswordusingotp()} className="btn btn-primary btn-block btn-block-custmstyler form-group-custstyler">Change Password</button>
                                                                <span className="error">
                                                                    {this.state.loginnerror}
                                                                </span>
                                                            </>
                                                        </div>
                                                    ) : (
                                                            <>
                                                                <div className="col-sm-6">
                                                                    <label htmlFor="currentpassword">Email / Mobile Number</label>
                                                                    <input type="text" className="form-control" onChange={this.onChangeHandler} name="currentpassword_otp" placeholder="Enter Email/Mobile number to generate otp" required="" value={this.state.currentpassword_otp} />
                                                                    <span className="error">
                                                                        {this.state.currentpasswordError}
                                                                    </span>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label htmlFor="currentpassword "> &nbsp;</label>
                                                                    <button type="submit" onClick={() => this.generatecurrentpasswordotp()} className="card-text-password reorder-btn btn btn-primary custprof-flright">Generate OTP</button>
                                                                </div>
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* <div className="card-text">

                                </div> */}



                                </div>
                                <div className="tab-pane" id="home">
                                    <div className="tab-leftside-head">
                                        <h2 className="color-b">Orders</h2>
                                        <p>See how your orders are managed and check the latest status on your order</p>
                                    </div>
                                    {this.state.orderDetails.length !== 0 ? (
                                        <div className="container no-pad-res">
                                            {
                                                this.state.orderDetails.map((orders) => (
                                                    <div key={orders.order_id}className="cart-items order-pad order-item-pad">
                                                        <div className="row cart-bottom">
                                                            <div className="col-lg-12">
                                                                <div className="order-header">
                                                                    <h4 className="order-title-style">Order No : {orders.orderno}</h4>
                                                                    <h4 className="order-date">{orders.ordered_date}</h4>
                                                                </div>
                                                            </div>
                                                            {orders.produc_info.map(products => (
                                                                <div key={products.prod_id} className="col-lg-12">
                                                                    <div className="row">
                                                                        <div className="col-lg-2 col-2 hide-on-mobile">
                                                                            <img className="cart-img order-img" src={products.produ_imgurl} alt="" />
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 col-sm-8 col-8">
                                                                            <div className="cart-details mb-2">
                                                                                {products.produ_imgurl === 'Requested Product' ? (
                                                                                    <p className="cart-category">{products.produ_imgurl}</p>
                                                                                ) : (
                                                                                        <p className="cart-category">{products.mcName}</p>
                                                                                    )}

                                                                                <p className="cart-name  mb-1"> {products.prod_name} {products.prod_quantity} {products.unit_id} </p>
                                                                                <p className="cart-total-price mb-0 mt-0"> {products.quantity} X {products.prod_quantity} {products.unit_id}</p>
                                                                                {/* <p className="cart-total-weight">UOM : {products.prod_quantity} {products.unit}</p> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-4">
                                                                            <div className="cart-details">
                                                                                <p className="cart-category">&nbsp;</p>
                                                                                {/* <p>&nbsp;</p> */}
                                                                                {/* <p className="cart-total-weight">Total UOM : {products.prod_total_qty}{products.unit_id}</p> */}
                                                                                {/* <p className="cart-name">AED {products.prod_total_price}</p> */}
                                                                                {products.produ_imgurl === 'Requested Product' ? (
                                                                                    <p className="cart-name">AED 0.00</p>
                                                                                ) : (
                                                                                        <p className="cart-name">AED {products.prod_total_price}</p>
                                                                                    )}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-6 web-res-mt-2">
                                                                        <h4 className="text-left">Payment Type : <span className="cart-total-price">{orders.payment_type}</span></h4>
                                                                    </div>
                                                                    <div className="col-lg-6 col-6 web-res-mt-2">
                                                                        <h4 className="text-right">Total : AED {orders.total_price}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 border-top">
                                                                <div className="row mt-1">
                                                                    {/* <div className="col-lg-6 col-6">
                                                        <Link to="/"><button className="guest-shopping-btn mb-2">Continue Shopping</button></Link>
                                                    </div> */}
                                                                    <div className="col-lg-12 col-12 text-right">
                                                                        <button onClick={() => this.reorder(orders.order_id, orders.orderno)} className="reorder-btn text-right guest-shopping-btn mb-2">Reorder</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ) : (
                                            <div className="row row-sm text-center">
                                                <div className="custom-no-product">
                                                    <img src="/assets/img/cart-empty.png" alt="" />
                                                    <p className="cart-empty-slogan">Hi <b>{localStorage.getItem('yallabasketuser')}</b>, you are yet to start your shopping journey with us.<br /> Excitedly looking forward to serving you</p>
                                                    <Link to="/"><button className="guest-shopping-btn mb-3">Start Shopping</button></Link>
                                                </div>
                                            </div>
                                        )}


                                </div>
                                <div className="tab-pane active" id="profile">
                                    <div className="tab-leftside-head">
                                        <h2 className="color-b">Address</h2>
                                        <p>Add, remove and select preferred addresses</p>
                                    </div>
                                    <div className="container mb-2">
                                        <div className="row">
                                            {this.state.useraddressDetails.length !== 0 ?
                                                (
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            {
                                                                this.state.useraddressDetails.map(useraddress => {
                                                                    return (
                                                                        <>

                                                                            <div className="col-lg-12">
                                                                                <div key={useraddress.address_id} className="custom-user-address custom-user-address-custt " onChange={this.onChangeValue}>
                                                                                    {/* <input type="radio"
                                                                id={useraddress.address_id}
                                                                onChange={this.onChangeHandler}
                                                                value={useraddress.address_id}
                                                                name="address_id" className="custom-radio mr-2" /> */}
                                                                                    <label className="addresscontent" htmlFor={useraddress.address_id}>
                                                                                        <p><b>{useraddress.address_type}</b></p>
                                                                                        <p>{useraddress.username},
                                                                    +971 {useraddress.mobile_no},<br />
                                                                                            {useraddress.flat_no}, {useraddress.building_name}, {useraddress.landmark}, {useraddress.area}, {useraddress.city}.</p>
                                                                                    </label>
                                                                                    <div className="addressbtnaction"> <p className="addressactionbtnclickprocess"
                                                                                        onClick={() => this.editaddresssingle(useraddress.address_id, useraddress.username, useraddress.mobile_no, useraddress.flat_no, useraddress.building_name, useraddress.landmark, useraddress.area, useraddress.city, useraddress.address_type)}> <i className="fas fa-edit"></i>&nbsp; Edit</p> </div>
                                                                                    <div className="addressbtnaction"> <p className="addressactionbtnclickprocess" onClick={() => this.deleteaddresssingle(useraddress.address_id)}> <i className="fas fa-trash-alt"></i>&nbsp; Delete</p> </div>
                                                                                </div>
                                                                                {/* <span className="error">
                                                                {this.state.radioaddressError}
                                                            </span> */}
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </div>

                                                        { this.state.address_status ? (
                                                            <div className="row mt-2">
                                                                <div className="form-group col-lg-12">
                                                                    {this.state.updateaddressstatus === false ? (
                                                                        <h3 className="addressheaderss">Add Address</h3>

                                                                    ) : (
                                                                            <h3 className="addressheaderss">Update Address</h3>
                                                                        )}

                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="username">Full Name</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="username" className="form-control form-control-sm" value={this.state.username} id="username" placeholder="Enter Fullname" />
                                                                    <span className="error">
                                                                        {this.state.usernameError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label className="form_mobile_num_code_lable" htmlFor="mobile_no">Mobile Number</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="mobile_no_code" className="form-control form-control-sm form_mobile_num_code" value="+971" readOnly />
                                                                    <input type="number" onChange={this.onChangeHandler} name="mobile_no" value={this.state.mobile_no} className="form-control form-control-sm form_mobile_num" id="mobile_no" placeholder="Enter Mobile Number" onKeyDown={this.handleKeyPress} />
                                                                    {/* <input type="mobile_no" onChange={this.onChangeHandler} name="mobile_no" className="form-control form-control-sm" value={this.state.mobile_no} id="mobile_no" placeholder="Enter Mobile Number" /> */}
                                                                    <span className="error">
                                                                        {this.state.mobile_noError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="email">Email</label>
                                                                    <input type="email" onChange={this.onChangeHandler} name="email" className="form-control form-control-sm" value={this.state.email} id="email" placeholder="Enter Email ID" />
                                                                    <span className="error">
                                                                        {this.state.email_noError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="flat_no">Flat No</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="flat_no" value={this.state.flat_no} className="form-control form-control-sm" id="flat_no" placeholder="Enter Flat Number" />
                                                                    <span className="error">
                                                                        {this.state.flatnoError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="building_name">Building Name</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="building_name" value={this.state.building_name} className="form-control form-control-sm" id="building_name" placeholder="Enter Building Name" />
                                                                    <span className="error">
                                                                        {this.state.buildingnameError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="landmark">Landmark or Street</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="landmark" value={this.state.landmark} className="form-control form-control-sm" id="landmark" placeholder="Enter Landmark" />
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="area">Area</label>
                                                                    <select onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" value={this.state.area}>
                                                                        <option value=""> -- Please Select Area -- </option>
                                                                        {/* {
                                                                this.state.ybarealist.map(arealistsds => {
                                                                    <option value={arealistsds.area_name}>{arealistsds.area_name}</option>
                                                                })} */}
                                                                        {this.state.ybarealist.map((team) => (
                                                                            <option
                                                                                key={team.area_master_id}
                                                                                value={team.area_name}
                                                                            >
                                                                                {team.area_name}
                                                                            </option>
                                                                        ))}

                                                                        {/* <option value=""> -- Please Select Area -- </option>
                                                                <option value="Al Barari">Al Barari</option>
                                                                <option value="AL Furjan">AL Furjan</option>
                                                                <option value="AL Quoz 1">AL Quoz 1</option>
                                                                <option value="Business Bay">Business Bay</option>
                                                                <option value="Downtown Dubai">Downtown Dubai</option>
                                                                <option value="Dubai Silicon Oasis">Dubai Silicon Oasis</option>
                                                                <option value="Falcon City of Wonders">Falcon City of Wonders</option>
                                                                <option value="Liwan Queue Point">Liwan Queue Point</option>
                                                                <option value="Nad Al Sheba">Nad Al Sheba</option>
                                                                <option value="The Villa">The Villa</option> */}
                                                                    </select>
                                                                    {/* <input type="text" onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" placeholder="Enter Pincode" /> */}
                                                                    <span className="error">
                                                                        {this.state.areaError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="city">City</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="city" className="form-control form-control-sm" id="city" placeholder="Enter City" value="Dubai" />
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="area">Address Type</label>
                                                                    <select onChange={this.onChangeHandler} name="address_type" className="form-control form-control-sm" id="address_type" value={this.state.address_type}>
                                                                        <option value=""> -- Please Select Type -- </option>
                                                                        <option value="Home">Home </option>
                                                                        <option value="Work">Work </option>
                                                                        <option value="Other">Other </option>
                                                                    </select>
                                                                    {/* <input type="text" onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" placeholder="Enter Pincode" /> */}
                                                                    <span className="error">
                                                                        {this.state.addresstypeError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-12">
                                                                    {this.state.useraddressDetails.length !== 0 ?
                                                                        <button type="button" onClick={() => this.hideaddresshorm()} className="btn btn-primary custom-address-btn m-l-5">Cancel</button>
                                                                        : null
                                                                    }
                                                                    {this.state.updateaddressstatus === false ? (
                                                                        <button type="button" onClick={() => this.addressForm(1, this.state.username, this.state.mobile_no, this.state.flat_no, this.state.building_name, this.state.landmark, this.state.area, this.state.city, this.state.email, this.state.address_type)} className="btn btn-primary custom-address-btn m-l-5">Add</button>

                                                                    ) : (
                                                                            <button type="button" onClick={() => this.updateaddressnew(1, this.state.username, this.state.mobile_no, this.state.flat_no, this.state.building_name, this.state.landmark, this.state.area, this.state.city, this.state.email, this.state.address_type)} className="btn btn-primary custom-address-btn m-l-5">Update</button>
                                                                        )}


                                                                </div>
                                                            </div>
                                                        ) : (
                                                                <div className="row custom-add-address">
                                                                    <p onClick={() => this.showaddressform()} className="custom-address-add custom-address-add-cust">&nbsp; <i className="fas fa-address-card"></i> Add Address</p>
                                                                </div>
                                                            )}
                                                    </div>
                                                ) : (
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="form-group col-lg-6">
                                                                <label htmlFor="username">Full Name</label>
                                                                <input type="text" onChange={this.onChangeHandler} name="username" className="form-control form-control-sm" value={this.state.username} id="username" placeholder="Enter Fullname" />
                                                                <span className="error">
                                                                    {this.state.usernameError}
                                                                </span>
                                                            </div>
                                                            <div className="form-group col-lg-6">
                                                                <label className="form_mobile_num_code_lable" htmlFor="mobile_no">Mobile Number</label>
                                                                <input type="text" onChange={this.onChangeHandler} name="mobile_no_code" className="form-control form-control-sm form_mobile_num_code" value="+971" readOnly />
                                                                <input type="number" onChange={this.onChangeHandler} name="mobile_no" value={this.state.mobile_no} className="form-control form-control-sm form_mobile_num" id="mobile_no" placeholder="Enter Mobile Number" onKeyDown={this.handleKeyPress} />
                                                                {/* <input type="mobile_no" onChange={this.onChangeHandler} name="mobile_no" className="form-control form-control-sm" value={this.state.mobile_no} id="mobile_no" placeholder="Enter Mobile Number" /> */}
                                                                <span className="error">
                                                                    {this.state.mobile_noError}
                                                                </span>
                                                            </div>
                                                            <div className="form-group col-lg-6">
                                                                <label htmlFor="email">Email</label>
                                                                <input type="email" onChange={this.onChangeHandler} name="email" className="form-control form-control-sm" value={this.state.email} id="email" placeholder="Enter Email ID" />
                                                            </div>
                                                            <div className="form-group col-lg-6">
                                                                <label htmlFor="flat_no">Flat No</label>
                                                                <input type="text" onChange={this.onChangeHandler} name="flat_no" className="form-control form-control-sm" id="flat_no" placeholder="Enter Flat Number" />
                                                                <span className="error">
                                                                    {this.state.flatnoError}
                                                                </span>
                                                            </div>
                                                            <div className="form-group col-lg-4">
                                                                <label htmlFor="building_name">Building Name</label>
                                                                <input type="text" onChange={this.onChangeHandler} name="building_name" className="form-control form-control-sm" id="building_name" placeholder="Enter Building Name" />
                                                                <span className="error">
                                                                    {this.state.buildingnameError}
                                                                </span>
                                                            </div>
                                                            <div className="form-group col-lg-4">
                                                                <label htmlFor="landmark">Landmark or Street</label>
                                                                <input type="text" onChange={this.onChangeHandler} name="landmark" className="form-control form-control-sm" id="landmark" placeholder="Enter Landmark" />
                                                            </div>
                                                            <div className="form-group col-lg-4">
                                                                <label htmlFor="area">Area</label>
                                                                <select onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area">
                                                                    <option value=""> -- Please Select Area -- </option>
                                                                    {/* {
                                                                this.state.ybarealist.map(arealistsds => {
                                                                    <option value={arealistsds.area_name}>{arealistsds.area_name}</option>
                                                                })} */}
                                                                    {this.state.ybarealist.map((team) => (
                                                                        <option
                                                                            key={team.area_master_id}
                                                                            value={team.area_name}
                                                                        >
                                                                            {team.area_name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {/* <input type="text" onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" placeholder="Enter Pincode" /> */}
                                                                <span className="error">
                                                                    {this.state.areaError}
                                                                </span>
                                                            </div>
                                                            <div className="form-group col-lg-6">
                                                                <label htmlFor="city">City</label>
                                                                <input type="text" onChange={this.onChangeHandler} name="city" className="form-control form-control-sm" id="city" placeholder="Enter City" value="Dubai" />
                                                            </div>
                                                            <div className="form-group col-lg-4">
                                                                <label htmlFor="area">Address Type</label>
                                                                <select onChange={this.onChangeHandler} name="address_type" className="form-control form-control-sm" id="address_type" >
                                                                    <option value=""> -- Please Select Type -- </option>
                                                                    <option value="Home">Home </option>
                                                                    <option value="Work">Work </option>
                                                                    <option value="Other">Other </option>
                                                                </select>
                                                                {/* <input type="text" onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" placeholder="Enter Pincode" /> */}
                                                                <span className="error">
                                                                    {this.state.addresstypeError}
                                                                </span>
                                                            </div>
                                                            <div className="form-group col-lg-12">
                                                                {this.state.useraddressDetails.length !== 0 ?
                                                                    <button type="button" onClick={() => this.hideaddresshorm()} className="btn btn-primary custom-address-btn m-l-5">Cancel</button>
                                                                    : null
                                                                }
                                                                {this.state.updateaddressstatus === false ? (
                                                                    <button type="button" onClick={() => this.addressForm(1, this.state.username, this.state.mobile_no, this.state.flat_no, this.state.building_name, this.state.landmark, this.state.area, this.state.city, this.state.email, this.state.address_type)} className="btn btn-primary custom-address-btn m-l-5">Add</button>

                                                                ) : (
                                                                        <button type="button" onClick={() => this.updateaddressnew(1, this.state.username, this.state.mobile_no, this.state.flat_no, this.state.building_name, this.state.landmark, this.state.area, this.state.city, this.state.email, this.state.address_type)} className="btn btn-primary custom-address-btn m-l-5">Update</button>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>


                                </div>
                                <div className="tab-pane" id="messages">
                                    <div className="tab-leftside-head">
                                        <h2 className="color-b">Returns</h2>
                                        <p>See how your orders are managed and check the latest status on your order</p>
                                    </div>
                                    <div className="row row-sm text-center">
                                        <div className="custom-no-product">
                                            <img src="/assets/img/return.png" alt="" />
                                            <p className="mb-2">You don't have any delivered returns requested yet!</p>
                                            <Link to="/"><button className="guest-shopping-btn mb-3">Start Shopping</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

export default Account