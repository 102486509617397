import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
// import Cookies from 'universal-cookie';
import {NotificationContainer, NotificationManager} from 'react-notifications'; 
import 'react-notifications/lib/notifications.css';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
// const cookies = new Cookies();

class Bannerproduct extends Component {

    constructor(props){
		super(props);
		this.state = {
            categoryList: [],
            offset: 0,
            elements: [],
            perPage: 28,
            currentPage: 0,
            productDetails_popup: [],
            clicks: 1,
            show: false,
            total_weight:'',
            total_price:'',
            mobile_no_code: '+971',             
            spinner : 'loading-overlay',
            small_spinner : 'ajax-overlay hide-content',
            brandvaluelist: [],
            value: { min: 0, max: 0 },
            visible: 28,
        };
        this.getBannerproductDetails = this.getBannerproductDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    componentDidMount() 
    {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' || 
            user_auth_key === "undefined" || user_auth_key === "null") 
        {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
        this.setState({currentPage: 0, offset: 0 });
        this.getBannerproductDetails();
    }
    
    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    
    loadMore() {
        this.setState((prev) => {
          return {visible: prev.visible + 28};
        });
      }

    async getBannerproductDetails() {
        var url = window.location.href;
        var parts = url.split('/');
        // var lastSegment = parts.pop() || parts.pop();

        const jsonValue = { banner_id: parts[6] , user_unique_id: localStorage.getItem('user_auth_key')  };
        axiosInstance
            .post(`getBannerproductDetails`, jsonValue)
            .then((res) => {
            if (res.data.status === "success" && res.data.statusCode === "200")
            {
                let CategoryValues = res.data.bannerprod_details;
                let CategoryDetails = CategoryValues.map(category_response => { return category_response; });  
                
                let brandfiltervalues = res.data.brand_filter;
                let brandvaluedetails = brandfiltervalues.map(subcat_response => { return subcat_response; });

                let price_filtervalues = res.data.price_filter;

                this.setState({ categoryList: CategoryDetails ,visible: 28, listcount: res.data.count,
                    brandvaluelist: brandvaluedetails, value: { min: price_filtervalues[0].min_price, max: price_filtervalues[0].max_price },
                    price_valueaa_min:price_filtervalues[0].min_price, price_valueaa_max:price_filtervalues[0].max_price });
                let spinner = 'loading-overlay hide-content';
                this.setState({spinner});
            }
            })
            .catch((err) => console.log("Error: ", err));
    }

    onOpenModal = (id) => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = { pId: id };
        axiosInstance
          .post(`getindividualProductDetails`, jsonValue)
          .then((res) => {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            if (res.data.status === "success" && res.data.statusCode === "200")
            {
              let ProductValues = res.data.product_details;
              let selling_price = res.data.selling_price;
              let prod_quantity = res.data.prod_quantity;
              let prod_idd = res.data.prod_id;
            //   let prod_dtl_nutrition_info = res.data.prod_dtl_nutrition_info;
              let ProductDetails = ProductValues.map(product_response => { return product_response; });
            //   let RelatedDetails = RelatedValues.map(related_response => { return related_response; });
              this.setState({ productDetails_popup: ProductDetails});
              this.setState({prod_quantity,selling_price,prod_idd})
              
              this.setState({ open: true });
            }
          })
          .catch((err) => console.log("Error: ", err));


      };

      onCloseModal = () => {
        //   alert('hii');
        this.setState({ open: false, clicks: 1,total_weight:'', total_price:'',show :false});
        // this.reload(); 
        // alert(this.state.open);
      };

      IncrementItem = () => 
      {
        this.setState({ clicks: this.state.clicks + 1 });
        // alert('hii');
        
        var total_clicks = this.state.clicks+1
        if(total_clicks>1)
        {
            var total_weight = total_clicks * this.state.prod_quantity;
            var total_price = this.state.selling_price * total_clicks;        
            var final_total = parseFloat(total_price).toFixed(2);        
            this.setState({show :true,total_weight:total_weight, total_price:final_total, total_clicks:total_clicks});
        }
        else
        {
            this.setState({show :false});
        }


      }

      DecreaseItem = () => {
          if(this.state.clicks>1)
          {            
            this.setState({ clicks: this.state.clicks - 1 });
            var total_clicks = this.state.clicks-1;
            if(total_clicks>1)
            {
                var total_weight = total_clicks * this.state.prod_quantity;
                var total_price = this.state.selling_price * total_clicks;    
                var final_total = parseFloat(total_price).toFixed(2);        
                this.setState({show :true,total_weight:total_weight, total_price:final_total, total_clicks:total_clicks});
            }
            else
            {
                this.setState({show :false});
            }
          }
          else
          {
              this.setState({show :false});
          }
      }

      ToggleClick = () => 
      {
            this.setState({ show: !this.state.show });
            // console.log(this.state.clicks);
      }

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChange(e)
    {       
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });

        const brandvalue = [];
        var chks_brand = document.getElementsByName('brand_checked_name');
        for(var j = 0; j < chks_brand.length; j++)
        {
            if (chks_brand[j].checked) 
            {
                brandvalue.push(chks_brand[j].value);
            }
        }

        var url = window.location.href;
        var parts = url.split('/');

        // componentDidMount()
        if(brandvalue.length>0 )
        {
            const jsonValue = { brand_name:brandvalue, user_unique_id: localStorage.getItem('user_auth_key') ,
            min_price:this.state.value.min,max_price:this.state.value.max,banner_id:parts[6] };
            axiosInstance
              .post(`getCompatibleBannerProduct`, jsonValue)
              .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200")
                {
                  let CategoryValues = res.data.bannerprod_details;
                  let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
    
                  this.setState({ categoryList: CategoryDetails ,listcount: res.data.count});
                  let spinner = 'loading-overlay hide-content';
                  this.setState({spinner});
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });

              })
              .catch((err) => console.log("Error: ", err));
        }
        else if(brandvalue.length===0)
        {
            if(this.state.value.min !== this.state.price_valueaa_min || this.state.value.max !== this.state.price_valueaa_max)
            {
                const jsonValue = { brand_name:[], user_unique_id: localStorage.getItem('user_auth_key') ,
                min_price:this.state.value.min,max_price:this.state.value.max, banner_id:parts[6] };
                axiosInstance
                  .post(`getCompatibleBannerProduct`, jsonValue)
                  .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200")
                    {
                      let CategoryValues = res.data.bannerprod_details;
                      let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
        
                      this.setState({ categoryList: CategoryDetails ,listcount: res.data.count });
                      let spinner = 'loading-overlay hide-content';
                      this.setState({spinner});
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
    
                  })
                  .catch((err) => console.log("Error: ", err));
            }
            else
            {
                this.getBannerproductDetails();          
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            }
        }

        else
        {
            this.getBannerproductDetails();  
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

    }


    movetobasket= () => 
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        var total_weight='';
        var total_price='';
        var total_clicks='';
        if(this.state.total_weight==='')
        {
            total_weight = this.state.prod_quantity;
        }
        else
        {
            total_weight = this.state.total_weight;
        }
        if(this.state.total_price==='')
        {
            total_price = this.state.selling_price;
        }
        else
        {
            total_price = this.state.total_price;
        }
        if(this.state.total_clicks===undefined)
        {
            total_clicks = 1;
        }
        else
        {
            total_clicks = this.state.total_clicks;
        }
        const jsonValue = {
            prod_id:this.state.prod_idd,
            prod_quantity:total_clicks,
            prod_price:this.state.selling_price,
            prod_weight:this.state.prod_quantity,
            prod_totalweight:total_weight,
            prod_totalprice:total_price,
            // prod_totalprice:total_price,
            user_unique_id:localStorage.getItem('user_auth_key') ,
        };
        axiosInstance
        .post(`inserttobasket`, jsonValue)
        .then((res) => {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            if ( res.data.status === "success" && res.data.statusCode === "200" ) {
                this.props.onIncrement();
                let cart_count = res.data.cart_count;
                this.setState({cart_count:cart_count});
                NotificationManager.success( res.data.quantity + ' X ' + res.data.weight + ' ' + res.data.uom + ' added sucessfully' , res.data.pname ,200);
                this.onCloseModal();
            } 
            else if ( res.data.status === "fail" && res.data.statusCode === "201" ) {
                NotificationManager.error('Something went wrong', 'Error',500);
            }
        })
        .catch((err) => console.log("Login: ", err));
    }  
    
    basketIncrementItem(prod_id,prodcode,prodprice,prodweight)
    {
        this.productaddtobasket(prod_id,prodcode,prodprice,prodweight);
    }

    basketDecreaseItem(prod_id,prodcode,prodprice,prodweight,cart_id)
    {
        let qty_value = document.getElementById(prodcode).value;
        if(qty_value>1)
        {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            var quantity = 1;
            var total_weight = quantity * prodweight;
            var total_price = prodprice * quantity;      
            var final_total = parseFloat(total_price).toFixed(2);               
            const jsonValue = { 
                prod_id:prod_id,
                prod_quantity: 1,
                prod_price:prodprice,
                prod_weight:prodweight,
                prod_totalweight: total_weight,
                prod_totalprice: final_total,
                user_unique_id: localStorage.getItem('user_auth_key')  };
            axiosInstance
              .post(`removebasketqty`, jsonValue)
              .then((res) => {
                // let small_spinner = 'ajax-overlay hide-content';
                // this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200")
                {
                    this.addtobasketrefresh();
                }
              })
              .catch((err) => console.log("Error: ", err));
        }
        else
        {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            const jsonValue = { 
                cart_id:cart_id,
                user_unique_id: localStorage.getItem('user_auth_key')  };
    
            axiosInstance
              .post(`removeCartDetails`, jsonValue)
              .then((res) => {
                // let small_spinner = 'ajax-overlay hide-content';
                // this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200")
                {
                    this.props.onIncrement();
                    this.addtobasketrefresh();
                }
              })
              .catch((err) => console.log("Error: ", err));
        }
    }

    productaddtobasket(prod_id,prodcode,prodprice,prodweight)
    {
        let prod_quantity = 1;
        let tol_prod_quantity = prod_quantity * prodweight;
        let tol_selling_price = prod_quantity * prodprice;
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            prod_id:prod_id,
            prod_price:prodprice,
            prod_quantity:prod_quantity,
            prod_weight:prodweight,
            prod_totalweight:tol_prod_quantity,
            prod_totalprice:tol_selling_price,
            user_unique_id:localStorage.getItem('user_auth_key') ,
        };
        axiosInstance
        .post(`inserttobasket`, jsonValue)
        .then((res) => {

            if ( res.data.status === "success" && res.data.statusCode === "200" ) {
                this.props.onIncrement();
                this.addtobasketrefresh();
                NotificationManager.success( res.data.quantity + ' X ' + res.data.weight + ' ' + res.data.uom + ' added sucessfully' , res.data.pname ,200);
            } 
            else if ( res.data.status === "fail" && res.data.statusCode === "201" ) {
                NotificationManager.error('Something went wrong', 'Error',500);
            }

            // let small_spinner = 'ajax-overlay hide-content';
            // this.setState({ small_spinner });
        })
        .catch((err) => console.log("Login: ", err));
    } 
    
    addtobasketrefresh()
    {
        const brandvalue = [];
        var chks_brand = document.getElementsByName('brand_checked_name');
        for(var j = 0; j < chks_brand.length; j++)
        {
            if (chks_brand[j].checked) 
            {
                brandvalue.push(chks_brand[j].value);
            }
        }

        var url = window.location.href;
        var parts = url.split('/');

        // componentDidMount()
        if(brandvalue.length>0 )
        {
            const jsonValue = { brand_name:brandvalue, user_unique_id: localStorage.getItem('user_auth_key') ,
            min_price:this.state.value.min,max_price:this.state.value.max,banner_id:parts[6] };
            axiosInstance
              .post(`getCompatibleBannerProduct`, jsonValue)
              .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200")
                {
                  let CategoryValues = res.data.bannerprod_details;
                  let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
    
                  this.setState({ categoryList: CategoryDetails ,listcount: res.data.count});
                  let spinner = 'loading-overlay hide-content';
                  this.setState({spinner});
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });

              })
              .catch((err) => console.log("Error: ", err));
        }
        else if(brandvalue.length===0)
        {
            if(this.state.value.min !== this.state.price_valueaa_min || this.state.value.max !== this.state.price_valueaa_max)
            {
                const jsonValue = { brand_name:[], user_unique_id: localStorage.getItem('user_auth_key') ,
                min_price:this.state.value.min,max_price:this.state.value.max, banner_id:parts[6] };
                axiosInstance
                  .post(`getCompatibleBannerProduct`, jsonValue)
                  .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200")
                    {
                      let CategoryValues = res.data.bannerprod_details;
                      let CategoryDetails = CategoryValues.map(category_response => { return category_response; });
        
                      this.setState({ categoryList: CategoryDetails ,listcount: res.data.count});
                    //   let spinner = 'loading-overlay hide-content';
                    //   this.setState({spinner});
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
    
                  })
                  .catch((err) => console.log("Error: ", err));
            }
            else
            {
                this.getBannerproductDetails();          
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            }
        }

        else
        {
            this.getBannerproductDetails();  
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
    }


    pricefun_filter = async (ttt) =>
    {
        // console.log(ttt);
        this.setState({ value:ttt })
        let min_prz = parseFloat(ttt.min).toFixed(2);
        let max_prz = parseFloat(ttt.max).toFixed(2);

        if(max_prz >=this.state.price_valueaa_max)
        {
            max_prz=this.state.price_valueaa_max;
        }
        if(min_prz <=this.state.price_valueaa_min)
        {
            min_prz=this.state.price_valueaa_min;
        }
        let prizee = { 
            min : parseFloat(min_prz), 
            max : parseFloat(max_prz)
        }; 
        await this.setState({ value:prizee })
    }

    clearpricezfilter= () =>
    {
        let prizee = { 
            min : parseFloat(this.state.price_valueaa_min), 
            max : parseFloat(this.state.price_valueaa_max)
        }; 
        this.setState({ value:prizee })
        setTimeout(function()
        { 
            this.handleChange(prizee); 
        }.bind(this), 100);
        // await this.handleChange(prizee);
    }

    closefilterr()
    {
        var elements = document.getElementsByClassName('main');
        for (var i = 0; i<elements.length; i++) {
           elements[i].classList.remove('sidebar-opened');
        }
    }

    render() {

        return (
            <main className="main">
                <NotificationContainer/>
                <div className={this.state.small_spinner}>
                    <i className="porto-loading-icon"></i>
                </div>
                <div className={this.state.spinner}>
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    
                    <div className="container">
                        <div className="hide_ipad_filter">
                            <p className="sidebar-toggle"> <img className="m_filter_img" src="/assets/img/m-filter.png" alt="" />&nbsp; &nbsp;  Filter</p>
                            <p className="short-product-toggle" > <img className="m_filter_img" src="/assets/img/m-sort.png" alt="" />&nbsp; &nbsp;  Sort</p>
                        </div>
  
                        <ol className="breadcrumb">
                            <>
                                <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                                <li className="breadcrumb-item active">Banner Product</li>
                            </>
                        </ol>
                    </div>
                
                    <div className="hide_mobile_filter">
                        <p className="sidebar-toggle"> <img className="m_filter_img" src="/assets/img/m-filter.png" alt="" />&nbsp; &nbsp;  Filter</p>
                        <p className="short-product-toggle" > <img className="m_filter_img" src="/assets/img/m-sort.png" alt="" />&nbsp; &nbsp;  Sort</p>
                    </div>


                </nav>
                

                { this.state.listcount !== 0 ? ( 
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12 col-xl-5col-4">
                                <nav className="toolbox mb-1">
                                    <div className="toolbox-item toolbox-show">
                                        { this.state.listcount<this.state.visible ? (
                                                <label>Showing {this.state.listcount} of {this.state.listcount} results</label>
                                        ) : (
                                                <label>Showing {this.state.visible} of {this.state.listcount} results</label>
                                        ) }  
                                    </div>
                                </nav>

                                <div className="row row-sm cust-max-height">
                                            {/* {paginationElement} */}
                                                {/* {this.state.elements}
                                        <div className="col-12">
                                            <div className="cust_stype_paginate">                                                
                                                    {paginationElement}
                                            </div>
                                        </div> */}
                                            {this.state.categoryList.slice(0, this.state.visible).map((values, index) => {
                                                return (
                                                    <div key={values.prod_id} className="col-lg-3 col-md-3 col-sm-4 col-6 pad-5">
                                                    <div className="product-default custom-shadow inner-quickview inner-icon">
                                                        <figure>
                                                            <Link to={{ pathname: '/'+values.md_url_code+'/'+values.sd_url_code+'/'+values.mc_url_code+'/'+values.sc_url_code+'/'+values.prod_url_param+'/'+values.prod_url_code+'' }}>
                                                                { values.outof_stock_flag === '1' ? (
                                                                    <img src={values.produ_imgurl} alt="" />
                                                                ) : (
                                                                    <img className="outofstockflagon" src={values.produ_imgurl} alt={values.prod_name} />
                                                                ) }
                                                                
                                                            </Link>
                                                            { values.offer_flag === '1' ? (
                                                                <div className="label-group">
                                                                    <span className="product-label label-cut">{values.offer_value}% OFF</span>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            ) }
                                                            <div className="btn-icon-group">
                                                                <button onClick={() =>
                                                                    this.onOpenModal(values.prod_id)
                                                                } title="Click to View" className="btn-icon btn-add-cart" data-toggle="modal" data-target="#addCartModal">
                                                                <i className="far fa-eye"></i></button>
                                                            </div>
                                                            {/* <span onClick={() =>
                                                                    this.onOpenModal(values.prod_id)
                                                                } className="btn-quickview show_quickview" title="Quick View">Quick View</span> */}
                                                        </figure>
                                                        <div className="product-details pad-10">
                                                            <div className="category-wrap">
                                                                <div className="category-list">
                                                                    <span className="product-category">{values.scName}</span>
                                                                </div>
                                                                {/* <span className="btn-icon-wish"><i className="icon-heart"></i></span> */}
                                                            </div>
                                                            <h2 className="product-title">
                                                            <Link to={{ pathname: '/'+values.md_url_code+'/'+values.sd_url_code+'/'+values.mc_url_code+'/'+values.sc_url_code+'/'+values.prod_url_param+'/'+values.prod_url_code+'' }}>
                                                                {values.prod_name}</Link>
                                                            </h2>
                                                            <span > Brand : {values.brand_name} </span>
                                                            <span > UOM &nbsp;: {values.prod_quantity} {values.unit_id}</span>
                                                            <div className="price-box">
                                                                {/* <span className="old-price">AED {values.market_price}</span> */}
                                                                {/* {values.market_price!=0 ? (
                                                                        <span className="old-price">AED {values.market_price}</span>
                                                                    ):(
                                                                        <> </>
                                                                    )}
                                                                <span className="product-price">AED {values.selling_price}</span> */}
                                                                {values.offer_flag==="1" ? (
                                                                <>
                                                                    {values.market_price!=="0.00" ? (
                                                                            <span className="old-price">AED {values.original_amt}</span>
                                                                        ):(
                                                                            <> </>
                                                                        )}
                                                                    <span className="product-price">AED {values.selling_price}</span>
                                                                    </>
                                                                ):(
                                                                    <> 
                                                                    {values.market_price!=="0.00" ? (
                                                                            <span className="old-price">AED {values.market_price}</span>
                                                                        ):(
                                                                            <> </>
                                                                        )}
                                                                    <span className="product-price">AED {values.selling_price}</span>
                                                                    </>
                                                                )}
                                        
                                                                
                                                            </div>
                                                            <div id={"addtobasket" + values.prod_code} className="cart-box col-lg-12 ">
                                                                <div className="row">
                                                                    {/* <div className="col-lg-5 custom-s-pc-res col-md-12 col-12 custom-res-mb-5 custom-cart-align-l">
                                                                        <div className="input-group mob-p-l">
                                                                            <span className="input-group-btn">
                                                                                <button type="button" className="btn btn-number product-custom-qty-m" onClick={() =>this.basketDecreaseItem(values.prod_code)} aria-label="Add one" data-direction="1">
                                                                                    <span className="fa fa-minus"></span>
                                                                                </button>
                                                                            </span>
                                                                            <input type="text" id={values.prod_code} className="form-control product-input-number" name="quantity" value="1" readOnly={true} />
                                                                            <span className="input-group-btn">
                                                                                <button type="button" className="btn btn-number product-custom-qty-a" onClick={() =>this.basketIncrementItem(values.prod_code)} aria-label="Subtract one" data-direction="-1">
                                                                                    <span className="fa fa-plus"></span>
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-7 custom-s-pc-res col-md-12 col-12 custom-cart-align-r">
                                                                        <button onClick={ () => this.productaddtobasket(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity) } className="cart-btn">Add to Basket</button>
                                                                    </div> */}
                                                                { values.outof_stock_flag === '1' ? (
                                                                    <>
                                                                        {values.cart_count>0 ? (
                                                                            <div className="col-lg-12 custom-s-pc-res col-md-12 col-12  custom-cart-align-c">
                                                                                <div className="input-group mob-p-l">
                                                                                    <span className="input-group-btn">
                                                                                        <button type="button" className="btn btn-number product-custom-qty-m" onClick={() =>this.basketDecreaseItem(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity,values.cart_id)} aria-label="Add one" data-direction="1">
                                                                                            <span className="fa fa-minus"></span>
                                                                                        </button>
                                                                                    </span>
                                                                                    <input type="text" id={values.prod_code} className="form-control product-input-number" name="quantity" value={values.cart_count} readOnly={true} />
                                                                                    <span className="input-group-btn">
                                                                                        <button type="button" className="btn btn-number product-custom-qty-a" onClick={() =>this.basketIncrementItem(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity)} aria-label="Subtract one" data-direction="-1">
                                                                                            <span className="fa fa-plus"></span>
                                                                                        </button>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        ):
                                                                        (
                                                                            <div className="col-lg-12 custom-s-pc-res col-md-12 col-12 custom-cart-align-c">
                                                                                <button onClick={ () => this.productaddtobasket(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity) } className="cart-btn">Add to Basket +</button>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <div className="col-lg-12 custom-s-pc-res col-md-12 col-12">
                                                                                {/* <button onClick={ () => this.productaddtobasket(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity) } className="cart-btn">Add to Basket +</button> */}
                                                                                <h5 className="outofstockflagcolor">Currently Out Of Stock</h5>
                                                                    </div>
                                                                    
                                                                ) }
                                        
                                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                );
                                                })}


                                            {this.state.visible < this.state.categoryList.length &&
                                                // <button onClick={this.loadMore} type="button" className="load-more">Load more</button>
                                                <div className="loadmorebtn_cust_div" id="hotseller_loadmore" >
                                                    <button className="loadmorecustbtn btn mb-1" onClick={ () => this.loadMore() }>load more... </button>
                                                </div>
                                            }
                                </div>
                            </div>
                            <aside className="sidebar-shop col-lg-3 col-xl-5col-1 order-lg-first res-d-none">
                                <div className="sidebar-wrapper">
                                    
                                    {/* Price Filter */}

                                    <div className="widget cust_sub_category_widget ">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Price Filter ( AED )</a>
                                        </h3>

                                        <div className="collapse show" id="widget-body-3">
                                            <div className="widget-body widget-body-price cust-padd_price">  
                                            <InputRange
                                                // draggableTrack
                                                step={0.01}
                                                maxValue={this.state.price_valueaa_max}
                                                minValue={this.state.price_valueaa_min}
                                                value={this.state.value}
                                                allowSameValues={false}
                                                onChange={value => 
                                                    this.pricefun_filter(value) }
                                                onChangeComplete={value => 
                                                    this.handleChange(value) }
                                                 />
                                            </div>
                                            <p className="clear_prz_btn" onClick={ () => this.clearpricezfilter() } >Clear Price </p> 
                                        </div>
                                            
                                    </div>

                                    {/* Brand Filter */}

                                    <div className="widget cust_sub_category_widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Brand</a>
                                        </h3>

                                        <div className="collapse show" id="widget-body-3">
                                            <div className="widget-body">
                                                <ul className="cat-list">                                               

                                                    {
                                                        this.state.brandvaluelist.map(values => {
                                                        return (
                                                            <li  key={values.prod_id} className="checkbox">
                                                                <label><input type="checkbox"  name="brand_checked_name" value={values.brand_name} onClick={this.handleChange} />{values.brand_name}  </label>
                                                            </li>
                                                        )})
                                                    }
   
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                ) : ( 
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-xl-5col-4">
                                <div className="row row-sm text-center cust-max-height">
                                    <div className="custom-no-product">
                                        <img src="/assets/img/no-product.gif" alt="" />
                                        <p>No Products Available</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ) }
                    <div>
                    {/* <button >Open modal</button> */}
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="container cust_stype_modal">
                        <div className="row row-sm">
                            <div className="col-lg-12 col-xl-12">
                            {this.state.productDetails_popup.map(values => (
                                <div key={values.prod_id}>
                                    <div className="product-single-container product-single-default">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-8 product-single-gallery">
                                                <div className="product-slider owl-carousel owl-theme" data-toggle="owl" data-owl-options="{
                                                    'items': 1,
                                                    'loop': false,
                                                    'autoplay': false,
                                                    'autoplayHoverPause': true
                                                }">
                                                    <div className="product-slide">
                                                        <div className="product-default">
                                                            <figure>
                                                                <a href={values.produ_imgurl}>
                                                                    <img src={values.produ_imgurl} alt="" />
                                                                </a>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="product-single-details">
                                                    <h1 className="product-title">{values.prod_name}</h1>
                                                    <p className="mb-1">Category: {values.scName} </p>
                                                    <p className="mb-1">UOM: {values.prod_quantity} {values.unit_id} </p>
                                                    <p className="mb-1">Brand : {values.brand_name}</p>
                                                    {values.offer_flag==='1' ? (
                                                    <>
                                                        <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                                {values.market_price!=="0.00" ? (
                                                                    <>( AED {values.original_amt} )</>
                                                                ):(
                                                                    <> </>
                                                                )}
                                                            </s> <br />
                                                        <span className="product-save-discount">You Save :&nbsp; {values.offer_value}   {values.offer_type==='P' ? (
                                                                    <> % </>
                                                                ):(
                                                                    <> AED </>
                                                                )} OFF</span>
                                                        </p>
                                                    </>
                                                ):(
                                                    <> 
                                                        <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                                {values.market_price!=="0.00" ? (
                                                                    <>( Avg. Market Price AED {values.market_price} )</>
                                                                ):(
                                                                    <> </>
                                                                )}
                                                            </s> <br />
                                                        </p>
                                                    </>
                                                )}
                                                   

                                                    {/* <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                        
                                                        {values.market_price!=0 ? (
                                                                 <>( Avg. Market Price AED {values.market_price} )</>
                                                            ):(
                                                                <> </>
                                                            )}
                                                        
                                                        </s> <br />
                                                     <span className="product-save-discount">You Save :&nbsp;26%</span> 
                                                    </p> */}

                                                    <div className="product-action product-all-icons mt-2">
                                                        <div className="product-quantity">
                                                            <input  onChange={this.onChangeHandler} className="custom-quantity" data-min="1" data-max="0" type="text" name="quantity" value={ this.state.clicks } readOnly={true} /><div className="quantity-selectors-container">
                                                            <div className="quantity-selectors">
                                                                <button type="button" onClick={this.IncrementItem} aria-label="Add one" data-direction="1"><span>&#43;</span></button>
                                                                <button type="button"  onClick={this.DecreaseItem} aria-label="Subtract one" data-direction="-1"><span>&#8722;</span></button>
                                                            </div>
                                                            </div>
                                                        </div>

                                                        <span className="paction add-cart" onClick={this.movetobasket} title="Add to Cart">
                                                            Add to Basket
                                                        </span>
                                                    </div>
                                                    {this.state.show === true ? (
                                                        <div className="autocalculate">
                                                            <p>Total UOM:&nbsp;{this.state.total_weight} {values.unit_id}</p>
                                                            <p>Total Price :&nbsp;AED {this.state.total_price}</p>
                                                            {/* <h3> Total UOM: {this.state.total_weight}</h3> */}<br />
                                                            {/* <h3> Total Price : {this.state.total_price}</h3> */}
                                                        </div>
                                                    ) :(
                                                        <></>
                                                    )}

                                                    <div className="product-desc">
                                                        <p>{values.prod_info}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                    </Modal>
                </div>

                    <div className="sidebar-overlay"></div>
                    {/* <div className="sidebar-toggle"><i className="icon-right-open"></i></div> */}
                    <aside className="sidebar-product col-lg-3 col-xl-2 padding-left-lg mobile-sidebar">
                            
                        <div>
                            <h4 className="close_filter" onClick={ () => this.closefilterr() }>Close <i className="icon-retweet"></i></h4>
                        </div>
                        <div className="sidebar-wrapper mt-3">

                            {/* Price Filter */}

                            <div className="widget cust_sub_category_widget ">
                                <h3 className="widget-title">
                                    <a data-toggle="collapse" href="#widget-body-4" role="button" aria-expanded="true" aria-controls="widget-body-4">Price Filter ( AED )</a>
                                </h3>

                                <div className="collapse show" id="widget-body-4">
                                    <div className="widget-body widget-body-price cust-padd_price">  
                                    <InputRange
                                        // draggableTrack
                                        step={0.01}
                                        maxValue={this.state.price_valueaa_max}
                                        minValue={this.state.price_valueaa_min}
                                        value={this.state.value}
                                        allowSameValues={false}
                                        onChange={value => 
                                            this.pricefun_filter(value) }
                                        onChangeComplete={value => 
                                            this.handleChange(value) }
                                            />
                                            {/* <p className="clear_prz_btn" onClick={ () => this.clearpricezfilter() } >Clear Price </p>  */}
                                    </div>
                                    <h3 className="clearprice_style">
                                    <span role="button" onClick={ () => this.clearpricezfilter() } >Clear Price</span>
                                </h3>
                                </div>
                                    
                            </div>

                            {/* Brand Filter */}

                            <div className="widget cust_sub_category_widget">
                                <h3 className="widget-title">
                                    <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Brand</a>
                                </h3>

                                <div className="collapse show" id="widget-body-3">
                                    <div className="widget-body">
                                        <ul className="cat-list">                                               

                                            {
                                                this.state.brandvaluelist.map(values => {
                                                return (
                                                    <li  key={values.prod_id} className="checkbox">
                                                        <label><input type="checkbox"  name="brand_checked_name" value={values.brand_name} onClick={this.handleChange} />{values.brand_name}  </label>
                                                    </li>
                                                )})
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>


            </main>
            )
    }
}

export default Bannerproduct