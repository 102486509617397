import React from "react";


const Card = ({ card, bgcolor = "" }) => {
//   const cardStyle = { ...style };

 
  return (
    <div  id='card1'>

        <div className="company-section"  >     
                   
            <div className="row">
                <div className="col-lg-12">
                    <h2 className="subtitle">About Us</h2>
                    <p className="about_cust_txt_style">Yalla Basket is an online grocery startup that aims at providing the best quality products. Our team understands the pain involved in the grocery shopping journey and truly believes in offering a service that will put a smile on the customer's face</p>
                    <p className="about_cust_txt_style">
                    Impeccable customer service and best product quality is what we stand for. With us you can forget about the tedious after sale experience as we believe in standing by your side through the entire cycle of purchase and consumption.  
                    </p>
                </div>


            </div>
        </div>
        <div className="company-section" id='card2' >
            <div className="container">
                <div className="row align-items-lg-center">
                    <div className="col-md-5">
                        <img src="/assets/img/01.jpg" className="mission_img" height="350px" alt=" "/>
                    </div>

                    <div className="col-md-7 padding-left-lg">
                        <h3 className="subtitle">Core Value</h3>
                        <p>Quality, customer centricity, convenience and value for money are the values that drive us.</p>

                        <h3 className="subtitle">Mission</h3>
                        <p>To provide an online grocery service that is more customer-centered and quality-oriented so that customers can spend more quality time with their families forgetting the task of grocery shopping.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="company-section"  id='card3' >
            <div className="row">
                <div className="col-lg-7">
                    <h2 className="subtitle">PAYMENT METHODS</h2>
                    <p className="about_cust_txt_style">With Yalla basket, you do not have to pay before you receive your delivery.</p>
                    <p className="about_cust_txt_style">
                    At the time of delivery, you are welcome to inspect the quality of products and pay for what you accept.
                    </p>
                    <p className="about_cust_txt_style">
                    You have wide array of payment option. You can choose to pay by Cash, Card or by Apple pay based on your convenience.
                    </p>
                </div>
                <div className="col-md-5">
                <img src="/assets/img/07.jpg" className="payment_img"  height="250px" alt=" "/>
                </div>


            </div>
        </div>

        <div className="company-section" id='card4' >
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <img src="/assets/img/04.jpg" className="delivery_img" height="410px" alt=" "/>
                    </div>
                    <div className="col-lg-7">
                        <h2 className="subtitle">DELIVERY OPTIONS AND FEES</h2>
                        <p className="about_cust_txt_style">We deliver all 365 days of the year with no exclusions of weekends or holidays.</p>
                        <p className="about_cust_txt_style">
                        <b>Delivery options</b>
                        </p>
                        <p className="about_cust_txt_style">
                        Our delivery slots are between 6:00 PM to 11:00 PM every day. Currently, we have a finite number of delivery slots. Orders that are placed before 10:00 AM in the morning will qualify for the same day free delivery in the evening based on the slot availability. The orders that are placed post 10:00 AM will qualify for next day free delivery. 
                        </p>
                        <p className="about_cust_txt_style">You can also schedule a delivery for a later date up to a range of next 7 days.</p>
                        <p className="about_cust_txt_style">
                        <b>Rescheduling the delivery</b>
                        </p>
                        <p className="about_cust_txt_style">
                        Got a last-minute plan! No issues. You can whatsapp us on +971 566339179 / +971 56672179 if you would like to reschedule the delivery that is scheduled.  
                        </p>
                        <p className="about_cust_txt_style">
                        <b>Delivery Fees</b>
                        </p>
                        <p className="about_cust_txt_style">
                        Yall basket always provides FREE shipping on all orders – no minimums or exclusions! You are welcome to order even a single product and we will happily service your order.  
                        </p>
                    </div>

                </div>
            </div>
        </div>


        <div className="company-section" id='card5' >
            <div className="row">
                <div className="col-lg-7">
                    <h2 className="subtitle">RETURNS </h2>
                    <p className="about_cust_txt_style">If you are not satisfied with the product, you can return it at the time of delivery and pay for only the products that you like.</p>
                    <p className="about_cust_txt_style">
                    To ensure the safety of you and our other customers, currently we are unable to accept returns post-delivery. 
                    </p>
                    <p className="about_cust_txt_style">
                    To ensure the safety of you and our other customers, currently we are unable to accept returns post-delivery. 
                    </p>
                </div>
                <div className="col-md-5">
                <img src="/assets/img/07.jpg" className="return_img"  height="250px" alt=" "/>
                </div>


            </div>
        </div>

        <div className="company-section mb-10" id='card6' >
            <div className="row">
                <div className="col-lg-12">
                    <h2 className="subtitle">CONTACT US</h2>
                    <p className="about_cust_txt_style">We love hearing from you. Please Whatsapp us on +971566339179 / +971566207179 or email us at hello@yallabasket.com and we will get in touch with you.</p>
                    
                </div>


            </div>
        </div>


    </div>
  );
};

export default Card;
