import React, { Component } from 'react';
import 'react-notifications/lib/notifications.css';
import ScrollTo from "react-scroll-into-view";
import styled from "styled-components";
import Card from "./card";

const Nav = styled.nav`
  text-align: center;

`;

const Button = styled.button`
    text-align: center;
    width:100%;
    border:none;
    background: center;
`;

class Aboutus extends Component {

    componentDidMount() {
        this.selector = "#card5";
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }

    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }


    render() {

        return (

            <main className="main home">


                <div className="page-header page-header-bg">
                    <div className="container">
                        <h1 className="beck_head"> </h1>
                    </div>
                </div>

                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            {/* <li className="breadcrumb-item"><a href="index.html"><i className="icon-home"></i></a></li>
                            <li className="breadcrumb-item active" aria-current="page">
                            about us</li> */}
                        </ol>
                    </div>
                </nav>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <Nav>
                                <ul>

                                    <li classname="btn_bfr_scrl">
                                        <ScrollTo selector={`#card1`} >
                                            <Button><h2 className="abt_left_cust_style"><i className="fa fa-user" aria-hidden="true"></i>&nbsp;  About Us</h2> </Button>
                                        </ScrollTo>
                                    </li>

                                    <li classname="btn_bfr_scrl">
                                        <ScrollTo selector={`#card2`} >
                                            <Button><h3 className="abt_left_cust_style"> <i className="fa fa-podcast" aria-hidden="true"></i>&nbsp;  Core Value</h3> </Button>
                                        </ScrollTo>
                                    </li>

                                    <li classname="btn_bfr_scrl">
                                        <ScrollTo selector={`#card3`} >
                                            <Button><h2 className="abt_left_cust_style"><i className="fa fa-credit-card"></i>&nbsp;  Payment Methods</h2> </Button>
                                        </ScrollTo>
                                    </li>

                                    <li classname="btn_bfr_scrl">
                                        <ScrollTo selector={`#card4`} >
                                            <Button><h2 className="abt_left_cust_style"><i className="fa fa-shipping-fast"></i>&nbsp;  Delivery Options and Fees</h2> </Button>
                                        </ScrollTo>
                                    </li>

                                    <li classname="btn_bfr_scrl">
                                        <ScrollTo selector={`#card5`} >
                                            <Button><h2 className="abt_left_cust_style"><i className="fa fa-undo-alt"></i>&nbsp;  Returns </h2> </Button>
                                        </ScrollTo>
                                    </li>

                                    <li classname="btn_bfr_scrl">
                                        <ScrollTo selector={`#card6`} >
                                            <Button><h2 className="abt_left_cust_style"><i className="fa fa-phone" aria-hidden="true"></i>
                                    &nbsp;  Contact Us</h2> </Button>
                                        </ScrollTo>
                                    </li>
                                </ul>
                            </Nav>
                        </div>
                        <div className="col-lg-9">
                            <Card card="1" />
                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

export default Aboutus