import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import './Checkout.css';
// import Cookies from 'universal-cookie';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Tabs, TabList, TabPanel } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ReactPixel from 'react-facebook-pixel';
// const cookies = new Cookies();

class Checkout extends Component {

    constructor() {
        super();
        this.state = {
            username: "",
            email: "",
            mobile_no: "",
            flat_no: "",
            building_name: "",
            landmark: "",
            area: "",
            userDetails: [],
            cartDetails: [],
            useraddressDetails: [],
            ybarealist: [],
            spinner: 'loading-overlay',
            small_spinner: 'ajax-overlay hide-content',
            userLogin: false,
            selectedIndex: 0,
            Preview: "Shipping Info",
            address_status: false,
            address_id: "",
            checkoutRedirect: false,
            placeorderRedirect: false,
            guest_checkout_status: false,
            dubaitime: "",
            todayDate: "",
            deliveryDate: "",
            query: "",
            schedule_date: "",
            placeorder: false,
            schedule: [],
            city: "Dubai",
            packing_type: '',
            applycoupontext: "",
            couponerror: false,
            coupon_count: 0,
            coupon_discount_amt: "",
            final_amount: "",
            total_without_coupon: "",
            Coupon_code_appliedd: "",
            Coupon_code_appliedd_id: "",
            promocode_selected_flag: "0",
            email_idError: "",
            mobile_verified: '',
            mobile_no_code: '+971',
            show_guestopterror_msg: false,
            guestotperror: '',
            pricerange_percentage: 0,
            pricerange_final_amt: 0,
            avaliable_requested_product: 0,
            disable_btn: false,
            yalla_money: 0, 
            checkout_yalla_money: 0.00, 
            yalla_money_used: 'no',
            remaining_yalla_money: 0, 
            referral: 'no',
            referral_amount: 0,

            // ordertime: "12:50"
        };
        this.getCheckoutDetails = this.getCheckoutDetails.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.addresssubmitForm = this.addresssubmitForm.bind(this);
        this.yallaMoneyDetails = this.yallaMoneyDetails.bind(this);
        this.addyallamoney = this.addyallamoney.bind(this);
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
        this.setState({
            couponerror: false,
            promocode_selected_flag: localStorage.getItem('promocode_selected_flag'),
            coupon_discount_amt: localStorage.getItem('coupon_discount_amt'),
            final_amount: localStorage.getItem('final_amount'),
            total_without_coupon: localStorage.getItem('total_without_coupon'),
            Coupon_code_appliedd: localStorage.getItem('Coupon_code_appliedd'),
            Coupon_code_appliedd_id: localStorage.getItem('Coupon_code_appliedd_id'),
            applycoupontext: localStorage.getItem('Coupon_code_appliedd'),
        }, () => {
            this.getCheckoutDetails();
            this.yallaMoneyDetails();
        });

        var d = new Date();
        var localTime = d.getTime();
        var localOffset = d.getTimezoneOffset() * 60000;
        var utc = localTime + localOffset;
        var offset = 4;    //UTC of Dubai is +04.00
        var dubai = utc + (3600000 * offset);
        var nd = new Date(dubai);
        // var h = nd.getHours();
        var time = nd.toLocaleTimeString('en-GB').replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        var t = time.replace(/:\d+ /, ' ');
        this.setState({ dubaitime: t });

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        this.setState({ todayDate: today });
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }


    async getCheckoutDetails() {
        let user_auth_key = localStorage.getItem('user_auth_key');
        const jsonValue = { user_unique_id: user_auth_key };
        await axiosInstance.post(`getCheckoutDetails`, jsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') {
                    let userResponse = res.data.user_details;
                    let cartResponse = res.data.cart_details;
                    let useraddressResponse = res.data.user_address_details;
                    let orderResponse = res.data.ordersetting;
                    let arealistResponse = res.data.area_list;
                    let Subtotal = res.data.total_price;
                    let bagtotal = res.data.bagtotal;
                    let bagsavings = res.data.bagsavings;
                    let yallasavings = res.data.yallasavings_offers;
                    let offer_used_type = res.data.offer_used_type;
                    let pricerange_percentage = res.data.pricerange_percentage;
                    let pricerange_final_amt = res.data.pricerange_final_amt;
                    let UserDetails = userResponse.map(user_response => { return user_response });
                    let CartDetails = cartResponse.map(cart_response => { return cart_response });
                    let UserAddressDetails = useraddressResponse.map(useraddress_response => { return useraddress_response });
                    let OrderDetails = orderResponse.map(order_response => { return order_response });
                    let arealists = arealistResponse.map(order_response => { return order_response });
                    let avaliable_requested_product = res.data.avaliable_requested_product;
                    this.setState({
                        userDetails: UserDetails, cartDetails: CartDetails, useraddressDetails: UserAddressDetails, schedule: OrderDetails, ybarealist: arealists, bagtotal: bagtotal, bagsavings: bagsavings, yallasavings:yallasavings,offer_used_type:offer_used_type, pricerange_percentage: pricerange_percentage,
                        pricerange_final_amt: pricerange_final_amt, avaliable_requested_product: avaliable_requested_product
                    });
                    let spinner = 'loading-overlay hide-content';

                    
                    if (this.state.userDetails.length === 0) {
                        this.setState({ spinner, username: '', email: '', mobile_no: '', Subtotal: Subtotal });
                    }
                    else {
                        this.setState({ spinner, username: res.data.user_details[0].username, email: res.data.user_details[0].email, mobile_no: res.data.user_details[0].mobile_no, Subtotal: Subtotal, placeorder: true });
                    }
                    if (res.data.count !== 1) {
                        this.setState({ userLogin: true });
                    }
                    if (this.state.useraddressDetails.length === 1) {
                        this.setState({ address_id: res.data.user_address_details[0].address_id });
                    }
                    if (CartDetails.length === 0) {
                        this.setState({ checkoutRedirect: true });
                    }
                    if (this.state.applycoupontext !== undefined && this.state.applycoupontext !== null && this.state.applycoupontext !== '') {
                        this.applycouponcode();
                    }
                    if (this.state.avaliable_requested_product === 0) {
                        window.location.href = 'http://yallabasket.com:3002/Cart';
                    }

                    if( yallasavings !== '0.00' ){
                        this.setState({  referral: 'yes', referral_amount: yallasavings });
                    }

                }
            })
            .catch(err => console.log('Error: ', err));
    }

    handleChange = date => {
        // console.log(date);
        this.setState({ expectedDate: date });
        var parts = date.split('/');
        var edate = new Date(parts[2], parts[1] - 1, parts[0]);
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var day = days[edate.getDay()];
        var month = months[edate.getMonth()];
        var d = edate.getDate();
        var delivery_date = day + ', ' + month + ' ' + d;
        this.setState({ deliveryDate: delivery_date })
    };

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    yallaMoneyDetails(){
        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
            .post(`getYallaBasketUserDetails`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let yalla_money_value = res.data.yb_user_details[0]['yalla_money'];
                    this.setState({ yalla_money: yalla_money_value});
                }
            })
            .catch((err) => console.log("Error: ", err));

    }

    addyallamoney(){
        // checkout_yalla_money

        const total_purchase = parseFloat(this.state.Subtotal);
        const yella_money = parseFloat(this.state.yalla_money); 
        if( total_purchase > yella_money ){  
            const final_total_purchase = total_purchase - yella_money;
            this.setState({ 
                yalla_money_used: 'yes',
                checkout_yalla_money: yella_money,
                Subtotal: parseFloat(final_total_purchase).toFixed(2),
                remaining_yalla_money: 0,
            });
        }
        else{ 
            const final_total_purchase = 0.00;
            const remainingyallamoney =  yella_money - total_purchase;
            this.setState({ 
                yalla_money_used: 'yes',
                checkout_yalla_money: total_purchase,
                Subtotal: parseFloat(final_total_purchase).toFixed(2),
                remaining_yalla_money : remainingyallamoney,
            });
        }
        
        
    }

    addresssubmitForm() {
        const jsonValue = {
            username: this.state.username,
            mobile_no: this.state.mobile_no,
            flat_no: this.state.flat_no,
            building_name: this.state.building_name,
            landmark: this.state.landmark,
            area: this.state.area,
            city: this.state.city,
            address_type: this.state.address_type,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`InsertAddress`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    NotificationManager.success('Address Added', 'Success', 200);
                    this.setState({ address_status: false, flat_no: "", building_name: "", landmark: "", area: "" });
                    this.componentDidMount();
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
            })
            .catch((err) => console.log("Login: ", err));
    }

    addressForm() {
        let usernameError = "";
        let mobile_noError = "";
        let flatnoError = "";
        let buildingnameError = "";
        let areaError = "";
        let addresstypeError = "";

        if (!this.state.username) {
            usernameError = "Username cannot be blank";
        }
        if (!this.state.mobile_no) {
            mobile_noError = "Enter Mobile Number";
        }
        else if (this.state.mobile_no.length !== 9) {
            mobile_noError = "Your mobile number should be 9 Digit";
        }

        if (!this.state.flat_no) {
            flatnoError = "Flat No cannot be blank";
        }

        if (!this.state.building_name) {
            buildingnameError = "Building name cannot be blank";
        }
        if (!this.state.area) {
            areaError = "Area cannot be blank";
        }
        if (!this.state.address_type) {
            addresstypeError = "Address type cannot be blank";
        }

        if (usernameError || mobile_noError || flatnoError || buildingnameError || areaError || addresstypeError) {
            this.setState({
                usernameError,
                mobile_noError,
                flatnoError,
                buildingnameError,
                areaError,
                addresstypeError
            });
            return false;
        }
        else {
            this.addresssubmitForm();
            return true;
        }
    }

    tabhandler = index => {
        this.setState({ selectedIndex: index });
    };

    submitorderhandler() {
        let Coupon_code_appliedd = localStorage.getItem('Coupon_code_appliedd');
        let Coupon_code_appliedd_id = localStorage.getItem('Coupon_code_appliedd_id');
        let coupon_discount_amt = localStorage.getItem('coupon_discount_amt');
        if (Coupon_code_appliedd === undefined || Coupon_code_appliedd === null) {
            Coupon_code_appliedd = "0";
        }
        if (Coupon_code_appliedd_id === undefined || Coupon_code_appliedd_id === null) {
            Coupon_code_appliedd_id = "0";
        }
        if (coupon_discount_amt === undefined || coupon_discount_amt === null) {
            coupon_discount_amt = "0";
        }

        const isvalid = this.ordervalidateFunction();
        if (isvalid) {
            this.setState({ small_spinner: "ajax-overlay" })
            const jsonValue = {
                address_id: this.state.address_id,
                // productdetails: this.state.cartDetails,
                subtotal: this.state.Subtotal,
                packing_type: this.state.packing_type,
                query: this.state.query,
                schedule_date: this.state.schedule_date,
                city: this.state.city,
                user_unique_id: localStorage.getItem('user_auth_key'),
                Coupon_code_appliedd: Coupon_code_appliedd,
                Coupon_code_appliedd_id: Coupon_code_appliedd_id,
                coupon_discount_amt: coupon_discount_amt,
                bagtotal: this.state.bagtotal,
                bagsavings: this.state.bagsavings,
                pricerange_percentage: this.state.pricerange_percentage,
                pricerange_final_amt: this.state.pricerange_final_amt,
                yalla_money: this.state.yalla_money_used,
                yalla_money_amount: this.state.checkout_yalla_money,
                referral : this.state.referral,
                referral_amount: this.state.referral_amount,
            };
            axiosInstance
                .post(`InsertUserOrder`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Order Placed Successfully', 'Success', 200);
                        this.fbtotalval(res.data.total_amount_purcgased);
                        this.setState({ flat_no: "", building_name: "", area: "", landmark: "", city: "", expectedDate: "", deliveryDate: "" });
                        this.setState({ placeorderRedirect: true });
                        localStorage.removeItem('coupon_discount_amt');
                        localStorage.removeItem('final_amount');
                        localStorage.removeItem('total_without_coupon');
                        localStorage.removeItem('Coupon_code_appliedd');
                        localStorage.removeItem('Coupon_code_appliedd_id');
                        localStorage.removeItem('promocode_selected_flag');
                        // setTimeout(
                        // function() {
                        //     this.setState({placeorderRedirect: true });
                        //     // window.location.href = "https://www.appteqdemo.in/#/OrderPlaced";   
                        //     // window.location.href = "https://www.yallabasket.com/#/OrderPlaced";   
                        //     // window.location.href = "https://yallabasket.appteq.in/#/OrderPlaced";   
                        //     // window.location.href = "http://localhost:3000/#/OrderPlaced";   
                        // },1000); 
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "201") {
                        NotificationManager.error('Something went wrong', 'Error', 500);
                    }
                })
                .catch((err) => console.log("Login: ", err));
        }
    }

    ordervalidateFunction = () => {
        let packing_typeError = "";
        let scheduledateError = "";
        let radioflatnoError = "";

        if (!this.state.address_id) {
            radioflatnoError = "Select Address";
        }

        // if (!this.state.packing_type) {
        //     packing_typeError = "Select Packing Type";
        // }

        if (!this.state.schedule_date) {
            scheduledateError = "Select Schedule Date";
        }

        if (radioflatnoError || packing_typeError || scheduledateError) {
            this.setState({ radioflatnoError, packing_typeError, scheduledateError });

            window.scrollTo({ top: 0, behavior: 'smooth' });
            return false;
        }
        return true;

    }

    ordervalidateFunctiontwo = () => {
        let usernameError = "";
        let mobile_noError = "";
        let flatnoError = "";
        let buildingnameError = "";
        let areaError = "";
        let packing_typeError = "";
        let scheduledateError = "";
        let addresstypeError = "";

        if (!this.state.username) {
            usernameError = "Username cannot be blank";
        }
        if (!this.state.mobile_no) {
            mobile_noError = "Enter Mobile Number";
        }
        else if (this.state.mobile_no.length !== 9) {
            mobile_noError = "Your mobile number should be 9 Digit";
        }

        if (!this.state.flat_no) {
            flatnoError = "Flat No cannot be blank";
        }

        if (!this.state.building_name) {
            buildingnameError = "Building name cannot be blank";
        }
        if (!this.state.area) {
            areaError = "Area cannot be blank";
        }
        if (!this.state.address_type) {
            addresstypeError = "Address type cannot be blank";
        }

        // if (!this.state.packing_type) {
        //     packing_typeError = "Select Packing Type";
        // }

        if (!this.state.schedule_date) {
            scheduledateError = "Select Schedule Date";
        }

        if (usernameError || mobile_noError || flatnoError || buildingnameError || areaError || packing_typeError || scheduledateError || addresstypeError) {
            this.setState({ usernameError, mobile_noError, flatnoError, buildingnameError, areaError, packing_typeError, scheduledateError, addresstypeError });
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return false;
        }
        return true;
    }

    submitorderhandlertwo() {
        let Coupon_code_appliedd = localStorage.getItem('Coupon_code_appliedd');
        let Coupon_code_appliedd_id = localStorage.getItem('Coupon_code_appliedd_id');
        let coupon_discount_amt = localStorage.getItem('coupon_discount_amt');
        if (Coupon_code_appliedd === undefined || Coupon_code_appliedd === null) {
            Coupon_code_appliedd = "0";
        }
        if (Coupon_code_appliedd_id === undefined || Coupon_code_appliedd_id === null) {
            Coupon_code_appliedd_id = "0";
        }
        if (coupon_discount_amt === undefined || coupon_discount_amt === null) {
            coupon_discount_amt = "0";
        }

        const isvalid = this.ordervalidateFunctiontwo();
        if (isvalid) {
            this.setState({ small_spinner: "ajax-overlay" });
            const jsonValue = {
                address_id: "",
                username: this.state.username,
                mobile_no: this.state.mobile_no,
                email: this.state.email,
                flat_no: this.state.flat_no,
                building_name: this.state.building_name,
                landmark: this.state.landmark,
                area: this.state.area,
                city: this.state.city,
                productdetails: this.state.cartDetails,
                subtotal: this.state.Subtotal,
                packing_type: this.state.packing_type,
                query: this.state.query,
                schedule_date: this.state.schedule_date,
                user_unique_id: localStorage.getItem('user_auth_key'),
                Coupon_code_appliedd: Coupon_code_appliedd,
                Coupon_code_appliedd_id: Coupon_code_appliedd_id,
                coupon_discount_amt: coupon_discount_amt,
                bagtotal: this.state.bagtotal,
                bagsavings: this.state.bagsavings,
                address_type: this.state.address_type,
                pricerange_percentage: this.state.pricerange_percentage,
                pricerange_final_amt: this.state.pricerange_final_amt,
            };
            axiosInstance
                .post(`InsertUserOrder`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Order Placed Successfully', 'Success', 200);
                        this.fbtotalval(res.data.total_amount_purcgased);
                        this.setState({ flat_no: "", building_name: "", landmark: "", area: "", expectedDate: "", deliveryDate: "" });
                        this.setState({ placeorderRedirect: true });
                        localStorage.removeItem('coupon_discount_amt');
                        localStorage.removeItem('final_amount');
                        localStorage.removeItem('total_without_coupon');
                        localStorage.removeItem('Coupon_code_appliedd');
                        localStorage.removeItem('Coupon_code_appliedd_id');
                        localStorage.removeItem('promocode_selected_flag');
                        // setTimeout(
                        // function() {
                        //     this.setState({placeorderRedirect: true });
                        //     // window.location.href = "https://www.appteqdemo.in/#/OrderPlaced";   
                        //     // window.location.href = "https://www.yallabasket.com/#/OrderPlaced";   
                        //     // window.location.href = "https://yallabasket.appteq.in/#/OrderPlaced";   
                        //     // window.location.href = "http://localhost:3000/#/OrderPlaced";   
                        // },1000); 
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "201") {
                        NotificationManager.error('Something went wrong', 'Error', 500);
                    }
                })
                .catch((err) => console.log("Login: ", err));
        }
    }

    showaddressform() {
        this.setState({ address_status: true });
    }

    hideaddresshorm() {
        this.setState({ address_status: false });
    }

    IncrementItem(prod_id, prod_quantity, prod_price, prod_weight) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        var quantity = 1;
        var total_weight = quantity * prod_weight;
        var total_price = prod_price * quantity;
        var final_total = parseFloat(total_price).toFixed(2);

        const jsonValue = {
            prod_id: prod_id,
            prod_quantity: 1,
            prod_price: prod_price,
            prod_weight: prod_weight,
            prod_totalweight: total_weight,
            prod_totalprice: final_total,
            user_unique_id: localStorage.getItem('user_auth_key')
        };

        axiosInstance
            .post(`inserttobasket`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.componentDidMount();
                }
            })
            .catch((err) => console.log("Error: ", err));


    }

    DecreaseItem(prod_id, prod_quantity, prod_price, prod_weight) {
        if (prod_quantity > 1) {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            var quantity = 1;
            var total_weight = quantity * prod_weight;
            var total_price = prod_price * quantity;
            var final_total = parseFloat(total_price).toFixed(2);
            const jsonValue = {
                prod_id: prod_id,
                prod_quantity: 1,
                prod_price: prod_price,
                prod_weight: prod_weight,
                prod_totalweight: total_weight,
                prod_totalprice: final_total,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
                .post(`removebasketqty`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        this.componentDidMount();
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
    }


    removecartitem(cart_id) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            cart_id: cart_id,
            user_unique_id: localStorage.getItem('user_auth_key')
        };

        axiosInstance
            .post(`removeCartDetails`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.props.onIncrement();
                    this.componentDidMount();
                }
            })
            .catch((err) => console.log("Error: ", err));
    }

    guest_checkout() {
        this.setState({ guest_checkout_status: true, placeorder: true });
    }


    applycouponcode() {
        if (this.state.applycoupontext === "") {
            this.setState({ couponerror: true });
        }
        else {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            const jsonValue = {
                Coupon_code: this.state.applycoupontext,
                // CartDetails: this.state.cartDetails,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
                .post(`applycouponcode`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let coupon_discount_amt = res.data.apliedpromocode.discountamount;
                        let final_amount = res.data.apliedpromocode.finalamount;
                        let total_without_coupon = res.data.apliedpromocode.overall_total;
                        let Coupon_code_appliedd = res.data.apliedpromocode.Coupon_code;
                        let Coupon_code_appliedd_id = res.data.apliedpromocode.promocode_id;
                        let promocode_selected_flag = "1";

                        this.setState({
                            couponerror: false, promocode_selected_flag: "1", coupon_discount_amt: coupon_discount_amt,
                            final_amount: final_amount,
                            total_without_coupon: total_without_coupon,
                            Coupon_code_appliedd: Coupon_code_appliedd,
                            Coupon_code_appliedd_id: Coupon_code_appliedd_id, Subtotal: final_amount
                        });

                        localStorage.setItem('coupon_discount_amt', coupon_discount_amt);
                        localStorage.setItem('final_amount', final_amount);
                        localStorage.setItem('total_without_coupon', total_without_coupon);
                        localStorage.setItem('Coupon_code_appliedd', Coupon_code_appliedd);
                        localStorage.setItem('Coupon_code_appliedd_id', Coupon_code_appliedd_id);
                        localStorage.setItem('promocode_selected_flag', promocode_selected_flag);
                        // this.onCloseModal();

                        // this.props.onIncrement();
                        // this.componentDidMount();
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "400") {
                        this.setState({
                            couponerror: true, promocode_selected_flag: "0", coupon_discount_amt: "",
                            final_amount: "",
                            total_without_coupon: "",
                            Coupon_code_appliedd: "",
                            Coupon_code_appliedd_id: ""
                        });
                        localStorage.removeItem('coupon_discount_amt');
                        localStorage.removeItem('final_amount');
                        localStorage.removeItem('total_without_coupon');
                        localStorage.removeItem('Coupon_code_appliedd');
                        localStorage.removeItem('Coupon_code_appliedd_id');
                        localStorage.removeItem('promocode_selected_flag');
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
    }

    handleBlur(type) {
        if (type === 'email') {
            if (this.state.email !== '') {
                let small_spinner = 'ajax-overlay';
                this.setState({ small_spinner });
                const jsonValue = {
                    email: this.state.email,
                    type: type
                };
                axiosInstance
                    .post(`checkavaliablity`, jsonValue)
                    .then((res) => {
                        if (res.data.status === "success" && res.data.statusCode === "200") {
                            this.setState({ email_idError: "Hello, this email id is already registered with us please login and continue your shopping" });
                        }
                        else if (res.data.status === "fail" && res.data.statusCode === "400") {
                            this.setState({ email_idError: " " });
                        }
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    })
                    .catch((err) => console.log("Login: ", err));
            }
        }
        if (type === 'mobile') {
            if (this.state.mobile_no !== '') {
                let small_spinner = 'ajax-overlay';
                this.setState({ small_spinner });
                const jsonValue = {
                    mobile_no: this.state.mobile_no,
                    type: type
                };
                axiosInstance
                    .post(`checkavaliablity`, jsonValue)
                    .then((res) => {
                        if (res.data.status === "success" && res.data.statusCode === "200") {
                            this.setState({ mobile_noError: "Hello, this mobile number is already registered with us please login and continue your shopping", mobile_verified: '', });
                            this.setState({ disable_btn: false });
                        }
                        else if (res.data.status === "fail" && res.data.statusCode === "400") {
                            if (this.state.mobile_no.length !== 9) {
                                this.setState({ mobile_noError: "Your mobile number should be 9 Digit", mobile_verified: '', disable_btn: true });
                            }
                            else {
                                this.setState({ mobile_noError: " ", mobile_verified: 'no', disable_btn: true });
                            }
                        }
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    })
                    .catch((err) => console.log("Login: ", err));
            }
        }
    }


    openotpmodelverify = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            mobile_no: this.state.mobile_no,
            mobile_no_code: this.state.mobile_no_code,
        };
        axiosInstance
            .post(`checkuserandsendguestotp`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {

                    this.setState({ open: true });
                    this.setState({ show_login_otp: true });
                    let LoginOtp_msg = res.data.msg;
                    this.setState({ LoginOtp_msg: LoginOtp_msg, loginotp_code: '', disable_btn: false });
                    // this.setState({show_loginerror_msg:true, loginerror:res.data.msg });
                }
                else if (res.data.status === "fail" && res.data.statusCode === "400") {
                    this.setState({ show_loginerror_msg: true, loginerror: res.data.msg });
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
    };

    onCloseModal = () => {
        //   alert('hii');
        this.setState({ open: false, clicks: 1 });
        this.setState({ show: false });
        this.setState({ couponerror: false });

    };

    verifyotpandplaceorderguest = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        if (this.state.loginotp_code === '') {
            this.setState({ loginotp_codeError: "Please Enter the OTP" });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else {
            this.setState({ loginotp_codeError: " " });
            const jsonValue = {
                mobile_no: this.state.mobile_no,
                mobile_no_code: this.state.mobile_no_code,
                otp_code: this.state.loginotp_code
            };
            axiosInstance
                .post(`verifyotpandplaceorderguest`, jsonValue)
                .then((res) => {
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let LoginOtp_msg = res.data.msg;
                        this.setState({ LoginOtp_msg: LoginOtp_msg, loginotp_code: '', open: false, mobile_verified: 'yes' });
                        NotificationManager.success('Number verified sucessfully', 'Success', 200);
                        document.getElementById("guestloginbtndisbale").disabled = false;
                        // this.setState({show_loginerror_msg:true, loginerror:res.data.msg });
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "400") {
                        this.setState({ show_guestopterror_msg: true, guestotperror: res.data.msg });
                    }
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                })
                .catch((err) => console.log("Login: ", err));
        }

    };



    resendguestverifyotp = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            mobile_no: this.state.mobile_no,
            mobile_no_code: this.state.mobile_no_code,
        };
        axiosInstance
            .post(`checkuserandsendguestotp`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.setState({ show_login_otp: true });
                    let LoginOtp_msg = res.data.msg;
                    this.setState({ LoginOtp_msg: LoginOtp_msg, loginotp_code: '' });
                    // this.setState({show_loginerror_msg:true, loginerror:res.data.msg });
                }
                else if (res.data.status === "fail" && res.data.statusCode === "400") {
                    this.setState({ show_loginerror_msg: true, loginerror: res.data.msg });
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
    };

    fbtotalval = (total_amount_purcgased) => {
        ReactPixel.init('217377136499232');
        ReactPixel.pageView();
        ReactPixel.track('Purchase', { currency: "AED", value: total_amount_purcgased });
    }

    render() {

        if (this.state.checkoutRedirect) {
            return <Redirect to="/" />;
        }

        if (this.state.placeorderRedirect) {
            return <Redirect to="/OrderPlaced" />;
        }

        // if (this.state.avaliable_requested_product===0) {
        //     return <Redirect to="/Cart"/>;
        // }

        // console.log(this.state.schedule[0]['day_month']);
        // console.log(this.state.schedule[0]['day_name']);

        return (
            <main className="main home mb-2">
                <NotificationContainer />
                <div className={this.state.small_spinner}>
                    <i className="porto-loading-icon"></i>
                </div>
                <div className={this.state.spinner}>
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            {localStorage.getItem('user_log_status') === "true" || this.state.guest_checkout_status ? (

                                <Tabs selectedIndex={this.state.selectedIndex}
                                    onSelect={this.handleSelect}>
                                    <TabList>
                                        <h1 className="order-title">{this.state.Preview}</h1>
                                    </TabList>
                                    <TabPanel>
                                        <div className="container mb-2">
                                            <div className="row">
                                                {this.state.useraddressDetails.length !== 0 && !this.state.guest_checkout_status ?
                                                    (
                                                        <div className="col-lg-12">
                                                            {
                                                                this.state.useraddressDetails.map(useraddress => {
                                                                    // console.log(this.state.address_status)
                                                                    return (
                                                                        <>
                                                                            <div key={useraddress.address_id} className="custom-user-address" onChange={this.onChangeValue}>
                                                                                {this.state.useraddressDetails.length === 1 ? (
                                                                                    <input type="radio"
                                                                                        id={useraddress.address_id}
                                                                                        onChange={this.onChangeHandler}
                                                                                        value={useraddress.address_id}
                                                                                        name="address_id" className="custom-radio mr-2" checked />
                                                                                ) : (
                                                                                    <input type="radio"
                                                                                        id={useraddress.address_id}
                                                                                        onChange={this.onChangeHandler}
                                                                                        value={useraddress.address_id}
                                                                                        name="address_id" className="custom-radio mr-2" />
                                                                                )
                                                                                }
                                                                                <label htmlFor={useraddress.address_id}>
                                                                                    <p><b>{useraddress.address_type}</b></p>
                                                                                    <p>{useraddress.username},
                                                                    +971 {useraddress.mobile_no},<br />
                                                                                        {useraddress.flat_no}, {useraddress.building_name} {useraddress.landmark}, {useraddress.area}, {useraddress.city}.</p>
                                                                                </label>
                                                                            </div>

                                                                        </>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                            <span className="error">
                                                                {this.state.radioflatnoError}
                                                            </span>
                                                            { this.state.address_status ? (
                                                                <div className="row mt-2">
                                                                    <div className="form-group col-lg-6">
                                                                        <label htmlFor="username">Full Name</label>
                                                                        <input type="text" onChange={this.onChangeHandler} name="username" className="form-control form-control-sm" value={this.state.username} id="username" placeholder="Enter Fullname" />
                                                                        <span className="error">
                                                                            {this.state.usernameError}
                                                                        </span>
                                                                    </div>
                                                                    <div className="form-group col-lg-6">
                                                                        <label className="form_mobile_num_code_lable" htmlFor="mobile_no">Mobile Number</label>
                                                                        <input type="text" onChange={this.onChangeHandler} name="mobile_no_code" className="form-control form-control-sm form_mobile_num_code" value="+971" readOnly />
                                                                        <input type="number" onChange={this.onChangeHandler} name="mobile_no" value={this.state.mobile_no} className="form-control form-control-sm form_mobile_num" id="mobile_no" placeholder="Enter Mobile Number" />
                                                                        <span className="error">
                                                                            {this.state.mobile_noError}
                                                                        </span>
                                                                    </div>
                                                                    <div className="form-group col-lg-6">
                                                                        <label htmlFor="email">Email</label>
                                                                        <input type="email" onChange={this.onChangeHandler} name="email" className="form-control form-control-sm" value={this.state.email} id="email" placeholder="Enter Email ID" />
                                                                    </div>
                                                                    <div className="form-group col-lg-6">
                                                                        <label htmlFor="flat_no">Flat No</label>
                                                                        <input type="text" onChange={this.onChangeHandler} name="flat_no" className="form-control form-control-sm" id="flat_no" placeholder="Enter Flat Number" />
                                                                        <span className="error">
                                                                            {this.state.flatnoError}
                                                                        </span>
                                                                    </div>
                                                                    <div className="form-group col-lg-4">
                                                                        <label htmlFor="building_name">Building Name</label>
                                                                        <input type="text" onChange={this.onChangeHandler} name="building_name" className="form-control form-control-sm" id="building_name" placeholder="Enter Building Name" />
                                                                        <span className="error">
                                                                            {this.state.buildingnameError}
                                                                        </span>
                                                                    </div>
                                                                    <div className="form-group col-lg-4">
                                                                        <label htmlFor="landmark">Landmark or Street</label>
                                                                        <input type="text" onChange={this.onChangeHandler} name="landmark" className="form-control form-control-sm" id="landmark" placeholder="Enter Landmark or Street" />
                                                                    </div>
                                                                    <div className="form-group col-lg-4">
                                                                        <label htmlFor="area">Area</label>
                                                                        <select onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area">
                                                                            <option value=""> -- Please Select Area -- </option>
                                                                            {/* {
                                                            this.state.ybarealist.map(arealistsds => {
                                                                <option value={arealistsds.area_name}>{arealistsds.area_name}</option>
                                                            })} */}
                                                                            {this.state.ybarealist.map((team) => (
                                                                                <option
                                                                                    key={team.area_master_id}
                                                                                    value={team.area_name}
                                                                                >
                                                                                    {team.area_name}
                                                                                </option>
                                                                            ))}


                                                                        </select>
                                                                        {/* <input type="text" onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" placeholder="Enter Pincode" /> */}
                                                                        <span className="error">
                                                                            {this.state.areaError}
                                                                        </span>
                                                                    </div>
                                                                    <div className="form-group col-lg-4">
                                                                        <label htmlFor="city">City</label>
                                                                        <input type="text" onChange={this.onChangeHandler} name="city" className="form-control form-control-sm" id="city" placeholder="Enter City" value="Dubai" />
                                                                    </div>
                                                                    <div className="form-group col-lg-4">
                                                                        <label htmlFor="area">Address Type</label>
                                                                        <select onChange={this.onChangeHandler} name="address_type" className="form-control form-control-sm" id="address_type">
                                                                            <option value=""> -- Please Select Type -- </option>
                                                                            <option value="Home">Home </option>
                                                                            <option value="Work">Work </option>
                                                                            <option value="Other">Other </option>
                                                                        </select>
                                                                        {/* <input type="text" onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" placeholder="Enter Pincode" /> */}
                                                                        <span className="error">
                                                                            {this.state.addresstypeError}
                                                                        </span>
                                                                    </div>
                                                                    <div className="form-group col-lg-4">
                                                                        {this.state.useraddressDetails.length !== 0 ?
                                                                            <>
                                                                                <p> &nbsp;</p>
                                                                                <button type="button" onClick={() => this.hideaddresshorm()} className="btn btn-primary custom-address-btn m-l-5">Cancel</button>
                                                                                <button type="button" onClick={() => this.addressForm(1, this.state.username, this.state.mobile_no, this.state.flat_no, this.state.building_name, this.state.landmark, this.state.area, this.state.city, this.state.address_type)} className="btn btn-primary custom-address-btn m-l-5">Add</button>
                                                                            </>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row custom-add-address">
                                                                    <p onClick={() => this.showaddressform()} className="custom-address-add">Add Address</p>
                                                                </div>
                                                            )}
                                                            <div className="row">
                                                                {/* <div className="form-group col-lg-6">
                                                            <label htmlFor="packing_type">Packing Preference</label><br />
                                                            <input type="radio"
                                                                    id="plastic"
                                                                    onChange={this.onChangeHandler}
                                                                    value="Plastic"
                                                                    name="packing_type" className="custom-radio mr-2" />
                                                            <label htmlFor="plastic" className="mr-4">
                                                                Plastic
                                                            </label>
                                                            <input type="radio"
                                                                    id="Paperbag"
                                                                    onChange={this.onChangeHandler}
                                                                    value="Paperbag"
                                                                    name="packing_type" className="custom-radio mr-2" />
                                                            <label htmlFor="Paperbag">
                                                                Paperbag
                                                            </label>
                                                            <br />
                                                            <span className="error">
                                                                {this.state.packing_typeError}
                                                            </span>
                                                        </div> */}
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="expected_date">Schedule your delivery : &nbsp; </label>
                                                                    <div className="res-schedule-date">
                                                                        <input type="hidden" name="focus_input" id="focus_input" />
                                                                        {
                                                                            this.state.schedule.map(scheduleorder => {
                                                                                return (
                                                                                    <label key={scheduleorder.order_setting_id} className="schedule-date-label">
                                                                                        { this.state.todayDate === scheduleorder.order_accept_date && this.state.dubaitime > scheduleorder.order_accept_time ? (
                                                                                            <>
                                                                                                <input type="radio"
                                                                                                    id={scheduleorder.order_setting_id}
                                                                                                    onChange={this.onChangeHandler}
                                                                                                    value={scheduleorder.order_setting_id}
                                                                                                    name="schedule_date" className="custom-radio mr-2" disabled />
                                                                                                <div className="schedule-date-selector date-expiry">
                                                                                                    <h6 className="mb-0">{scheduleorder.day_month}</h6>
                                                                                                    <h4 className="mb-0 font-12">{scheduleorder.day_name}</h4>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                { scheduleorder.order_accept_count !== 0 && scheduleorder.order_accept_count !== "0" ? (
                                                                                                    <>
                                                                                                        <input type="radio"
                                                                                                            id={scheduleorder.order_setting_id}
                                                                                                            onChange={this.onChangeHandler}
                                                                                                            value={scheduleorder.order_setting_id}
                                                                                                            name="schedule_date" className="custom-radio mr-2" />
                                                                                                        <div className="schedule-date-selector">
                                                                                                            <h6 className="mb-0">{scheduleorder.day_month}</h6>
                                                                                                            <h4 className="mb-0 font-12">{scheduleorder.day_name}</h4>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <input type="radio"
                                                                                                            id={scheduleorder.order_setting_id}
                                                                                                            onChange={this.onChangeHandler}
                                                                                                            value={scheduleorder.order_setting_id}
                                                                                                            name="schedule_date" className="custom-radio mr-2" disabled />
                                                                                                        <div className="schedule-date-selector date-expiry">
                                                                                                            <h6 className="mb-0">{scheduleorder.day_month}</h6>
                                                                                                            <h4 className="mb-0 font-12">{scheduleorder.day_name}</h4>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </label>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <span className="error">
                                                                        {this.state.scheduledateError}
                                                                    </span>
                                                                    <p>Next available slot : Eve 6PM to 11PM</p>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="query">Order / Delivery Instructions ( We take your instructions seriously and will try our best to meet your expectations )</label>
                                                                    <textarea type="text" rows="3" onChange={this.onChangeHandler} name="query" className="form-control form-control-sm" id="query" placeholder="Eg: Please call me before coming , deliver after 8 Pm , Try to get me unripe bananas etc."></textarea>
                                                                </div>
                                                                <div className="form-group col-lg-12">
                                                                    {/* <label htmlFor="query">Payment Type</label>
                                                            <h4 className="no-mar mb-1">Pay on delivery</h4> */}
                                                                    <p className="notes">Note : you can pay cash / card during delivery</p>
                                                                </div>
                                                            </div>
                                                            <div className="custom-order-btn col-lg-12 res-tab-none">
                                                                <button type="button" onClick={() => this.submitorderhandler()} className="btn btn-primary custom-nxt-btn m-l-5">Place Order</button>
                                                            </div>
                                                        </div>
                                                    ) : !this.state.guest_checkout_status ? (
                                                        <div className="col-lg-12">
                                                            <div className="row">
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="username">Full Name</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="username" className="form-control form-control-sm" value={this.state.username} id="username" placeholder="Enter Fullname" />
                                                                    <span className="error">
                                                                        {this.state.usernameError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label className="form_mobile_num_code_lable" htmlFor="mobile_no">Mobile Number</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="mobile_no_code" className="form-control form-control-sm form_mobile_num_code" value="+971" readOnly />
                                                                    <input type="number" onChange={this.onChangeHandler} name="mobile_no" value={this.state.mobile_no} className="form-control form-control-sm form_mobile_num" id="mobile_no" placeholder="Enter Mobile Number" />
                                                                    <span className="error">
                                                                        {this.state.mobile_noError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="email">Email</label>
                                                                    <input type="email" onChange={this.onChangeHandler} name="email" className="form-control form-control-sm" value={this.state.email} id="email" placeholder="Enter Email ID" />
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="flat_no">Flat No</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="flat_no" className="form-control form-control-sm" id="flat_no" placeholder="Enter Flat No" />
                                                                    <span className="error">
                                                                        {this.state.flatnoError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="building_name">Building Name</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="building_name" className="form-control form-control-sm" id="building_name" placeholder="Enter Building Name" />
                                                                    <span className="error">
                                                                        {this.state.buildingnameError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="landmark">Landmark or Street</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="landmark" className="form-control form-control-sm" id="landmark" placeholder="Enter Landmark or Street" />
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="area">Area</label>
                                                                    <select onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area">
                                                                        <option value=""> -- Please Select Area -- </option>
                                                                        {/* {
                                                            this.state.ybarealist.map(arealistsds => {
                                                                <option value={arealistsds.area_name}>{arealistsds.area_name}</option>
                                                            })} */}
                                                                        {this.state.ybarealist.map((team) => (
                                                                            <option
                                                                                key={team.area_master_id}
                                                                                value={team.area_name}
                                                                            >
                                                                                {team.area_name}
                                                                            </option>
                                                                        ))}

                                                                        {/* <option value=""> -- Please Select Area -- </option>
                                                                <option value="Al Barari">Al Barari</option>
                                                                <option value="AL Furjan">AL Furjan</option>
                                                                <option value="AL Quoz 1">AL Quoz 1</option>
                                                                <option value="Business Bay">Business Bay</option>
                                                                <option value="Downtown Dubai">Downtown Dubai</option>
                                                                <option value="Dubai Silicon Oasis">Dubai Silicon Oasis</option>
                                                                <option value="Falcon City of Wonders">Falcon City of Wonders</option>
                                                                <option value="Liwan Queue Point">Liwan Queue Point</option>
                                                                <option value="Nad Al Sheba">Nad Al Sheba</option>
                                                                <option value="The Villa">The Villa</option> */}
                                                                    </select>
                                                                    {/* <input type="text" onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" placeholder="Enter Pincode" /> */}
                                                                    <span className="error">
                                                                        {this.state.areaError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="city">City</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="city" className="form-control form-control-sm" id="city" placeholder="Enter City" value="Dubai" />
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="area">Address Type</label>
                                                                    <select onChange={this.onChangeHandler} name="address_type" className="form-control form-control-sm" id="address_type">
                                                                        <option value=""> -- Please Select Type -- </option>
                                                                        <option value="Home">Home </option>
                                                                        <option value="Work">Work </option>
                                                                        <option value="Other">Other </option>
                                                                    </select>
                                                                    {/* <input type="text" onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" placeholder="Enter Pincode" /> */}
                                                                    <span className="error">
                                                                        {this.state.addresstypeError}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="custom-order-btn col-lg-12">
                                                                <button type="button" onClick={() => this.addressForm()} className="btn btn-primary custom-nxt-btn m-l-5">Continue</button>
                                                            </div>
                                                        </div>
                                                    ) : this.state.guest_checkout_status ? (
                                                        <div className="col-lg-12">
                                                            <div className="row">
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="username">Full Name</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="username" className="form-control form-control-sm" value={this.state.username} id="username" placeholder="Enter Fullname" />
                                                                    <span className="error">
                                                                        {this.state.usernameError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label className="form_mobile_num_code_lable" htmlFor="mobile_no">Mobile Number</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="mobile_no_code" className="form-control form-control-sm form_mobile_num_code" value="+971" readOnly />
                                                                    <input type="number" onChange={this.onChangeHandler} name="mobile_no" value={this.state.mobile_no} className="form-control form-control-sm form_mobile_num" id="mobile_no" placeholder="Enter Mobile Number" onBlur={() => this.handleBlur('mobile')} />
                                                                    <span className="error">
                                                                        {this.state.mobile_noError}
                                                                    </span>
                                                                    {this.state.mobile_verified === 'no' ? (
                                                                        <>
                                                                            <span className="error" onClick={() => this.openotpmodelverify()}>
                                                                                Click here to verify your mobile number to place the order
                                                                    </span>
                                                                        </>
                                                                    ) : this.state.mobile_verified === 'yes' ? (
                                                                        <>
                                                                            <span className="succes-text">
                                                                                Mobile number verified
                                                                </span>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="email">Email</label>
                                                                    <input type="email" onChange={this.onChangeHandler} name="email" className="form-control form-control-sm" value={this.state.email} id="email" placeholder="Enter Email ID" onBlur={() => this.handleBlur('email')} />
                                                                    <span className="error">
                                                                        {this.state.email_idError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="flat_no">Flat No</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="flat_no" className="form-control form-control-sm" id="flat_no" placeholder="Enter Flat No" />
                                                                    <span className="error">
                                                                        {this.state.flatnoError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="building_name">Building Name</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="building_name" className="form-control form-control-sm" id="building_name" placeholder="Enter Building Name" />
                                                                    <span className="error">
                                                                        {this.state.buildingnameError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="landmark">Landmark or Street</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="landmark" className="form-control form-control-sm" id="landmark" placeholder="Enter Landmark or Street" />
                                                                </div>
                                                                <div className="form-group col-lg-4">
                                                                    <label htmlFor="area">Area</label>
                                                                    <select onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area">
                                                                        <option value=""> -- Please Select Area -- </option>
                                                                        {this.state.ybarealist.map((team) => (
                                                                            <option
                                                                                key={team.area_master_id}
                                                                                value={team.area_name}
                                                                            >
                                                                                {team.area_name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <span className="error">
                                                                        {this.state.areaError}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="city">City</label>
                                                                    <input type="text" onChange={this.onChangeHandler} name="city" className="form-control form-control-sm" id="city" placeholder="Enter City" value="Dubai" />
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="area">Address Type</label>
                                                                    <select onChange={this.onChangeHandler} name="address_type" className="form-control form-control-sm" id="address_type">
                                                                        <option value=""> -- Please Select Type -- </option>
                                                                        <option value="Home">Home </option>
                                                                        <option value="Work">Work </option>
                                                                        <option value="Other">Other </option>
                                                                    </select>
                                                                    {/* <input type="text" onChange={this.onChangeHandler} name="area" className="form-control form-control-sm" id="area" placeholder="Enter Pincode" /> */}
                                                                    <span className="error">
                                                                        {this.state.addresstypeError}
                                                                    </span>
                                                                </div>
                                                                {/* <div className="form-group col-lg-6">
                                                            <label htmlFor="packing_type">Packing Preference</label><br />
                                                            <input type="radio"
                                                                    id="plastic"
                                                                    onChange={this.onChangeHandler}
                                                                    value="Plastic"
                                                                    name="packing_type" className="custom-radio mr-2" />
                                                            <label htmlFor="plastic" className="mr-4">
                                                                Plastic
                                                            </label>
                                                            <input type="radio"
                                                                    id="Paperbag"
                                                                    onChange={this.onChangeHandler}
                                                                    value="Paperbag"
                                                                    name="packing_type" className="custom-radio mr-2" />
                                                            <label htmlFor="Paperbag">
                                                                Paperbag
                                                            </label>
                                                            <br />
                                                            <span className="error">
                                                                {this.state.packing_typeError}
                                                            </span>
                                                        </div> */}
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="expected_date">Schedule your delivery : &nbsp; </label>
                                                                    <div className="res-schedule-date">
                                                                        <input type="hidden" name="focus_input2" id="focus_input2" />
                                                                        {

                                                                            this.state.schedule.map((scheduleorder) => (
                                                                                <label key={scheduleorder.order_setting_id} className="schedule-date-label">
                                                                                    { this.state.todayDate === scheduleorder.order_accept_date && this.state.dubaitime > scheduleorder.order_accept_time ? (
                                                                                        <>
                                                                                            <input type="radio"
                                                                                                id={scheduleorder.order_setting_id}
                                                                                                onChange={this.onChangeHandler}
                                                                                                value={scheduleorder.order_setting_id}
                                                                                                name="schedule_date" className="custom-radio mr-2" disabled />
                                                                                            <div className="schedule-date-selector date-expiry">
                                                                                                <h6 className="mb-0">{scheduleorder.day_month}</h6>
                                                                                                <h4 className="mb-0 font-12">{scheduleorder.day_name}</h4>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            { scheduleorder.order_accept_count !== 0 && scheduleorder.order_accept_count !== "0" ? (
                                                                                                <>
                                                                                                    <input type="radio"
                                                                                                        id={scheduleorder.order_setting_id}
                                                                                                        onChange={this.onChangeHandler}
                                                                                                        value={scheduleorder.order_setting_id}
                                                                                                        name="schedule_date" className="custom-radio mr-2" />
                                                                                                    <div className="schedule-date-selector">
                                                                                                        <h6 className="mb-0">{scheduleorder.day_month}</h6>
                                                                                                        <h4 className="mb-0 font-12">{scheduleorder.day_name}</h4>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <input type="radio"
                                                                                                        id={scheduleorder.order_setting_id}
                                                                                                        onChange={this.onChangeHandler}
                                                                                                        value={scheduleorder.order_setting_id}
                                                                                                        name="schedule_date" className="custom-radio mr-2" disabled />
                                                                                                    <div className="schedule-date-selector date-expiry">
                                                                                                        <h6 className="mb-0">{scheduleorder.day_month}</h6>
                                                                                                        <h4 className="mb-0 font-12">{scheduleorder.day_name}</h4>
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </label>
                                                                            ))}
                                                                    </div>
                                                                    <span className="error">
                                                                        {this.state.scheduledateError}
                                                                    </span>
                                                                    <p>Next available slot : Eve 6PM to 11PM</p>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label htmlFor="query">Order / Delivery Instructions ( We take your instructions seriously and will try our best to meet your expectations )</label>
                                                                    <textarea type="text" rows="3" onChange={this.onChangeHandler} name="query" className="form-control form-control-sm" id="query" placeholder="Eg: Please call me before coming , deliver after 8 Pm , Try to get me unripe bananas etc."></textarea>
                                                                </div>
                                                                <div className="form-group col-lg-12">
                                                                    <p className="notes">Note : you can pay cash / card during delivery</p>
                                                                </div>
                                                                <div className="custom-order-btn col-lg-12 res-tab-none">
                                                                <button type="button" onClick={() => this.submitorderhandlertwo()} id="guestloginbtndisbale"
                                                                        className="btn btn-primary custom-nxt-btn m-l-5" disabled={this.state.disable_btn}>Place Order</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (null)}
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            ) : (
                                <div className="row row-sm text-center">
                                    <div className="custom-no-product">
                                        <img className="res-checkout-img" src="/assets/img/login.png" alt="" />
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <p className="text-right"><button className="login-shopping-btn mb-3 login-modal">Login to Checkout</button></p>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <p className="text-left"><button onClick={() => this.guest_checkout()} className="guest-shopping-btn mb-3">Checkout as Guest</button></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-3">
                            <div className="checkout-summary">
                                <h3>Order Summary</h3>
                                <table className="table table-totals no-mb">
                                    <tbody>
                                        {
                                            this.state.cartDetails.map(product => {
                                                return (
                                                    <>
                                                        {(product.item_type === 'AP') ? (
                                                            <tr key={product.cart_id}>
                                                                <td>{product.prod_name} ({product.prod_quantity}{product.unit} x {product.product_cart_qty}) </td>
                                                                <td>AED {product.prod_totalprice}</td>
                                                            </tr>
                                                        ) : (product.item_type === 'slot_prod') ? (
                                                            <tr key={product.cart_id}>
                                                                <td>Yalla rush product ({product.slot_price} x {product.product_cart_qty}) </td>
                                                                <td>AED {product.prod_totalprice}</td>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                    </>
                                                )
                                            }
                                            )}

                                        <tr className="pad-1-6 bt-line">
                                            <td>Bag total</td>
                                            <td>AED {this.state.bagtotal}</td>
                                        </tr>

                                        {(this.state.yalla_money_used == 'yes') ? (
                                            <>
                                                <tr>
                                                    <td>Yalla Money</td>
                                                    <td className="offeramountflag">AED -{this.state.checkout_yalla_money}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <> </>
                                        )}


                                        {(this.state.bagsavings !== "0.00") ? (
                                            <>
                                                <tr>
                                                    <td>Bag savings</td>
                                                    <td className="offeramountflag">AED -{this.state.bagsavings}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <> </>
                                        )}
                                        {(this.state.yallasavings !== "0.00") ? (
                                            <>
                                                <tr>
                                                    <td>{this.state.offer_used_type}</td>
                                                    <td className="offeramountflag">AED -{this.state.yallasavings}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <> </>
                                        )}

                                        {(this.state.promocode_selected_flag === "1") ? (
                                            <>
                                                <tr>
                                                    <td>Coupon saving</td>
                                                    <td className="offeramountflag">AED -{this.state.coupon_discount_amt}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <> </>
                                        )}

                                        {/* <tr className="pad-1-6 bt-line">
                                            <td>Subtotal</td>
                                            <td>AED {this.state.Subtotal}</td>
                                        </tr> */}

                                        {/* <tr>
                                            <td>Tax</td>
                                            <td>AED 4.50</td>
                                        </tr> */}

                                        <tr>
                                            <td>Delivery</td>
                                            <td className="f-600">FREE</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="f-600 cart-total-width">Total</td>
                                            <td className="f-600">AED {this.state.Subtotal}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            {/* yallamoney start */}

                            

                            {(this.state.yalla_money > 0 && this.state.yalla_money_used=='no') ? (
                            <>
                                <div className="bg-whitegrayout mt-2">
                                    <table className="table table-totals table-totals-cust ">
                                        <tbody> 
                                                    <tr>
                                                        <td className="f-600 cart-total-width cust_td_margin">
                                                            <h3 className="back-clg2 text-uppercase"> Yalla Money </h3>
                                                            <h3 className="back-clg2 mt-1"> {this.state.yalla_money} AED</h3> 
                                                        </td>
                                                        {/* <td className="f-600" style={{width:130}}>Total <span className="checkout-total-span">(Inclusive of VAT)</span> </td> */}
                                                        <td className="f-600 cust_td_margin">
                                                            <p onClick={() => this.addyallamoney()} className="btn btn-primary">Use</p>
                                                        </td>
                                                    </tr> 
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            ) : (
                            <>
                                <div className="bg-whitegrayout mt-2">
                                    <table className="table table-totals table-totals-cust ">
                                        <tbody> 
                                                    <tr>
                                                        <td className="f-600 cart-total-width cust_td_margin">
                                                            <h3 className="back-clg2 text-left text-uppercase"> Your Yalla Money </h3>
                                                            <h3 className="back-clg2 text-left mt-1">  {this.state.remaining_yalla_money} AED</h3> 
                                                        </td>
                                                    </tr> 
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            )}
                        {/* yallamoney end  */}


                            {this.state.placeorder ? (
                                <div className="custom-order-btn col-lg-12 d-none res-tab-block">
                                    { !this.state.guest_checkout_status ? (
                                        <button type="button" onClick={() => this.submitorderhandler()} className="btn btn-primary custom-nxt-btn m-l-5">Place Order</button>
                                    ) : (
                                        <button type="button" onClick={() => this.submitorderhandlertwo()} className="btn btn-primary custom-nxt-btn m-l-5">Place Order</button>
                                    )}
                                </div>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                </div>


                <div>
                    {/* <button >Open modal</button> */}
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="container cust_stype_modal">
                            <>
                                <div className="form-group">
                                    <p className="loginotp_msgstyle">{this.state.LoginOtp_msg} <span className="hideloginotomodel_changebtn" onClick={() => this.onCloseModal()} > Change </span></p>
                                </div>
                                <div className="form-group form-group-custstyler">
                                    <label htmlFor="username"></label>
                                    <input type="text" onChange={this.onChangeHandler} name="loginotp_code" className="form-control form-control-sm"
                                        value={this.state.loginotp_code} id="loginotp_code" placeholder="Enter OTP" />
                                    <span className="error">
                                        {this.state.loginotp_codeError}
                                    </span>
                                </div>

                                <button type="button" onClick={() => this.verifyotpandplaceorderguest()} className="btn btn-secondary btn-block btn-block-custmstyler">Verify</button>
                                <ul className="nav nav-tabs nav-tabs-custstylere">
                                    <li className="nav-item nave-item-custstylers sign-up">
                                        Not received your code? <span className="nav-link nav-link-cust-styler" onClick={() => this.resendguestverifyotp()} > Resend code</span>
                                    </li>
                                </ul>

                                {this.state.show_guestopterror_msg === true ? (
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.guestotperror}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>

                        </div>
                    </Modal>
                </div>

            </main>
        )
    }

}

export default Checkout
