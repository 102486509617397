import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
// import Cookies from 'universal-cookie';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
// import { useToasts } from 'react-toast-notifications'
// const cookies = new Cookies();
// const current = new Date();
// const nextYear = new Date();
// nextYear.setFullYear(current.getFullYear() + 1);

class Cart extends Component {

    constructor() {
        super();
        this.state = {
            CartDetails: [],
            avaliable_coupones: [],
            spinner: 'loading-overlay',
            small_spinner: 'ajax-overlay hide-content',
            applycoupontext: "",
            couponerror: false,
            couponerror_textmsg: '',
            coupon_count: 0,
            coupon_discount_amt: "",
            final_amount: "",
            total_without_coupon: "",
            Coupon_code_appliedd: "",
            promocode_discount_value: "",
            promocode_discount_type: "",
            Coupon_code_appliedd_id: "",
            promocode_selected_flag: "0",
            outofstock_product_count: 0,
            pricerange_percentage: 0,
            pricerange_final_amt: 0,
            outofstock_product_errortest: false,
            avaliable_requested_product: 0,
            slotproduct_errortest: false
        };
        this.getcartdetails = this.getcartdetails.bind(this);
        this.applycouponcode = this.applycouponcode.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }

        if (localStorage.getItem('user_log_status') === "false") {
            this.setState({
                Subtotal: this.state.total_without_coupon,
                couponerror: false, promocode_selected_flag: "0", coupon_discount_amt: "", couponerror_textmsg: '',
                final_amount: "",
                total_without_coupon: "",
                Coupon_code_appliedd: "",
                promocode_discount_value: "",
                promocode_discount_type: "",
                Coupon_code_appliedd_id: "",
                applycoupontext: "",
            });
            localStorage.removeItem('coupon_discount_amt');
            localStorage.removeItem('final_amount');
            localStorage.removeItem('total_without_coupon');
            localStorage.removeItem('Coupon_code_appliedd');
            localStorage.removeItem('promocode_discount_value');
            localStorage.removeItem('promocode_discount_type');
            localStorage.removeItem('Coupon_code_appliedd_id');
            localStorage.removeItem('promocode_selected_flag');
        }

        this.setState({
            couponerror: false,
            promocode_selected_flag: localStorage.getItem('promocode_selected_flag'),
            coupon_discount_amt: localStorage.getItem('coupon_discount_amt'),
            final_amount: localStorage.getItem('final_amount'),
            total_without_coupon: localStorage.getItem('total_without_coupon'),
            Coupon_code_appliedd: localStorage.getItem('Coupon_code_appliedd'),
            Coupon_code_appliedd_id: localStorage.getItem('Coupon_code_appliedd_id'),
            promocode_discount_value: localStorage.getItem('promocode_discount_value'),
            promocode_discount_type: localStorage.getItem('promocode_discount_type'),
            applycoupontext: localStorage.getItem('Coupon_code_appliedd'),
        }, () => {
            this.getcartdetails();
        });
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ couponerror: false, couponerror_textmsg: '' });
    }

    getcartdetails() {
        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
            .post(`getUserCartDetails`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let cart_details = res.data.cart_details;
                    let Subtotal = res.data.total_price;
                    let bagtotal = res.data.bagtotal;
                    let bagsavings = res.data.bagsavings;
                    let yallasavings = res.data.yallasavings_offers;
                    let yallareferral_offers = res.data.yallareferral_offers;
                    let pricerange_percentage = res.data.pricerange_percentage;
                    let pricerange_final_amt = res.data.pricerange_final_amt;
                    let outofstock_product_count = res.data.outofstock_product_count;
                    let avaliable_requested_product = res.data.avaliable_requested_product;
                    let CartDetail = cart_details.map(product_response => { return product_response; });
                    this.setState({
                        CartDetails: CartDetail, Subtotal: Subtotal, bagtotal: bagtotal, bagsavings: bagsavings, yallasavings: yallasavings, yallareferral_offers: yallareferral_offers,
                        outofstock_product_count: outofstock_product_count, outofstock_product_errortest: false, pricerange_percentage: pricerange_percentage,
                        pricerange_final_amt: pricerange_final_amt, avaliable_requested_product: avaliable_requested_product
                    });
                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner });
                    if (this.state.promocode_selected_flag === "1" || this.state.promocode_selected_flag === 1) {
                        this.applycouponcode();
                    }
                }
            })
            .catch((err) => console.log("Error: ", err));
    }

    IncrementItem(prod_id, prod_quantity, prod_price, prod_weight) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        var quantity = 1;
        var total_weight = quantity * prod_weight;
        var total_price = prod_price * quantity;
        var final_total = parseFloat(total_price).toFixed(2);

        const jsonValue = {
            prod_id: prod_id,
            prod_quantity: 1,
            prod_price: prod_price,
            prod_weight: prod_weight,
            prod_totalweight: total_weight,
            prod_totalprice: final_total,
            user_unique_id: localStorage.getItem('user_auth_key')
        };

        axiosInstance
            .post(`inserttobasket`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.getcartdetails();
                }
            })
            .catch((err) => console.log("Error: ", err));
    }

    DecreaseItem(prod_id, prod_quantity, prod_price, prod_weight) {
        if (prod_quantity > 1) {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            var quantity = 1;
            var total_weight = quantity * prod_weight;
            var total_price = prod_price * quantity;
            var final_total = parseFloat(total_price).toFixed(2);
            const jsonValue = {
                prod_id: prod_id,
                prod_quantity: 1,
                prod_price: prod_price,
                prod_weight: prod_weight,
                prod_totalweight: total_weight,
                prod_totalprice: final_total,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
                .post(`removebasketqty`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        this.getcartdetails();
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
    }


    removecartitem(cart_id) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            cart_id: cart_id,
            user_unique_id: localStorage.getItem('user_auth_key')
        };

        axiosInstance
            .post(`removeCartDetails`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.props.onIncrement();
                    this.getcartdetails();
                }
            })
            .catch((err) => console.log("Error: ", err));

    }

    applycouponcode() {
        if (this.state.applycoupontext === "" || this.state.applycoupontext === null) {
            this.setState({ couponerror: true, couponerror_textmsg: 'Please enter the valid coupon code' });
        }
        else {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            const jsonValue = {
                Coupon_code: this.state.applycoupontext,
                CartDetails: this.state.CartDetails,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
                .post(`applycouponcode`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        let coupon_discount_amt = res.data.apliedpromocode.discountamount;
                        let final_amount = res.data.apliedpromocode.finalamount;
                        let total_without_coupon = res.data.apliedpromocode.overall_total;
                        let Coupon_code_appliedd = res.data.apliedpromocode.Coupon_code;
                        let Coupon_code_appliedd_id = res.data.apliedpromocode.promocode_id;
                        let promocode_discount_value = res.data.apliedpromocode.promocode_discount_value;
                        let promocode_discount_type = res.data.apliedpromocode.promocode_discount_type;
                        let promocode_selected_flag = "1";
                        let couponerror_textmsg = res.data.msg;

                        this.setState({
                            couponerror: false, promocode_selected_flag: "1", coupon_discount_amt: coupon_discount_amt,
                            final_amount: final_amount,
                            total_without_coupon: total_without_coupon,
                            Coupon_code_appliedd: Coupon_code_appliedd,
                            Coupon_code_appliedd_id: Coupon_code_appliedd_id,
                            promocode_discount_value: promocode_discount_value,
                            promocode_discount_type: promocode_discount_type,
                            Subtotal: final_amount, couponerror_textmsg: couponerror_textmsg
                        });

                        localStorage.setItem('coupon_discount_amt', coupon_discount_amt);
                        localStorage.setItem('final_amount', final_amount);
                        localStorage.setItem('total_without_coupon', total_without_coupon);
                        localStorage.setItem('Coupon_code_appliedd', Coupon_code_appliedd);
                        localStorage.setItem('Coupon_code_appliedd_id', Coupon_code_appliedd_id);
                        localStorage.setItem('promocode_discount_value', promocode_discount_value);
                        localStorage.setItem('promocode_discount_type', promocode_discount_type);
                        localStorage.setItem('promocode_selected_flag', promocode_selected_flag);
                        this.onCloseModal();

                        // this.props.onIncrement();
                        // this.componentDidMount();
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "400") {
                        let couponerror_textmsg = res.data.msg;
                        this.setState({
                            couponerror: true, promocode_selected_flag: "0", coupon_discount_amt: "",
                            final_amount: "", couponerror_textmsg: couponerror_textmsg,
                            total_without_coupon: "",
                            Coupon_code_appliedd: "",
                            promocode_discount_value: "",
                            promocode_discount_type: "",
                            Coupon_code_appliedd_id: ""
                        });
                        localStorage.removeItem('coupon_discount_amt');
                        localStorage.removeItem('final_amount');
                        localStorage.removeItem('total_without_coupon');
                        localStorage.removeItem('Coupon_code_appliedd');
                        localStorage.removeItem('Coupon_code_appliedd_id');
                        localStorage.removeItem('promocode_discount_value');
                        localStorage.removeItem('promocode_discount_type');
                        localStorage.removeItem('promocode_selected_flag');
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
    }
    applyselectedpromocode(promo_code, promocode_id) {

        this.setState({ applycoupontext: promo_code, couponerror: false });

        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            Coupon_code: promo_code,
            CartDetails: this.state.CartDetails,
            user_unique_id: localStorage.getItem('user_auth_key')
        };
        axiosInstance
            .post(`applycouponcode`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let coupon_discount_amt = res.data.apliedpromocode.discountamount;
                    let final_amount = res.data.apliedpromocode.finalamount;
                    let total_without_coupon = res.data.apliedpromocode.overall_total;
                    let Coupon_code_appliedd = res.data.apliedpromocode.Coupon_code;
                    let Coupon_code_appliedd_id = res.data.apliedpromocode.promocode_id;
                    let promocode_discount_value = res.data.apliedpromocode.promocode_discount_value;
                    let promocode_discount_type = res.data.apliedpromocode.promocode_discount_type;
                    let promocode_selected_flag = "1";

                    this.setState({
                        couponerror: false, promocode_selected_flag: "1", coupon_discount_amt: coupon_discount_amt,
                        final_amount: final_amount,
                        total_without_coupon: total_without_coupon,
                        Coupon_code_appliedd: Coupon_code_appliedd,
                        Coupon_code_appliedd_id: Coupon_code_appliedd_id,
                        promocode_discount_value: promocode_discount_value,
                        promocode_discount_type: promocode_discount_type,
                        Subtotal: final_amount
                    });

                    localStorage.setItem('coupon_discount_amt', coupon_discount_amt);
                    localStorage.setItem('final_amount', final_amount);
                    localStorage.setItem('total_without_coupon', total_without_coupon);
                    localStorage.setItem('Coupon_code_appliedd', Coupon_code_appliedd);
                    localStorage.setItem('Coupon_code_appliedd_id', Coupon_code_appliedd_id);
                    localStorage.setItem('promocode_discount_value', promocode_discount_value);
                    localStorage.setItem('promocode_discount_type', promocode_discount_type);
                    localStorage.setItem('promocode_selected_flag', promocode_selected_flag);

                    this.onCloseModal();

                }
                else if (res.data.status === "fail" && res.data.statusCode === "400") {
                    this.setState({
                        couponerror: true, promocode_selected_flag: "0", coupon_discount_amt: "",
                        final_amount: "",
                        total_without_coupon: "",
                        Coupon_code_appliedd: "",
                        promocode_discount_value: "",
                        promocode_discount_type: "",
                        Coupon_code_appliedd_id: ""
                    });
                    localStorage.removeItem('coupon_discount_amt');
                    localStorage.removeItem('final_amount');
                    localStorage.removeItem('total_without_coupon');
                    localStorage.removeItem('Coupon_code_appliedd');
                    localStorage.removeItem('promocode_discount_value');
                    localStorage.removeItem('promocode_discount_type');
                    localStorage.removeItem('Coupon_code_appliedd_id');
                    localStorage.removeItem('promocode_selected_flag');
                }
            })
            .catch((err) => console.log("Error: ", err));
    }

    clearcoupontext() {
        this.setState({ applycoupontext: "", couponerror: false, couponerror_textmsg: '' });
    }

    onOpenModal = () => {
        this.setState({ applycoupontext: "", open: true });
        // this.setState({ open: true })
        // let small_spinner = 'ajax-overlay';
        // this.setState({ small_spinner });
        // const jsonValue = { user_unique_id: cookies.get('user_auth_key') };
        // axiosInstance
        //   .post(`getavaliablepromocodes`, jsonValue)
        //   .then((res) => {
        //     let small_spinner = 'ajax-overlay hide-content';
        //     this.setState({ small_spinner });
        //     if (res.data.status === "success" && res.data.statusCode === "200")
        //     {
        //       let ProductValues = res.data.avaliable_promocodes;
        //       let coupon_count = res.data.count;
        //       let ProductDetails = ProductValues.map(product_response => { return product_response; });     
        //       this.setState({ avaliable_coupones: ProductDetails,coupon_count:coupon_count});              
        //       this.setState({ open: true });
        //     }
        //   })
        //   .catch((err) => console.log("Error: ", err));


    };

    onCloseModal = () => {
        //   alert('hii');
        this.setState({ open: false, clicks: 1 });
        this.setState({ show: false });
        this.setState({ couponerror: false });
        // this.setState({ applycoupontext:"" });
        // this.reload();
        // alert(this.state.open);
    };

    removeappliedcoupon = () => {
        //   alert('hii');
        this.setState({
            Subtotal: this.state.total_without_coupon,
            couponerror: false, promocode_selected_flag: "0", coupon_discount_amt: "", couponerror_textmsg: '',
            final_amount: "",
            total_without_coupon: "",
            Coupon_code_appliedd: "",
            promocode_discount_value: "",
            promocode_discount_type: "",
            Coupon_code_appliedd_id: "",
            applycoupontext: "",
        });
        localStorage.removeItem('coupon_discount_amt');
        localStorage.removeItem('final_amount');
        localStorage.removeItem('total_without_coupon');
        localStorage.removeItem('Coupon_code_appliedd');
        localStorage.removeItem('promocode_discount_value');
        localStorage.removeItem('promocode_discount_type');
        localStorage.removeItem('Coupon_code_appliedd_id');
        localStorage.removeItem('promocode_selected_flag');
        // this.reload();
        // alert(this.state.open);
    };

    removeoutofstockprod() {
        this.setState({ outofstock_product_errortest: true });
    }

    showsloterror() {
        this.setState({ slotproduct_errortest: true });
    }


    render() {
        // console.log(this.state.promocode_selected_flag);
        // console.log(localStorage.getItem('promocode_selected_flag'));
        return (
            <main className="main setminheight">
                <div className={this.state.small_spinner}>
                    <i className="porto-loading-icon"></i>
                </div>
                <div className={this.state.spinner}>
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                            <li className="breadcrumb-item"><span>Basket </span></li>
                        </ol>
                    </div>
                </nav>

                <div className="container">
                    <div className="row mb-2 min_hg_500">
                        <div className="col-lg-9">
                            {this.state.CartDetails.length !== 0 ? (
                                <div className="container no-pad-res">
                                    <div className="cart-items p-15">
                                        {
                                            this.state.CartDetails.map(cart => {
                                                return (
                                                    <div key={cart.cart_id} className="row cart-bottom p-15">

                                                        { (cart.item_type === 'AP') ? (
                                                            <>
                                                                <div className="col-lg-1 col-3">
                                                                    {(cart.outof_stock_flag === '0') ? (
                                                                        <>
                                                                            <img className="outofstockflagon" src={cart.produ_imgurl} alt={cart.prod_name} />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <img className="cart-img" src={cart.produ_imgurl} alt={cart.prod_name} />
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className="col-lg-11 col-9">
                                                                    <div className="row">
                                                                        <div className="col-lg-3 col-12">
                                                                            <div className="cart-details">
                                                                                <p className="cart-category p-0 m-0">
                                                                                    {cart.mcName}
                                                                                    {(cart.offer_flag === "1" && cart.redeemed_offers_flag === 1) || (cart.offer_flag === "0" && cart.redeemed_offers_flag === 1) ? (
                                                                                        <>
                                                                                            <span className="product-save-discount">&nbsp; {cart.redeemed_offers_value}   {cart.redeemed_offers_type === 'P' ? (
                                                                                                <> % </>
                                                                                            ) : (
                                                                                                <> AED </>
                                                                                            )} OFF</span>
                                                                                        </>
                                                                                    ) : (cart.offer_flag === "1" && cart.redeemed_offers_flag === 0) ? (
                                                                                        <>
                                                                                            <span className="product-save-discount">&nbsp; {cart.offer_value}   {cart.offer_type === 'P' ? (
                                                                                                <> % </>
                                                                                            ) : (
                                                                                                <> AED </>
                                                                                            )} OFF</span>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                        </>
                                                                                    )}
                                                                                </p>
                                                                                <p className="cart-name ">{cart.prod_name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-12">
                                                                            <p className="cart-total-weight  p-0 m-0">UOM : {cart.product_cart_qty} X {cart.prod_weight} {cart.unit_id} </p>
                                                                            {(cart.outof_stock_flag === '0') ? (
                                                                                <>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {/* <p className=" p-0 m-0"><span className="cart-currency  p-0 m-0">AED</span> <span className="cart-price">{cart.original_amt}</span></p> */}
                                                                                    {(cart.offer_flag === "1" && cart.redeemed_offers_flag === 1) || (cart.offer_flag === "0" && cart.redeemed_offers_flag === 1) ? (
                                                                                        <>
                                                                                            <span className="old-price">AED {cart.original_amt}</span>
                                                                                            <span className="product-price">AED {cart.selling_price}</span>
                                                                                        </>
                                                                                    ) : (cart.offer_flag === "1" && cart.redeemed_offers_flag === 0) ? (
                                                                                        <>
                                                                                            <span className="old-price">AED {cart.original_amt}</span>
                                                                                            <span className="product-price">AED {cart.selling_price}</span>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span className="product-price">AED {cart.selling_price}</span>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-lg-3 col-12">
                                                                            {(cart.outof_stock_flag === '0') ? (
                                                                                <>
                                                                                    <h5 className="outofstockflagcolor  p-0 m-0">Currently Out Of Stock</h5>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {(cart.unit_id === 'Pack' || cart.unit_id === 'Piece') && cart.prod_totalweight > 1 ? (
                                                                                        <p className="cart-total-weight  p-0 m-0">Total UOM : {cart.prod_totalweight} {cart.unit_id}s</p>
                                                                                    ) : (cart.unit_id === 'Bunch' || cart.unit_id === 'Box') && cart.prod_totalweight > 1 ? (
                                                                                        <p className="cart-total-weight  p-0 m-0">Total UOM : {cart.prod_totalweight} {cart.unit_id}es</p>
                                                                                    ) : (
                                                                                        <p className="cart-total-weight  p-0 m-0">Total UOM : {cart.prod_totalweight} {cart.unit_id}</p>
                                                                                    )}
                                                                                    <p className="cart-total-price ">Total Price : AED <span className="cart-price  p-0 m-0">{cart.prod_totalprice}</span></p>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-lg-3 col-12">
                                                                            {(cart.outof_stock_flag === '0') ? (
                                                                                <>
                                                                                    <span onClick={() => this.removecartitem(cart.cart_id)} className="cart-remove"> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="input-group mob-p-l">
                                                                                        <span className="input-group-btn">
                                                                                            <button type="button" className="btn btn-number custom-qty-m" onClick={() => this.DecreaseItem(cart.prod_id, cart.product_cart_qty, cart.selling_price, cart.prod_weight)} aria-label="Add one" data-direction="1">
                                                                                                <span className="fa fa-minus"></span>
                                                                                            </button>
                                                                                        </span>
                                                                                        <input type="text" className="form-control input-number" name="quantity" value={cart.product_cart_qty} readOnly={true} />
                                                                                        <span className="input-group-btn">
                                                                                            <button type="button" className="btn btn-number custom-qty-a" onClick={() => this.IncrementItem(cart.prod_id, cart.product_cart_qty, cart.selling_price, cart.prod_weight)} aria-label="Subtract one" data-direction="-1">
                                                                                                <span className="fa fa-plus"></span>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                    <span onClick={() => this.removecartitem(cart.cart_id)} className="cart-remove mob-d-none"> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                                    <span onClick={() => this.removecartitem(cart.cart_id)} className="cart-remove d-none mob-d-block f-right"> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (cart.item_type === 'NAP') ? (
                                                            <>
                                                                <div className="col-lg-1 col-3">
                                                                    <div className="cart-details">
                                                                        <>
                                                                            <img className="cart-img" src="/assets/img/unnamed.png" alt="requested-prod" />
                                                                        </>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-11 col-9">
                                                                    <div className="row">
                                                                        <div className="col-lg-3 col-12">
                                                                            <div className="cart-details">
                                                                                <p className="cart-category  p-0 m-0">Requested Product</p>
                                                                                <p className="cart-name">{cart.item_name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-12">
                                                                            <p className="cart-total-weight  p-0 m-0">UOM : {cart.product_cart_qty} X {cart.prod_weight} {cart.unit_id} </p>
                                                                            <div className="cart-details  p-0 m-0">
                                                                                <></>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-12">
                                                                            <p className="cart-total-weight  p-0 m-0 f-left d-none mob-d-block">
                                                                                <span>Price : AED 0.00 </span>
                                                                                <span onClick={() => this.removecartitem(cart.cart_id)} className="cart-remove d-none mob-d-block f-right"> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                            </p>
                                                                            <p className="cart-total-weight  p-0 m-0 mob-d-none">Price : AED 0.00 </p>

                                                                            <div className="cart-details  p-0 m-0">
                                                                                <></>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-12">
                                                                            <span onClick={() => this.removecartitem(cart.cart_id)} className="cart-remove mob-d-none"> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (cart.item_type === 'slot_prod') ? (
                                                            <>
                                                                <div className="col-lg-3 col-6">
                                                                    <div className="dis-slot-imag">
                                                                        <img class="cart-img" src={cart.slot_prod1} alt="slot products" />
                                                                        <img class="cart-img" src={cart.slot_prod2} alt="slot products" />
                                                                        <img class="cart-img" src={cart.slot_prod3} alt="slot products" />
                                                                    </div>

                                                                </div>
                                                                <div className="col-lg-9 col-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-12">
                                                                            <div className="cart-details">
                                                                                <p className="cart-category  p-0 m-0">Yalla Rush Offer</p>
                                                                                <p className="cart-name  p-0 m-0">Combo of 3 product</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-12">
                                                                            <div className="cart-details">
                                                                                <>
                                                                                    <p className="cart-total-weight  p-0 m-0">AED {cart.slot_price} X {cart.product_cart_qty}</p>
                                                                                    <p className="cart-total-price  p-0 m-0">Total Price : AED <span className="cart-price">{cart.prod_totalprice}</span></p>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-12">
                                                                            <span onClick={() => this.removecartitem(cart.cart_id)} className="cart-remove"> <i className="fa fa-trash-alt"></i> Remove</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <> </>
                                                        )}
                                                    </div>
                                                )
                                            }
                                            )}
                                    </div>
                                    <p className="continue_shop_test"> <Link to="/" className="cont_text"> Continue Shopping </Link></p>
                                </div>
                            ) : (
                                <div className="row row-sm text-center">
                                    <div className="custom-no-product">
                                        <img className="mb-2" src="/assets/img/Basket-New_1_Animation_1.gif" alt="" />
                                        {/* <div className="testdivcust mb-2">
                                        <p className="mb-1 cart-empty-slogan mt-4" >" Hi  {  cookies.get('yallabasketuser') !== undefined ? (
                                        <span> { cookies.get('yallabasketuser') }</span>
                                        ) : ( 
                                            <span> guest</span>
                                        ) },  </p>
                                        <p className="mb-0 cart-empty-slogan mt-4">Eva the basket looks sad as you are yet to add products to her. Please start shopping to make her happy "</p>
                                    </div> */}
                                        <div>
                                            <Link to="/"><button className="login-shopping-btn mb-3">Start Shopping</button></Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col-lg-3 ">

                            <div className="bg-whitegrayout">
                                <table className="table table-totals table-totals-cust ">
                                    <tbody>
                                        {(this.state.promocode_selected_flag === "1") ? (
                                            <>
                                                <tr>
                                                    <td className="f-600 cart-total-width cust_td_margin">
                                                        <p className="offertypedisplycuster"><img src="/assets/img/discount.png" alt="discount"></img>&nbsp; Coupon applied </p>
                                                        <small> {this.state.Coupon_code_appliedd} saving {this.state.promocode_discount_value}
                                                            {this.state.promocode_discount_type === 'P' ? (
                                                                <> % OFF</>
                                                            ) : (
                                                                <> AED OFF</>
                                                            )}
                                                        </small>
                                                    </td>
                                                    {/* <td className="f-600" style={{width:130}}>Total <span className="checkout-total-span">(Inclusive of VAT)</span> </td> */}
                                                    <td className="f-600 cust_td_margin">
                                                        <p onClick={() => this.removeappliedcoupon()} className=" back-clg2">Remove</p>
                                                    </td>

                                                </tr>
                                            </>
                                        ) : (
                                            <>
                                                <tr>
                                                    {/* <td className="f-600" style={{width:130}}>Total <span className="checkout-total-span">(Inclusive of VAT)</span> </td> */}
                                                    {localStorage.getItem('user_log_status') === "true" ? (
                                                        <>
                                                            <td className="f-600 cart-total-width cust_td_margin text-left">
                                                                <p className="offertypedisplycuster offerportweight">&nbsp; Enter a Coupon Code </p>
                                                                <div className="row row-sm ">

                                                                    <div className="form-group form-group-custeded col-lg-8 col-8 pd-rht-cust-0">
                                                                        <input type="text" name="applycoupontext" onChange={this.onChangeHandler} value={this.state.applycoupontext} className="border-color-nnon form-control form-control-sm" id="building_name" placeholder="Enter Coupon Code or Gift Card" />
                                                                    </div>
                                                                    <div className="form-group form-group-custeded col-lg-3 col-3 text-center pd-cust-0">
                                                                        <p onClick={() => this.applycouponcode()} className="form-control form-control-sm back-clg"> Apply </p>
                                                                    </div>
                                                                    {(this.state.applycoupontext !== "") ? (
                                                                        <>
                                                                            {(this.state.applycoupontext !== null) ? (
                                                                                <p onClick={() => this.clearcoupontext()} className="back-clg pd-cust-0 clear-coupon-text"> Clear Coupon * </p>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    {(this.state.couponerror === true) ? (
                                                                        <>
                                                                            <p className="back-clg pd-cust-0 clear-coupon-text clear-coupon-text-error"> {this.state.couponerror_textmsg} </p>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>

                                                            </td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td className="f-600 cart-total-width cust_td_margin">
                                                                <p className="offertypedisplycuster"><img src="/assets/img/discount.png" alt="discount"></img>&nbsp; Have a Coupon Code ?</p>
                                                                {/* <p onClick={() => this.onOpenModal() } className=" back-clg2 removecoloroffer">
                                                            Select a Coupon Code </p> */}
                                                            </td>
                                                            <td className="f-600 cust_td_margin">
                                                                <p id="login-modal" className=" back-clg2 login-modal">Apply</p>
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="cart-summary">
                                <h3>Order Summary</h3>

                                <table className="table table-totals">
                                    <tbody>
                                        <tr>
                                            <td>Bag total</td>
                                            <td>AED {this.state.bagtotal}</td>
                                        </tr>
                                        {(this.state.bagsavings !== "0.00") ? (
                                            <>
                                                <tr>
                                                    <td>Bag savings</td>
                                                    <td className="offeramountflag">AED -{this.state.bagsavings}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <> </>
                                        )}

{(this.state.yallasavings !== 0 && this.state.yallasavings !== "0" && this.state.yallasavings !== "0.00") ? (
                                            <>
                                                <tr>
                                                    <td>Yalla credit savings</td>
                                                    <td className="offeramountflag">AED -{this.state.yallasavings}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <> </>
                                        )}

                                        {(this.state.yallareferral_offers !== 0 && this.state.yallareferral_offers !== "0" && this.state.yallareferral_offers !== "0.00") ? (
                                            <>
                                                <tr>
                                                    <td>Yalla referral savings</td>
                                                    <td className="offeramountflag">AED -{this.state.yallareferral_offers}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <> </>
                                        )}

                                        {(this.state.promocode_selected_flag === "1") ? (
                                            <>
                                                <tr>
                                                    <td>Coupon Saving</td>
                                                    <td className="offeramountflag">AED -{this.state.coupon_discount_amt}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <> </>
                                        )}
                                        {(this.state.applycoupontext === "" || this.state.applycoupontext === null) ? (
                                            <>
                                                {(this.state.pricerange_percentage > 0 || this.state.pricerange_percentage > "0") ? (
                                                    <>
                                                        <tr>
                                                            <td>Discount on bill</td>
                                                            <td className="offeramountflag">AED -{this.state.pricerange_percentage}</td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <> </>
                                                )}
                                            </>
                                        ) : (
                                            <> </>
                                        )}

                                        <tr>
                                            <td>Delivery</td>
                                            <td className="f-600">FREE</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>

                                            <td className="f-600 cart-total-width">Total</td>
                                            {/* <td className="f-600" style={{width:130}}>Total <span className="checkout-total-span">(Inclusive of VAT)</span> </td> */}

                                            {(this.state.applycoupontext === "" || this.state.applycoupontext === null) ? (
                                                <>
                                                    {(this.state.pricerange_percentage > 0 || this.state.pricerange_percentage > "0") ? (
                                                        <>
                                                            <td className="f-600">AED {this.state.pricerange_final_amt}</td>
                                                        </>
                                                    ) : (
                                                        <td className="f-600">AED {this.state.Subtotal}</td>
                                                    )}
                                                </>
                                            ) : (
                                                <td className="f-600">AED {this.state.Subtotal}</td>
                                            )}

                                        </tr>
                                    </tfoot>
                                </table>

                                {this.props.counter !== 0 ? (
                                    <>
                                        {this.state.outofstock_product_count === 0 ? (
                                            <>
                                                {this.state.avaliable_requested_product === 1 ? (
                                                    <div className="checkout-methods">
                                                        <Link to="/Checkout" className="btn btn-block btn-sm btn-primary"> Checkout Now</Link>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="checkout-methods">
                                                            <span onClick={() => this.showsloterror()} className="btn btn-block btn-sm btn-primary">Checkout Now</span>
                                                        </div>
                                                        {this.state.slotproduct_errortest === true ? (
                                                            <>
                                                                <h5 className="outofstockflagcolor Collapsible__contentOuter">*  Note you cannot checkout only with Yalla Rush products</h5>
                                                            </>
                                                        ) : (
                                                            <>   </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="checkout-methods">
                                                    <span onClick={() => this.removeoutofstockprod()} className="btn btn-block btn-sm btn-primary">Checkout Now</span>
                                                </div>
                                                {this.state.outofstock_product_errortest === true ? (
                                                    <>
                                                        <h5 className="outofstockflagcolor Collapsible__contentOuter">*  Remove the out of stock product from cart to proceed next</h5>
                                                    </>
                                                ) : (
                                                    <>   </>
                                                )}

                                            </>
                                        )}
                                    </>

                                ) : (
                                    <div className="checkout-methods">
                                        <span className="btn btn-block btn-sm btn-primary Checkout-Now-disable">Checkout Now</span>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>

                <div>
                    {/* <button >Open modal</button> */}
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="container cust_stype_modal">
                            <h2 className="product-title offers_img_displytypee">
                                <img src="/assets/img/discount.png" alt="discount"></img> Available Offers
                        </h2>
                            <div className="row row-sm">
                                <div className="form-group form-group-custeded col-lg-8 col-8 pd-rht-cust-0">
                                    <input type="text" name="applycoupontext" onChange={this.onChangeHandler} value={this.state.applycoupontext} className="border-color-nnon form-control form-control-sm" id="building_name" placeholder="Enter Coupon Code or Gift Card" />
                                </div>
                                <div className="form-group form-group-custeded col-lg-3 col-3 text-center pd-cust-0">
                                    <p onClick={() => this.applycouponcode()} className="form-control form-control-sm back-clg"> Apply </p>
                                </div>
                                {/* <p>Note : In case you don't have any Promo code you may click the  </p> */}
                                {(this.state.applycoupontext !== "") ? (
                                    <>
                                        <p onClick={() => this.clearcoupontext()} className="back-clg pd-cust-0 clear-coupon-text"> Clear Coupon *</p>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {(this.state.couponerror === true) ? (
                                    <>
                                        <p className="back-clg pd-cust-0 clear-coupon-text clear-coupon-text-error">  {this.state.couponerror_textmsg} </p>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </Modal>
                </div>
            </main>
        )
    }
}

export default Cart