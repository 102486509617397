import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class Orderdetails extends Component {

    constructor() {
        super();
        this.state = {
            orderDetails: [],
            spinner: 'loading-overlay',
            small_spinner: 'ajax-overlay hide-content',
            product_freshness: [],
            product_packing: [],
            product_delivery_experience: [],
            order_review_comments: "",
            reviewErrormsg: "",
            log_status:true,
        };
        this.getordersdetailsbyid = this.getordersdetailsbyid.bind(this);
        this.handelratingpoints = this.handelratingpoints.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
        }
        var url = window.location.href;
        var parts = url.split('=');
        var lastSegment = parts.pop() || parts.pop();
        this.getordersdetailsbyid(lastSegment);
    }

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    async getordersdetailsbyid(lastSegment) {
        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key'), orderno: lastSegment };
        axiosInstance
            .post(`getordersdetailsbyid`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") 
                {
                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner, log_status:res.data.log_status });

                    let order_details = res.data.order_details;
                    let OrderDetails = order_details.map(order_response => { return order_response; });
                    this.setState({ orderDetails: OrderDetails });

                    if(res.data.order_details[0]["order_current_status"]==="order_delivered")
                    {
                        let userorder_rating = res.data.order_details[0]["userorder_rating"];
                        if(userorder_rating.length>0)
                        {
                            this.setState({ order_review_comments:userorder_rating[0]["order_review_comments"] });
                            
                            if(userorder_rating[0]["product_freshness"]!=='' && userorder_rating[0]["product_freshness"]!==null)
                            {
                                var product_freshness_ls = [];
                                product_freshness_ls.push(userorder_rating[0]["product_freshness"]);
                                this.setState({
                                    product_freshness: product_freshness_ls
                                }, () => {
                                    var array = this.state.product_freshness;
                                    if (array.length > 0) {
                                        var largest = array[0];
                                        for (var l = 0; l <= largest; l++) {
                                            if (array[l] > largest) {
                                                largest = array[l];
                                            }
                                        }
                                        for (let p2 = 1; p2 <= 5; p2++) {
                                            var seleted_check = document.getElementById('product_freshness' + p2 + '').parentElement.firstChild.firstElementChild;
                                            seleted_check.src = "/assets/img/review.png";
                                            // document.getElementById('product_freshness' + p2 + '').parentElement.firstChild.classList.remove("order-pg-charle-img-selected");
                                            document.getElementById('product_freshness' + p2 + '').checked = false;
                                        }
                                        setTimeout(
                                            function () {
                                                for (let p = 1; p <= largest; p++) {
                                                    var seleted_check = document.getElementById('product_freshness' + p + '').parentElement.firstChild.firstElementChild;
                                                    seleted_check.src = "/assets/img/review-01.png";
                                                    // document.getElementById('product_freshness' + p + '').parentElement.firstChild.classList.add("order-pg-charle-img-selected");
                                                    document.getElementById('product_freshness' + p + '').checked = true;
                                                }
                                            }, 100
                                        );
                                    }
                                });
                            }
                            if(userorder_rating[0]["packing"]!=='' && userorder_rating[0]["packing"]!==null)
                            {
                                var product_packing_ls = [];
                                product_packing_ls.push(userorder_rating[0]["packing"]);
                                this.setState({
                                    product_packing: product_packing_ls
                                }, () => {
                                    var array2 = this.state.product_packing;
                                    if (array2.length > 0) {
                                        var largest2 = array2[0];
                                        for (var l = 0; l <= largest2; l++) {
                                            if (array2[l] > largest2) {
                                                largest2 = array2[l];
                                            }
                                        }
                                        for (let pl2 = 1; pl2 <= 5; pl2++) {
                                            var seleted_check = document.getElementById('packing' + pl2 + '').parentElement.firstChild.firstElementChild;
                                            seleted_check.src = "/assets/img/review.png";
                                            // document.getElementById('packing' + pl2 + '').parentElement.firstChild.classList.remove("order-pg-charle-img-selected");
                                            document.getElementById('packing' + pl2 + '').checked = false;
                                        }
                                        setTimeout(
                                            function () {
                                                for (let pl = 1; pl <= largest2; pl++) {
                                                    var seleted_check = document.getElementById('packing' + pl + '').parentElement.firstChild.firstElementChild;
                                                    seleted_check.src = "/assets/img/review-01.png";
                                                    // document.getElementById('packing' + pl + '').parentElement.firstChild.classList.add("order-pg-charle-img-selected");
                                                    document.getElementById('packing' + pl + '').checked = true;
                                                }
                                            }, 100
                                        );
                                    }
                                });
                            }
                            if(userorder_rating[0]["delivery_experience"]!=='' && userorder_rating[0]["delivery_experience"]!==null)
                            {
                                var product_delivery_experience_ls = [];
                                product_delivery_experience_ls.push(userorder_rating[0]["delivery_experience"]);
                                this.setState({
                                    product_delivery_experience: product_delivery_experience_ls
                                }, () => {
                                    var array3 = this.state.product_delivery_experience;
                                    if (array3.length > 0) {
                                        var largest3 = array3[0];
                                        for (var l = 0; l <= largest3; l++) {
                                            if (array3[l] > largest3) {
                                                largest3 = array3[l];
                                            }
                                        }
                                        for (let pd2 = 1; pd2 <= 5; pd2++) {
                                            var seleted_check = document.getElementById('delivery_experience' + pd2 + '').parentElement.firstChild.firstElementChild;
                                            seleted_check.src = "/assets/img/review.png";
                                            // document.getElementById('delivery_experience' + pd2 + '').parentElement.firstChild.classList.remove("order-pg-charle-img-selected");
                                            document.getElementById('delivery_experience' + pd2 + '').checked = false;
                                        }
                                        setTimeout(
                                            function () {
                                                for (let pd = 1; pd <= largest3; pd++) {
                                                    var seleted_check = document.getElementById('delivery_experience' + pd + '').parentElement.firstChild.firstElementChild;
                                                    seleted_check.src = "/assets/img/review-01.png";
                                                    // document.getElementById('delivery_experience' + pd + '').parentElement.firstChild.classList.add("order-pg-charle-img-selected");
                                                    document.getElementById('delivery_experience' + pd + '').checked = true;
                                                }
                                            }, 100
                                        );
                                    }
                                });
                            }                         

                        }   
                    }
                }
            })
            .catch((err) => console.log("Error: ", err));
    }

    reorder(id, code) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            order_id: id,
            orderno: code,
            user_unique_id: localStorage.getItem('user_auth_key')
        };
        axiosInstance
            .post(`reordercustomer`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.setState({ userRedirect: true });
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                }
            })
            .catch((err) => console.log("Error: ", err));

    }


    handelratingpoints = (value,img_id,order_id,orderno) => {
        if (value === "product_freshness") {
            this.setState({
                product_freshness: []
            });
            var product_freshness = [];
            var chks = document.getElementsByClassName('visually-hidden-lable-product-freshness');
            for (var i = 0; i < chks.length; i++) {
                if (chks[i].checked) {
                    product_freshness.push(chks[i].value);
                }
            }

            this.setState({
                product_freshness: product_freshness
            }, () => {
                var array = this.state.product_freshness;
                if (array.length > 0) {
                    var largest = array[0];
                    for (var l = 0; l <= largest; l++) {
                        if (array[l] > largest) {
                            largest = array[l];
                        }
                    }
                    for (let p2 = 1; p2 <= 5; p2++) {
                        var seleted_check = document.getElementById('product_freshness' + p2 + '').parentElement.firstChild.firstElementChild;
                        seleted_check.src = "/assets/img/review.png";
                        // document.getElementById('product_freshness' + p2 + '').parentElement.firstChild.classList.remove("order-pg-charle-img-selected");
                        document.getElementById('product_freshness' + p2 + '').checked = false;
                    }
                    setTimeout(
                        function () {
                            for (let p = 1; p <= largest; p++) {
                                var seleted_check = document.getElementById('product_freshness' + p + '').parentElement.firstChild.firstElementChild;
                                seleted_check.src = "/assets/img/review-01.png";
                                // console.log(document.getElementById('product_freshness' + p + '').parentElement.firstChild.firstElementChild);
                                // document.getElementById('product_freshness' + p + '').parentElement.firstChild.classList.add("order-pg-charle-img-selected");
                                document.getElementById('product_freshness' + p + '').checked = true;
                            }
                        }, 100
                    );
                }

                // let small_spinner = 'ajax-overlay';
                // this.setState({ small_spinner });
                const jsonValue = {
                    product_freshness: this.state.product_freshness,
                    product_packing: this.state.product_packing,
                    product_delivery_experience: this.state.product_delivery_experience,
                    order_id: order_id,
                    orderno: orderno,
                    order_review_comments: this.state.order_review_comments,
                    user_unique_id: localStorage.getItem('user_auth_key')
                };
                axiosInstance
                    .post(`checkandupdatereview`, jsonValue)
                    .then((res) => {
                        if (res.data.status === "success" && res.data.statusCode === "200") {
                            let small_spinner = 'ajax-overlay hide-content';
                            this.setState({ small_spinner });
                            NotificationManager.success('Your rating has been saved', 'Success', 1000);
                        }
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    })
                    .catch((err) => console.log("Error: ", err));

            });
        }
        if (value === "product_packing") {
            this.setState({ product_packing: [] });
            var product_packing = [];
            var chks_product_packing = document.getElementsByClassName('visually-hidden-lable-product-packing');
            for (var j = 0; j < chks_product_packing.length; j++) {
                if (chks_product_packing[j].checked) {
                    chks_product_packing[j].parentElement.firstChild.classList.add("order-pg-charle-img-selected")
                    product_packing.push(chks_product_packing[j].value);
                }
                else {
                    chks_product_packing[j].parentElement.firstChild.classList.remove("order-pg-charle-img-selected")
                }
            }
            this.setState({
                product_packing: product_packing
            }, () => {
                var array2 = this.state.product_packing;
                if (array2.length > 0) {
                    var largest2 = array2[0];
                    for (var l = 0; l <= largest2; l++) {
                        if (array2[l] > largest2) {
                            largest2 = array2[l];
                        }
                    }
                    for (let pl2 = 1; pl2 <= 5; pl2++) {
                        var seleted_check = document.getElementById('packing' + pl2 + '').parentElement.firstChild.firstElementChild;
                        seleted_check.src = "/assets/img/review.png";
                        // document.getElementById('packing' + pl2 + '').parentElement.firstChild.classList.remove("order-pg-charle-img-selected");
                        document.getElementById('packing' + pl2 + '').checked = false;
                    }
                    setTimeout(
                        function () {
                            for (let pl = 1; pl <= largest2; pl++) {
                                var seleted_check = document.getElementById('packing' + pl + '').parentElement.firstChild.firstElementChild;
                                seleted_check.src = "/assets/img/review-01.png";
                                // document.getElementById('packing' + pl + '').parentElement.firstChild.classList.add("order-pg-charle-img-selected");
                                document.getElementById('packing' + pl + '').checked = true;
                            }
                        }, 100
                    );
                }

                const jsonValue = {
                    product_freshness: this.state.product_freshness,
                    product_packing: this.state.product_packing,
                    product_delivery_experience: this.state.product_delivery_experience,
                    order_id: order_id,
                    order_review_comments: this.state.order_review_comments,
                    user_unique_id: localStorage.getItem('user_auth_key')
                };
                axiosInstance
                    .post(`checkandupdatereview`, jsonValue)
                    .then((res) => {
                        if (res.data.status === "success" && res.data.statusCode === "200") {
                            let small_spinner = 'ajax-overlay hide-content';
                            this.setState({ small_spinner });
                            NotificationManager.success('Your rating has been saved', 'Success', 1000);
                        }
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    })
                    .catch((err) => console.log("Error: ", err));
            });
        }
        if (value === "product_delivery_experience") {
            this.setState({ product_delivery_experience: [] });
            var product_delivery_experience = [];
            var chks_product_delivery_experience = document.getElementsByClassName('visually-hidden-lable-delivery-experience');
            for (var k = 0; k < chks_product_delivery_experience.length; k++) {
                if (chks_product_delivery_experience[k].checked) {
                    chks_product_delivery_experience[k].parentElement.firstChild.classList.add("order-pg-charle-img-selected")
                    product_delivery_experience.push(chks_product_delivery_experience[k].value);
                }
                else {
                    chks_product_delivery_experience[k].parentElement.firstChild.classList.remove("order-pg-charle-img-selected")
                }
            }
            this.setState({
                product_delivery_experience: product_delivery_experience
            }, () => {
                var array3 = this.state.product_delivery_experience;
                if (array3.length > 0) {
                    var largest3 = array3[0];
                    for (var l = 0; l <= largest3; l++) {
                        if (array3[l] > largest3) {
                            largest3 = array3[l];
                        }
                    }
                    for (let pd2 = 1; pd2 <= 5; pd2++) {
                        var seleted_check = document.getElementById('delivery_experience' + pd2 + '').parentElement.firstChild.firstElementChild;
                        seleted_check.src = "/assets/img/review.png";
                        // document.getElementById('delivery_experience' + pd2 + '').parentElement.firstChild.classList.remove("order-pg-charle-img-selected");
                        document.getElementById('delivery_experience' + pd2 + '').checked = false;
                    }
                    setTimeout(
                        function () {
                            for (let pd = 1; pd <= largest3; pd++) {
                                var seleted_check = document.getElementById('delivery_experience' + pd + '').parentElement.firstChild.firstElementChild;
                                seleted_check.src = "/assets/img/review-01.png";
                                // document.getElementById('delivery_experience' + pd + '').parentElement.firstChild.classList.add("order-pg-charle-img-selected");
                                document.getElementById('delivery_experience' + pd + '').checked = true;
                            }
                        }, 100
                    );
                }

                // let small_spinner = 'ajax-overlay';
                // this.setState({ small_spinner });
                const jsonValue = {
                    product_freshness: this.state.product_freshness,
                    product_packing: this.state.product_packing,
                    product_delivery_experience: this.state.product_delivery_experience,
                    order_id: order_id,
                    order_review_comments: this.state.order_review_comments,
                    user_unique_id: localStorage.getItem('user_auth_key')
                };
                axiosInstance
                    .post(`checkandupdatereview`, jsonValue)
                    .then((res) => {
                        if (res.data.status === "success" && res.data.statusCode === "200") {
                            let small_spinner = 'ajax-overlay hide-content';
                            this.setState({ small_spinner });
                            NotificationManager.success('Your rating has been saved', 'Success', 1000);
                        }
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    })
                    .catch((err) => console.log("Error: ", err));

            });
        }
    }

    submitOrderReview = (order_id) => {

        if (this.state.product_freshness.length === 0 && this.state.product_packing.length === 0 && this.state.product_delivery_experience.length === 0) {
            document.getElementById('order_review_comments').classList.add("error-border");
            this.setState({ reviewErrormsg: "Select any rating" });
        }
        else if (this.state.order_review_comments === '' || this.state.order_review_comments === ' ' || this.state.order_review_comments === '  ' || this.state.order_review_comments === '   ') {
            document.getElementById('order_review_comments').classList.add("error-border");
            this.setState({ reviewErrormsg: "Review comments cannot be empty" });
        }
        else if (this.state.order_review_comments !== '') {
            this.setState({ reviewErrormsg: " " });
            document.getElementById('order_review_comments').classList.remove("error-border");
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            const jsonValue = {
                product_freshness: this.state.product_freshness,
                product_packing: this.state.product_packing,
                product_delivery_experience: this.state.product_delivery_experience,
                order_id: order_id,
                order_review_comments: this.state.order_review_comments,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
            .post(`checkandupdatereview`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    NotificationManager.success('Your rating has been saved', 'Success', 1000);
                }
                else
                {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Error: ", err));
        }
    }

    render() {

        if (this.state.userRedirect) {
            return <Redirect to="/Cart" />;
        }

        return (
            <main className="main">
                <NotificationContainer />
                <div className={this.state.small_spinner}>
                    <i className="porto-loading-icon"></i>
                </div>
                <div className={this.state.spinner}>
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                            <li className="breadcrumb-item"><span>Order Details </span></li>
                        </ol>
                    </div>
                </nav>


                {
                this.state.log_status===true?
                <>
                    <div className="container">
                        <div className="row mb-2">
                            <div className="col-lg-12">

                                {this.state.orderDetails.length !== 0 ? (

                                    <div className="container no-pad-res">

                                        {
                                            this.state.orderDetails.map((orders) => (


                                                <div className="cart-items order-pad order-item-pad">


                                                    <div key={orders.order_id} className="row cart-bottom">
                                                        <div className="col-lg-12">
                                                            <div className="order-header order-pg-order-header">
                                                                <h4 className="order-title-style">Order No : {orders.orderno}</h4>
                                                                <h4 className="order-date"><button onClick={() => this.reorder(orders.order_id, orders.orderno)} className="reorder-btn text-right guest-shopping-btn">Reorder</button> </h4>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <div className="row mb-2">
                                                                <div className="col-lg-6 col-12 web-res-mt-2">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-12">
                                                                            {/* <div className="order_delivery_address">
                                                                    <h4 className="order-title-style">Order No : {orders.orderno}</h4>                                                           
                                                                    <h4 className="order-title-style">Order Date : {orders.ordered_date}</h4>                                                           
                                                                    <h4 className="order-title-style">Delivery Date : {orders.delivery_date}</h4>                                                           
                                                                </div> */}
                                                                            <div className="order_delivery_address">
                                                                                <h4 className="order-pg-deliverylist">Delivery Address</h4>
                                                                                <label className="addresscontent order-pg-addresscontent">
                                                                                    <p><b>{orders.address_info[0].username} ,</b><br />
                                                                        +971 {orders.address_info[0].mobile_no} ,<br />
                                                                                        {orders.address_info[0].flat_no}, {orders.address_info[0].building_name}, {orders.address_info[0].landmark}, {orders.address_info[0].area}, {orders.address_info[0].city}.</p>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-12 hideatmobile">
                                                                            <div className="cart-summary order-pg-summery">
                                                                                <h3>Order Summary</h3>

                                                                                <table className="table table-totals">
                                                                                    <tbody>

                                                                                        <tr>
                                                                                            <td>Bag total</td>
                                                                                            <td>AED {orders.orderbag_total}</td>
                                                                                        </tr>

                                                                                        {orders.discount_price !== '0.00' && orders.discount_price !== null ? (
                                                                                            <tr>
                                                                                                <td>Bag Saving</td>
                                                                                                <td>AED {orders.discount_price}</td>
                                                                                            </tr>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {orders.promo_code !== '0' && orders.promo_code !== null ? (
                                                                                            <tr>
                                                                                                <td>Coupon Saving </td>
                                                                                                <td>AED {orders.promocode_price}</td>
                                                                                            </tr>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {orders.price_range_discount !== '0' && orders.price_range_discount !== 0 && orders.price_range_discount !== "0.00" && orders.price_range_discount !== null ? (
                                                                                            <tr>
                                                                                                <td>Discount on bill </td>
                                                                                                <td>AED {orders.price_range_discount}</td>
                                                                                            </tr>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        <tr>
                                                                                            <td>Shipping</td>
                                                                                            <td className="f-600">FREE</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                    <tfoot>
                                                                                        <tr>
                                                                                            <td className="f-600 cart-total-width">Total</td>
                                                                                            <td className="f-600 f-16">AED {orders.total_price}</td>
                                                                                        </tr>
                                                                                    </tfoot>
                                                                                </table>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12 col-12">
                                                                            <div className="web-res-mt-2 mb-2">
                                                                                <h4 className="text-left order-pg-deliverylist">Track Order</h4>
                                                                                {orders.order_current_status === 'order_placed' ? (
                                                                                    <ol className="progtrckr" data-progtrckr-steps="4">
                                                                                        <li className="progtrckr-done">Order Placed</li>
                                                                                        <li className="progtrckr-todo">In Progress</li>
                                                                                        <li className="progtrckr-todo">Out for Delivery</li>
                                                                                        <li className="progtrckr-todo">Delivered </li>
                                                                                        {/* <li className="progtrckr-todo">Cancelled</li> */}
                                                                                    </ol>
                                                                                ) : orders.order_current_status === 'order_inprogress' ? (
                                                                                    <ol className="progtrckr" data-progtrckr-steps="4">
                                                                                        <li className="progtrckr-done">Order Placed</li>
                                                                                        <li className="progtrckr-done">In Progress</li>
                                                                                        <li className="progtrckr-todo">Out for Delivery</li>
                                                                                        <li className="progtrckr-todo">Delivered </li>
                                                                                        {/* <li className="progtrckr-todo">Cancelled</li> */}
                                                                                    </ol>
                                                                                ) : orders.order_current_status === 'order_outofdelivery' ? (
                                                                                    <ol className="progtrckr" data-progtrckr-steps="4">
                                                                                        <li className="progtrckr-done">Order Placed</li>
                                                                                        <li className="progtrckr-done">In Progress</li>
                                                                                        <li className="progtrckr-done">Out for Delivery</li>
                                                                                        <li className="progtrckr-todo">Delivered </li>
                                                                                        {/* <li className="progtrckr-todo">Cancelled</li> */}
                                                                                    </ol>
                                                                                ) : orders.order_current_status === 'order_delivered' ? (
                                                                                    <ol className="progtrckr" data-progtrckr-steps="4">
                                                                                        <li className="progtrckr-done">Order Placed</li>
                                                                                        <li className="progtrckr-done">In Progress</li>
                                                                                        <li className="progtrckr-done">Out for Delivery</li>
                                                                                        <li className="progtrckr-done">Delivered </li>
                                                                                        {/* <li className="progtrckr-todo">Cancelled</li> */}
                                                                                    </ol>
                                                                                ) : orders.order_current_status === 'order_cancelled' ? (
                                                                                    <ol className="progtrckr" data-progtrckr-steps="4">
                                                                                        <li className="progtrckr-done">Order Placed</li>
                                                                                        <li className="progtrckr-done">In Progress</li>
                                                                                        <li className="progtrckr-done">Cancelled</li>
                                                                                    </ol>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12 col-12">
                                                                            <div className="row">
                                                                                {orders.order_current_status !== 'order_cancelled' && orders.order_current_status !== 'order_delivered' ? (
                                                                                    <>
                                                                                        <div className="col-lg-6 col-12">
                                                                                            <div className="card-text">
                                                                                                <h4 className="text-left order-pg-deliverylist">Delivery </h4>
                                                                                                {orders.order_current_status !== 'order_cancelled' && orders.order_current_status !== 'order_delivered' ? (
                                                                                                    <p className="order-pg-deliverdate">&nbsp;  &nbsp; &nbsp;  &nbsp; Excepted delivery at {orders.delivery_date}</p>
                                                                                                ) : orders.order_current_status === 'order_delivered' ? (
                                                                                                    <p className="order-pg-deliverdate">&nbsp;  &nbsp; &nbsp;  &nbsp; Delivered at {orders.delivery_date} </p>
                                                                                                ) : orders.order_current_status === 'order_cancelled' ? (
                                                                                                    <p className="order-pg-deliverdate">&nbsp;  &nbsp; &nbsp;  &nbsp; Order Cancelled at {orders.delivery_date}</p>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-12">
                                                                                            <div className="card-text">
                                                                                                <h4 className="text-left order-pg-deliverylist">Change of Plans ?</h4>
                                                                                                <p className="mb-2">Contact us now to modify or cancel your order  &nbsp;  &nbsp;
                                                                                    <a className="displayinlineblosck" href="https://wa.me/message/MY2RPGA2B7BDJ1" target="_blank" rel="noopener noreferrer nofollow" ><p className="md-0-cust" ><img className="what_logo_style what_logo_style_order" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6207179</p></a>
                                                                                    &nbsp;  &nbsp;  <a className="displayinlineblosck" href="https://wa.me/message/BKO5NZ5MJOH6A1" target="_blank" rel="noopener noreferrer nofollow" ><p className="md-0-cust"><img className="what_logo_style what_logo_style_order" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6339179</p></a></p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className="col-lg-12 col-12">
                                                                                            <div className="card-text">
                                                                                                <h4 className="text-left order-pg-deliverylist">Delivery </h4>
                                                                                                {orders.order_current_status !== 'order_cancelled' && orders.order_current_status !== 'order_delivered' ? (
                                                                                                    <p className="order-pg-deliverdate">&nbsp;  &nbsp; &nbsp;  &nbsp; Excepted delivery at {orders.delivery_date}</p>
                                                                                                ) : orders.order_current_status === 'order_delivered' ? (
                                                                                                    <p className="order-pg-deliverdate">&nbsp;  &nbsp; &nbsp;  &nbsp; Delivered at {orders.delivery_date} </p>
                                                                                                ) : orders.order_current_status === 'order_cancelled' ? (
                                                                                                    <p className="order-pg-deliverdate">&nbsp;  &nbsp; &nbsp;  &nbsp; Order Cancelled at {orders.delivery_date}</p>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12 col-12">
                                                                            {orders.order_current_status === 'order_delivered' ? (
                                                                                <>
                                                                                    <h4 className="text-left order-pg-deliverylist">Customer Review </h4>
                                                                                    <div className="col-lg-12 col-12 mb-1">
                                                                                        <div className="row">
                                                                                            <div className="col-lg-3 col-12 p-0 m-0">
                                                                                                <p className="f-14 ml-4 mt-2">Product freshness</p>
                                                                                            </div>
                                                                                            <div className="col-lg-8 col-12 mb-1">
                                                                                                <div className="row">
                                                                                                    <div className="col-lg-7 col-9 pr-0 pl-0">
                                                                                                        <div className="order-pg-custreview">
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="product_freshness1"> <img id="product_freshness_img1" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="product_freshness" id="product_freshness1" value="1" class="visually-hidden-lable-product-freshness visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_freshness","product_freshness_img1",orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="product_freshness2"> <img id="product_freshness_img2" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="product_freshness" id="product_freshness2" value="2" class="visually-hidden-lable-product-freshness visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_freshness","product_freshness_img2", orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="product_freshness3"> <img id="product_freshness_img3" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="product_freshness" id="product_freshness3" value="3" class="visually-hidden-lable-product-freshness visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_freshness","product_freshness_img3",orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="product_freshness4"> <img id="product_freshness_img4" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="product_freshness" id="product_freshness4" value="4" class="visually-hidden-lable-product-freshness visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_freshness","product_freshness_img4",orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="product_freshness5"> <img id="product_freshness_img5" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="product_freshness" id="product_freshness5" value="5" class="visually-hidden-lable-product-freshness visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_freshness","product_freshness_img5", orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-3 col-3 m-0 p-0">
                                                                                                        {this.state.product_freshness[0] === '1' ? (
                                                                                                            <p className="mt-2 very-bad  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Very Bad</p>
                                                                                                        ) : this.state.product_freshness[0] === '2' ? (
                                                                                                            <p className="mt-2 bad  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Bad</p>
                                                                                                        ) : this.state.product_freshness[0] === '3' ? (
                                                                                                            <p className="mt-2 Good  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Good</p>
                                                                                                        ) : this.state.product_freshness[0] === '4' ? (
                                                                                                            <p className="mt-2 very-Good  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Very Good</p>
                                                                                                        ) : this.state.product_freshness[0] === '5' ? (
                                                                                                            <p className="mt-2 Excellent  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Excellent</p>
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-12 col-12 mb-1">
                                                                                        <div className="row">
                                                                                                <div className="col-lg-3 col-12 p-0 m-0">
                                                                                                    <p className="f-14 ml-4 mt-2">Product Packaging</p>
                                                                                                </div>
                                                                                                <div className="col-lg-8 col-12 mb-1">
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-7 col-9 pr-0 pl-0">
                                                                                                            <div className="order-pg-custreview">
                                                                                                                <div className="order-pg-review">
                                                                                                                    <label className="display_biweekly_popup_content order-pg-charle-img" for="packing1"> <img id="packing_img1" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                    <input type="radio" name="packing" id="packing1" value="1" class="visually-hidden-lable-product-packing visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_packing","packing_img1", orders.order_id,orders.orderno)} />
                                                                                                                </div>
                                                                                                                <div className="order-pg-review">
                                                                                                                    <label className="display_biweekly_popup_content order-pg-charle-img" for="packing2"> <img id="packing_img2" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                    <input type="radio" name="packing" id="packing2" value="2" class="visually-hidden-lable-product-packing visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_packing","packing_img2", orders.order_id,orders.orderno)} />
                                                                                                                </div>
                                                                                                                <div className="order-pg-review">
                                                                                                                    <label className="display_biweekly_popup_content order-pg-charle-img" for="packing3"> <img id="packing_img3" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                    <input type="radio" name="packing" id="packing3" value="3" class="visually-hidden-lable-product-packing visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_packing","packing_img3", orders.order_id,orders.orderno)} />
                                                                                                                </div>
                                                                                                                <div className="order-pg-review">
                                                                                                                    <label className="display_biweekly_popup_content order-pg-charle-img" for="packing4"> <img id="packing_img4" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                    <input type="radio" name="packing" id="packing4" value="4" class="visually-hidden-lable-product-packing visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_packing","packing_img4", orders.order_id,orders.orderno)} />
                                                                                                                </div>
                                                                                                                <div className="order-pg-review">
                                                                                                                    <label className="display_biweekly_popup_content order-pg-charle-img" for="packing5"> <img id="packing_img5" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                    <input type="radio" name="packing" id="packing5" value="5" class="visually-hidden-lable-product-packing visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_packing","packing_img5", orders.order_id,orders.orderno)} />
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="col-lg-3 col-3 m-0 p-0">
                                                                                                            {this.state.product_packing[0] === '1' ? (
                                                                                                                <p className="mt-2 very-bad pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Very Bad</p>
                                                                                                            ) : this.state.product_packing[0] === '2' ? (
                                                                                                                <p className="mt-2 bad  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Bad</p>
                                                                                                            ) : this.state.product_packing[0] === '3' ? (
                                                                                                                <p className="mt-2 Good  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Good</p>
                                                                                                            ) : this.state.product_packing[0] === '4' ? (
                                                                                                                <p className="mt-2 very-Good  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Very Good</p>
                                                                                                            ) : this.state.product_packing[0] === '5' ? (
                                                                                                                <p className="mt-2 Excellent  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Excellent</p>
                                                                                                            ) : (
                                                                                                                <></>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                        </div>

                                                                                        {/* <p className="f-14">Rate the packaging</p> */}
                                                                                        
                                                                                    </div>
                                                                                    <div className="col-lg-12 col-12 mb-1">
                                                                                        <div className="row">
                                                                                            <div className="col-lg-3 col-12 p-0 m-0">
                                                                                                <p className="f-14 ml-4 mt-2">Delivery Experience</p>
                                                                                            </div>
                                                                                            <div className="col-lg-8 col-12 mb-1">
                                                                                                <div className="row">
                                                                                                    <div className="col-lg-7 col-9 pr-0 pl-0">
                                                                                                        <div className="order-pg-custreview">
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="delivery_experience1"> <img id="delivery_experience_img1" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="delivery_experience" id="delivery_experience1" value="1" class="visually-hidden-lable-delivery-experience visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_delivery_experience","delivery_experience_img1",orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="delivery_experience2"> <img id="delivery_experience_img2" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="delivery_experience" id="delivery_experience2" value="2" class="visually-hidden-lable-delivery-experience visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_delivery_experience","delivery_experience_img2",orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="delivery_experience3"> <img id="delivery_experience_img3" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="delivery_experience" id="delivery_experience3" value="3" class="visually-hidden-lable-delivery-experience visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_delivery_experience","delivery_experience_img3",orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="delivery_experience4"> <img id="delivery_experience_img4" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="delivery_experience" id="delivery_experience4" value="4" class="visually-hidden-lable-delivery-experience visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_delivery_experience","delivery_experience_img4",orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                            <div className="order-pg-review">
                                                                                                                <label className="display_biweekly_popup_content order-pg-charle-img" for="delivery_experience5"> <img id="delivery_experience_img5" src="/assets/img/review.png" alt="order rating"></img></label>
                                                                                                                <input type="radio" name="delivery_experience" id="delivery_experience5" value="5" class="visually-hidden-lable-delivery-experience visually-hidden-lable-biweekly" onClick={() => this.handelratingpoints("product_delivery_experience","delivery_experience_img5",orders.order_id,orders.orderno)} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-3 col-3 m-0 p-0">
                                                                                                        {this.state.product_delivery_experience[0] === '1' ? (
                                                                                                            <p className="mt-2 very-bad  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Very Bad</p>
                                                                                                        ) : this.state.product_delivery_experience[0] === '2' ? (
                                                                                                            <p className="mt-2 bad  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Bad</p>
                                                                                                        ) : this.state.product_delivery_experience[0] === '3' ? (
                                                                                                            <p className="mt-2 Good  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Good</p>
                                                                                                        ) : this.state.product_delivery_experience[0] === '4' ? (
                                                                                                            <p className="mt-2 very-Good  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Very Good</p>
                                                                                                        ) : this.state.product_delivery_experience[0] === '5' ? (
                                                                                                            <p className="mt-2 Excellent  pb-0 mb-0 mt-1 f-600 order-pg-f-16"> Excellent</p>
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <p className="f-14">Rate the delivery experience</p> */}
                                                                                        

                                                                                    </div>
                                                                                    <div className="col-lg-12 col-12 mb-1">
                                                                                        <p className="f-14">Review this order</p>
                                                                                        <div className="order-pg-custreview">
                                                                                            <textarea className="form-control" maxLength="250" name="order_review_comments" required value={this.state.order_review_comments} onChange={this.onChangeHandler} id="order_review_comments" placeholder="Comments..." rows="4" cols="50">
                                                                                            </textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-12 col-12 mb-1">
                                                                                        <span className="error text-right ml-4">{this.state.reviewErrormsg}</span>
                                                                                        <button className="reorder-btn f-right guest-shopping-btn" onClick={() => this.submitOrderReview(orders.order_id)}> Submit </button>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                                <div className="col-lg-6 col-12 mt-2">
                                                                    <h4 className="order-pg-prodlist"> Products list</h4>
                                                                    {orders.produc_info.map(products => (
                                                                        <div key={products.prod_id} className="col-lg-12">
                                                                            <div className="row">
                                                                                <div className="col-lg-2 col-2 hide-on-mobile">
                                                                                    {products.product_type === 'slot_prod' ? (
                                                                                        <>
                                                                                            <img className="cart-img order-img  order-img-slot" src={products.slot_prod1} alt="" />
                                                                                            <img className="cart-img order-img  order-img-slot" src={products.slot_prod2} alt="" />
                                                                                            <img className="cart-img order-img  order-img-slot" src={products.slot_prod3} alt="" />
                                                                                        </>

                                                                                        ) : products.product_type === 'NAP' ? (
                                                                                            <>
                                                                                                <img className="cart-img order-img" src="/assets/img/unnamed.png" alt="requested-prod" />
                                                                                            </>
                                                                                        ) : products.product_type === 'AP' ? (
                                                                                            <>
                                                                                                <img className="cart-img order-img" src={products.produ_imgurl} alt="" />
                                                                                            </>
                                                                                        ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </div>
                                                                                <div className="col-lg-7 col-7 ml-3">
                                                                                    <div className="cart-details mb-2">
                                                                                        {products.product_type === 'AP' ? (
                                                                                            <>
                                                                                                {/* <p className="cart-category">{products.mcName}</p> */}
                                                                                                <p className="cart-name order-pg-cart-name  mb-1"> {products.prod_name} {products.prod_quantity} {products.unit_id} </p>
                                                                                                <p className="cart-total-price mb-0 mt-0"> {products.quantity} X {products.prod_quantity} {products.unit_id}</p>
                                                                                                {products.prod_original_price !== products.prod_price ? (
                                                                                                    <>
                                                                                                        <p className="cart-total-price mb-0 mt-0">Price : AED <s>{products.prod_original_price}</s> {products.prod_price}</p>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <p className="cart-total-price mb-0 mt-0">Price : AED {products.prod_price}</p>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ) : products.product_type === 'NAP' ? (
                                                                                            <>
                                                                                                <img className="cart-img order-img" src={products.produ_imgurl} alt="" />
                                                                                                <p className="cart-name order-pg-cart-name  mb-1"> {products.prod_name} {products.prod_quantity} {products.unit_id} </p>
                                                                                                <p className="cart-total-price mb-0 mt-0"> {products.quantity} X {products.prod_quantity} {products.unit_id}</p>
                                                                                                <p className="cart-total-price mb-0 mt-0">Price : AED {products.prod_price}</p>
                                                                                            </>
                                                                                        ) : products.product_type === 'slot_prod' ? (
                                                                                            <>
                                                                                                <p className="cart-category">{products.produ_imgurl}</p>
                                                                                                <p className="cart-name order-pg-cart-name  mb-1"> {products.prod_name} </p>
                                                                                                <p className="cart-total-price mb-0 mt-0"> {products.quantity} X AED {products.selling_price} </p>
                                                                                                <p className="cart-total-price mb-0 mt-0">Price : AED {products.prod_price}</p>
                                                                                            </>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="col-lg-2 col-2">
                                                                                    <div className="cart-details">
                                                                                        <p className="cart-category">&nbsp;</p>
                                                                                        {/* <p>&nbsp;</p> */}
                                                                                        {/* <p className="cart-total-weight">Total UOM : {products.prod_total_qty}{products.unit_id}</p> */}
                                                                                        {products.produ_imgurl === 'Requested Product' ? (
                                                                                            <p className="cart-name order-pg-cart-name">AED {products.prod_total_price}</p>
                                                                                        ) : (
                                                                                            <p className="cart-name order-pg-cart-name">AED {products.prod_total_price}</p>
                                                                                        )}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}

                                                                </div>

                                                                <div className="col-lg-6 col-12 hideatmobile showatmobile">
                                                                    <div className="cart-summary order-pg-summery">
                                                                        <h3>Order Summary</h3>

                                                                        <table className="table table-totals">
                                                                            <tbody>

                                                                                <tr>
                                                                                    <td>Bag total</td>
                                                                                    <td>AED {orders.orderbag_total}</td>
                                                                                </tr>

                                                                                {orders.discount_price !== '0.00' && orders.discount_price !== null ? (
                                                                                    <tr>
                                                                                        <td>Bag Saving</td>
                                                                                        <td>AED {orders.discount_price}</td>
                                                                                    </tr>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                {orders.promo_code !== '0' && orders.promo_code !== null ? (
                                                                                    <tr>
                                                                                        <td>Coupon Saving </td>
                                                                                        <td>AED {orders.promocode_price}</td>
                                                                                    </tr>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                {orders.price_range_discount !== '0' && orders.price_range_discount !== 0 && orders.price_range_discount !== "0.00" && orders.price_range_discount !== null ? (
                                                                                    <tr>
                                                                                        <td>Discount on bill </td>
                                                                                        <td>AED {orders.price_range_discount}</td>
                                                                                    </tr>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                <tr>
                                                                                    <td>Shipping</td>
                                                                                    <td className="f-600">FREE</td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td className="f-600 cart-total-width">Total</td>
                                                                                    <td className="f-600 f-16">AED {orders.total_price}</td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                ) : (
                                    <div className="row row-sm text-center">
                                        <div className="custom-no-product">
                                            <img src="/assets/img/cart-empty.png" alt="" />
                                            <p className="cart-empty-slogan">Hi <b>{localStorage.getItem('yallabasketuser')}</b>, you are yet to start your shopping journey with us.<br /> Excitedly looking forward to serving you</p>
                                            <Link to="/"><button className="guest-shopping-btn mb-3">Start Shopping</button></Link>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>

                </>
                :
                <>
                <div className="container">
                    <div className="row row-sm text-center">
                        <div className="custom-no-product">
                            <img className="res-checkout-img" src="/assets/img/login.png" alt="" />
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <p className="m-0 p-0 mb-4">Please make sure that you hvae logged in to proceed next</p>
                                    {/* <p className="text-center m-0 p-0 mt-2"> */}
                                    {/* <button id="login-modal" className="sdsdsdsds btn custom-login-btn login-modal">Login </button></p> */}
                                        {/* <button id="login-modal" className="sdsdsdsds btn custom-login-btn login-modal">Click here to Login</button></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                </>
                    
                }
            </main>
        )
    }
}

export default Orderdetails