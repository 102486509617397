import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
// import Cookies from 'universal-cookie';
// import Collapsible from 'react-collapsible';
// const cookies = new Cookies();

class Orders extends Component {

    constructor(){
        super();
        this.state = {
            orderDetails: [],
            spinner : 'loading-overlay',
            small_spinner : 'ajax-overlay hide-content',
        };
        this.getordersdetails = this.getordersdetails.bind(this);
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' || 
            user_auth_key === "undefined" || user_auth_key === "null") 
        {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
        this.getordersdetails();
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }
    
    async getordersdetails() {
        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
          .post(`getUserOrderDetails`, jsonValue)
          .then((res) => {
            if (res.data.status === "success" && res.data.statusCode === "200")
            {
              let order_details = res.data.order_details;
              let OrderDetails = order_details.map(order_response => { return order_response; });
              this.setState({ orderDetails: OrderDetails});
              let spinner = 'loading-overlay hide-content';
              this.setState({spinner});
            }
          })
          .catch((err) => console.log("Error: ", err));
    }


    reorder(id,code)
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = { 
            order_id : id,
            orderno :code,
            user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
          .post(`reordercustomer`, jsonValue)
          .then((res) => {
            if (res.data.status === "success" && res.data.statusCode === "200")
            {
                this.setState({userRedirect:true});
            //   let order_details = res.data.order_details;
            //   let OrderDetails = order_details.map(order_response => { return order_response; });
            //   this.setState({ orderDetails: OrderDetails});
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            }
          })
          .catch((err) => console.log("Error: ", err));

    }

    render() {

        if (this.state.userRedirect) {
            return <Redirect to="/Cart" />;
          }

        return (
            <main className="main">
                <div className={this.state.small_spinner}>
                    <i className="porto-loading-icon"></i>
                </div>
                <div className={this.state.spinner}>
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                            <li className="breadcrumb-item"><span>My Orders </span></li>
                        </ol>
                    </div>
                </nav>

                <div className="container">
                    <div className="row mb-2">
                        <div className="col-lg-12">

                        { this.state.orderDetails.length !== 0 ? ( 

                            <div className="container no-pad-res">
                               
                                {                                    
                                    this.state.orderDetails.map((orders) => (

                      
                                <div className="cart-items order-pad order-item-pad">                                  
                                                      
                                    
                                    <div key={orders.order_id} className="row cart-bottom">
                                        <div className="col-lg-12">
                                            <div className="order-header">
                                                <h4 className="order-title-style">Order No : {orders.orderno}</h4>
                                                <h4 className="order-date"><button onClick={() =>this.reorder(orders.order_id,orders.orderno)}  className="reorder-btn text-right guest-shopping-btn">Reorder</button> </h4>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-12 web-res-mt-2">
                                                    <h4 className="text-left"><span className="order-title_case"> Order Date </span>: <span className="cart-total-priceerr">{orders.ordered_date}</span></h4>
                                                    { orders.orderbag_total!=='0' && orders.orderbag_total!==null  ? (
                                                        <h4 className="text-left"><span className="order-title_case">Bag Total </span>: <span className="cart-total-priceerr">AED {orders.orderbag_total}</span></h4>
                                                    ) :(
                                                        <></>
                                                    )}
                                                    { orders.discount_price!=='0.00' && orders.discount_price!==null ? (
                                                        <h4 className="text-left"><span className="order-title_case">Bag Saving </span>: <span className="cart-total-priceerr">AED {orders.discount_price}</span></h4>
                                                    ) :(
                                                        <></>
                                                    )}

                                                    { orders.yalla_money=='yes' ? (
                                                        <h4 className="text-left"><span className="order-title_case"> yalla Money </span>: <span className="cart-total-priceerr">AED {orders.yalla_money_amount}</span></h4>
                                                    ) :(
                                                        <></>
                                                    )}
                                                    
                                                    { orders.promo_code!=='0' && orders.promo_code!==null ? (
                                                        <h4 className="text-left"><span className="order-title_case">Coupon Saving </span>: <span className="cart-total-priceerr">AED {orders.promocode_price}</span></h4>
                                                    ) :(
                                                        <></>
                                                    )}
                                                    { orders.price_range_discount!=='0' && orders.price_range_discount!==null ? (
                                                        <h4 className="text-left"><span className="order-title_case">Discount on bill </span>: <span className="cart-total-priceerr">AED {orders.price_range_discount}</span></h4>
                                                    ) :(
                                                        <></>
                                                    )}
                                                    <h4 className="text-left"><span className="order-title_case">Item Total </span>: <span className="cart-total-priceerr">AED {orders.total_price}</span></h4>

                                                    {/* <h4 className="text-left"><span className="order-title_case">Delivery Fee </span>: <span className="cart-total-priceerr">AED 0.00</span></h4> */}
                                                    {/* <h4 className="text-left"><span className="order-title_case">Discount Applied </span>: <span className="cart-total-price">{orders.payment_type}</span></h4> */}
                                                    {/* <h4 className="text-left"><span className="order-title_case">Paid Amount </span>: <span className="cart-total-price">{orders.payment_type}</span></h4> */}
                                                    <h4 className="text-left"><span className="order-title_case">Payment type </span>: <span className="cart-total-priceerr">{orders.payment_type}</span></h4>
                                                    {/* <h4 className="text-left"><span className="order-title_case">Total Amount </span>: <span className="cart-total-price">AED {orders.total_price}</span></h4> */}
                                                    <h4 className="text-left"><span className="order-title_case">Delivery Date</span>: <span className="cart-total-priceerr">{orders.delivery_date} ( Eve 6PM to 11PM )</span></h4>
                                                </div>
                                                <div className="col-lg-6 col-12 web-res-mt-2 mb-2 hideatmobile">
                                                    <div className="web-res-mt-2 mb-2">
                                                        <h4 className="text-left">Track Order</h4>
                                                        { orders.order_current_status==='order_placed' ? (
                                                            <ol className="progtrckr" data-progtrckr-steps="4">
                                                                <li className="progtrckr-done">Order Placed</li>
                                                                <li className="progtrckr-todo">In Progress</li>
                                                                <li className="progtrckr-todo">Out for Delivery</li>
                                                                <li className="progtrckr-todo">Delivered </li>
                                                                {/* <li className="progtrckr-todo">Cancelled</li> */}
                                                            </ol>
                                                        ) : orders.order_current_status==='order_inprogress' ? ( 
                                                            <ol className="progtrckr" data-progtrckr-steps="4">
                                                                <li className="progtrckr-done">Order Placed</li>
                                                                <li className="progtrckr-done">In Progress</li>
                                                                <li className="progtrckr-todo">Out for Delivery</li>
                                                                <li className="progtrckr-todo">Delivered </li>
                                                                {/* <li className="progtrckr-todo">Cancelled</li> */}
                                                            </ol>
                                                        ) : orders.order_current_status==='order_outofdelivery' ? ( 
                                                            <ol className="progtrckr" data-progtrckr-steps="4">
                                                                <li className="progtrckr-done">Order Placed</li>
                                                                <li className="progtrckr-done">In Progress</li>
                                                                <li className="progtrckr-done">Out for Delivery</li>
                                                                <li className="progtrckr-todo">Delivered </li>
                                                                {/* <li className="progtrckr-todo">Cancelled</li> */}
                                                            </ol>
                                                        ) : orders.order_current_status==='order_delivered' ? ( 
                                                            <ol className="progtrckr" data-progtrckr-steps="4">
                                                                <li className="progtrckr-done">Order Placed</li>
                                                                <li className="progtrckr-done">In Progress</li>
                                                                <li className="progtrckr-done">Out for Delivery</li>
                                                                <li className="progtrckr-done">Delivered </li>
                                                                {/* <li className="progtrckr-todo">Cancelled</li> */}
                                                            </ol>
                                                        ) : orders.order_current_status==='order_cancelled' ? ( 
                                                            <ol className="progtrckr" data-progtrckr-steps="4">
                                                                <li className="progtrckr-done">Order Placed</li>
                                                                <li className="progtrckr-done">In Progress</li>
                                                                <li className="progtrckr-done">Cancelled</li>
                                                            </ol>
                                                        ) :(
                                                            <></>
                                                        )}
                                                    </div>
                                                    <div className="card-text">
                                                        <h4 className="text-left">Change of Plans ?</h4>
                                                            <p className="mb-2">&nbsp;  &nbsp; &nbsp;  &nbsp; Contact us now to modify or cancel your order  &nbsp;  &nbsp; 
                                                            <a className="displayinlineblosck" href="https://wa.me/message/MY2RPGA2B7BDJ1" target="_blank"  rel="noopener noreferrer nofollow" ><p className="md-0-cust" ><img className="what_logo_style what_logo_style_order" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6207179</p></a>
                                                            &nbsp;  &nbsp;  <a className="displayinlineblosck" href="https://wa.me/message/BKO5NZ5MJOH6A1" target="_blank"  rel="noopener noreferrer nofollow" ><p className="md-0-cust"><img className="what_logo_style what_logo_style_order" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6339179</p></a></p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-12 web-res-mt-2 mb-2 showatmobile">
                                                    <div className="web-res-mt-2 mb-2">
                                                        <h4 className="text-left"><span className="order-title_case">Order Status </span>:  
                                                        &nbsp;<span className="blink_me"><i className="fa fa-check" aria-hidden="true"></i> </span> 

                                                        { orders.order_current_status==='order_placed' ? (
                                                            <span className="cart-total-priceerr">&nbsp; Order Placed</span>
                                                        ) : orders.order_current_status==='order_inprogress' ? ( 
                                                            <span className="cart-total-priceerr">&nbsp; In Progress</span>
                                                            
                                                        ) : orders.order_current_status==='order_outofdelivery' ? ( 
                                                            <span className="cart-total-priceerr">&nbsp; Out for Delivery</span>

                                                        ) : orders.order_current_status==='order_delivered' ? ( 
                                                            <span className="cart-total-priceerr">&nbsp; Delivered</span>
                                                        ) : orders.order_current_status==='order_cancelled' ? (
                                                            <span className="cart-total-priceerr">&nbsp; Cancelled</span> 
                                                        ) :(
                                                            <></>
                                                        )}
                                                        </h4> 
                                                    </div>
                                                    <div className="card-text">
                                                        <h4 className="text-left">Change of Plans ?</h4>
                                                            <p className="mb-2">&nbsp;  &nbsp; &nbsp;  &nbsp; Contact us now to modify or cancel your order  &nbsp;  &nbsp; 
                                                            <a className="displayinlineblosck" href="https://wa.me/message/MY2RPGA2B7BDJ1" target="_blank"  rel="noopener noreferrer nofollow" ><p className="md-0-cust Collapsible__contentOuter" ><img className="what_logo_style what_logo_style_order" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6207179</p></a>
                                                            &nbsp;  &nbsp;  <a className="displayinlineblosck" href="https://wa.me/message/BKO5NZ5MJOH6A1" target="_blank"  rel="noopener noreferrer nofollow" ><p className="md-0-cust"><img className="what_logo_style what_logo_style_order" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6339179</p></a></p>
                                                    </div>

                                                </div>

                                                <div className="col-lg-12 col-12 mb-2">
                                                    <Link to={{ pathname: '/Orderdetails/orderno?=' + orders.orderno + '' }}> <h3 className="f-500 f-right">view more</h3></Link>    
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                ) )}
                            </div>
                        ) : ( 
                            <div className="row row-sm text-center">
                                <div className="custom-no-product">
                                    <img src="/assets/img/cart-empty.png" alt="" />                                    
                                    <p className="cart-empty-slogan">Hi <b>{ localStorage.getItem('yallabasketuser') }</b>, you are yet to start your shopping journey with us.<br/> Excitedly looking forward to serving you</p>
                                    <Link to="/"><button className="guest-shopping-btn mb-3">Start Shopping</button></Link>
                                </div>
                            </div>
                        ) }
                        </div>
                                       
                    </div>
                </div>

            </main>
        )
    }
}

export default Orders