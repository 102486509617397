import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import { Modal } from 'react-responsive-modal';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ProductCategory from './ProductCategory';

const options = {
    margin: 10,
    responsiveClass: true,
    autoplayHoverPause: true,
    slideBy: 1,
    nav: true,
    autoplay: true,
    smartSpeed: 1000,
    dots: true,
    loop: true,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};


class Home extends Component {

    constructor() {
        super();
        this.state = {
            loginusername: "",
            loginpassword: "",
            username: "",
            email: "",
            mobile_no: "",
            password: "",
            hotsellerproductValues: [],
            dealsproductValues: [],
            bannerDetails: [],
            smallproductDetails: [],
            brandbannerDetails: [],
            review_banner_details: [],
            productDetails_popup: [],
            clicks: 1,
            show: false,
            total_weight: '',
            total_price: '',
            spinner: 'loading-overlay',
            small_spinner: 'ajax-overlay hide-content',
            cart_count: 0,
            hotseller_startpagecount: 0,
            hotseller_startpagecount_limit: 10,
            todaydeals_startpagecount: 0,
            todaydeals_startpagecount_limit: 10,
            staticarray: [],
            rolling: false,
            rolling_basket: true,
            rolling_basket_disable: true,
            shownameidcode: false,
            slotproname1: "",
            slotsellingprice1: "",
            slotproname2: "",
            slotsellingprice2: "",
            slotproname3: "",
            slotsellingprice3: "",
            slot_loaded_epic_btn: true,
            slotwebbackgroundimage: "",
            slotipadbackgroundimage: "",
            slotmobilebackgroundimage: "",
            slot_previous_purchase_flag: "",
            slot_previous_purchase_value: "",
            order_previous_purchase_value: "",
            slot_rules: "",
            subscriptionopen: false,
            subscription_weekly: [],
            subscription_biweekly: [],
            subscription_monthly: [],
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            showsubscriptionerrormessage: false,
            subscription_errormsg: "",
            subscription_prod_id: "",
            subscription_prod_code: "",
            Sun_quantity: 0,
            Mon_quantity: 0,
            Tue_quantity: 0,
            Wed_quantity: 0,
            Thu_quantity: 0,
            Fri_quantity: 0,
            Sat_quantity: 0,
            subscription_produ_imgurl: "",
            subscription_produ_category: "",
            subscription_produ_name: "",
            subscription_produ_brand: "",
            subscription_produ_umo: "",
            addresflagopenpopup: false
        };
        this.getHomePageDetails = this.getHomePageDetails.bind(this);
        this.getHotsellerDetails = this.getHotsellerDetails.bind(this);
        this.getTodaysdealsDetails = this.getTodaysdealsDetails.bind(this);
        this.slotRef = [createRef(), createRef(), createRef()];
        this.getweeklysubscriptionvalues = this.getweeklysubscriptionvalues.bind(this);
        this.getbiweeklysubscriptionvalues = this.getbiweeklysubscriptionvalues.bind(this);
        this.getmonthlysubscriptionvalues = this.getmonthlysubscriptionvalues.bind(this);
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
        }
        this.getHomePageDetails();
        this.getHotsellerDetails();
        this.getTodaysdealsDetails();
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    async getHomePageDetails() {
        const jsonValue = {
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`getHomePageDetails`, jsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') {
                    // let dealsproductResponse = res.data.deals_products;
                    let bannerResponse = res.data.banner_details;
                    let smallproductResponse = res.data.small_prouct_details;
                    let brandbannerResponse = res.data.brand_banner_details;
                    let rievwbannerResponse = res.data.review_banner_details;
                    let slot_loaded_epic_btn = res.data.slot_loaded_epic;
                    let cart_loaded_epic_btn = res.data.cart_loaded_epic;
                    let slot_redeemed_loaded_epic_btn = res.data.slot_redeemed_loaded_epic;
                    let slotprod_list = res.data.slotprod_list;
                    let responseId = res.data.responseId;
                    let shuffled_info = res.data.shuffled_info;
                    let slot_previous_purchase_flag = res.data.slot_activate_flag;
                    let slot_previous_purchase_value = res.data.slot_activate_value;
                    let order_previous_purchase_value = res.data.activatedvalues;
                    let slot_rules_value = res.data.slot_rules;
                    // let DealsProducts = dealsproductResponse.map(dealsproduct_response => { return dealsproduct_response });
                    let Banner = bannerResponse.map(banner_response => { return banner_response });
                    let SmallProduct = smallproductResponse.map(smallproduct_response => { return smallproduct_response });
                    let BrandBanner = brandbannerResponse.map(brandbanner_response => { return brandbanner_response });
                    let RievwBanner = rievwbannerResponse.map(rievwbanner_response => { return rievwbanner_response });
                    let staticarray = slotprod_list.map(slotprod_response => { return slotprod_response });

                    let subscription_weeklyvalues = res.data.subscription_details["subscription_weekly"];
                    let subscription_weeklydetails = subscription_weeklyvalues.map(subcat_response => { return subcat_response; });

                    let subscription_biweeklyvalues = res.data.subscription_details["subscription_biweekly"];
                    let subscription_biweeklydetails = subscription_biweeklyvalues.map(subcat_response => { return subcat_response; });

                    let subscription_monthlyvalues = res.data.subscription_details["subscription_monthly"];
                    let subscription_monthlydetails = subscription_monthlyvalues.map(subcat_response => { return subcat_response; });

                    staticarray.unshift("/assets/slot/just-green.png", "/assets/slot/slot3.gif");

                    if (slot_loaded_epic_btn === "false") {
                        this.setState({ rolling: false });
                    }
                    else if (slot_loaded_epic_btn === "true") 
                    {
                        this.setState({ rolling: true });
                        this.alreadyloadedslot(responseId, shuffled_info, cart_loaded_epic_btn, slot_loaded_epic_btn, slot_redeemed_loaded_epic_btn);
                    }

                    this.setState({
                        bannerDetails: Banner, smallproductDetails: SmallProduct, brandbannerDetails: BrandBanner,
                        review_banner_details: RievwBanner, staticarray: staticarray, slotwebbackgroundimage: "/assets/slot/1440x300_1.jpg",
                        slot_previous_purchase_flag: slot_previous_purchase_flag,
                        slot_previous_purchase_value: slot_previous_purchase_value,
                        order_previous_purchase_value: order_previous_purchase_value,
                        slot_rules: slot_rules_value,
                        subscription_weekly: subscription_weeklydetails,
                        subscription_biweekly: subscription_biweeklydetails,
                        subscription_monthly: subscription_monthlydetails
                    });

                    let mq_ipad = window.matchMedia("(max-width: 850px)");
                    if (mq_ipad.matches) {
                        this.setState({ slotwebbackgroundimage: "/assets/slot/800x300_1.jpg" });
                    }

                    let mq_mobile = window.matchMedia("(max-width: 592px)");
                    if (mq_mobile.matches) {
                        this.setState({ slotwebbackgroundimage: "/assets/slot/350x250_1.jpg" });
                    }

                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner });
                }
            })
            .catch(err => console.log('Error: ', err));
    }


    async getHotsellerDetails() {
        const jsonValue = {
            user_unique_id: localStorage.getItem('user_auth_key'), hotseller_startpagecount: this.state.hotseller_startpagecount, hotseller_startpagecount_limit: this.state.hotseller_startpagecount_limit
        };
        axiosInstance
            .post(`getHotsellerDetails`, jsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') {
                    let hotsellerproductResponse = res.data.hot_seller_products;
                    let HotSellerProducts = hotsellerproductResponse.map(hotsellerproduct_response => { return hotsellerproduct_response });
                    this.setState({ hotsellerproductValues: HotSellerProducts, hotseller_startpagecount_limit: hotsellerproductResponse.length });
                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner });
                }
            })
            .catch(err => console.log('Error: ', err));
    }

    async getTodaysdealsDetails() {
        const jsonValue = {
            user_unique_id: localStorage.getItem('user_auth_key'), todaydeals_startpagecount: this.state.todaydeals_startpagecount, todaydeals_startpagecount_limit: this.state.todaydeals_startpagecount_limit
        };
        axiosInstance
            .post(`getTodaysdealsDetails`, jsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') {
                    let dealsproductResponse = res.data.deals_products;
                    let DealsProducts = dealsproductResponse.map(dealsproduct_response => { return dealsproduct_response });
                    this.setState({ dealsproductValues: DealsProducts, todaydeals_startpagecount_limit: dealsproductResponse.length });
                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner });
                }
            })
            .catch(err => console.log('Error: ', err));
    }

    onOpenModal = (id) => {

        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });

        const jsonValue = { pId: id };
        axiosInstance
            .post(`getindividualProductDetails`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let ProductValues = res.data.product_details;
                    let selling_price = res.data.selling_price;
                    let prod_quantity = res.data.prod_quantity;
                    let prod_idd = res.data.prod_id;
                    //   let prod_dtl_nutrition_info = res.data.prod_dtl_nutrition_info;
                    let ProductDetails = ProductValues.map(product_response => { return product_response; });
                    //   let RelatedDetails = RelatedValues.map(related_response => { return related_response; });
                    this.setState({ productDetails_popup: ProductDetails, prod_quantity, selling_price, prod_idd, open: true });
                }
            })
            .catch((err) => console.log("Error: ", err));
    };

    onCloseModal = () => {
        //   alert('hii');
        this.setState({ open: false, clicks: 1, total_weight: '', total_price: '' });
        this.setState({ show: false });
        // this.reload();
        // alert(this.state.open);
    };

    IncrementItem = () => {
        this.setState({ clicks: this.state.clicks + 1 });
        // alert('hii');
        var total_clicks = this.state.clicks + 1
        if (total_clicks > 1) {
            var total_weight = total_clicks * this.state.prod_quantity;
            var total_price = this.state.selling_price * total_clicks;
            var final_total = parseFloat(total_price).toFixed(2);
            // console.log(final_total);
            this.setState({ show: true, total_weight: total_weight, total_price: final_total, total_clicks: total_clicks });
        }
        else {
            this.setState({ show: false });
        }
    }

    DecreaseItem = () => {
        if (this.state.clicks > 1) {
            this.setState({ clicks: this.state.clicks - 1 });
            // console.log(this.state.clicks-1);
            var total_clicks = this.state.clicks - 1;
            if (total_clicks > 1) {
                var total_weight = total_clicks * this.state.prod_quantity;
                var total_price = this.state.selling_price * total_clicks;
                var final_total = parseFloat(total_price).toFixed(2);
                this.setState({ show: true, total_weight: total_weight, total_price: final_total, total_clicks: total_clicks });
            }
            else {
                this.setState({ show: false });
            }
        }
        else {
            this.setState({ show: false });
        }
    }

    ToggleClick = () => {
        this.setState({ show: !this.state.show });
        // console.log(this.state.clicks);
    }

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    movetobasket = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        var total_weight = this.state.total_weight;
        if (this.state.total_weight === '') {
            total_weight = this.state.prod_quantity;
        }
        var total_price = this.state.total_price;
        if (this.state.total_price === '') {
            total_price = this.state.selling_price;
        }
        var total_clicks = this.state.total_clicks;
        if (this.state.total_clicks === undefined) {
            total_clicks = 1;
        }

        const jsonValue = {
            prod_id: this.state.prod_idd,
            prod_quantity: total_clicks,
            prod_price: this.state.selling_price,
            prod_weight: this.state.prod_quantity,
            prod_totalweight: total_weight,
            prod_totalprice: total_price,
            // prod_totalprice:total_price,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`inserttobasket`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.props.onIncrement();
                    let cart_count = res.data.cart_count;
                    this.setState({ cart_count: cart_count });
                    NotificationManager.success(res.data.quantity + ' X ' + res.data.weight + ' ' + res.data.uom + ' added sucessfully', res.data.pname, 200);
                    this.onCloseModal();
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
            })
            .catch((err) => console.log("Login: ", err));
    }

    basketIncrementItem(prod_id, prodcode, prodprice, prodweight) {
        this.productaddtobasket(prod_id, prodcode, prodprice, prodweight);
    }

    basketDecreaseItem(prod_id, prodcode, prodprice, prodweight, cart_id) {
        let qty_value = document.getElementById(prodcode).value;
        if (qty_value > 1) {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            var quantity = 1;
            var total_weight = quantity * prodweight;
            var total_price = prodprice * quantity;
            var final_total = parseFloat(total_price).toFixed(2);
            const jsonValue = {
                prod_id: prod_id,
                prod_quantity: 1,
                prod_price: prodprice,
                prod_weight: prodweight,
                prod_totalweight: total_weight,
                prod_totalprice: final_total,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
                .post(`removebasketqty`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        this.hotsellerloadmore(this.state.hotseller_startpagecount, 'stopnoadnext');
                        this.todaydealsloadmore(this.state.todaydeals_startpagecount, 'stopnoadnext');
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
        else {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            const jsonValue = {
                cart_id: cart_id,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
                .post(`removeCartDetails`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        this.props.onIncrement();
                        this.hotsellerloadmore(this.state.hotseller_startpagecount, 'stopnoadnext');
                        this.todaydealsloadmore(this.state.todaydeals_startpagecount, 'stopnoadnext');
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
    }

    productaddtobasket(prod_id, prodcode, prodprice, prodweight) {
        let prod_quantity = 1;
        let tol_prod_quantity = prod_quantity * prodweight;
        let tol_selling_price = prod_quantity * prodprice;
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            prod_id: prod_id,
            prod_price: prodprice,
            prod_quantity: prod_quantity,
            prod_weight: prodweight,
            prod_totalweight: tol_prod_quantity,
            prod_totalprice: tol_selling_price,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`inserttobasket`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.props.onIncrement();
                    this.hotsellerloadmore(this.state.hotseller_startpagecount, 'stopnoadnext');
                    this.todaydealsloadmore(this.state.todaydeals_startpagecount, 'stopnoadnext');
                    NotificationManager.success(res.data.quantity + ' X ' + res.data.weight + ' ' + res.data.uom + ' added sucessfully', res.data.pname, 200);
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
    }

    productaddtobasketview(prodcode) {
        var showbasket = document.getElementById('addtobasket' + prodcode);
        showbasket.classList.add("d-block");
        var hideaddbasket = document.getElementById('addproduct' + prodcode);
        hideaddbasket.classList.add("d-none");
    }

    hotsellerloadmore(pre_pag, from_load) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        var nextpagelimit = '';
        var nextpagcount = '';
        if (from_load === 'stopnoadnext') {
            nextpagcount = 0;
            nextpagelimit = this.state.hotseller_startpagecount_limit;
            const jsonValue = {
                user_unique_id: localStorage.getItem('user_auth_key'), hotseller_startpagecount: nextpagcount, hotseller_startpagecount_limit: nextpagelimit
            };
            axiosInstance
                .post(`getHotsellerDetails`, jsonValue)
                .then(res => {
                    if (res.data.status === 'success' && res.data.statusCode === '200') {
                        let hotsellerproductResponse = res.data.hot_seller_products;
                        let HotSellerProducts = hotsellerproductResponse.map(hotsellerproduct_response => { return hotsellerproduct_response });
                        // var joined = this.state.hotsellerproductValues.concat(HotSellerProducts);
                        this.setState({ hotsellerproductValues: HotSellerProducts })
                        if (res.data.count < 10) {
                            let ele = document.getElementById('hotseller_loadmore');
                            ele.classList.add("hide-content");
                        }
                        else {
                            let ele = document.getElementById('hotseller_loadmore');
                            ele.classList.remove("hide-content");
                        }
                        // this.setState({ hotsellerproductValues: HotSellerProducts});
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    }
                })
                .catch(err => console.log('Error: ', err));
        }
        else {
            nextpagcount = pre_pag + 1;
            nextpagelimit = 10;
            const jsonValue = {
                user_unique_id: localStorage.getItem('user_auth_key'), hotseller_startpagecount: nextpagcount, hotseller_startpagecount_limit: nextpagelimit
            };
            axiosInstance
                .post(`getHotsellerDetails`, jsonValue)
                .then(res => {
                    if (res.data.status === 'success' && res.data.statusCode === '200') {
                        let hotsellerproductResponse = res.data.hot_seller_products;
                        let HotSellerProducts = hotsellerproductResponse.map(hotsellerproduct_response => { return hotsellerproduct_response });

                        if (res.data.count < 10) {
                            let ele = document.getElementById('hotseller_loadmore');
                            ele.classList.add("hide-content");
                        }
                        else {
                            let ele = document.getElementById('hotseller_loadmore');
                            ele.classList.remove("hide-content");
                        }

                        var joined = this.state.hotsellerproductValues.concat(HotSellerProducts);
                        this.setState({ hotsellerproductValues: joined, hotseller_startpagecount_limit: joined.length })

                        this.setState({ hotseller_startpagecount: nextpagcount });
                        // this.setState({ hotsellerproductValues: HotSellerProducts});
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    }
                })
                .catch(err => console.log('Error: ', err));
        }

    }

    todaydealsloadmore(pre_pag, from_load) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        if (from_load === 'stopnoadnext') {

            let nextpagcount = 0;
            let nextpagelimit = this.state.todaydeals_startpagecount_limit;
            const jsonValue = {
                user_unique_id: localStorage.getItem('user_auth_key'), todaydeals_startpagecount: nextpagcount, todaydeals_startpagecount_limit: nextpagelimit
            };
            axiosInstance
                .post(`getTodaysdealsDetails`, jsonValue)
                .then(res => {
                    if (res.data.status === 'success' && res.data.statusCode === '200') {
                        let dealsproductResponse = res.data.deals_products;
                        let DealsProducts = dealsproductResponse.map(dealsproduct_response => { return dealsproduct_response });
                        this.setState({ dealsproductValues: DealsProducts });
                        let spinner = 'loading-overlay hide-content';
                        this.setState({ spinner });

                        if (res.data.count < 10) {
                            let ele = document.getElementById('todaydeals_hide');
                            ele.classList.add("hide-content");
                        }
                        else {
                            let ele = document.getElementById('todaydeals_hide');
                            ele.classList.remove("hide-content");
                        }

                        // if(res.data.count<10)
                        // {
                        //     // ('#todaydeals_hide').hide();
                        //     document.getElementById('todaydeals_hide').hide();
                        // } 
                    }
                })
                .catch(err => console.log('Error: ', err));
        }
        else {
            let nextpagcount = pre_pag + 1;
            let nextpagelimit = 10;
            const jsonValue = {
                user_unique_id: localStorage.getItem('user_auth_key'), todaydeals_startpagecount: nextpagcount, todaydeals_startpagecount_limit: nextpagelimit
            };
            axiosInstance
                .post(`getTodaysdealsDetails`, jsonValue)
                .then(res => {
                    if (res.data.status === 'success' && res.data.statusCode === '200') {
                        let dealsproductResponse = res.data.deals_products;
                        let DealsProducts = dealsproductResponse.map(dealsproduct_response => { return dealsproduct_response });

                        if (res.data.count < 10) {
                            let ele = document.getElementById('todaydeals_hide');
                            ele.classList.add("hide-content");
                        }
                        else {
                            let ele = document.getElementById('todaydeals_hide');
                            ele.classList.remove("hide-content");
                        }

                        var joined = this.state.dealsproductValues.concat(DealsProducts);
                        this.setState({ dealsproductValues: joined, todaydeals_startpagecount_limit: joined.length })

                        this.setState({ todaydeals_startpagecount: nextpagcount });
                        // this.setState({ hotsellerproductValues: HotSellerProducts});
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                    }
                })
                .catch(err => console.log('Error: ', err));
        }

    }

    alreadyloadedslot(responseId, shuffled_info, cart_loaded_epic_btn, slot_loaded_epic_btn, slot_redeemed_loaded_epic_btn) {
        let parts = responseId.split('-');
        let slotprod1 = parseInt(parts[1]);
        let slotprod2 = parseInt(parts[1]) + parseInt(parts[3]);
        let slotprod3 = parseInt(parts[1]) + parseInt(parts[3]) + parseInt(parts[3]);
        setTimeout(() => {
            // console.log(this.slotRef);
            this.slotRef.forEach((boxes, i) => {
                if (boxes.current !== null) {
                    const arr = [slotprod1, slotprod2, slotprod3];
                    // this will trigger rolling effect
                    const selected = this.triggerSlotRotation(boxes.current, arr[i]);
                    this.setState({ [`fruit${i}`]: selected });
                }
            });

            this.setState({
                shownameidcode: true,
                slotproname1: shuffled_info[0]["prod_details"]["prod_name"],
                slotsellingprice1: shuffled_info[0]["prod_details"]["selling_price"],
                slotproname2: shuffled_info[1]["prod_details"]["prod_name"],
                slotsellingprice2: shuffled_info[1]["prod_details"]["selling_price"],
                slotproname3: shuffled_info[2]["prod_details"]["prod_name"],
                slotsellingprice3: shuffled_info[2]["prod_details"]["selling_price"],
            });

            if (cart_loaded_epic_btn === "false") {
                this.setState({ rolling_basket: false, rolling_basket_disable: false });

            }
            else if (cart_loaded_epic_btn === "true") {
                this.setState({ rolling_basket: true, rolling_basket_disable: false });
            }

            if (cart_loaded_epic_btn === "true" && slot_loaded_epic_btn === "true" && slot_redeemed_loaded_epic_btn ==="true") {
                this.setState({ rolling_basket: true, rolling_basket_disable: true, rolling:true });
            }


        }, 4000);
    }



    roll = () => {
        this.setState({
            rolling: true,
            rolling_basket: true,
            rolling_basket_disable: false
        });

        // looping through all 3 slots to start rolling
        this.slotRef.forEach((boxes, i) => {
            const arr = [1, 1, 1];
            // this will trigger rolling effect
            const selected = this.triggerSlotRotation(boxes.current, arr[i]);
            this.setState({ [`fruit${i}`]: selected });
        });

        const jsonValue = {
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`getslotspinnedprod`, jsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') {
                    let slot_loaded_epic = res.data.slot_loaded_epic;
                    let responseId = res.data.responseId;
                    let shuffled_info = res.data.shuffled_info;
                    let parts = responseId.split('-');
                    let slotprod1 = parseInt(slot_loaded_epic[0]);
                    let slotprod2 = parseInt(slot_loaded_epic[0]) + parseInt(parts[3]);
                    let slotprod3 = parseInt(slot_loaded_epic[0]) + parseInt(parts[3]) + parseInt(parts[3]);

                    setTimeout(() => {
                        this.slotRef.forEach((boxes, i) => {
                            const arr = [slotprod1, slotprod2, slotprod3];
                            // this will trigger rolling effect
                            const selected = this.triggerSlotRotation(boxes.current, arr[i]);
                            this.setState({ [`fruit${i}`]: selected });
                        });

                        this.setState({
                            shownameidcode: true,
                            slotproname1: shuffled_info[0]["prod_details"]["prod_name"],
                            slotsellingprice1: shuffled_info[0]["prod_details"]["selling_price"],
                            slotproname2: shuffled_info[1]["prod_details"]["prod_name"],
                            slotsellingprice2: shuffled_info[1]["prod_details"]["selling_price"],
                            slotproname3: shuffled_info[2]["prod_details"]["prod_name"],
                            slotsellingprice3: shuffled_info[2]["prod_details"]["selling_price"],
                            rolling_basket: false,
                            rolling_basket_disable: true
                        });
                    }, 4000);
                }
                else if (res.data.status === 'fail' && res.data.statusCode === '400') {
                    NotificationManager.error('Make sure that you have logged in', 'Error', 500);
                    this.slotRef.forEach((boxes, i) => {
                        const arr = [0, 0, 0];
                        // this will trigger rolling effect
                        const selected = this.triggerSlotRotation(boxes.current, arr[i]);
                        this.setState({ [`fruit${i}`]: selected });
                    });
                }
            })
            .catch(err => console.log('Error: ', err));

    };

    // this will create a rolling effect and return random selected option
    triggerSlotRotation = (ref, i) => {
        // console.log(ref.children);
        function setTop(top) {
            ref.style.top = `${top}px`;
        }
        let options = ref.children;
        let choosenOption = options[i];
        setTop(-choosenOption.offsetTop + 2);
        return this.state.staticarray[i];
    };


    // adding slot products to basket function

    addslotproductstocart = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });

        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
            .post(`addslotproductstocart`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.props.onIncrement();
                    NotificationManager.success('yallarush added to basket', 'Success', 1000);
                    this.setState({
                        rolling: true,
                        rolling_basket: true,
                        rolling_basket_disable: false,
                    });
                }
                else {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
            })
            .catch((err) => console.log("Error: ", err));
    };

    alreadyinbasket = () => {
        NotificationManager.info('yallarush product already added to basket', 'Info', 1000);
    }

    basketvalueislow = () => {
        NotificationManager.info('Your previous order should be above Rs. ' + this.state.slot_previous_purchase_value + ' to spin', 'Info', 1000);
    }


    // subscription model start here

    opensubscriptionmodel = (prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces, subscribe_offer_flag, subscribe_offer_type, subscribe_offer_value) => {
        var produ_umo_cust = "" + produ_umo + "";
        if (no_of_prod_pisces !== '' && no_of_prod_pisces !== null && no_of_prod_pisces !== ' ' && no_of_prod_pisces !== undefined) {
            produ_umo_cust = "" + produ_umo + " ( approx " + no_of_prod_pisces + ")";
        }
        var subscribe_offer_text = "";
        if (subscribe_offer_flag !== 0 && subscribe_offer_flag !== null && subscribe_offer_flag !== '' && subscribe_offer_flag !== undefined) {
            if (subscribe_offer_type === 'P') {
                subscribe_offer_text = "Subscribe & save " + subscribe_offer_value + " %";
            }
            if (subscribe_offer_type === 'A') {
                subscribe_offer_text = "Subscribe & save Rs. " + subscribe_offer_value + "";
            }
        }

        this.setState({
            subscriptionopen: true,
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            subscription_prod_id: pId,
            subscription_prod_code: prod_code,
            Sun_quantity: 0,
            Mon_quantity: 0,
            Tue_quantity: 0,
            Wed_quantity: 0,
            Thu_quantity: 0,
            Fri_quantity: 0,
            Sat_quantity: 0,
            subscription_produ_imgurl: produ_imgurl,
            subscription_produ_category: produ_category,
            subscription_produ_name: produ_name,
            subscription_produ_brand: produ_brand,
            subscription_produ_umo: produ_umo_cust,
            subscribe_offer_text: subscribe_offer_text

        });
        setTimeout(function () {
            var tab1 = document.getElementById('subscription_tab_one');
            if (tab1 !== null && tab1 !== undefined) {
                tab1.classList.add("subscription_tab_active");
            }
            else {
                this.closesubscriptionmodel();
                this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces, subscribe_offer_flag, subscribe_offer_type, subscribe_offer_value);
            }
            var tab2 = document.getElementById('subscription_tab_two_content');
            if (tab2 !== null && tab2 !== undefined) {
                tab2.classList.add("d-none");
            }
            else {
                this.closesubscriptionmodel();
                this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces, subscribe_offer_flag, subscribe_offer_type, subscribe_offer_value);
            }
            var tab3 = document.getElementById('subscription_tab_three_content');
            if (tab3 !== null && tab3 !== undefined) {
                tab3.classList.add("d-none");
            }
            else {
                this.closesubscriptionmodel();
                this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces, subscribe_offer_flag, subscribe_offer_type, subscribe_offer_value);
            }

        }, 100);
    };

    showtabone = () => {
        document.getElementById('subscription_tab_one').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.add("d-none");
        document.getElementById('subscription_tab_three_content').classList.add("d-none");
        if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length > 0) {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }
    };
    showtabtwo = () => {
        document.getElementById('subscription_tab_one').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.add("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.add("d-none");
        if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }

    };
    showtabthree = () => {
        document.getElementById('subscription_tab_one').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.add("d-none");
        document.getElementById('subscription_tab_two_content').classList.add("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }
    };



    closesubscriptionmodel = () => {
        this.setState({
            subscriptionopen: false,
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            Sun_quantity: 0,
            Mon_quantity: 0,
            Tue_quantity: 0,
            Wed_quantity: 0,
            Thu_quantity: 0,
            Fri_quantity: 0,
            Sat_quantity: 0,
            subscription_produ_imgurl: "",
            subscription_produ_category: "",
            subscription_produ_name: "",
            subscription_produ_brand: "",
            subscription_produ_umo: "",
            subscribe_offer_text: "",
        });

    };

    closesubscriptionaddressmodel = () => {
        this.setState({
            addresflagopenpopup: false
        });
    }

    subscription_quantity = (name, value, type, id) => {

        if (type === "add") {
            let addvalue = this.state[name] + 1;
            this.setState({
                [name]: addvalue
            }, () => {
                if (name !== "biweekly_quantity" && name !== "monthly_quantity") {
                    if (this.state[name] > 0) {
                        document.getElementById(id).parentElement.classList.add("subscription_day_lable_selected");
                        document.getElementById(id).checked = true;
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        document.getElementById(id).parentElement.classList.remove("subscription_day_lable_selected");
                        document.getElementById(id).checked = false;
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }

                    if (this.state.Sun_quantity > 0 || this.state.Mon_quantity > 0 || this.state.Tue_quantity > 0 || this.state.Wed_quantity > 0
                        || this.state.Thu_quantity > 0 || this.state.Fri_quantity > 0 || this.state.Sat_quantity > 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                    this.getweeklysubscriptionvalues();
                }
                if (name === "biweekly_quantity") {
                    if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                }
                if (name === "monthly_quantity") {
                    if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                }
            });
        }
        if (type === "minus") {
            let minusvalue = this.state[name];
            if (minusvalue !== 0) {
                this.setState({
                    [name]: minusvalue - 1
                }, () => {
                    if (name !== "biweekly_quantity" && name !== "monthly_quantity") {
                        if (this.state[name] > 0) {
                            document.getElementById(id).parentElement.classList.add("subscription_day_lable_selected");
                            document.getElementById(id).checked = true;
                        }
                        else {
                            document.getElementById(id).parentElement.classList.remove("subscription_day_lable_selected");
                            document.getElementById(id).checked = false;
                        }

                        if (this.state.Sun_quantity > 0 || this.state.Mon_quantity > 0 || this.state.Tue_quantity > 0 || this.state.Wed_quantity > 0
                            || this.state.Thu_quantity > 0 || this.state.Fri_quantity > 0 || this.state.Sat_quantity > 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                        this.getweeklysubscriptionvalues();
                    }
                    if (name === "biweekly_quantity") {
                        if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                    }
                    if (name === "monthly_quantity") {
                        if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                    }
                });
            }
        }
    };



    getweeklysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.classList.remove("subscription_day_lable_selected")
            }
        }

        var quantity_values = document.getElementsByClassName('subscription_weekly_quantity_value');
        const weekly_quantity = [];

        for (let j = 0; j < quantity_values.length; j++) {
            if (quantity_values[j].value > 0) {
                // console.log(quantity_values[j].value);
                weekly_quantity.push(quantity_values[j].value);
            }
        }

        this.setState({
            weekly_selected_date: value, weekly_selected_quntity: weekly_quantity
        }, () => {
            // console.log(this.state.biweekly_selected_date.length);
            if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length > 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });

    }

    getbiweeklysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable-biweekly');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.firstChild.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            }
        }

        this.setState({
            biweekly_selected_date: value
        }, () => {
            if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });
    }

    getmonthlysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable-monthly');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.firstChild.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            }
        }

        this.setState({
            monthly_selected_date: value
        }, () => {
            if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });
    }

    clearselectedsubscription = (value) => {
        // alert(value);
        var chks = document.getElementsByClassName('' + value + '');
        // console.log(chks);
        for (var i = 0; i < chks.length; i++) {
            chks[i].checked = false;
            chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            // console.log(chks[i].checked)
        }
        this.setState({
            showsubscriptionerrormessage: false,
            subscription_errormsg: ""
        });
        if (value === "visually-hidden-lable-biweekly") {
            this.getbiweeklysubscriptionvalues();
        }
        else if (value === "visually-hidden-lable-monthly") {
            this.getmonthlysubscriptionvalues();
        }

    }


    submit_subscription = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({
            showsubscriptionerrormessage: false,
            subscription_errormsg: ""
        });

        if (this.state.weekly_selected_date.length === 0 && this.state.weekly_selected_quntity.length !== 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabone();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.biweekly_selected_date.length === 0 && this.state.biweekly_quantity !== 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabtwo();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.monthly_selected_date.length === 0 && this.state.monthly_quantity !== 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabthree();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length === 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabone();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity === 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabtwo();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity === 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabthree();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else {
            const jsonValue = {
                weekly_selected_date: this.state.weekly_selected_date,
                weekly_quantity: this.state.weekly_selected_quntity,
                biweekly_selected_date: this.state.biweekly_selected_date,
                biweekly_quantity: this.state.biweekly_quantity,
                monthly_selected_date: this.state.monthly_selected_date,
                monthly_quantity: this.state.monthly_quantity,
                subscription_prod_id: this.state.prod_unit,
                subscription_prod_code: this.state.subscription_prod_code,
                user_unique_id: localStorage.getItem('user_auth_key'),
            };
            axiosInstance
                .post(`insersubscriptionproduct`, jsonValue)
                .then((res) => {

                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Product Subscribed Sucessfully', 'Success', 200);
                        this.closesubscriptionmodel();
                        this.hotsellerloadmore(this.state.hotseller_startpagecount, 'stopnoadnext');
                        this.todaydealsloadmore(this.state.todaydeals_startpagecount, 'stopnoadnext');
                        if (res.data.address_flag === 0) {
                            this.setState({ addresflagopenpopup: true });
                        }
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "201") {
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                        NotificationManager.error('Something went wrong', 'Error', 500);
                    }
                })
                .catch((err) => console.log("Login: ", err));
        }
    }



    render() {

        return (

            <main className="main home bg-white">
                <NotificationContainer />

                <div className={this.state.small_spinner}>
                    <i className="porto-loading-icon"></i>
                </div>
                <div className={this.state.spinner}>
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <div className="container-fluid no-pad res-no-pad">
                    <div className="bg-white">
                        <div id="web-view" className="row row-sm mb-2">
                            <div className="col-lg-12">
                                {this.state.bannerDetails.length && (
                                    <OwlCarousel
                                        {...options}
                                        className="home-slider owl-carousel owl-theme"
                                    >
                                        {
                                            this.state.bannerDetails.map(banner => {
                                                const web_imageurl = banner.web_imageurl;
                                                return (
                                                    <>
                                                        {banner.prodcount > '0' ? (
                                                            <>
                                                                <Link to={{ pathname: "/banneroffers/searchdetails/" + banner.banner_name + "/" + banner.banner_id + "/getyallaproduct/bannersproduct" }} >
                                                                    <div key={banner.banner_id} className="home-slide item" style={{ backgroundImage: "url(" + web_imageurl + ")" }}>
                                                                    </div>
                                                                </Link>

                                                            </>
                                                        ) :
                                                            (
                                                                <>
                                                                    <div key={banner.banner_id} className="home-slide item" style={{ backgroundImage: "url(" + web_imageurl + ")" }}>
                                                                    </div>
                                                                </>
                                                            )}

                                                    </>
                                                )
                                            }
                                            )}
                                    </OwlCarousel>

                                )}
                            </div>
                        </div>
                        <div id="mobile-view" className="row row-sm mb-2">
                            <div className="col-lg-12">
                                {this.state.bannerDetails.length && (
                                    <OwlCarousel
                                        {...options}
                                        className="home-slider owl-carousel owl-theme"
                                    >
                                        {
                                            this.state.bannerDetails.map(banner => {
                                                const mobile_imageurl = banner.mobile_imageurl;
                                                return (
                                                    <>
                                                        {banner.prodcount > '0' ? (
                                                            <>
                                                                <Link to={{ pathname: "/banneroffers/searchdetails/" + banner.banner_name + "/" + banner.banner_id + "/getyallaproduct/bannersproduct" }} >
                                                                    <div key={banner.banner_id} className="home-slide item" style={{ backgroundImage: "url(" + mobile_imageurl + ")" }}>
                                                                    </div>
                                                                </Link>

                                                            </>
                                                        ) :
                                                            (
                                                                <>
                                                                    <div key={banner.banner_id} className="home-slide item" style={{ backgroundImage: "url(" + mobile_imageurl + ")" }}>
                                                                    </div>
                                                                </>
                                                            )}

                                                    </>

                                                )
                                            }
                                            )}
                                    </OwlCarousel>
                                )}
                            </div>
                        </div>

                        <section className="product-category-panel mb-3 pad-lr-50 no-pad-res">
                            <div className="pad-30-tp">
                                <h2>Shop by Category</h2>
                                <p>Shop amazing products</p>

                                {/* <ProductCategory /> */}
                            </div>
                            
                            {
                                this.state.smallproductDetails.map(smallproduct => {
                                    // console.log(smallproduct);
                                    return (
                                        <div key={smallproduct.category_id} className="custom-category-col pad-l-2">
                                            {smallproduct.redirect_url_to === 'main_department' ? (
                                                <>
                                                    <Link to={{ pathname: '/' + smallproduct.md_url_code + '/' + smallproduct.md_url_param }}><span><img className="custom-small-style" src={smallproduct.top_imageurl} alt="" />{smallproduct.display_name}</span></Link>
                                                </>
                                            ) :
                                                smallproduct.redirect_url_to === 'sub_department' ? (
                                                    <>
                                                        <Link to={{ pathname: '/' + smallproduct.md_url_code + '/' + smallproduct.sd_url_code + '/' + smallproduct.sd_url_param }}><span><img className="custom-small-style" src={smallproduct.top_imageurl} alt="" />{smallproduct.display_name}</span></Link>
                                                    </>
                                                ) :
                                                    smallproduct.redirect_url_to === 'main_category' ? (
                                                        <>
                                                            <Link to={{ pathname: '/' + smallproduct.md_url_code + '/' + smallproduct.sd_url_code + '/' + smallproduct.mc_url_code + '/' + smallproduct.mc_url_param }}><span><img className="custom-small-style" src={smallproduct.top_imageurl} alt="" />{smallproduct.display_name}</span></Link>
                                                        </>
                                                    ) :
                                                        (
                                                            <>
                                                                <Link to={{ pathname: '/' + smallproduct.md_url_code + '/' + smallproduct.sd_url_code + '/' + smallproduct.mc_url_code + '/' + smallproduct.sc_url_code + '/' + smallproduct.sc_url_param }}><span><img className="custom-small-style" src={smallproduct.top_imageurl} alt="" />{smallproduct.display_name}</span></Link>
                                                            </>
                                                        )}

                                        </div>
                                    )
                                }
                                )}
                        </section>


                        <div className="product-panel pad-lr-50">
                            <section className="mb-2">
                                {/* web view contents slot */}

                                <div className="SlotMachine" style={{ backgroundImage: "url(" + this.state.slotwebbackgroundimage + ")" }}>
                                    <div className="segments row w-100 justify-content-center">
                                        <span className="rulesname_test blink" data-tip={this.state.slot_rules}>
                                            <span className="responsivedesig"> </span> <img src="/assets/slot/information.png" alt="information" />
                                        </span>
                                        <div className="col-lg-3 col-md-2 col-sm-2 col-xs-2 col-2 buttons_slots">

                                            {localStorage.getItem('user_log_status') === "true" ? (
                                                <>
                                                    {this.state.slot_previous_purchase_flag === "0" ? (
                                                        <>
                                                            <button className="btn spin_btn spin_btn_background hide-content" id="spinner" onClick={() => this.roll()}
                                                                disabled={this.state.rolling} > start </button>
                                                        </>
                                                    ) : this.state.slot_previous_purchase_flag === "1" ? (
                                                        <>
                                                            {this.state.order_previous_purchase_value >= this.state.slot_previous_purchase_value ? (
                                                                <>
                                                                    <button className="btn spin_btn spin_btn_background hide-content" id="spinner" onClick={() => this.roll()}
                                                                        disabled={this.state.rolling} > start </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button className="btn spin_btn spin_btn_background hide-content" id="spinner" 
                                                                        disabled={this.state.rolling} onClick={() => this.basketvalueislow()}
                                                                    > start </button>
                                                                </>
                                                            )}

                                                        </>
                                                    ) : (
                                                        <>
                                                            <button className="btn spin_btn spin_btn_background hide-content" id="spinner" onClick={() => this.roll()}
                                                                disabled="true" > start </button>
                                                        </>
                                                    )}

                                                </>
                                            ) : (
                                                <button className="login-modal btn spin_btn spin_btn_background hide-content" id="login-modal" > start </button>
                                            )}
                                        </div>
                                        <div className="col-lg-6-slot col-lg-6 col-md-10 col-sm-12 col-12">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                                    <div className="door">
                                                        {this.state.shownameidcode === true ? (
                                                            <>
                                                                <div className="product-default  product-default-slot">
                                                                    <div className="label-group label-group-slot">
                                                                        <span className="product-label product-label-slot label-cut slot-cut-text ">{this.state.slotproname1}</span>
                                                                    </div>
                                                                    <div className="label-group label-group-slot-price">
                                                                        <span className="product-label label-cut product-price-label-slot">
                                                                            <span className="old-price old-price-slot">Rs. {this.state.slotsellingprice1}</span>
                                                                            <span className="product-price product-price-slot"> 0.99</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <div className="boxes">
                                                            <div className="slottcontainer" ref={this.slotRef[0]}>
                                                                {this.state.staticarray.map(fruit => (
                                                                    <div>
                                                                        <img className="image slot-image" src={fruit} alt="yalla rush product" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                                    <div className="door">
                                                        {this.state.shownameidcode === true ? (
                                                            <>
                                                                <div className="product-default  product-default-slot">
                                                                    <div className="label-group label-group-slot">
                                                                        <span className="product-label product-label-slot label-cut slot-cut-text ">{this.state.slotproname2}</span>
                                                                    </div>
                                                                    <div className="label-group label-group-slot-price">
                                                                        <span className="product-label label-cut product-price-label-slot">
                                                                            <span className="old-price old-price-slot">Rs. {this.state.slotsellingprice2}</span>
                                                                            <span className="product-price product-price-slot"> 0.99</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <div className="boxes" >
                                                            <div className="slottcontainer" ref={this.slotRef[1]}>
                                                                {this.state.staticarray.map(fruit => (
                                                                    <div>
                                                                        <img className="image slot-image" src={fruit} alt="yalla rush product" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                                    <div className="door">
                                                        {this.state.shownameidcode === true ? (
                                                            <>
                                                                <div className="product-default  product-default-slot">
                                                                    <div className="label-group label-group-slot">
                                                                        <span className="product-label product-label-slot label-cut slot-cut-text ">{this.state.slotproname3}</span>
                                                                    </div>
                                                                    <div className="label-group label-group-slot-price">
                                                                        <span className="product-label label-cut product-price-label-slot">
                                                                            <span className="old-price old-price-slot">Rs. {this.state.slotsellingprice3}</span>
                                                                            <span className="product-price product-price-slot"> 0.99</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <div className="boxes">
                                                            <div className="slottcontainer" ref={this.slotRef[2]}>
                                                                {this.state.staticarray.map(fruit => (
                                                                    <div>
                                                                        <img className="image slot-image" src={fruit} alt="yalla rush product" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-2 col-sm-2 col-xs-2 col-2 buttons_slots  buttons_slots_hideat_six ">

                                            {localStorage.getItem('user_log_status') === "true" ? (
                                                <>
                                                    {this.state.slot_previous_purchase_flag === "0" ? (
                                                        <>
                                                            <button className="buttons-web-slot btn spin_btn spin_btn_background " id="spinner" onClick={() => this.roll()}
                                                                disabled={this.state.rolling} > start </button>
                                                        </>
                                                    ) : this.state.slot_previous_purchase_flag === "1" ? (
                                                        <>
                                                            {this.state.order_previous_purchase_value >= this.state.slot_previous_purchase_value ? (
                                                                <>
                                                                    <button className="buttons-web-slot btn spin_btn spin_btn_background " id="spinner" onClick={() => this.roll()}
                                                                        disabled={this.state.rolling} > start </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button className="buttons-web-slot btn spin_btn spin_btn_background " id="spinner" 
                                                                    disabled={this.state.rolling} onClick={() => this.basketvalueislow()}
                                                                    > start </button>
                                                                </>
                                                            )}

                                                        </>
                                                    ) : (
                                                        <>
                                                            <button className="buttons-web-slot btn spin_btn spin_btn_background " id="spinner" onClick={() => this.roll()}
                                                                disabled="true" > start </button>
                                                        </>)}

                                                </>
                                            ) : (
                                                <button className="login-modal btn spin_btn spin_btn_background " id="login-modal" > start </button>
                                            )}
                                            {this.state.rolling_basket === true ? (
                                                <>
                                                    <button onClick={() => this.alreadyinbasket()} className="buttons-web-slot btn spin_btn basket_btn_background"
                                                        disabled={this.state.rolling_basket_disable} >  Add to basket </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button onClick={() => this.addslotproductstocart()} className="buttons-web-slot btn spin_btn basket_btn_background"
                                                        disabled={this.state.rolling_basket} >  Add to basket </button>
                                                </>
                                            )}

                                        </div>

                                        <div className="row mobileslotresp-div">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 buttons-responsive buttons-responsive-slot">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-6  col-6 buttons-bottom">
                                                        {localStorage.getItem('user_log_status') === "true" ? (
                                                            <>
                                                                {this.state.slot_previous_purchase_flag === "0" ? (
                                                                    <>
                                                                        <button className="btn spin_btn spin_btn_background" id="spinner" onClick={() => this.roll()}
                                                                            disabled={this.state.rolling} > start </button>
                                                                    </>
                                                                ) : this.state.slot_previous_purchase_flag === "1" ? (
                                                                    <>
                                                                        {this.state.order_previous_purchase_value >= this.state.slot_previous_purchase_value ? (
                                                                            <>
                                                                                <button className="btn spin_btn spin_btn_background" id="spinner" onClick={() => this.roll()}
                                                                                    disabled={this.state.rolling} > start </button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <button className="btn spin_btn spin_btn_background" id="spinner" 
                                                                                disabled={this.state.rolling} onClick={() => this.basketvalueislow()}
                                                                                > start </button>
                                                                            </>
                                                                        )}

                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button className="btn spin_btn spin_btn_background" id="spinner" onClick={() => this.roll()}
                                                                            disabled="true" > start </button>
                                                                    </>)}

                                                            </>
                                                        ) : (
                                                            <button className="login-modal btn spin_btn spin_btn_background " id="login-modal" > start </button>
                                                        )}
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6  col-6 buttons-bottom">
                                                        {this.state.rolling_basket === true ? (
                                                            <>
                                                                <button onClick={() => this.alreadyinbasket()} className="btn spin_btn basket_btn_background"
                                                                    disabled={this.state.rolling_basket_disable} >  Add to basket </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button onClick={() => this.addslotproductstocart()} className="btn spin_btn basket_btn_background"
                                                                    disabled={this.state.rolling_basket} >  Add to basket </button>
                                                            </>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </section>
                        </div>



                        <section className="product-panel pad-lr-50">
                            <div className="section-title mb-2">
                                <h2>Hot Sellers <i style={{ color: "#FF5500" }} className="fas fa-fire"></i>
                                </h2>
                            </div>
                            <div className="cus-product-row">

                            {
                                this.state.hotsellerproductValues.map(value => {
                                    return (
                                        <div key={value.prod_id} className="cus-div-lay">
                                            <a className="cus-div-a" href="#">
                                                <div className="cus-div-sty">
                                                    <div className="cus-head-div">
                                                        <div className="cus-img-div">
                                                            <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                                {value.outof_stock_flag === '1' ? (
                                                                    <img className="cus-product-img" src={value.produ_imgurl} alt={value.prod_name} />
                                                                ) : (
                                                                    <img className="cus-product-img outofstockflagon" src={value.produ_imgurl} alt={value.prod_name} />
                                                                )}
                                                            </Link>
                                                        </div>

                                                        {value.subacribed_prod === true || value.subacribed_prod === "true" ? (
                                                            <div class="cus-subscribed-sty cus-subscribed-sty-two">
                                                                <div class="cus-subscribed-txt">Subscribed</div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <div className="cus-title-div">
                                                            <div className="cus-title-main">
                                                                <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                                    {value.prod_name}
                                                                </Link>
                                                            </div>
                                                            <div className="cus-title-secondary">{value.scName}</div>
                                                        </div>
                                                        <div className="cus-price">
                                                            <span> UOM : {value.prod_quantity} {value.unit_id}</span>
                                                            <div>
                                                                {(value.offer_flag === "1" && value.redeemed_offers_flag === 1) || (value.offer_flag === "0" && value.redeemed_offers_flag === 1) ? (
                                                                    <>
                                                                        <span className="old-price">Rs. {value.original_amt}</span>
                                                                        <span className="product-price">Rs. {value.selling_price}</span>
                                                                    </>
                                                                ) : (value.offer_flag === "1" && value.redeemed_offers_flag === 0) ? (
                                                                    <>
                                                                        <span className="old-price">Rs. {value.original_amt}</span>
                                                                        <span className="product-price">Rs. {value.selling_price}</span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {value.market_price !== "0.00" ? (
                                                                            <span className="old-price">Rs. {value.market_price}</span>
                                                                        ) : (
                                                                            <> </>
                                                                        )}
                                                                        <span className="product-price">Rs. {value.selling_price}</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {value.outof_stock_flag === '1' ? (
                                                            <>
                                                                {value.cart_count > 0 ? (
                                                                    <>
                                                                    
                                                                        <div className="buttons_slots mt-1">
                                                                            <div className="input-group mob-p-l">
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number product-custom-qty-m" onClick={() => this.basketDecreaseItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity, value.cart_id)} aria-label="Add one" data-direction="1">
                                                                                        <span className="fa fa-minus"></span>
                                                                                    </button>
                                                                                </span>
                                                                                <input type="text" id={value.prod_code} className="form-control product-input-number" name="quantity" value={value.cart_count} readOnly={true} />
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number product-custom-qty-a" onClick={() => this.basketIncrementItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} aria-label="Subtract one" data-direction="-1">
                                                                                        <span className="fa fa-plus"></span>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="cus-subscribe">
                                                                            {localStorage.getItem('user_log_status') === "true" ? (
                                                                                <>
                                                                                    {value.subacribed_prod === "true" ? (
                                                                                        <Link to="/Subscription"> <span className="product-subscribe" > View Subscription  </span></Link>
                                                                                    ) : (
                                                                                        <span className="product-subscribe"
                                                                                            onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <span className="product-subscribe" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                ) :
                                                                    (
                                                                        <>

                                                                            <div className="buttons_slots mt-1">
                                                                                <button onClick={() => this.productaddtobasket(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} className="cart-btn">Add +</button>
                                                                            </div>
                                                                            <div className="cus-subscribe">
                                                                                {localStorage.getItem('user_log_status') === "true" ? (
                                                                                    <>
                                                                                        {value.subacribed_prod === "true" ? (
                                                                                            <Link to="/Subscription"> <span className="product-subscribe" > View Subscription  </span></Link>
                                                                                        ) : (
                                                                                            <span className="product-subscribe"
                                                                                                onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                    value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <span className="product-subscribe" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <div className="buttons_slots mt-1">
                                                                <span className="outofstockflagcolor">Currently Out Of Stock</span>
                                                            </div>

                                                        )}
                                                            
                                                        { (value.offer_flag === '1' && value.redeemed_offers_flag === 1) || (value.offer_flag === '0' && value.redeemed_offers_flag === 1) ? (
                                                            <div className="cus-dis-div">
                                                                {value.redeemed_offers_type === 'A' ? (
                                                                    <span className="cus-dis-span-two">{value.redeemed_offers_value} off | Use STEALDEAL</span>
                                                                ) : (
                                                                    <span className="cus-dis-span-two">{value.redeemed_offers_value}% off | Use STEALDEAL</span>
                                                                )}
                                                            </div>
                                                        ) : (value.offer_flag === '1' && value.redeemed_offers_flag === 0) ? (
                                                            <div className="cus-dis-div">
                                                                {value.redeemed_offers_type === 'A' ? (
                                                                    <span className="cus-dis-span-two">{value.offer_value} off | Use STEALDEAL</span>
                                                                ) : (
                                                                    <span className="cus-dis-span-two">{value.offer_value}% off | Use STEALDEAL</span>
                                                                )}
                                                            </div>
                                                        ) : (
                                                                <>
                                                                </>
                                                            )}
                                                    </div>
                                                    <div className="cus-div-btn-div">
                                                        <span role="button" aria-label="Open" className="cus-hov-btn cus-hov-sty" onClick={() => this.onOpenModal(value.prod_id)} data-toggle="modal" data-target="#addCartModal">Quick View</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })
                            }
{/* 
                            {
                                this.state.hotsellerproductValues.map(value => {
                                    return (
                                        <div key={value.prod_id} className="col-lg-2-5 pad-5">
                                            <div className="product-default custom-shadow inner-quickview inner-icon">
                                                <figure>
                                                    <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                        {value.outof_stock_flag === '1' ? (
                                                            <img src={value.produ_imgurl} alt={value.prod_name} />
                                                        ) : (
                                                            <img className="outofstockflagon" src={value.produ_imgurl} alt={value.prod_name} />
                                                        )}
                                                    </Link>
                                                    {(value.offer_flag === '1' && value.redeemed_offers_flag === 1) || (value.offer_flag === '0' && value.redeemed_offers_flag === 1) ? (
                                                        <>
                                                            <div className="label-group">
                                                                <span className="product-label label-cut">
                                                                    {value.redeemed_offers_type === 'A' ? (
                                                                        <>Rs. {value.redeemed_offers_value} off</>
                                                                    ) : (
                                                                        <><>{value.redeemed_offers_value}% off</></>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {value.subacribed_prod === true || value.subacribed_prod === "true" ? (
                                                                <div className="label-group subscribed-lable subscribed-lable-withoffer">
                                                                    <span className="product-label label-cut">
                                                                        Subscribed
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    ) : (value.offer_flag === '1' && value.redeemed_offers_flag === 0) ? (
                                                        <>
                                                            <div className="label-group">
                                                                <span className="product-label label-cut">
                                                                    {value.offer_type === 'A' ? (
                                                                        <>Rs. {value.offer_value} off</>
                                                                    ) : (
                                                                        <><>{value.offer_value}% off</></>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {value.subacribed_prod === true || value.subacribed_prod === "true" ? (
                                                                <div className="label-group subscribed-lable subscribed-lable-withoffer">
                                                                    <span className="product-label label-cut">
                                                                        Subscribed
                                                                </span>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {value.subacribed_prod === true || value.subacribed_prod === "true" ? (
                                                                <div className="label-group subscribed-lable subscribed-lable-withoutoffer">
                                                                    <span className="product-label label-cut">
                                                                        Subscribed
                                                            </span>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    )}

                                                    <div className="btn-icon-group">
                                                        <button  className="btn-icon btn-add-cart" title="Click to View" onClick={() => this.onOpenModal(value.prod_id)} data-toggle="modal" data-target="#addCartModal"><i className="far fa-eye"></i></button>
                                                    </div>
                                                </figure>
                                                <div className="product-details pad-10">
                                                    <div className="category-wrap">
                                                        <div className="category-list">
                                                            <span className="product-category">{value.scName}</span>
                                                        </div>
                                                    </div>
                                                    <h2 className="product-title">
                                                        <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                            {value.prod_name}</Link>
                                                    </h2>
                                                    <span > UOM : {value.prod_quantity} {value.unit_id}</span>
                                                    <div className="price-box">
                                                        {(value.offer_flag === "1" && value.redeemed_offers_flag === 1) || (value.offer_flag === "0" && value.redeemed_offers_flag === 1) ? (
                                                            <>
                                                                <span className="old-price">Rs. {value.original_amt}</span>
                                                                <span className="product-price">Rs. {value.selling_price}</span>
                                                            </>
                                                        ) : (value.offer_flag === "1" && value.redeemed_offers_flag === 0) ? (
                                                            <>
                                                                <span className="old-price">Rs. {value.original_amt}</span>
                                                                <span className="product-price">Rs. {value.selling_price}</span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {value.market_price !== "0.00" ? (
                                                                    <span className="old-price">Rs. {value.market_price}</span>
                                                                ) : (
                                                                    <> </>
                                                                )}
                                                                <span className="product-price">Rs. {value.selling_price}</span>
                                                            </>
                                                        )}

                                                    </div>
                                                    <div className="cart-box col-lg-12 ">
                                                        <div className="row">
                                                            {value.outof_stock_flag === '1' ? (
                                                                <>
                                                                    {value.cart_count > 0 ? (
                                                                        <>
                                                                            <div className="col-lg-6 custom-s-pc-res col-md-12 col-12 p-0 custom-cart-align-c mb-1">
                                                                                <div className="input-group mob-p-l">
                                                                                    <span className="input-group-btn">
                                                                                        <button type="button" className="btn btn-number product-custom-qty-m" onClick={() => this.basketDecreaseItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity, value.cart_id)} aria-label="Add one" data-direction="1">
                                                                                            <span className="fa fa-minus"></span>
                                                                                        </button>
                                                                                    </span>
                                                                                    <input type="text" id={value.prod_code} className="form-control product-input-number" name="quantity" value={value.cart_count} readOnly={true} />
                                                                                    <span className="input-group-btn">
                                                                                        <button type="button" className="btn btn-number product-custom-qty-a" onClick={() => this.basketIncrementItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} aria-label="Subtract one" data-direction="-1">
                                                                                            <span className="fa fa-plus"></span>
                                                                                        </button>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 custom-s-pc-res col-md-12 col-12 p-0 custom-cart-align-c  mb-1">
                                                                                {localStorage.getItem('user_log_status') === "true" ? (
                                                                                    <>
                                                                                        {value.subacribed_prod === "true" ? (
                                                                                            <Link to="/Subscription"> <span className="addsubscribelableimg viewsubscribelableimg" > View Subscription  </span></Link>
                                                                                        ) : (
                                                                                            <span className="addsubscribelableimg"
                                                                                                onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                    value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <span className="addsubscribelableimg" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    ) :
                                                                        (
                                                                            <>
                                                                                <div className="col-lg-6 custom-s-pc-res col-md-12 col-12 p-0 custom-cart-align-c mb-1">
                                                                                    <button onClick={() => this.productaddtobasket(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} className="cart-btn">Add +</button>
                                                                                </div>
                                                                                <div className="col-lg-6 custom-s-pc-res col-md-12 col-12 p-0 custom-cart-align-c  mb-1">
                                                                                    {localStorage.getItem('user_log_status') === "true" ? (
                                                                                        <>
                                                                                            {value.subacribed_prod === "true" ? (
                                                                                                <Link to="/Subscription"> <span className="addsubscribelableimg viewsubscribelableimg" > View Subscription  </span></Link>
                                                                                            ) : (
                                                                                                <span className="addsubscribelableimg"
                                                                                                    onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                        value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <span className="addsubscribelableimg" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                </>
                                                            ) : (
                                                                <div className="col-lg-12 custom-s-pc-res col-md-12 col-12">
                                                                    <h5 className="outofstockflagcolor">Currently Out Of Stock</h5>
                                                                </div>

                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            } */}
                            </div>
                            {this.state.hotsellerproductValues.length > 9 ? (
                                <>
                                    <div className="loadmorebtn_cust_div" id="hotseller_loadmore" >
                                        <button className="loadmorecustbtn btn mb-1" onClick={() => this.hotsellerloadmore(this.state.hotseller_startpagecount, 'loadnextt')}>load more... </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                </>
                            )}
                        </section>

                        <div id="web-view-brand" className="row row-sm mb-2">
                            <div className="col-lg-12">
                                {this.state.brandbannerDetails.length > 0 ? (
                                    <OwlCarousel
                                        {...options}
                                        className="home-slider owl-carousel owl-theme"
                                    >
                                        {
                                            this.state.brandbannerDetails.map(brandbanner => {
                                                const brandweb_imageurl = brandbanner.web_imageurl;
                                                return (
                                                    <div key={brandbanner.banner_id} className="brand-slide" style={{ backgroundImage: "url(" + brandweb_imageurl + ")" }}>
                                                    </div>
                                                )
                                            }
                                            )}
                                    </OwlCarousel>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <div id="mobile-view-brand" className="row row-sm mb-2">
                            <div className="col-lg-12">
                                {this.state.bannerDetails.length > 0 ? (
                                    <OwlCarousel
                                        {...options}
                                        className="home-slider owl-carousel owl-theme"
                                    >
                                        {
                                            this.state.brandbannerDetails.map(brandbanner => {
                                                const brandmobile_imageurl = brandbanner.mobile_imageurl;
                                                return (
                                                    <div key={brandbanner.banner_id} className="brand-slide item" style={{ backgroundImage: "url(" + brandmobile_imageurl + ")" }}>
                                                    </div>
                                                )
                                            }
                                            )}
                                    </OwlCarousel>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <section className="product-panel pad-lr-50 mb-2">
                            <div className="section-title mb-2 mt-2">
                                <h2>Todays Deal</h2>
                            </div>
                            <div className="cus-product-row">

                            {
                                this.state.dealsproductValues.map(value => {
                                    return (
                                        <div key={value.prod_id} className="cus-div-lay">
                                            <a className="cus-div-a" href="#">
                                                <div className="cus-div-sty">
                                                    <div className="cus-head-div">
                                                        <div className="cus-img-div">
                                                            <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                                {value.outof_stock_flag === '1' ? (
                                                                    <img className="cus-product-img" src={value.produ_imgurl} alt={value.prod_name} />
                                                                ) : (
                                                                    <img className="cus-product-img outofstockflagon" src={value.produ_imgurl} alt={value.prod_name} />
                                                                )}
                                                            </Link>
                                                        </div>

                                                        {value.subacribed_prod === true || value.subacribed_prod === "true" ? (
                                                            <div class="cus-subscribed-sty cus-subscribed-sty-two">
                                                                <div class="cus-subscribed-txt">Subscribed</div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <div className="cus-title-div">
                                                            <div className="cus-title-main">
                                                                <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                                    {value.prod_name}
                                                                </Link>
                                                            </div>
                                                            <div className="cus-title-secondary">{value.scName}</div>
                                                        </div>
                                                        <div className="cus-price">
                                                            <span> UOM : {value.prod_quantity} {value.unit_id}</span>
                                                            <div>
                                                                {(value.offer_flag === "1" && value.redeemed_offers_flag === 1) || (value.offer_flag === "0" && value.redeemed_offers_flag === 1) ? (
                                                                    <>
                                                                        <span className="old-price">Rs. {value.original_amt}</span>
                                                                        <span className="product-price">Rs. {value.selling_price}</span>
                                                                    </>
                                                                ) : (value.offer_flag === "1" && value.redeemed_offers_flag === 0) ? (
                                                                    <>
                                                                        <span className="old-price">Rs. {value.original_amt}</span>
                                                                        <span className="product-price">Rs. {value.selling_price}</span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {value.market_price !== "0.00" ? (
                                                                            <span className="old-price">Rs. {value.market_price}</span>
                                                                        ) : (
                                                                            <> </>
                                                                        )}
                                                                        <span className="product-price">Rs. {value.selling_price}</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {value.outof_stock_flag === '1' ? (
                                                            <>
                                                                {value.cart_count > 0 ? (
                                                                    <>
                                                                    
                                                                        <div className="buttons_slots mt-1">
                                                                            <div className="input-group mob-p-l">
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number product-custom-qty-m" onClick={() => this.basketDecreaseItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity, value.cart_id)} aria-label="Add one" data-direction="1">
                                                                                        <span className="fa fa-minus"></span>
                                                                                    </button>
                                                                                </span>
                                                                                <input type="text" id={value.prod_code} className="form-control product-input-number" name="quantity" value={value.cart_count} readOnly={true} />
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number product-custom-qty-a" onClick={() => this.basketIncrementItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} aria-label="Subtract one" data-direction="-1">
                                                                                        <span className="fa fa-plus"></span>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="cus-subscribe">
                                                                            {localStorage.getItem('user_log_status') === "true" ? (
                                                                                <>
                                                                                    {value.subacribed_prod === "true" ? (
                                                                                        <Link to="/Subscription"> <span className="product-subscribe" > View Subscription  </span></Link>
                                                                                    ) : (
                                                                                        <span className="product-subscribe"
                                                                                            onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <span className="product-subscribe" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                ) :
                                                                    (
                                                                        <>

                                                                            <div className="buttons_slots mt-1">
                                                                                <button onClick={() => this.productaddtobasket(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} className="cart-btn">Add +</button>
                                                                            </div>
                                                                            <div className="cus-subscribe">
                                                                                {localStorage.getItem('user_log_status') === "true" ? (
                                                                                    <>
                                                                                        {value.subacribed_prod === "true" ? (
                                                                                            <Link to="/Subscription"> <span className="product-subscribe" > View Subscription  </span></Link>
                                                                                        ) : (
                                                                                            <span className="product-subscribe"
                                                                                                onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                    value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <span className="product-subscribe" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <div className="buttons_slots mt-1">
                                                                <span className="outofstockflagcolor">Currently Out Of Stock</span>
                                                            </div>

                                                        )}
                                                            
                                                        { (value.offer_flag === '1' && value.redeemed_offers_flag === 1) || (value.offer_flag === '0' && value.redeemed_offers_flag === 1) ? (
                                                            <div className="cus-dis-div">
                                                                {value.redeemed_offers_type === 'A' ? (
                                                                    <span className="cus-dis-span-two">{value.redeemed_offers_value} off | Use STEALDEAL</span>
                                                                ) : (
                                                                    <span className="cus-dis-span-two">{value.redeemed_offers_value}% off | Use STEALDEAL</span>
                                                                )}
                                                            </div>
                                                        ) : (value.offer_flag === '1' && value.redeemed_offers_flag === 0) ? (
                                                            <div className="cus-dis-div">
                                                                {value.redeemed_offers_type === 'A' ? (
                                                                    <span className="cus-dis-span-two">{value.offer_value} off | Use STEALDEAL</span>
                                                                ) : (
                                                                    <span className="cus-dis-span-two">{value.offer_value}% off | Use STEALDEAL</span>
                                                                )}
                                                            </div>
                                                        ) : (
                                                                <>
                                                                </>
                                                            )}
                                                    </div>
                                                    <div className="cus-div-btn-div">
                                                        <span role="button" aria-label="Open" className="cus-hov-btn cus-hov-sty" onClick={() => this.onOpenModal(value.prod_id)} data-toggle="modal" data-target="#addCartModal">Quick View</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })
                            }
                            </div>
                            {/* <div className="loadmorebtn_cust_div" id="todaydeals_hide">
                                <p className="loadmorebtn_cust_text_div" onClick={ () => this.todaydealsloadmore(this.state.todaydeals_startpagecount,'loadnextt') }>load more...</p>
                            </div> */}
                            {this.state.dealsproductValues.length >= 10 ? (
                                <>
                                    <div className="loadmorebtn_cust_div" id="todaydeals_hide" >
                                        <button className="loadmorecustbtn btn mb-1" onClick={() => this.todaydealsloadmore(this.state.todaydeals_startpagecount, 'loadnextt')}>load more... </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                </>
                            )}


                        </section>
                    </div>
                </div>

                <div>
                    {/* <button >Open modal</button> */}
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="container cust_stype_modal">
                            <div className="row row-sm">
                                <div className="col-lg-12 col-xl-12">
                                    {this.state.productDetails_popup.map(values => (
                                        <div key={values.prod_id}>
                                            <div className="product-single-container product-single-default">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-8 product-single-gallery">
                                                        <div className="product-slider owl-carousel owl-theme" data-toggle="owl" data-owl-options="{
                                                    'items': 1,
                                                    'loop': false,
                                                    'autoplay': false,
                                                    'autoplayHoverPause': true
                                                }">
                                                            <div className="product-slide">
                                                                <div className="product-default">
                                                                    <figure>
                                                                        <a href={values.produ_imgurl}>
                                                                            <img src={values.produ_imgurl} alt="" />
                                                                        </a>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="product-single-details">
                                                            <h1 className="product-title">{values.prod_name}</h1>
                                                            <p className="mb-1">Category: {values.scName} </p>
                                                            <p className="mb-1">Brand : {values.brand_name}</p>
                                                            <p className="mb-1">UOM : {values.prod_quantity} {values.unit_id} </p>
                                                            {/* <p>Our Price :&nbsp;<span className="product-price"> Rs. {values.selling_price}</span> &ensp;<s>
                                                            {values.market_price!=0 ? (
                                                                <>( Avg. Market Price Rs. {values.market_price} )</>
                                                            ):(
                                                                <> </>
                                                            )}
                                                        </s> <br />
                                                    </p> */}
                                                            {values.offer_flag === '1' ? (
                                                                <>
                                                                    <p>Our Price :&nbsp;<span className="product-price"> Rs. {values.selling_price}</span> &ensp;<s>
                                                                        {values.market_price !== "0.00" ? (
                                                                            <>( Rs. {values.original_amt} )</>
                                                                        ) : (
                                                                            <> </>
                                                                        )}
                                                                    </s> <br />
                                                                        <span className="product-save-discount">You Save :&nbsp; {values.offer_value}   {values.offer_type === 'P' ? (
                                                                            <> % </>
                                                                        ) : (
                                                                            <> Rs. </>
                                                                        )} OFF</span>
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p>Our Price :&nbsp;<span className="product-price"> Rs. {values.selling_price}</span> &ensp;<s>
                                                                        {values.market_price !== "0.00" ? (
                                                                            <>( Avg. Market Price Rs. {values.market_price} )</>
                                                                        ) : (
                                                                            <> </>
                                                                        )}
                                                                    </s> <br />
                                                                    </p>
                                                                </>
                                                            )}
                                                            <div className="product-action product-all-icons mt-2">
                                                                {values.outof_stock_flag === '1' ? (
                                                                    <>
                                                                        <div className="product-quantity">
                                                                            <input onChange={this.onChangeHandler} className="custom-quantity" data-min="1" data-max="0" type="text" name="quantity" value={this.state.clicks} readOnly={true} /><div className="quantity-selectors-container">
                                                                                <div className="quantity-selectors">
                                                                                    <button type="button" onClick={this.IncrementItem} aria-label="Add one" data-direction="1"><span>&#43;</span></button>
                                                                                    <button type="button" onClick={this.DecreaseItem} aria-label="Subtract one" data-direction="-1"><span>&#8722;</span></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <span className="paction add-cart" onClick={this.movetobasket} title="Add to Cart">
                                                                            Add to Basket
                                                                </span>
                                                                    </>
                                                                ) : (
                                                                    <div className="col-lg-12 custom-s-pc-res col-md-12 col-12">
                                                                        {/* <button onClick={ () => this.productaddtobasket(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity) } className="cart-btn">Add to Basket +</button> */}
                                                                        <h4 className="outofstockflagcolor">Currently Out Of Stock</h4>

                                                                    </div>
                                                                )}

                                                            </div>
                                                            {/* <div className="product-action product-all-icons mt-2">
                                                        <div className="product-quantity">
                                                            <input  onChange={this.onChangeHandler} className="custom-quantity" data-min="1" data-max="0" type="text" name="quantity" value={ this.state.clicks } readOnly={true} /><div className="quantity-selectors-container">
                                                            <div className="quantity-selectors">
                                                                <button type="button" onClick={this.IncrementItem} aria-label="Add one" data-direction="1"><span>&#43;</span></button>
                                                                <button type="button"  onClick={this.DecreaseItem} aria-label="Subtract one" data-direction="-1"><span>&#8722;</span></button>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <span className="paction add-cart" onClick={this.movetobasket} title="Add to Cart">
                                                            Add to Basket
                                                        </span>
                                                    </div> */}
                                                            {this.state.show === true ? (
                                                                <div className="autocalculate">
                                                                    <p>Total UOM :&nbsp;{this.state.total_weight} {values.unit_id}</p>
                                                                    <p>Total Price :&nbsp;Rs. {parseFloat(this.state.total_price).toFixed(2)}</p>
                                                                    {/* <h3> Total UOM: {this.state.total_weight}</h3> */}<br />
                                                                    {/* <h3> Total Price : {this.state.total_price}</h3> */}
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div className="product-desc">
                                                                <p>{values.prod_info}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* <div className="sidebar-overlay"></div>
                            <div className="sidebar-toggle"><i className="icon-right-open"></i></div> */}
                            </div>
                        </div>
                    </Modal>
                    <Popup
                        open={this.state.subscriptionopen}
                        modal
                        nested
                        closeOnDocumentClick={false}
                    >
                        {close => (
                            <div className="subsription_modal">
                                <button className="subsription_close" onClick={() => this.closesubscriptionmodel()}>
                                    &times;
                                    </button>
                                <div className="subsription_header"> Subscribe
                                    </div>
                                <div className="subsription_content">
                                    {' '}
                                    <div className="ml-3 subsription_product_details_display">
                                        <div className="subsription_product_img">
                                            <img src={this.state.subscription_produ_imgurl} alt="" />
                                        </div>
                                        <div className="subsription_product_info">
                                            <span className="font-size-14"><b>{this.state.subscription_produ_name}</b></span>
                                            <span> UOM  : {this.state.subscription_produ_umo} </span>
                                            <span className="product-save-discount"> {this.state.subscribe_offer_text} </span>
                                        </div>
                                    </div>

                                    <div className="subscription_header_tab">
                                        <div className="subscription_tab" id="subscription_tab_one" onClick={() => this.showtabone()}>
                                            <p className="m-0">Weekly</p>
                                        </div>
                                        <div className="subscription_tab" id="subscription_tab_two" onClick={() => this.showtabtwo()}>
                                            <p className="m-0">Bi-Weekly</p>
                                        </div>
                                        <div className="subscription_tab" id="subscription_tab_three" onClick={() => this.showtabthree()}>
                                            <p className="m-0">Monthly</p>
                                        </div>
                                    </div>

                                    <div id="subscription_tab_one_content">
                                        <div className="display_weekly_days mb-1">
                                            <div className="subscription_name_lable_div subscription_name_lable_popup mb-1">
                                                <span className="mr-2 subscription_name_lable"> Which Days ? </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup mb-1">
                                                {
                                                    this.state.subscription_weekly.map(values => {
                                                        return (
                                                            <span key={values.subscription_weekly_id} className="subscription_weekly_days subscription_weekly_days_popup">
                                                                <div className="subscription_day_lable_popup">
                                                                    <label for={values.subscription_weekly_id + "toggle"}>{values.week_name}</label>
                                                                    <input type="checkbox" name="subscription_weekly" id={values.subscription_weekly_id + "toggle"} value={values.subscription_weekly_id} className="visually-hidden-lable" />
                                                                </div>
                                                                <div className="subscription_weekly_add_minus" onClick={() => this.subscription_quantity(values.week_name + "_quantity", "1", "add", values.subscription_weekly_id + "toggle")}>
                                                                    <span className="fa fa-plus"></span>
                                                                </div>
                                                                <div className="subscription_weekly_quantity">
                                                                    <input readOnly className="subscription_weekly_quantity_value" type="text" name={values.week_name + "_quantity"} id={values.week_name + "_quantity"} value={this.state[values.week_name + "_quantity"]} />
                                                                </div>
                                                                <div className="subscription_weekly_add_minus" onClick={() => this.subscription_quantity(values.week_name + "_quantity", "1", "minus", values.subscription_weekly_id + "toggle")}>
                                                                    <span className="fa fa-minus"></span>
                                                                </div>

                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/* <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                    <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup">
                                                    <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                        <div className="input-group ">
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("weekly_quantity", "1", "minus")} aria-label="Add one" data-direction="1">
                                                                    <span className="fa fa-minus"></span>
                                                                </button>
                                                            </span>
                                                            <input type="text" className="form-control product-input-number product-input-number-custsty" name="weekly_quantity" value={this.state.weekly_quantity} readOnly={true} />
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("weekly_quantity", "1", "add")} aria-label="Subtract one" data-direction="-1">
                                                                    <span className="fa fa-plus"></span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>
                                    </div>

                                    <div id="subscription_tab_two_content">
                                        <div className="display_weekly_days mb-1">
                                            <div className="subscription_name_lable_div subscription_name_lable_popup mb-2">
                                                <span className="subscription_name_lable"> Which Dates ? </span>
                                                <span className="subscription_name_lable cust_text_clear float-right text-right" onClick={() => this.clearselectedsubscription("visually-hidden-lable-biweekly")}> Clear selected </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup subscription_biweekly_content_popup mb-1">
                                                {
                                                    this.state.subscription_biweekly.map(values => {
                                                        return (
                                                            // <span key={values.subscription_biweekly_id} className="display_biweekly_popup_content"> {values.biweekly_dates} </span>
                                                            <span key={values.subscription_biweekly_id} className="subscription_biweekly_days_popup">
                                                                <div key={values.subscription_biweekly_id}>
                                                                    <label className="display_biweekly_popup_content" for={values.subscription_biweekly_id + "toggle_biweekly"}>{values.biweekly_dates}</label>
                                                                    <input type="radio" name="subscription_biweekly" id={values.subscription_biweekly_id + "toggle_biweekly"} value={values.subscription_biweekly_id} className="visually-hidden-lable-biweekly" onClick={this.getbiweeklysubscriptionvalues} />
                                                                </div>
                                                            </span>

                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup">
                                                <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                    <div className="input-group ">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("biweekly_quantity", "1", "minus", "dummy")} aria-label="Add one" data-direction="1">
                                                                <span className="fa fa-minus"></span>
                                                            </button>
                                                        </span>
                                                        <input type="text" className="form-control product-input-number product-input-number-custsty" name="biweekly_quantity" value={this.state.biweekly_quantity} readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("biweekly_quantity", "1", "add", "dummy")} aria-label="Subtract one" data-direction="-1">
                                                                <span className="fa fa-plus"></span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="subscription_tab_three_content">
                                        <div className="display_weekly_days mb-1">
                                            <div className="subscription_name_lable_div subscription_name_lable_popup mb-2">
                                                <span className="subscription_name_lable"> Which Date ? </span>
                                                <span className="subscription_name_lable cust_text_clear float-right text-right" onClick={() => this.clearselectedsubscription("visually-hidden-lable-monthly")}> Clear selected </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup subscription_biweekly_content_popup mb-1">
                                                {
                                                    this.state.subscription_monthly.map(values => {
                                                        return (
                                                            // <span key={values.subscription_monthly_id} className="display_monthly_popup_content"> {values.monthly_date}  </span>
                                                            <span key={values.subscription_monthly_id} className="subscription_monthly_days_popup">
                                                                <div >
                                                                    <label className="display_monthly_popup_content" for={values.subscription_monthly_id + "toggle_monthly"}>{values.monthly_date}</label>
                                                                    <input type="radio" name="subscription_biweekly" id={values.subscription_monthly_id + "toggle_monthly"} value={values.subscription_monthly_id} className="visually-hidden-lable-monthly" onClick={this.getmonthlysubscriptionvalues} />
                                                                </div>
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup">
                                                <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                    <div className="input-group ">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("monthly_quantity", "1", "minus", "dummy")} aria-label="Add one" data-direction="1">
                                                                <span className="fa fa-minus"></span>
                                                            </button>
                                                        </span>
                                                        <input type="text" className="form-control product-input-number product-input-number-custsty" name="monthly_quantity" value={this.state.monthly_quantity} readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("monthly_quantity", "1", "add", "dummy")} aria-label="Subtract one" data-direction="-1">
                                                                <span className="fa fa-plus"></span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.showsubscriptionerrormessage === true ? (
                                        <p className="error ml-4"> {this.state.subscription_errormsg} </p>
                                    ) : (
                                        <> </>
                                    )}
                                    <input type="hidden" value={this.subscription_prod_id} name="subscription_prod_id" />
                                    <input type="hidden" value={this.subscription_prod_code} name="subscription_prod_code" />

                                    {this.state.addtosubscribebtn === true ? (
                                        <button className="paction add-subscription float-left add-cart add-cart-custsty" title="Add to subscription"
                                            onClick={() => this.submit_subscription()}> Add to subscription </button>
                                    ) : (
                                        <button className="paction add-subscription float-left add-subscription-disabled add-cart add-cart-custsty" title="Add to subscription"
                                            disabled="true" > Add to subscription </button>
                                    )}
                                    <span className="float-right view_subscriotion_popup" title="View subscription"
                                    > <Link to="/Subscription"> View Subscription </Link> </span>
                                </div>

                            </div>
                        )}
                    </Popup>
                    <Popup
                        open={this.state.addresflagopenpopup}
                        modal
                        nested
                        closeOnDocumentClick={false}
                    >
                        {close => (
                            <div className="subsription_modal Add_address_modal">
                                <button className="subsription_close" onClick={() => this.closesubscriptionaddressmodel()}>
                                    &times;
                                    </button>
                                <div className="subsription_header"> Add address info
                                    </div>
                                <div className="subsription_content">
                                    {' '}
                                    <p className="text-center add_subscribe_address">Click the Add address button to add the address for subscription Order</p>
                                    <Link to={{ pathname: '/Accountdetails/pgtype?=myaddress' }}>
                                        <button className="btn add_subscribed_address_btn" title="Add Address">Add Address</button>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </Popup>

                </div>
            </main>
        )
    }
}

export default Home