import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import ScriptTag from 'react-script-tag';
import { Link } from 'react-router-dom';

class ErrorPage extends Component {

  render() {
    return [
      <>
        <Helmet>
          <title>404 - Document Not Found</title>
          <meta name="description" content="404 - Document Not Found" />
        </Helmet>
        <div className="container">
          <div className="error_page">
            <img src="/assets/img/logo.png" alt="" />
          </div>
          <div className="row text-center min_hg_500">
            <div className="col-lg-12 card-text">
              <h3>Looking for something?</h3>
              <h5 className="errormsg_dip_est">We're sorry. The Web address you entered is not a functioning page on our site. <br></br>
                                Go to <Link to="/"> Home</Link> Page</h5>
            </div>
          </div>
        </div>
        <ScriptTag type="text/javascript" src="/assets/js/plugins.min.js" />
        <ScriptTag type="text/javascript" src="/assets/js/plugins/isotope-docs.min.js" />
        <ScriptTag type="text/javascript" src="/assets/js/main.min.js" />
        <ScriptTag type="text/javascript" src="/assets/js/custom.js" />
        <a id="scroll-top" href="#top" title="Top" role="button"><i className="icon-angle-up"></i></a>
      </>

    ];
  }
}

export default ErrorPage

