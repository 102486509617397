import React, { Component } from 'react';
import { Helmet } from "react-helmet";
// import MetaTags from 'react-meta-tags';
import ScriptTag from 'react-script-tag';
import TopMenu from '../../Component/TopMenu/TopMenu'
import Privacypolicy from '../../Component/Privacypolicy/Privacypolicy'
import LoginModal from '../../Component/LoginModal/LoginModal'
import Footer from '../../Component/Footer/Footer'
import MobileMenu from '../../Component/TopMenu/MobileMenu'
import { axiosInstance } from '../../Services/PostData';
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

class PrivacypolicyDetails extends Component {

    state = { cart_count: 0 }
    incrementState() {
        const jsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance.post(`getUserCartDetails`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let clicks = res.data.count;
                    this.setState({ cart_count: clicks });
                }
            })
            .catch((err) => console.log("Error: ", err));
    }

    render() {
        return [
            <>
                <Helmet>
                    <title>Yalla Basket | Privacy policy</title>
                    <meta name="description" content="Yalla Basket is an online grocery delivery service. Fresh fruits, vegetables and groceries are delivered to your doorstep. We stand for the best customer service. Try us now" />
                </Helmet>
                <div className="page-wrapper">
                    {/* <MetaTags>
                    <title>Yalla Basket | Privacy policy</title>
                    <meta name="description" content="Yalla Basket is an online grocery delivery service. Fresh fruits, vegetables and groceries are delivered to your doorstep. We stand for the best customer service. Try us now" />
                    <meta property="og:title" content="Yalla Basket" />
                    <meta property="og:image" content="/assets/img/symbol.png" />
                </MetaTags> */}
                    <TopMenu counter={this.state.cart_count} onIncrement={() => this.incrementState()} />
                    <Privacypolicy onIncrement={() => this.incrementState()} />
                    <LoginModal />
                    <Footer />
                </div>
                <div className="mobile-menu-overlay"></div>
                <MobileMenu />
                {/* <ScriptTag type="text/javascript" src="/assets/js/jquery.min.js" />
            <ScriptTag type="text/javascript" src="/assets/js/bootstrap.bundle.min.js" /> */}
                <ScriptTag type="text/javascript" src="/assets/js/plugins.min.js" />
                <ScriptTag type="text/javascript" src="/assets/js/plugins/isotope-docs.min.js" />
                <ScriptTag type="text/javascript" src="/assets/js/main.min.js" />
                <ScriptTag type="text/javascript" src="/assets/js/custom.js" />
                <a id="scroll-top" href="#top" title="Top" role="button"><i className="icon-angle-up"></i></a>
            </>

        ];
    }
}

export default PrivacypolicyDetails

