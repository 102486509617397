import React, { Component } from 'react';
// import MetaTags from 'react-meta-tags';
import ScriptTag from 'react-script-tag';
import Termsandconditions from '../../Component/Terms_and_conditions/Terms_and_conditions'

class Terms_and_conditionsDetailsphone extends Component {

    render() {
      return [
        <>
            <div className="page-wrapper">
                <Termsandconditions />
            </div>
            <div className="mobile-menu-overlay"></div>
            {/* <MobileMenu /> */}
            {/* <ScriptTag type="text/javascript" src="/assets/js/jquery.min.js" />
            <ScriptTag type="text/javascript" src="/assets/js/bootstrap.bundle.min.js" /> */}
            <ScriptTag type="text/javascript" src="/assets/js/plugins.min.js" />
            <ScriptTag type="text/javascript" src="/assets/js/plugins/isotope-docs.min.js" /> 
            <ScriptTag type="text/javascript" src="/assets/js/main.min.js" />
            <ScriptTag type="text/javascript" src="/assets/js/custom.js" />
            <a id="scroll-top" href="#top" title="Top" role="button"><i className="icon-angle-up"></i></a>
        </>
  
      ]; 
    }
}

export default Terms_and_conditionsDetailsphone

