import React, { Component } from 'react';

class Termsandconditions extends Component {

    componentDidMount() {
        this.selector="#card5";
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' || 
            user_auth_key === "undefined" || user_auth_key === "null") 
        {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
    }
    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }
    render() {

        return (
            
            <main className="main home">


                <div className="page-header page-header-bg">
                    <div className="container">
                        <h1 className="beck_head">Terms and Condtions </h1>
                    </div>
                </div>

                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            {/* <li className="breadcrumb-item"><a href="index.html"><i className="icon-home"></i></a></li>
                            <li className="breadcrumb-item active" aria-current="page">
                            about us</li> */}
                        </ol>
                    </div>
                </nav>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="company-section" id='card2' >
                            <div className="container">
                                <div className="row align-items-lg-center">
                                    <div className="col-md-12 padding-left-lg">
                                        <h3 className="subtitle">Introduction:</h3>
                                        <p >&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Hello there, <a href="https://www.yallabasket.com/" target="_blank"   rel="noopener noreferrer nofollow">www.yallabasket.com</a>   is the website / App offered by Quality Yalla Basket Foodstuff Trading LLC ( “we” or “us”) to our customers ( “you”). The use of the website confirms your unconditional agreement to abide by our terms and conditions, if you do not agree to any of our terms & conditions kindly refrain from using this website. We reserve the right to change the terms & conditions at any time. All such changes will be posted on the website and will be effective immediately. The customer/user is responsible for reviewing the terms & conditions on each instance while using the website. Continued usage shall signify that you have accepted our terms & conditions.
All details provided by you must be accurate and providing any inaccurate information will be considered a breach of terms & conditions. All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the consent of, and be supervised by, their parent or guardian to use the website. If you are a minor, you must have your parent or guardian read and agree to this Agreement before you using the website.
</p>

                                        <h3 className="subtitle">Explanation of our services & Information:</h3>
                                        <p className="pl-20">
                                        <ul className="term_line_stle">
                                            <li>The website / App is designed and offered to provide our customers with an easy & convenient way to shop for groceries & other products.</li>
                                            <li>The Website/APP functionalities will allow customers to search & add products to the cart as per their requirements</li>
                                            <li>The Website/App requires you to verify your mobile number using an OTP sent by us and a username and password is required to create an account, the account is subject to be used only by the sole individual and is not to be shared with friends or family member</li>
                                            <li>It is the sole responsibility of the user to keep their passwords safe and we will not be liable for any issues arising due to the use of the password,  <a href="https://www.yallabasket.com/" target="_blank"   rel="noopener noreferrer nofollow">www.yallabasket.com</a>  doesn’t share the user details to third parties neither does our team have access to the same</li>
                                            <li>All Product descriptions, nutritional information’s, Storage instructions, Diet Specifications, and details provided are carefully vetted by our team and uploaded on the website however we do not take any responsibility to verify the authenticity of the details and will not be liable for any issues arising due to the same</li>
                                            <li>The images of our products that are displayed on the website will be uploaded accurately as much as possible. However, the color can defer based on the devices used to view the website or the application and we are not liable for any change in the color of the actual product</li>
                                            <li>All Perishable, Seasonal products that are displayed on the website are for reference purposes only, and the actual appearance, color, shape, or features of the product might change in reality, any claims arising by comparing the images on the website will not be entertained</li>
                                        </ul></p>

                                        <h3 className="subtitle">Order creation, Pricing, Payments:</h3>
                                        <p className="pl-20">
                                        <ul className="term_line_stle">
                                            <li>You must have a valid UAE mobile number and account with us to place an order with us</li>
                                            <li>Once the Products are added to the cart, you will be prompted to Checkout by providing the information requested by us to fulfill the delivery, please note it is important for you to check and provide information and correct any errors before placing the order</li>
                                            <li>We make every effort to ensure the pricing is updated, stock availability is checked and product details are accurate, due to any errors or due to any unavoidable circumstances we reserve the right to cancel or reschedule the orders without prior consent by the customer and the same will be notified by SMS or email.</li>
                                            <li>We currently do not accept payments through online channels, all payments should be made by cash or credit card at the time of delivery. It is the customer’s responsibility to ensure the correct amount is paid by cash or card and any disputes should be raised immediately with the delivery person, any complaints or claims will not be entertained later</li>
                                            <li>We currently do not accept payments through online channels, all payments should be made by cash or credit card at the time of delivery. It is the customer’s responsibility to ensure the correct amount is paid by cash or card and any disputes should be raised immediately with the delivery person, any complaints or claims will not be entertained later</li>
                                        </ul></p>

                                        <h3 className="subtitle">Delivery & Returns:</h3>
                                        <p className="pl-20">
                                        <ul className="term_line_stle">
                                            <li>Customers are solely responsible for verifying the details provided such as the delivery address, mobile number, delivery date and time, etc. in case of errors we will try our best to contact you to resolve the same to ensure the delivery is not affected</li>
                                            <li>We try our best to meet the delivery date and time as promised during the order checkout however the delivery can be delayed or canceled due to unavoidable circumstances that are beyond our control. Our team will get in touch with you immediately once we are aware of any such incidents</li>
                                            <li>During the delivery if you are not available at the provided address we will not deliver the product to any other address or locations the order will be either canceled or rescheduled.</li>
                                            <li>Failure to make the payment at the time of accepting the delivery will result in the cancellation of the order</li>
                                            <li>We are not responsible for any liabilities arising due to the usage of a credit/debit card by the person accepting the delivery at the provided address, we do not store or verify the card details that are used to make the payment</li>
                                            <li>Any purchase, dispute, or claim arising out of or in connection with our website shall be governed and taken per the laws of UAE</li>
                                            <li>As part of our customer happiness policy, we allow customers to inspect the products before accepting the order, the same needs to be done Infront of our delivery personal.</li>
                                            <li>Touching or unpacking of the packages to inspect the product may be denied due to health reasons if proper safety gears are not used by the customer, however, if the customer has damaged or changed the product to any extent that the product loses its value to be resold to another customer, the customer will be liable to accept and pay for the product</li>
                                            <li>Once the products are accepted by the customer we do not accept returns for health & safety reasons concerning you and our other customers</li>
                                            <li>If you are not happy with the product quality once you the same, we encourage you to write to us at hello@yallabasket.com so that we can address the issue immediately</li>
                                        </ul></p>

                                        <h3 className="subtitle">Other terms:</h3>
                                        <p className="pl-20">
                                        <ul className="term_line_stle">
                                            <li>Any comments, feedback, or suggestions submitted by you through the website or any other channels must not attempt to impersonate anyone, using the credentials of other users</li>
                                            <li>The user has no right or must not harass, annoy, intimidate or threaten the employees of  <a href="https://www.yallabasket.com/" target="_blank"   rel="noopener noreferrer nofollow">www.yallabasket.com</a>  through any mode of communication directly or indirectly, you will be solely responsible for any such incidents being taken place</li>
                                            <li>We can temporarily or permanently stop the services without any prior notice without accepting any liabilities arising through this situation</li>
                                            <li>The website may be unavailable due to various reasons like maintenance or upgrades, we will try our best to keep you notified of such changes</li>
                                        </ul></p>
                                        <p>The illegal acts listed in the above passages are non-negotiables. You will pay Quality Yalla Basket Foodstuff Trading LLC for all costs and damages which it incurs as a result of you breaching any of these restrictions. We will fully co-operate with any law enforcement authorities or court mandate requesting or guiding us to disclose the identity or location of anyone posting any material in breach of the above clauses.</p>
                                        <h3 className="subtitle">Governing Law:</h3>
                                        <p>
                                        These Website Terms shall be governed by and construed following UAE law. Disputes arising in connection with these Website Terms (including non-contractual disputes) shall be subject to the exclusive jurisdiction of the Dubai courts.
                                        </p>

                                        {/* <p>To provide an online grocery service that is more customer-centered and quality-oriented so that customers can spend more quality time with their families forgetting the task of grocery shopping.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

export default Termsandconditions