import axios from "axios";

export const axiosInstance = axios.create({
 baseURL: 'https://yallabaskets.com/webcrmpanel/YallakartWebCon' 
// baseURL: 'https://www.appteqdemo.in/admin/YallakartCon'
// baseURL: 'http://localhost/other_projects/yallabasket-admin/YallakartWebCon'
//  baseURL: 'http://localhost/project/yallabasket-admin/YallakartCon'
//  https://www.yallabasket.appteq.in/
});


