import React, { Component } from 'react';
import { axiosInstance } from '../../Services/PostData';
// import Cookies from 'universal-cookie';
import {NotificationManager} from 'react-notifications'; 
import 'react-notifications/lib/notifications.css';
// const cookies = new Cookies();
// const current = new Date();
// const nextYear = new Date();
// nextYear.setFullYear(current.getFullYear() + 1);

class LoginModal extends Component {

    constructor(){
		super();
		this.state = {
            loginusername: "",
            loginoption: "",
            loginotp: "",
            loginpassword: "",
            loginerror: "",
            username: "",
            email: "",
            mobile_no: "",
            password: "",
            user_referral_code: "",
            otp_code:"",
            LoginOtp_msg:"",
            loginotp_codeError:"",
            mobile_no_code:"+971",
            show_otp:false,
            show_login_otp:false,
            show_forgetpassword:false,
            show_signupform:true,
            show_error_msg:false,
            show_logingerror_msg:false,
            loginnerror:'',
            Otp_msg:'',
            show_loginerror_msg:false,
            show_passwordlogerror_msg:false,
            loginpassworderror:'',
            image: 'signin',
            loginusertype: 'mobile',
            spinner : 'loading-overlay',
            small_spinner : 'ajax-overlay hide-content',
        };
        this.submitFormHandler = this.submitFormHandler.bind(this);
        this.loginFormHandler = this.loginFormHandler.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    onChangeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
        if(this.state.loginoption === 'Password')
        {
            this.setState({loginerror:"", show_loginerror_msg:false})
        }
        if(this.state.loginoption === 'OTP')
        {
            this.setState({loginerror:"", show_loginerror_msg:false})
        }



    }

    loginvalidateFunction() {
        let loginusernameError = "";
        let loginpasswordError = "";
    
        if (!this.state.loginusername) {
            loginusernameError = "Please enter valid Email ID/Mobile number";
        }

        else if (!this.state.loginpassword) 
        {
            loginpasswordError = "Please enter Password";
        }
    
        if ( loginusernameError  || loginpasswordError ) 
        {
          this.setState({
            loginusernameError,
            loginpasswordError,
          });
          return false;
        }
        return true;
    }

    loginFormHandler() {
        const isLoginValid = this.loginvalidateFunction();
        if (isLoginValid) {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            let loginusernameError = "";
            let loginpasswordError = "";
            this.setState({
                loginusernameError,
                loginpasswordError,
              });
            const jsonValue = {
                username: this.state.loginusername,
                logintype: "Password",
                otp: "",
                password: this.state.loginpassword,
                user_unique_id: localStorage.getItem('user_auth_key'),
            };
            axiosInstance
            .post(`YallaBasketLogin`, jsonValue)
            .then((res) => {
                if ( res.data.status === "success" && res.data.statusCode === "200" ) {
                    NotificationManager.success('Login Successfully', 'Success',200);
                    localStorage.setItem('yallabasketuser', res.data.username);
                    localStorage.setItem('user_auth_key', res.data.user_unique_id);
                    localStorage.setItem('user_log_status', "true");
                    // cookies.set('yallabasketuser', res.data.username, {
                    //         expires: nextYear,
                    //     } );
                    // cookies.set('user_auth_key', res.data.user_unique_id, {
                    //         expires: nextYear,
                    //     } );
                    window.location.reload();
                } 
                else if ( res.data.status === "fail" && res.data.statusCode === "201" ) {
                    NotificationManager.error('Something went wrong', 'Error',500);
                    this.setState({show_loginerror_msg:true});
                    this.setState({ loginerror: res.data.errorStatus })
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
        }
    }  

    validateFunction() {
        let usernameError = "";
        let emailError = "";
        let mobileError = "";
        let passwordError = "";
    
        if (!this.state.username) {
            usernameError = "Username cannot be blank";
        }

        if (!this.state.mobile_no) 
        {
          mobileError = "Enter Mobile Number";
        } 
        else if ( this.state.mobile_no.length !== 9 ) 
        {
          mobileError = "Your mobile number should be 9 Digit";
        }
    
        if (!this.state.email) {
          emailError = "Email cannot be blank";
        }
        else if (!this.state.email.includes("@")) {
            emailError = "invalid email";
          }
    
        // password

        // const strongRegex = new RegExp(
        //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        // );

        if (!this.state.password) 
        {
          passwordError = "Password required";
        } 
        else if (this.state.password.length <= 5) 
        {
          passwordError = "Password should be 5 characters";
        } 
        // else if (!strongRegex.test(this.state.admin_password)) 
        // {
        //   passwordError = "use special character, number and capital letter";
        // }
    
        if ( usernameError  || emailError|| mobileError || passwordError ) {
          this.setState({
            usernameError,
            emailError,
            mobileError,
            passwordError,
          });
          return false;
        }
        return true;
    }

    submitFormHandler() {
        const isValid = this.validateFunction();
        if (isValid) {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner ,user_referral_codeError:''});
            const jsonValue = {
                mobile_no: this.state.mobile_no,
                mobile_no_code: this.state.mobile_no_code,
                user_referral_code: this.state.user_referral_code
            };
            axiosInstance
            .post(`sendotpcode`, jsonValue)
            .then((res) => {
                if ( res.data.status === "success" && res.data.statusCode === "200" ) {
                    this.setState({show_otp:true, show_signupform:false});
                    NotificationManager.success('OTP sent to your mobile number', 'Success',200);
                    // let Otp_msg = res.data.msg;
                    this.setState({Otp_msg:res.data.msg});
                } 
                else if ( res.data.status === "fail" && res.data.statusCode === "400" ) 
                {
                    this.setState({show_otp:false, show_signupform:true});
                    this.setState({user_referral_codeError:res.data.msg});
                    NotificationManager.error('Something went wrong', 'Error',500);
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
        }
    }

    sendotp()
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({show_loginerror_msg:false, loginotpError:""});
        let loginusernameError='';
        if (!this.state.loginusername) {
            let small_spinner = 'ajax-overlay hide-content';
            loginusernameError = "Please enter valid Email ID/Mobile number";
            this.setState({
                loginusernameError,
                small_spinner
              });
        }
        else
        {
            let loginusernameError = "";
            let loginoptionError = "";
            let loginotpError = "";
            let loginpasswordError = "";
            this.setState({
                loginusernameError,
                loginotpError,
                loginpasswordError,
                loginoptionError,
              });

            const jsonValue = {
                mobile_no: this.state.loginusername,
                mobile_no_code: this.state.mobile_no_code,
            };
            axiosInstance
            .post(`checkuserandsendotp`, jsonValue)
            .then((res) => {
                if ( res.data.status === "success" && res.data.statusCode === "200" ) {

                    this.setState({show_login_otp:true});
                    let LoginOtp_msg = res.data.msg;
                    this.setState({LoginOtp_msg:LoginOtp_msg, loginotp_code:''});
                    // this.setState({show_loginerror_msg:true, loginerror:res.data.msg });
                } 
                else if ( res.data.status === "fail" && res.data.statusCode === "400" ) 
                {
                    this.setState({show_loginerror_msg:true, loginerror:res.data.msg });
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
        }
    }

    resendloginotp()
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({show_loginerror_msg:false, loginotpError:""});
        let loginusernameError='';
        if (!this.state.loginusername) {
            let small_spinner = 'ajax-overlay hide-content';
            loginusernameError = "Please enter valid Email ID/Mobile number";
            this.setState({
                loginusernameError,
                small_spinner
              });
        }
        else
        {
            let loginusernameError = "";
            let loginoptionError = "";
            let loginotpError = "";
            let loginpasswordError = "";
            this.setState({
                loginusernameError,
                loginotpError,
                loginpasswordError,
                loginoptionError,
              });

            const jsonValue = {
                mobile_no: this.state.loginusername,
                mobile_no_code: this.state.mobile_no_code,
            };
            axiosInstance
            .post(`checkuserandsendotp`, jsonValue)
            .then((res) => {
                if ( res.data.status === "success" && res.data.statusCode === "200" ) {

                    NotificationManager.success('OTP Sent Successfully', 'Success',200);
                    this.setState({loginotp_code:''});
                } 
                else if ( res.data.status === "fail" && res.data.statusCode === "400" ) 
                {
                    NotificationManager.error('Something went wrong', 'Error',500);
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
        }
    }

    verifyotpandloginotp() 
    {
        let loginotp_codeError = "";
        if (!this.state.loginotp_code) {
            loginotp_codeError = "Please enter OTP";
            this.setState({
                loginotp_codeError,
              });
        }
        else
        {
              let small_spinner = 'ajax-overlay';
              this.setState({ small_spinner });
              this.setState({
                  loginotp_codeError,
                });
              const jsonValue = {
                  username: this.state.loginusername,
                  logintype: "OTP",
                  otp: this.state.loginotp_code,
                  password: '',
                  user_unique_id: localStorage.getItem('user_auth_key'),
              };
              axiosInstance
              .post(`YallaBasketLogin`, jsonValue)
              .then((res) => {
                  if ( res.data.status === "success" && res.data.statusCode === "200" ) {
                      NotificationManager.success('Login Successfully', 'Success',200);
                      localStorage.setItem('yallabasketuser', res.data.username);
                      localStorage.setItem('user_auth_key', res.data.user_unique_id);
                      localStorage.setItem('user_log_status', "true");
                    //   cookies.set('yallabasketuser', res.data.username, {
                    //           expires: nextYear,
                    //       } );
                    //   cookies.set('user_auth_key', res.data.user_unique_id, {
                    //           expires: nextYear,
                    //       } );
                      window.location.reload();
                  } 
                  else if ( res.data.status === "fail" && res.data.statusCode === "201" ) {
                    //   NotificationManager.error('Something went wrong', 'Error',500);
                      this.setState({show_logingerror_msg:true});
                      this.setState({ loginnerror: res.data.errorStatus })
                  }
                  let small_spinner = 'ajax-overlay hide-content';
                  this.setState({ small_spinner });
              })
              .catch((err) => console.log("Login: ", err));
        }
    }



    verifyotpandinsert()
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        let otp_codeError= "";
        if (!this.state.otp_code) 
        {
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
            otp_codeError = "OTP cannot be blank";
        }
        else
        {
            const jsonValue = {
                username: this.state.username,
                email: this.state.email,
                mobile_no: this.state.mobile_no,
                password: this.state.password,
                user_unique_id: localStorage.getItem('user_auth_key'),
                mobile_no_code: this.state.mobile_no_code,
                otp_code: this.state.otp_code,
                user_referral_code: this.state.user_referral_code,
                
            };
            axiosInstance
            .post(`InsertUsers`, jsonValue)
            .then((res) => {
                if ( res.data.status === "success" && res.data.statusCode === "200" ) 
                {
                    NotificationManager.success('Registered Successfully', 'Success',200);
                    localStorage.setItem('yallabasketuser', res.data.username);
                    localStorage.setItem('user_auth_key', res.data.user_unique_id);
                    localStorage.setItem('user_log_status', "true");
                    // cookies.set('yallabasketuser', this.state.username, {
                    //         expires: nextYear,
                    //     } );
                    // cookies.set('user_auth_key', res.data.user_auth_key, {
                    //         expires: nextYear,
                    //     } );
                    window.location.reload();
                } 
                else if ( res.data.status === "fail" && res.data.statusCode === "400" ) 
                {
                    this.setState({signuperror:res.data.msg , show_error_msg:true});

                    // NotificationManager.error('Something went wrong', 'Error',500);
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
        }
        this.setState({ otp_codeError:otp_codeError });

    }


    changenumber()
    {
        this.setState({show_otp:false, show_signupform:true, show_error_msg:false});
    }

    handleBlur(type)
    {
        if(type==='email' && this.state.email!=='')
        {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email))
            {
                document.getElementById("submitFormHandler").disabled = false;
                const jsonValue = {
                    email: this.state.email,
                    type: type
                };
                axiosInstance
                .post(`checkavaliablity`, jsonValue)
                .then((res) => {
                    if ( res.data.status === "success" && res.data.statusCode === "200" ) {
                        document.getElementById("submitFormHandler").disabled = true;
                        this.setState({emailError:"Hello, this email id is already registered with us", });
                    } 
                    else if ( res.data.status === "fail" && res.data.statusCode === "400" ) 
                    {
                        document.getElementById("submitFormHandler").disabled = false;
                        this.setState({emailError:" ", });
                    }
                })
                .catch((err) => console.log("Login: ", err));
            }
            else
            {
                document.getElementById("submitFormHandler").disabled = true;
                this.setState({emailError:"Please enter the valid email id", }); 
            }
        }
        if(type==='mobile' && this.state.mobile_no!=='')
        {
            if(this.state.mobile_no.length>9)
            {
                document.getElementById("submitFormHandler").disabled = true;
                this.setState({mobileError:"Please enter the valid mobile no", }); 
            }
            else
            {
                document.getElementById("submitFormHandler").disabled = false;
                const jsonValue = {
                    mobile_no: this.state.mobile_no,
                    type: type
                };
                axiosInstance
                .post(`checkavaliablity`, jsonValue)
                .then((res) => {
                    if ( res.data.status === "success" && res.data.statusCode === "200" ) {
                        document.getElementById("submitFormHandler").disabled = true;
                        this.setState({mobileError:"Hello, the mobile number entered is already registered with us", });    
                    }
                    else if ( res.data.status === "fail" && res.data.statusCode === "400" ) 
                    {
                        document.getElementById("submitFormHandler").disabled = false;
                        this.setState({mobileError:" ", });
                    }
                })
                .catch((err) => console.log("Login: ", err));
            }
        }
        if(type==='referral' && this.state.user_referral_code!=='')
        {
            const jsonValue = {
                user_referral_code: this.state.user_referral_code,
            };
            axiosInstance
            .post(`checkreferralavaliablity`, jsonValue)
            .then((res) => {
                if ( res.data.status === "success" && res.data.statusCode === "200" ) {
                    document.getElementById("submitFormHandler").disabled = false;
                    this.setState({user_referral_codeError:" ", });    
                }
                else if ( res.data.status === "fail" && res.data.statusCode === "400" ) 
                {
                    document.getElementById("submitFormHandler").disabled = true;
                    this.setState({user_referral_codeError: res.data.msg, });
                }
            })
            .catch((err) => console.log("Login: ", err)); 
        }
        else
        {
            this.setState({user_referral_codeError:" ", });
            document.getElementById("submitFormHandler").disabled = false;
        }

    }

    onclosemodell()
    {
        this.setState( {
            loginusername: "",
            loginoption: "",
            loginotp: "",
            loginpassword: "",
            loginerror: "",
            loginusernameError: "",
            loginoptionError: "",
            loginotpError: "",
            loginpasswordError: "",
            usernameError: "",
            emailError: "" ,
            mobileError: "",
            passwordError: "",
            username: "",
            email: "",
            mobile_no: "",
            password: "",
            otp_code:"",
            mobile_no_code:"+971",
            show_otp:false,
            show_signupform:true,
            show_error_msg:false,
            Otp_msg:'',
            show_loginerror_msg:false,
        });

        var ele = document.getElementsByName("loginoption");
        for(var i=0;i<ele.length;i++)
        {
            ele[i].checked = false;
        }
        document.getElementById("Login").classList.add("active");
        document.getElementById("Login").classList.add("show");
        document.getElementById("Signup").classList.remove("active");
        document.getElementById("Signup").classList.remove("show");

    }

    loginmodal(loginimg){
        this.setState({ image : loginimg });   
        document.getElementById("Login-tab").classList.remove("active");
        document.getElementById("Login-tab").classList.remove("show");
        document.getElementById("Signup-tab").classList.remove("active");
        document.getElementById("Signup-tab").classList.remove("show");

        this.setState( {
            loginusername: "",
            loginoption: "",
            loginotp: "",
            loginpassword: "",
            loginerror: "",
            loginusernameError: "",
            loginoptionError: "",
            loginotpError: "",
            loginpasswordError: "",
            usernameError: "",
            emailError: "" ,
            mobileError: "",
            passwordError: "",
            username: "",
            email: "",
            mobile_no: "",
            password: "",
            otp_code:"",
            mobile_no_code:"+971",
            show_otp:false,
            show_signupform:true,
            show_error_msg:false,
            Otp_msg:'',
            show_loginerror_msg:false,
            show_forgetpassword:false,
            show_passwordlogerror_msg:false,
            loginpassworderror:'',
        });

        this.setState({show_forgetpassword:false});

    }

    hideloginotomodel()
    {
        this.setState({show_login_otp:false});
        this.setState({show_forgetpassword:false});
    }

    changelogintype(logintype){
        this.setState({ loginusertype : logintype, loginusername : '' });
    }


    forgetpasswords()
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({show_loginerror_msg:false, loginotpError:""});
        let loginusernameError='';
        if (!this.state.loginusername) {
            let small_spinner = 'ajax-overlay hide-content';
            loginusernameError = "Please enter valid Email ID/Mobile number";
            this.setState({
                loginusernameError,
                small_spinner
              });
        }
        else
        {
            let loginusernameError = "";
            let loginoptionError = "";
            let loginotpError = "";
            let loginpasswordError = "";
            this.setState({
                loginusernameError,
                loginotpError,
                loginpasswordError,
                loginoptionError,
              });

            const jsonValue = {
                mobile_no: this.state.loginusername,
                mobile_no_code: this.state.mobile_no_code,
            };
            axiosInstance
            .post(`checkuserandsendotp`, jsonValue)
            .then((res) => {
                if ( res.data.status === "success" && res.data.statusCode === "200" ) 
                {
                    this.setState({show_forgetpassword:true});
                    this.setState({ passwordotpcode:'', setpasswordnew:'',LoginOtp_msg:res.data.msg});
                } 
                else if ( res.data.status === "fail" && res.data.statusCode === "400" ) 
                {
                    this.setState({show_loginerror_msg:true, loginerror:res.data.msg });
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
        }
    }

    loginwithupdatepassword()
    {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({show_passwordlogerror_msg:false, loginpassworderror:""});
        let passwordotpcodeError='';
        let setpasswordnewError='';

        if (!this.state.passwordotpcode) {
            let small_spinner = 'ajax-overlay hide-content';
            passwordotpcodeError = "Please enter valid OTP";
            this.setState({
                passwordotpcodeError,
                setpasswordnewError,
                small_spinner
              });
        }
        else  if (!this.state.setpasswordnew) {
            let small_spinner = 'ajax-overlay hide-content';
            setpasswordnewError = "Please enter Password";
            this.setState({
                setpasswordnewError,
                passwordotpcodeError,
                small_spinner
              });
        }
        else  if (this.state.setpasswordnew.length<=7) {
            let small_spinner = 'ajax-overlay hide-content';
            setpasswordnewError = "Password must contain 8 characters";
            this.setState({
                setpasswordnewError,
                passwordotpcodeError,
                small_spinner
              });
        }
        else
        {
            let small_spinner = 'ajax-overlay';
            this.setState({
                passwordotpcodeError,
                setpasswordnewError,
                small_spinner,
              });
              const jsonValue = {
                  username: this.state.loginusername,
                  logintype: "OTP",
                  otp: this.state.passwordotpcode,
                  password: '',
                  user_unique_id: localStorage.getItem('user_auth_key'),
                  updatepassword:this.state.setpasswordnew,
              };
              axiosInstance
              .post(`YallaBasketLogin`, jsonValue)
              .then((res) => {
                  if ( res.data.status === "success" && res.data.statusCode === "200" ) {
                      NotificationManager.success('Login Successfully', 'Success',200);
                      localStorage.setItem('yallabasketuser', res.data.username);
                      localStorage.setItem('user_auth_key', res.data.user_unique_id);
                      localStorage.setItem('user_log_status', "true");
                    //   cookies.set('yallabasketuser', res.data.username, {
                    //           expires: nextYear,
                    //       } );
                    //   cookies.set('user_auth_key', res.data.user_unique_id, {
                    //           expires: nextYear,
                    //       } );
                      window.location.reload();
                  } 
                  else if ( res.data.status === "fail" && res.data.statusCode === "201" ) {
                    //   NotificationManager.error('Something went wrong', 'Error',500);
                    // this.setState({show_passwordlogerror_msg:true, loginpassworderror:res.data.errorStatus });
                      this.setState({show_passwordlogerror_msg:true});
                      this.setState({ loginpassworderror: res.data.errorStatus })
                  }
                  let small_spinner = 'ajax-overlay hide-content';
                  this.setState({ small_spinner });
              })
              .catch((err) => console.log("Login: ", err));
        }
    }



    render() {
        // console.log(cookies.get('yallabasketuser'));
        return (
            <>
            <div className={this.state.small_spinner}>
                <i className="porto-loading-icon"></i>
            </div>
            <div className="modal fade" id="login-form" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title w-100 text-center">Yalla Basket</h4>
                        <button type="button" className="close" onClick={ () =>this.onclosemodell()} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div id="web-login-img" className="col-md-6 text-center">
                            { this.state.image === 'signin' ? 
                                (
                                    <>
                                        <img className="w-100 signin_ing_cust" src="/assets/img/signin.gif" alt="" />
                                        <label className="text-center"> Facing trouble  on sign-in whatsapp us. </label>
                                        <div className="contact-widget">
                                            <a href="https://wa.me/message/MY2RPGA2B7BDJ1"  rel="noopener noreferrer nofollow"  target="_blank"><p className="md-1-cust" ><img className="what_logo_style_login" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6207179</p></a>
                                            {/* <a href="https://wa.me/message/BKO5NZ5MJOH6A1" target="_blank"><p className="md-1-cust"><img className="what_logo_style_login" src="assets/img/whatsapp.png" />&nbsp;  +971 56 6339179</p></a> */}
                                            {/* <Link to="https://wa.me/message/MY2RPGA2B7BDJ1"><p className="md-0-cust">+971 56 6207179</p></Link>
                                            <Link to="https://wa.me/message/BKO5NZ5MJOH6A1"><p className="md-0-cust">+971 56 6339179</p></Link> */}
                                        </div>
                                    </>
                                    
                                ) : (
                                    <>
                                        <img className="w-100 signin_ing_cust" src="/assets/img/signin.gif" alt="" />
                                        <label className="text-center"> Facing trouble  on sign-up whatsapp us. </label>
                                        <div className="contact-widget">
                                            <a href="https://wa.me/message/MY2RPGA2B7BDJ1"  rel="noopener noreferrer nofollow" target="_blank"><p className="md-1-cust" ><img className="what_logo_style_login" src="/assets/img/whatsapp.png" alt="" />&nbsp;  +971 56 6207179</p></a>
                                            {/* <a href="https://wa.me/message/BKO5NZ5MJOH6A1" target="_blank"><p className="md-1-cust"><img className="what_logo_style_login" src="assets/img/whatsapp.png" />&nbsp;  +971 56 6339179</p></a> */}
                                            {/* <Link to="https://wa.me/message/MY2RPGA2B7BDJ1"><p className="md-0-cust">+971 56 6207179</p></Link>
                                            <Link to="https://wa.me/message/BKO5NZ5MJOH6A1"><p className="md-0-cust">+971 56 6339179</p></Link> */}
                                        </div>
                                    </>
                                    
                                ) }
                            </div>
                            <div className="col-md-6">
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="Login" role="tabpanel" aria-labelledby="Login-tab">

                                    {this.state.show_login_otp === true ? (

                                    // <form id="SingupForm">
                                    <>
                                        <div className="form-group">
                                            <p className="loginotp_msgstyle">{this.state.LoginOtp_msg} <span className="hideloginotomodel_changebtn" onClick={ () => this.hideloginotomodel() } > Change </span></p> 
                                        </div>
                                        <div className="form-group form-group-custstyler">
                                            <label htmlFor="username"></label>
                                            <input type="text" onChange={this.onChangeHandler} name="loginotp_code" className="form-control form-control-sm" 
                                            value={this.state.loginotp_code} id="loginotp_code" placeholder="Enter OTP"/>
                                            <span className="error">
                                                {this.state.loginotp_codeError}
                                            </span>
                                        </div>

                                        <button type="button" onClick={ () => this.verifyotpandloginotp() } className="btn btn-secondary btn-block btn-block-custmstyler">Verify</button>
                                        {/* <div className="sign-up">
                                             <a onClick={ () => this.submitFormHandler() } >Resend code</a>
                                        </div> */}
                                        <ul className="nav nav-tabs nav-tabs-custstylere">
                                            <li className="nav-item nave-item-custstylers sign-up">
                                            Not received your code? <span className="nav-link nav-link-cust-styler" onClick={ () => this.resendloginotp() } > Resend code</span>
                                            </li>
                                        </ul>


                                        {this.state.show_logingerror_msg === true ? (
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.loginnerror}
                                            </div>
                                        ):(
                                            <></>
                                        )}
                                        </>
                                    // </form>
                                    ):(
                                    <>
                                    {this.state.show_forgetpassword === false ? (
                                        <div className="card-text">
                                            <div className="form-group form-group-custstyler">
                                                <label htmlFor="loginusername" className="w-100">Email / Mobile number</label>
                                                <input type="text" onChange={this.onChangeHandler} name="loginusername" className="form-control form-control-sm" 
                                                value={this.state.loginusername} id="loginusername" placeholder="Enter Email/Mobile number" />
                                                <p className="text-tran-clas"><b>Note * : </b> Mobile number the format to be entered is 55xxxx958 </p>
                                                <span className="error">
                                                {this.state.loginusernameError}
                                                </span>
                                            </div>
                                            <div className="form-group form-group-custstyler">
                                                <label htmlFor="loginpassword">Password</label>
                                                <input type="password" onChange={this.onChangeHandler} name="loginpassword" className="form-control form-control-sm" 
                                                value={this.state.loginpassword} id="loginpassword" placeholder="Enter Password" />
                                                <span className="error">
                                                    {this.state.loginpasswordError}
                                                </span>
                                            </div>
                                            <div className="form-group form-group-custstyler">
                                                    <label>
                                                        <input type="checkbox" name="rememberMe" />
                                                            <i className="a-icon a-icon-checkbox"></i>
                                                             &nbsp; Keep me signed in.
                                                    </label>
                                                <span onClick={ () => this.forgetpasswords() } className="forget-text">Forgot password?</span>
                                            </div>
                                            <button type="button" onClick={ () => this.loginFormHandler() } className="btn btn-primary btn-block btn-block-custmstyler form-group-custstyler">Login</button>
                                            <p className="text-center">-- OR --</p>
                                            <button type="button" onClick={ () => this.sendotp() } className="btn btn-secondary btn-block btn-block-custmstyler form-group-custstyler">Request OTP</button>
                                        </div>
                                        ):(
                                            <>
                                                {/* <div className="form-group form-group-custstyler">
                                                    <label >Email / Mobile number</label>
                                                    <span onClick={ () => this.hideloginotomodel() } className="forget-text"> Change ?</span>
                                                    <input type="text" readOnly  className="form-control form-control-sm"  value={this.state.loginusername}  />
                                                </div> */}
                                                <div className="form-group">
                                                    <p className="loginotp_msgstyle">{this.state.LoginOtp_msg} <span className="hideloginotomodel_changebtn " onClick={ () => this.hideloginotomodel() } > Change </span></p> 
                                                </div>
                                                <ul className="nav nav-tabs nav-tabs-custstylere">
                                                    <li className="nav-item nave-item-custstylers">
                                                    Not received your code? <span className="nav-link nav-link-cust-styler" onClick={ () => this.resendloginotp() } > Resend code</span>
                                                    </li>
                                                </ul>
                                                <div className="form-group form-group-custstyler">
                                                    <label htmlFor="passwordotpcode">OTP</label>
                                                    <input type="text" onChange={this.onChangeHandler} name="passwordotpcode" className="form-control form-control-sm" 
                                                    value={this.state.passwordotpcode} id="passwordotpcode" placeholder="Enter OTP" />
                                                    <span className="error">
                                                        {this.state.passwordotpcodeError}
                                                    </span>
                                                </div>
                                                <div className="form-group form-group-custstyler">
                                                    <label htmlFor="setpasswordnew">Set Password</label>
                                                    <input type="password" onChange={this.onChangeHandler} name="setpasswordnew" className="form-control form-control-sm" 
                                                    value={this.state.setpasswordnew} id="setpasswordnew" placeholder="Enter Password" />
                                                    <span className="error">
                                                        {this.state.setpasswordnewError}
                                                    </span>
                                                </div>
                                                <button type="button" onClick={ () => this.loginwithupdatepassword() } className="btn btn-primary btn-block btn-block-custmstyler form-group-custstyler">Login</button>
                                            </>
                                        )}

                                        <ul className="nav nav-tabs nav-tabs-custstylere" id="myTab" role="tablist">
                                            <li className="nav-item nave-item-custstylers sign-up">
                                            New to Yallabasket?<a className="nav-link nav-link-cust-styler" onClick={ () => this.loginmodal("signup") } id="Signup-tab" data-toggle="tab" href="#Signup" role="tab" aria-controls="Signup" aria-selected="false"> Create an account</a>
                                            </li>
                                        </ul>

                                        {this.state.show_passwordlogerror_msg === true ? (
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.loginpassworderror}
                                            </div>
                                        ):(
                                            <></>
                                            
                                        )}
                                        {this.state.show_loginerror_msg === true ? (
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.loginerror}
                                            </div>
                                        ):(
                                            <></>
                                        )}

                                    </>
                                    )}



                                    </div>
                                    <div className="tab-pane fade" id="Signup" role="tabpanel" aria-labelledby="Signup-tab">
                                        <div className="card-text">
                                        {this.state.show_signupform === true ? (
                                            <>
                                                <div className="form-group mb-0">
                                                    <label htmlFor="username">Full Name</label>
                                                    <input type="text" onChange={this.onChangeHandler} name="username" value={this.state.username} className="form-control form-control-sm" id="username" placeholder="Enter Username" />
                                                    <span className="error">
                                                        {this.state.usernameError}
                                                    </span>
                                                </div>
                                                <div className="form-group  mb-0">
                                                    <label htmlFor="email">Email ID</label>
                                                    <input type="email" onChange={this.onChangeHandler} name="email" value={this.state.email} className="form-control form-control-sm" id="email" placeholder="Enter Email ID" onBlur={ () => this.handleBlur('email')} />
                                                    <span className="error">
                                                        {this.state.emailError}
                                                    </span>
                                                </div>
                                                <div className="form-group  mb-0">
                                                    <label className="form_mobile_num_code_lable" htmlFor="mobile_no">Mobile Number</label>
                                                    <input type="text" onChange={this.onChangeHandler} name="mobile_no_code" className="form-control form-control-sm form_mobile_num_code" id="mobile_no_code" value={this.state.mobile_no_code} readOnly />
                                                    <input type="number"  onChange={this.onChangeHandler} name="mobile_no" value={this.state.mobile_no} 
                                                    className="form-control form-control-sm form_mobile_num" id="mobile_no" placeholder="Enter Mobile Number"  
                                                    onBlur={ () => this.handleBlur('mobile')}/>
                                                    <span className="error">
                                                        {this.state.mobileError}
                                                    </span> 
                                                </div>
                                                <div className="form-group  mb-0">
                                                    <label htmlFor="password">Password</label>
                                                    <input type="password" onChange={this.onChangeHandler} name="password"  value={this.state.password} className="form-control form-control-sm" id="password" placeholder="Enter Password" />
                                                    <span className="error">
                                                        {this.state.passwordError}
                                                    </span>
                                                </div>
                                                <div className="form-group mt-1">
                                                    <label htmlFor="user_referral_code">Have a referral code ? ( optional )</label>
                                                    <input type="text" onChange={this.onChangeHandler} name="user_referral_code" value={this.state.user_referral_code} className="form-control form-control-sm" id="username" placeholder="Enter referral code" onBlur={ () => this.handleBlur('referral')} />
                                                    <span className="error">
                                                        {this.state.user_referral_codeError}
                                                    </span>
                                                </div>
                                                <button type="button" onClick={ () => this.submitFormHandler() } id="submitFormHandler" className="btn btn-primary btn-block btn-block-custmstyler">Sign up</button>
                                                {/* <div className="sign-up">
                                                    Already have an account? <a href="#Login">Sign in</a>
                                                </div> */}
                                            </>
                                            ):(
                                                <></>
                                            )}


                                            {this.state.show_otp === true ? (

                                            <>
                                                <div className="form-group">
                                                     <p>{this.state.Otp_msg}</p>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="username">OTP</label>
                                                    <input type="text" onChange={this.onChangeHandler} name="otp_code" className="form-control form-control-sm" 
                                                    value={this.state.otp_code} id="otp_code" placeholder="Enter OTP"/>
                                                    <span className="error">
                                                        {this.state.otp_codeError}
                                                    </span>
                                                </div>
                                                <div className="form-group">
                                                    <div className="custcss_send_otp">
                                                        <p onClick={ () => this.submitFormHandler() } className="text-right custom-send-otp c-pointer">Resend OTP</p>
                                                    </div>
                                                         
                                                </div>

                                                <button type="button" onClick={ () => this.verifyotpandinsert() } className="btn btn-secondary btn-block btn-block-custmstyler">Sign up</button>
                                                {/* <div className="sign-up">
                                                    Already have an account? <a href="#Login">Sign in</a>
                                                </div> */}
                                                {this.state.show_error_msg === true ? (
                                                    <div className="alert alert-danger" role="alert">
                                                        {this.state.signuperror}
                                                    </div>
                                                ):(
                                                    <></>
                                                )}
                                                
                                            </>
                                        ):(
                                            <></>
                                        )}


                                        </div>

                                        <ul className="nav nav-tabs nav-tabs-custstylere" id="myTab" role="tablist">
                                            <li className="nav-item nave-item-custstylers">
                                            Existing User? <a className="nav-link nav-link-cust-styler" onClick={ () => this.loginmodal("signin") } id="Login-tab" data-toggle="tab" href="#Login" role="tab" aria-controls="Login" aria-selected="true">Log in</a>
                                            </li>
                                            {/* <li className="nav-item nave-item-custstylers sign-up">
                                            New to Yallabasket?<a className="nav-link nav-link-cust-styler" onClick={ () => this.loginmodal("signup") } id="Signup-tab" data-toggle="tab" href="#Signup" role="tab" aria-controls="Signup" aria-selected="false"> Create an account</a>
                                            </li> */}

                                            {/* <li className="nav-item">
                                                <a className="nav-link" onClick={ () => this.loginmodal("signup") } id="Signup-tab" data-toggle="tab" href="#Signup" role="tab" aria-controls="Signup" aria-selected="false">Signup</a>
                                            </li> */}
                                        </ul>

                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default LoginModal