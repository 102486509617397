import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();
let mobilemenu = '';

class MobileMenu extends Component {

     constructor(){
		super();
		this.state = {
            responseValue: [],
            Redirecturl:false
        };
		this.getMainDepartments = this.getMainDepartments.bind(this);
    }
    
    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' || 
            user_auth_key === "undefined" || user_auth_key === "null") 
        {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
        window.scrollTo(0, 0)
        this.getMainDepartments();
    }

    opensubmeny(val)
    {
        var i = document.getElementById('openmainmenu'+val).closest("li");
        var n = document.getElementById('opensubmenu'+val);
        // console.log(i.classList.contains("open"));

        if(i.classList.contains("open"))
        {
            i.classList.remove("open");
            n.classList.add("menudiaplynone");
            n.classList.remove("menudiaplyblock");
        }
        else
        {
            i.classList.add("open");
            n.classList.remove("menudiaplynone");
            n.classList.add("menudiaplyblock");
        }
    }
    
    opensubcategoryy(val)
    {
      var i = document.getElementById('opensubmenu'+val).closest("li");
      var n = document.getElementById('opensubcatmenu'+val);

      if(i.classList.contains("open"))
      {
          i.classList.remove("open");
          n.classList.add("menudiaplynone");
          n.classList.remove("menudiaplyblock");
      }
      else
      {
          i.classList.add("open");
          n.classList.remove("menudiaplynone");
          n.classList.add("menudiaplyblock");
      }


    }


    dec2hex (dec) 
    {
        return dec < 10
        ? '0' + String(dec)
        : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId (len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    async getMainDepartments() {
        const mainjsonValue = { user_unique_id: localStorage.getItem('user_auth_key') };
        await axiosInstance.post(`getMainDepartments`, mainjsonValue)
            .then(res => {
                if (res.data.status === 'success' && res.data.statusCode === '200') {
                    let response = res.data.main_departments;
                    // let ProductDetails = ProductValues.map(product_response => { return product_response; });
                    let Details = response.map(values_response => { return values_response; });
                    this.setState({ responseValue: Details });
                }
            })
            .catch(err => console.log('Error: ', err));
    }

    logout(){
        localStorage.removeItem('yallabasketuser');
        localStorage.removeItem('user_auth_key');
        window.location.href = "https://www.yallabasket.com/";
        localStorage.removeItem('yallabasketuser');
        localStorage.removeItem('user_auth_key');
        // cookies.remove('yallabasketuser');
        // cookies.remove('user_auth_key');
    }


    closemenu(val1,val2,val3)
    {
        if(val2!=='empty' && val1!=='empty')
        {
            if(val3==='main')
            {                
                this.opensubmeny(val1);
            }
            else if(val3==='sub')
            {
                this.opensubmeny(val1);
                this.opensubcategoryy(val2);
            }
        }
        mobilemenu.click();
    }
    

    render() {
        


        return (
            <div className="mobile-menu-container">
                <div className="mobile-menu-wrapper">
                <input type="hidden" className="mobile-menu-close" ref={input => { mobilemenu = input; }} />
                    <span className="mobile-menu-close"><i className="icon-retweet"></i></span>
                    <nav className="mobile-nav">
                        <div className="custom-flex">
                            <div className="col-6">
                                <div className="custom-card">
                                    <span className="mobile-top-span"><i className="fa fa-shipping-fast"></i></span>
                                    <p>Free Delivery</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="custom-card">
                                    <span className="mobile-top-span"><i className="fa fa-credit-card"></i></span>
                                    <p>No Pre-Payment</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="custom-card">
                                    <span className="mobile-top-span"><i className="icon-mini-cart"></i></span>
                                    <p>No Minimum Order</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="custom-card">
                                    <span className="mobile-top-span"><i className="fa fa-clock"></i></span>
                                    <p>Delivery Time : 6 - 11 PM</p>
                                </div>
                            </div>
                        </div>
                        
                        <ul className="mobile-menu">
                            {
                                this.state.responseValue.map((main_dep) => (
                                <li key={"mainnned"+main_dep.mdId} className=" mobile-menu-name">
                                    <p id={"openmainmenu"+main_dep.md_code} onClick={() => this.opensubmeny(main_dep.md_code) } >
                                                {main_dep.mdName} <span className="mmenu-btn"></span>
                                    </p>
                                    <ul id={"opensubmenu"+main_dep.md_code} >
                                        {main_dep.sub_departments.map(sub_dep => ( 
                                        <li  key={"subdepteded"+sub_dep.sdId} className="sub_dept_menu_style">
                                            <p  onClick={() => this.opensubcategoryy(sub_dep.sd_code) }  id={"opensubmenu"+sub_dep.sd_code}>
                                            {sub_dep.sdName}
                                            {/* <a className="pad-r-1" onClick={() => this.opensubcategoryy() } >{sub_dep.sdName}<span className=" subcategorry "></span></a> */}
                                             <span className="subcategorry"></span> 
                                            </p>
                                            <ul id={"opensubcatmenu"+sub_dep.sd_code}>
                                                {
                                                sub_dep.main_category.map(main_cat => ( 
                                                <li key={"maincateded"+main_cat.mcId} className="mobile-menu-name">
                                                   <Link onClick={() =>this.closemenu(main_dep.md_code,sub_dep.sd_code,'sub')} to={{ pathname: '/'+main_dep.md_url_code+'/'+sub_dep.sd_url_code+'/'+main_cat.mc_url_code+'/'+main_cat.mcId }} >{main_cat.mcName}</Link>
                                                </li>
                                             ))}

                                                {/* <li><a href="product.html">Horizontal Thumbnails</a></li>
                                                <li><a href="product-full-width.html">Vertical Thumbnails<span className="tip tip-hot">Hot!</span></a></li>
                                                <li><a href="product.html">Inner Zoom</a></li>
                                                <li><a href="product-addcart-sticky.html">Addtocart Sticky</a></li>
                                                <li><a href="product-sidebar-left.html">Accordion Tabs</a></li> */}
                                            </ul>
                                        </li>
                                     ))}
                                    </ul>
                                </li>

                                // <li  className="" key={main_dep.md_code}>
                                //     <p>{main_dep.mdName}<span className="mmenu-btn"></span></p>
                                //     {
                                //         main_dep.sub_departments.length === 0 ? (
                                //             null
                                //         ) : ( 
                                //             <ul>
                                //                 {main_dep.sub_departments.map(sub_dep => ( 
                                //                     <li  className="" key={sub_dep.sd_code}>
                                //                          {sub_dep.sdName}  <span className="mmenu-btn"></span> 
                                //                         {
                                //                             sub_dep.main_category.length === 0 ? (
                                //                                 null
                                //                             ) : (
                                //                             <ul>
                                //                             {
                                //                                 sub_dep.main_category.map(main_cat => ( 
                                //                                 <li key={main_cat.mc_code}>
                                //                                     <Link onClick={this.closemenu} to={{ pathname: '/'+main_cat.mcName+'/'+main_cat.md_code+'/'+main_cat.sd_code+'/'+main_cat.mc_code }} className="mobile-menu-name">{main_cat.mcName}</Link>
                                //                                 </li>
                                //                             ))}
                                //                             </ul>
                                //                         )}
                                //                     </li>
                                //                 ))}
                                //             </ul>
                                //             )
                                //     }
                                // </li>
                            ))}
                        </ul>
                    </nav>

                    <div className="social-icons">
                        <span className="social-icon" target="_blank"><i className="icon-facebook"></i></span>
                        <span className="social-icon" target="_blank"><i className="icon-twitter"></i></span>
                        <span className="social-icon" target="_blank"><i className="icon-instagram"></i></span>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileMenu
