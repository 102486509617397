import React, { Component } from 'react';

class Privacypolicy extends Component {

    componentDidMount() {
        this.selector = "#card5";
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
    }
    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }
    render() {

        return (

            <main className="main home">


                <div className="page-header page-header-bg">
                    <div className="container">
                        <h1 className="beck_head">Frequently Asked Questions</h1>
                    </div>
                </div>

                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        <ol className="breadcrumb">
                            {/* <li className="breadcrumb-item"><a href="index.html"><i className="icon-home"></i></a></li>
                            <li className="breadcrumb-item active" aria-current="page">
                            about us</li> */}
                        </ol>
                    </div>
                </nav>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="company-section" id='card2' >
                                <div className="container">
                                    <div className="row align-items-lg-center">
                                        <div className="col-md-12 padding-left-lg">
                                            <p><b>What is www.YallaBasket.com?</b></p>
                                            <p className="pl-20">
                                                Yalla Basket is an online grocery startup that aims at providing the best quality products. Our team understands the pain involved in the grocery shopping journey and truly believes in offering a service that will put a smile on the customer's face!
                                            </p>
                                            <p><b>Why should I order from Yalla Basket?</b></p>
                                            <p className="pl-20">
                                                We procure everyday fresh products straight from the market. The products reach customer’s doorsteps from the market within 24 hours of its shelf life. Customer gets to taste the freshness of the product. (You can experience the Crunchiness of blueberries, Freshness and lettuce and much more!)
                                            </p>
                                            <p><b>Does Yalla Basket offer free shipping?</b></p>
                                            <p className="pl-20">
                                                Yes. Currently, we offer free delivery of our products all over Dubai. We are working on starting our delivery across UAE Shortly.
                                            </p>
                                            <p><b>What is your minimum order limit?</b></p>
                                            <p className="pl-20">
                                                We don't have any minimum order limit on Yalla Basket. We will deliver even if you order a product worth 1 AED.
                                            </p>
                                            <p><b>How does product subscription work?</b></p>
                                            <p className="pl-20">
                                                Our Subscription model provides you a simple and effortless way of shopping. With Subscription you can finish your shopping within 2 minutes!<br></br>
                                                It allows you to plan your weekly grocery without having to commit. You do not have to spend much time (except for the time setting up the basket for the first time)<br></br>
                                                It allows you to plan your weekly grocery without having to commit. You do not have to spend much time (except for the time setting up the basket for the first time)
                                            </p>
                                            <p><b>How is your customer service?</b></p>
                                            <p className="pl-20">
                                                Customers come first for us. We ensure that you are satisfied with your purchases from us. We ensure that we standby you through your complete journey. We are always accessible by WhatsApp or through email. On every purchase, you will get a follow up message from our team to ensure you are happy with your previous delivery!
                                            </p>
                                            <p><b>Is there any coupon code option?</b></p>
                                            <p className="pl-20">
                                                Yes, we do have option to use coupon codes. A Valid Coupon code that you have can be applied at the checkout page.
                                            </p>

                                            <h2 className="subtitle">App</h2>
                                            <p><b>Do you have an app?</b></p>
                                            <p className="pl-20">
                                                Yes,  <a href="https://play.google.com/store/apps/details?id=com.buyer.yallabasket" target="_blank" rel="noopener noreferrer nofollow"> <b>click here</b> </a> for our Android app and <a href="https://apps.apple.com/ae/app/yalla-basket/id1570948340" target="_blank" rel="noopener noreferrer nofollow"> <b>click here</b> </a> for the IOS (iPhone) app.
                                            </p>

                                            <h2 className="subtitle">Orders & Delivery</h2>
                                            <p><b>How do I place an order?</b></p>
                                            <p className="pl-20">
                                                Your best source for getting the freshest groceries in town is your Yalla Basket. You can place an order with us either through our app or through our website or even on WhatsApp (+971 56 6339179)!<br></br>
                                                At the time of placing an order, you do not have to prepay for your orders. You pay during the time of delivery after checking the products and ensuring that it is satisfactory.
                                            </p>
                                            <p><b>Can I order through an App?</b></p>
                                            <p className="pl-20">
                                                Absolutely! We have Yalla Basket App available for both Android <a href="https://play.google.com/store/apps/details?id=com.buyer.yallabasket" target="_blank" rel="noopener noreferrer nofollow"> <b>click here</b> </a> for our Android app and <a href="https://apps.apple.com/ae/app/yalla-basket/id1570948340" target="_blank" rel="noopener noreferrer nofollow"> <b>click here</b> </a> for the IOS (iPhone) app.
                                            </p>
                                            <p><b>Can I choose the delivery time?</b></p>
                                            <p className="pl-20">
                                                You get the flexibility to select the date with us, However the delivery slot is currently fixed between 6pm to 11pm. In case if you want a specific delivery slot between 6pm to 11pm, you can request us (or just mention it in the comment box while checking out) and we will be happy to take it into account based on our availabilities.
                                            </p>
                                            <p><b>How do I request a product which is not on the Website/App?</b></p>
                                            <p className="pl-20">
                                                If you are not able to find a product that you are looking for on our site, do not worry!<br></br>We’ve made things easy by having an option to request the product where in you can input the product details like name, size and quantity and the same gets added to the cart!
                                            </p>
                                            <p><b>Can I order a single item?</b></p>
                                            <p className="pl-20">
                                                Yes, you can order a single item, and we will deliver it for free!
                                            </p>
                                            <p><b>Does Yalla Basket Offer Same Day Delivery?</b></p>
                                            <p className="pl-20">
                                                Yes, we offer same-day delivery of our products all over Dubai. Our delivery slots are between 6:00 PM and 11:00 PM every day. Currently, we have a finite number of delivery slots. Orders placed before 11:00 AM will qualify for the same-day free delivery in the evening based on the slot availability. The orders that are placed post 11:00 AM will be eligible for next-day free delivery.
                                            </p>
                                            <p><b>Does Yalla Basket Deliver on Weekends?</b></p>
                                            <p className="pl-20">
                                                Yes, we deliver all days of the week.
                                            </p>
                                            <p><b>How can I order from a different address?</b></p>
                                            <p className="pl-20">
                                                While Checking out, mention your desired location (within Dubai). And we will deliver it to that place. You can prefer the delivery to be done on a different address for one or multiple instances also. Which means you can add multiple addresses & prefer get the delivery at different locations.
                                            </p>
                                            <p><b>How to know if my order is confirmed?</b></p>
                                            <p className="pl-20">
                                                You will receive a message once you confirm your order.
                                            </p>
                                            <p><b>How to check the status of my orders?</b></p>
                                            <p className="pl-20">
                                                You can use the "Orders" option on the Account tab of the website/app to check the progress of your orders. You can see multiple stages- if the order is being packed or was dispatched etc.
                                            </p>
                                            <h2 className="subtitle">Delivery</h2>
                                            <p><b>What are your delivery hours?</b></p>
                                            <p className="pl-20">
                                                Our delivery slots are between 6:00 PM and 11:00 PM every day. Currently, we have a finite number of delivery slots. Orders placed before 11:00 AM will qualify for the same-day free delivery in the evening based on the slot availability. The orders that are placed post 11:00 AM will be eligible for next-day free delivery. You can also schedule a delivery for a later date up to a range of the next 7 days.
                                            </p>
                                            <p><b>Do you Deliver outside Dubai?</b></p>
                                            <p className="pl-20">
                                                Currently, we deliver to the locations inside Dubai. However, we are currently working on increasing the radius of our delivery to other Emirates
                                            </p>
                                            <p><b>Can my friend/family receive my order on my behalf?</b></p>
                                            <p className="pl-20">
                                                Yes, we deliver to your mentioned address, whoever collects the order get to pay after inspecting the delivery.
                                            </p>
                                            <p><b>How do you deliver?</b></p>
                                            <p className="pl-20">
                                                Yalla Basket offers door delivery across the Dubai Area. We have our inhouse delivery team which handles the delivery of your orders timely fashion.
                                            </p>
                                            <h2 className="subtitle">Cart</h2>
                                            <p><b>How do I view what's in my shopping cart?</b></p>
                                            <p className="pl-20">
                                                To view your shopping cart, <a href="https://www.yallabasket.com/Cart" target="_blank" rel="noopener noreferrer nofollow"> <b>click here</b> </a>.
                                            </p>
                                            <p><b>How do I add items to my cart?</b></p>
                                            <p className="pl-20">
                                                Click "add to basket" on your desired item on that individual product page OR from the home page
                                            </p>
                                            <h2 className="subtitle">Payment</h2>
                                            <p><b>How do I pay for my orders? Should I pre-pay my order?</b></p>
                                            <p className="pl-20">
                                                As per the company policy we do not force or expect our customers to pay upfront for the orders. You are free to open the bag, check the products, inspect and pay for the items only if you are satisfied.
                                            </p>
                                            <p><b>Do you accept cash on Delivery? Do you accept Debit/Credit Cards?</b></p>
                                            <p className="pl-20">
                                                You have a wide array of payment options. You can choose to pay by cash, card, or Apple pay based on your convenience at the time of delivery.
                                            </p>
                                            <p><b>Do you accept Cryptocurrency?</b></p>
                                            <p className="pl-20">
                                                No, we don't accept Cryptocurrency yet.
                                            </p>
                                            <p><b>Will I receive a receipt for the items bought? If yes, where?</b></p>
                                            <p className="pl-20">
                                                Yes, you will receive an electronically generated copy of the bill to your registered email address.
                                            </p>
                                            <h2 className="subtitle">Cancellation, Returns & Refunds:</h2>
                                            <p><b>What is the refund or exchange policy for Yalla Basket Delivery?</b></p>
                                            <p className="pl-20">
                                                Since we love our customers, Upon the receipt of delivery our customer care team will check with you on the feedback of the delivery. <br></br>
                                                In case if an issue is highlighted, we will personally look into the issue and replace the product or fix the particular issue to ensure that you are happy with our services!
                                            </p>
                                            <p><b>Does Yalla Basket Offer Any Return Policy?</b></p>
                                            <p className="pl-20">
                                                Yes. We will replace the product if it is damaged or if you have received wrong products. We follow a strict quality check on all our products before dispatch. However, if any of the products delivered doesn’t meet your expectations, we replace the products till you feel satisfied with it!
                                            </p>
                                            <p><b>How can I re-order a previous order?</b></p>
                                            <p className="pl-20">
                                                To re-order any of your previous orders, follow the same procedure as an existing customer or login to your account and proceed. Go to Account-Orders-Click on the "Reorder" button, and you are done!
                                            </p>
                                            <p><b>I Ordered/Received the wrong item by mistake. What should I do now?</b></p>
                                            <p className="pl-20">
                                                In case of a wrong item being delivered, please do not worry, our team would ensure that your issue is fixed within 24 hours. Contact us at hello@yallabasket.com or simply Whatsapp us: +971 56 6339179 and we will rectify your order ASAP.
                                            </p>
                                            <p><b>I cannot receive my delivery due to no one at home to accept it. What should I do?</b></p>
                                            <p className="pl-20">
                                                In case of a change of plan, do not worry, you can contact our team through WhatsApp (+971 56 6339179) and we will modify the delivery timing based on your preferences to the available slot.
                                            </p>
                                            <p><b>I did not receive the order confirmation email?</b></p>
                                            <p className="pl-20">
                                                In case if the order confirmation email was not sent to you, you will receive a system generated SMS on your order as a backup. If it is not, please contact us at hello@yallabasket.com or Whatsapp us: +971 56 6339179.
                                            </p>
                                            <p><b>I did not receive my order despite the tracking number indicating a delivery.</b></p>
                                            <p className="pl-20">
                                                In case of such a situation, please be assured that it might be a technical error. Please Highlight such issues at hello@yallabasket.com or Whatsapp us: +971 56 6339179. We will fix it ASAP.
                                            </p>

                                            <h2 className="subtitle">Contact</h2>
                                            <p><b>How do I contact Yalla Basket?</b></p>
                                            <p className="pl-20">
                                                Email : hello@yallabasket.com<br></br>
                                                Whatsapp us: +971 56 6339179
                                            </p>
                                            <p><b>How do I contact for business enquiries?</b></p>
                                            <p className="pl-20">
                                                Email : hello@yallabasket.com<br></br>
                                                Whatsapp us: +971 56 6339179
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

export default Privacypolicy