import React, { Component } from 'react';
// import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";
import JsonLd from 'jsonld';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
// import Cookies from 'universal-cookie';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Carousel, { Modal as Modall, ModalGateway } from 'react-images';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ProdCarousel } from 'react-responsive-carousel';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// const cookies = new Cookies();

const options = {
    margin: 10,
    responsiveClass: true,
    loop: false,
    slideBy: 1,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 2,
        },
        400: {
            items: 2,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1000: {
            items: 5,

        }
    },
};

class ProductDescription extends Component {

    constructor() {
        super();
        this.state = {
            productDetails: [],
            productDetails_popup: [],
            relatedproductValues: [],
            product_imagesValues: [],
            open: false,
            clicks: 1,
            clicks_pd: 1,
            show: false,
            show_pd: false,
            total_weight: '',
            total_price: '',
            spinner: 'loading-overlay',
            small_spinner: 'ajax-overlay hide-content',
            modalIsOpen: false,
            singleprodname: "",
            singleprodimage: "",
            singleproddescription: "",
            singleprodurl: "",
            singleprodprice: "",

            subscriptionopen: false,
            subscription_weekly: [],
            subscription_biweekly: [],
            subscription_monthly: [],
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            showsubscriptionerrormessage: false,
            subscription_errormsg: "",
            subscription_prod_id: "",
            subscription_prod_code: "",
            Sun_quantity: 0,
            Mon_quantity: 0,
            Tue_quantity: 0,
            Wed_quantity: 0,
            Thu_quantity: 0,
            Fri_quantity: 0,
            Sat_quantity: 0,
            subscription_produ_imgurl: "",
            subscription_produ_category: "",
            subscription_produ_name: "",
            subscription_produ_brand: "",
            subscription_produ_umo: "",
            seotitle: "",
            seodescription: "",

        };
        this.getProductDetails = this.getProductDetails.bind(this);
        this.showslider = this.showslider.bind(this);
        this.getweeklysubscriptionvalues = this.getweeklysubscriptionvalues.bind(this);
        this.getbiweeklysubscriptionvalues = this.getbiweeklysubscriptionvalues.bind(this);
        this.getmonthlysubscriptionvalues = this.getmonthlysubscriptionvalues.bind(this);
    }

    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    }

    showslider = (values) => {
        const images = [{ source: values }];
        this.setState({ modalIsOpen: true, images_source: images });
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' ||
            user_auth_key === "undefined" || user_auth_key === "null") {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
        let spinner = 'loading-overlay';
        this.setState({ spinner });
        window.scrollTo(0, 0)
        var url = window.location.href;
        var parts = url.split('/');
        var lastSegment = parts.pop() || parts.pop();

        if (lastSegment !== "") 
        {
            this.getProductDetails(parts[7]);
        }
        // console.log(routesEqual);
        //  && forceRefresh
    }

    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    componentDidUpdate(prevProps, prevState) {

        var url = window.location.href;
        var parts = url.split('/');
        // var lastSegment = parts.pop() || parts.pop(); 
        if (prevState.spinner === 'loading-overlay hide-content') {
            if (prevState.productDetails.length !== 0) 
            {
                if (prevState.productDetails[0].prod_url_param !== parts[7]) 
                {
                    prevState.productDetails[0].prod_url_param = parts[7];
                    this.reload();
                }
            }
        }

    }

    reload = () => {
        this.componentDidMount();
        // alert('hii');
    }

    onOpenModal = (id) => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = { pId: id };
        axiosInstance
            .post(`getindividualProductDetails`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let ProductValues = res.data.product_details;
                    let selling_price = res.data.selling_price;
                    let prod_quantity = res.data.prod_quantity;
                    let prod_idd = res.data.prod_id;

                    //   let prod_dtl_nutrition_info = res.data.prod_dtl_nutrition_info;
                    let ProductDetails = ProductValues.map(product_response => { return product_response; });
                    //   let RelatedDetails = RelatedValues.map(related_response => { return related_response; });
                    this.setState({ productDetails_popup: ProductDetails });
                    this.setState({ prod_quantity, selling_price, prod_idd })

                    this.setState({ open: true });
                }
            })
            .catch((err) => console.log("Error: ", err));


    };

    async getProductDetails(id) {
        const jsonValue = { pId: id, user_unique_id: localStorage.getItem('user_auth_key') };
        axiosInstance
            .post(`getProductDetails`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    let ProductValues = res.data.product_details;
                    let product_imagesValuess = res.data.product_images;
                    let selling_price_pd = res.data.selling_price;
                    let prod_quantity_pd = res.data.prod_quantity;
                    let prod_idd_pd = res.data.prod_id;

                    let subscription_weeklyvalues = res.data.subscription_details["subscription_weekly"];
                    let subscription_weeklydetails = subscription_weeklyvalues.map(subcat_response => { return subcat_response; });

                    let subscription_biweeklyvalues = res.data.subscription_details["subscription_biweekly"];
                    let subscription_biweeklydetails = subscription_biweeklyvalues.map(subcat_response => { return subcat_response; });

                    let subscription_monthlyvalues = res.data.subscription_details["subscription_monthly"];
                    let subscription_monthlydetails = subscription_monthlyvalues.map(subcat_response => { return subcat_response; });

                    let name = res.data.product_details[0]["prod_name"];
                    let image = res.data.product_details[0]["produ_imgurl"];
                    let description = res.data.product_details[0]["md_url_code"];
                    let product_seoDes = res.data.product_details[0]["seoDes"];
                    let product_seoTitle = res.data.product_details[0]["seoTitle"];
                    let url = "http://yallabasket.com:3002/" + res.data.product_details[0]["md_url_code"] + "/" + res.data.product_details[0]["sd_url_code"] + "/" + res.data.product_details[0]["mc_url_code"] + "/" + res.data.product_details[0]["sc_url_code"] + "/" + res.data.product_details[0]["prod_url_param"] + "/" + res.data.product_details[0]["prod_url_code"] + "";
                    let price = res.data.selling_price;


                    let RelatedValues = res.data.related_products;
                    let prod_dtl_nutrition_info = res.data.prod_dtl_nutrition_info;
                    let ProductDetails = ProductValues.map(product_response => { return product_response; });
                    let RelatedDetails = RelatedValues.map(related_response => { return related_response; });
                    let product_imagesValues = product_imagesValuess.map(related_response => { return related_response; });
                    this.setState({ productDetails: ProductDetails, product_imagesValues: product_imagesValues, relatedproductValues: RelatedDetails, prod_dtl_nutrition_info: prod_dtl_nutrition_info });
                    this.setState({
                        prod_idd_pd, selling_price_pd, prod_quantity_pd, singleprodname: name, singleprodimage: image, singleproddescription: description, singleprodurl: url, singleprodprice: price,
                        subscription_weekly: subscription_weeklydetails,
                        subscription_biweekly: subscription_biweeklydetails,
                        subscription_monthly: subscription_monthlydetails,
                        seotitle:product_seoTitle,
                        seodescription:product_seoDes
                    });
                    let spinner = 'loading-overlay hide-content';
                    this.setState({ spinner });
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                }
            })
            .catch((err) => console.log("Error: ", err));
    }


    onCloseModal = () => {
        //   alert('hii');
        this.setState({ open: false, clicks: 1 });
        this.setState({ total_weight: '', total_price: '' });
        this.setState({ show: false });
        // this.reload();
        // alert(this.state.open);
    };

    IncrementItem = () => {
        this.setState({ clicks: this.state.clicks + 1 });

        const total_clicks = this.state.clicks + 1
        if (total_clicks > 1) {
            const total_weight = total_clicks * this.state.prod_quantity;
            const total_price = this.state.selling_price * total_clicks;
            const final_total = parseFloat(total_price).toFixed(2);
            this.setState({ show: true, total_weight: total_weight, total_price: final_total, total_clicks: total_clicks });
        }
        else {
            this.setState({ show: false });
        }


    }

    DecreaseItem = () => {
        if (this.state.clicks > 1) {
            this.setState({ clicks: this.state.clicks - 1 });
            const total_clicks = this.state.clicks - 1;
            if (total_clicks > 1) {
                const total_weight = total_clicks * this.state.prod_quantity;
                const total_price = this.state.selling_price * total_clicks;
                const final_total = parseFloat(total_price).toFixed(2);
                this.setState({ show: true, total_weight: total_weight, total_price: final_total, total_clicks: total_clicks });
            }
            else {
                this.setState({ show: false });
            }
        }
        else {
            this.setState({ show: false });
        }
    }


    movetobasket = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        var total_weight = this.state.total_weight;
        if (this.state.total_weight === '') {
            total_weight = this.state.prod_quantity;
        }
        var total_price = this.state.total_price;
        if (this.state.total_price === '') {
            total_price = this.state.selling_price;
        }
        var total_clicks = this.state.total_clicks;
        if (this.state.total_clicks === undefined) {
            total_clicks = 1;
        }

        const jsonValue = {
            prod_id: this.state.prod_idd,
            prod_quantity: total_clicks,
            prod_price: this.state.selling_price,
            prod_weight: this.state.prod_quantity,
            prod_totalweight: total_weight,
            prod_totalprice: total_price,
            // prod_totalprice:total_price,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`inserttobasket`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.props.onIncrement();
                    let cart_count = res.data.cart_count;
                    this.setState({ cart_count: cart_count });
                    NotificationManager.success(res.data.quantity + ' X ' + res.data.weight + ' ' + res.data.uom + ' added sucessfully', res.data.pname, 200);
                    this.onCloseModal();
                    // confirmAlert({
                    //     title: 'Info',
                    //     message: 'Added to Basket',
                    //     color: 'green',
                    //     buttons: [
                    //       {
                    //         label: 'Ok',
                    //         onClick: () => {
                    //             this.onCloseModal();
                    //             // this.renderUpdate();
                    //         }   
                    //       }
                    //     ]
                    // });
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                    // confirmAlert({
                    //     title: 'Error',
                    //     message: 'Something went wrong',
                    //     color: 'red',
                    //     buttons: [
                    //       {
                    //         label: 'Ok',
                    //         onClick: () => {
                    //             // window.location.reload();
                    //         }
                    //       }
                    //     ]
                    // });
                }
            })
            .catch((err) => console.log("Login: ", err));
    }


    movetocart(prod_id, selling_price, prod_quantity) {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            prod_id: prod_id,
            prod_quantity: 1,
            prod_price: selling_price,
            prod_weight: prod_quantity,
            prod_totalweight: prod_quantity,
            prod_totalprice: selling_price,
            // prod_totalprice:total_price,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`inserttobasket`, jsonValue)
            .then((res) => {
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });

                if (res.data.status === "success" && res.data.statusCode === "200") {
                    // let user_auth_key = res.data.user_auth_key;
                    this.props.onIncrement();
                    NotificationManager.success(res.data.quantity + ' X ' + res.data.weight + ' ' + res.data.uom + ' added sucessfully', res.data.pname, 200);
                    // confirmAlert({
                    //     title: 'Info',
                    //     message: 'Added to Basket',
                    //     color: 'green',
                    //     buttons: [
                    //     {
                    //         label: 'Ok',
                    //         onClick: () => {
                    //         }   
                    //     }
                    //     ]
                    // });
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                    // confirmAlert({
                    //     title: 'Error',
                    //     message: 'Something went wrong',
                    //     color: 'red',
                    //     buttons: [
                    //     {
                    //         label: 'Ok',
                    //         onClick: () => {
                    //             // window.location.reload();
                    //         }
                    //     }
                    //     ]
                    // });
                }
            })
            .catch((err) => console.log("Login: ", err));
    }

    basketIncrementItem(prod_id, prodcode, prodprice, prodweight) {
        this.productaddtobasket(prod_id, prodcode, prodprice, prodweight);
    }
    basketDecreaseItem(prod_id, prodcode, prodprice, prodweight, cart_id) {
        let qty_value = document.getElementById(prodcode).value;
        if (qty_value > 1) {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            var quantity = 1;
            var total_weight = quantity * prodweight;
            var total_price = prodprice * quantity;
            var final_total = parseFloat(total_price).toFixed(2);
            const jsonValue = {
                prod_id: prod_id,
                prod_quantity: 1,
                prod_price: prodprice,
                prod_weight: prodweight,
                prod_totalweight: total_weight,
                prod_totalprice: final_total,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
                .post(`removebasketqty`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        this.addtobasketrefresh();
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
        else {
            let small_spinner = 'ajax-overlay';
            this.setState({ small_spinner });
            const jsonValue = {
                cart_id: cart_id,
                user_unique_id: localStorage.getItem('user_auth_key')
            };
            axiosInstance
                .post(`removeCartDetails`, jsonValue)
                .then((res) => {
                    let small_spinner = 'ajax-overlay hide-content';
                    this.setState({ small_spinner });
                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        this.props.onIncrement();
                        this.addtobasketrefresh();
                    }
                })
                .catch((err) => console.log("Error: ", err));
        }
    }
    productaddtobasket(prod_id, prodcode, prodprice, prodweight) {
        let prod_quantity = 1;
        let tol_prod_quantity = prod_quantity * prodweight;
        let tol_selling_price = prod_quantity * prodprice;
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        const jsonValue = {
            prod_id: prod_id,
            prod_price: prodprice,
            prod_quantity: prod_quantity,
            prod_weight: prodweight,
            prod_totalweight: tol_prod_quantity,
            prod_totalprice: tol_selling_price,
            user_unique_id: localStorage.getItem('user_auth_key'),
        };
        axiosInstance
            .post(`inserttobasket`, jsonValue)
            .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200") {
                    this.props.onIncrement();
                    this.addtobasketrefresh();
                    NotificationManager.success(res.data.quantity + ' X ' + res.data.weight + ' ' + res.data.uom + ' added sucessfully', res.data.pname, 200);
                }
                else if (res.data.status === "fail" && res.data.statusCode === "201") {
                    NotificationManager.error('Something went wrong', 'Error', 500);
                }
                let small_spinner = 'ajax-overlay hide-content';
                this.setState({ small_spinner });
            })
            .catch((err) => console.log("Login: ", err));
    }


    addtobasketrefresh() {
        var url = window.location.href;
        var parts = url.split('/');
        var lastSegment = parts.pop() || parts.pop();
        if (lastSegment !== "") {
            this.getProductDetails(parts[7]);
        }
    }


    // subscription model start here


    opensubscriptionmodel = (prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces,subscribe_offer_flag,subscribe_offer_type,subscribe_offer_value) => {
        var produ_umo_cust = "" + produ_umo + "";
        if (no_of_prod_pisces !== '' && no_of_prod_pisces !== null && no_of_prod_pisces !== ' ' && no_of_prod_pisces !== undefined) {
            produ_umo_cust = "" + produ_umo + " ( approx " + no_of_prod_pisces + ")";
        }
        var subscribe_offer_text = "";
        if(subscribe_offer_flag!==0 && subscribe_offer_flag !==null && subscribe_offer_flag!=='' && subscribe_offer_flag!==undefined)
        {
            if(subscribe_offer_type==='P')
            {
                subscribe_offer_text="Subscribe & save "+subscribe_offer_value+" %";
            }
            if(subscribe_offer_type==='A')
            {
                subscribe_offer_text="Subscribe & save AED "+subscribe_offer_value+"";
            }
        }

        this.setState({
            subscriptionopen: true,
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            subscription_prod_id: pId,
            subscription_prod_code: prod_code,
            Sun_quantity: 0,
            Mon_quantity: 0,
            Tue_quantity: 0,
            Wed_quantity: 0,
            Thu_quantity: 0,
            Fri_quantity: 0,
            Sat_quantity: 0,
            subscription_produ_imgurl: produ_imgurl,
            subscription_produ_category: produ_category,
            subscription_produ_name: produ_name,
            subscription_produ_brand: produ_brand,
            subscription_produ_umo: produ_umo_cust,
            subscribe_offer_text:subscribe_offer_text

        });
        setTimeout(function () {
            var tab1 = document.getElementById('subscription_tab_one');
            if (tab1 !== null && tab1 !== undefined) {
                tab1.classList.add("subscription_tab_active");
            }
            else {
                this.closesubscriptionmodel();
                this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces,subscribe_offer_flag,subscribe_offer_type,subscribe_offer_value);
            }
            var tab2 = document.getElementById('subscription_tab_two_content');
            if (tab2 !== null && tab2 !== undefined) {
                tab2.classList.add("d-none");
            }
            else {
                this.closesubscriptionmodel();
                this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces,subscribe_offer_flag,subscribe_offer_type,subscribe_offer_value);
            }
            var tab3 = document.getElementById('subscription_tab_three_content');
            if (tab3 !== null && tab3 !== undefined) {
                tab3.classList.add("d-none");
            }
            else {
                this.closesubscriptionmodel();
                this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces,subscribe_offer_flag,subscribe_offer_type,subscribe_offer_value);
            }

        }, 100);
    };

    showtabone = () => {
        document.getElementById('subscription_tab_one').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.add("d-none");
        document.getElementById('subscription_tab_three_content').classList.add("d-none");
        if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length > 0) {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }
    };
    showtabtwo = () => {
        document.getElementById('subscription_tab_one').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.add("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.add("d-none");
        if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }

    };
    showtabthree = () => {
        document.getElementById('subscription_tab_one').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_two').classList.remove("subscription_tab_active");
        document.getElementById('subscription_tab_three').classList.add("subscription_tab_active");
        document.getElementById('subscription_tab_one_content').classList.remove("d-none");
        document.getElementById('subscription_tab_two_content').classList.remove("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        document.getElementById('subscription_tab_one_content').classList.add("d-none");
        document.getElementById('subscription_tab_two_content').classList.add("d-none");
        document.getElementById('subscription_tab_three_content').classList.remove("d-none");
        if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
            this.setState({
                addtosubscribebtn: true
            });
        }
        else {
            this.setState({
                addtosubscribebtn: false
            });
        }
    };



    closesubscriptionmodel = () => {
        this.setState({
            subscriptionopen: false,
            weekly_quantity: 0,
            biweekly_quantity: 0,
            monthly_quantity: 0,
            weekly_selected_date: [],
            weekly_selected_quntity: [],
            biweekly_selected_date: [],
            monthly_selected_date: [],
            addtosubscribebtn: false,
            Sun_quantity: 0,
            Mon_quantity: 0,
            Tue_quantity: 0,
            Wed_quantity: 0,
            Thu_quantity: 0,
            Fri_quantity: 0,
            Sat_quantity: 0,
            subscription_produ_imgurl: "",
            subscription_produ_category: "",
            subscription_produ_name: "",
            subscription_produ_brand: "",
            subscription_produ_umo: "",
            subscribe_offer_text:"",
        });

    };

    subscription_quantity = (name, value, type, id) => {

        if (type === "add") {
            let addvalue = this.state[name] + 1;
            this.setState({
                [name]: addvalue
            }, () => {
                if (name !== "biweekly_quantity" && name !== "monthly_quantity") {
                    if (this.state[name] > 0) {
                        document.getElementById(id).parentElement.classList.add("subscription_day_lable_selected");
                        document.getElementById(id).checked = true;
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        document.getElementById(id).parentElement.classList.remove("subscription_day_lable_selected");
                        document.getElementById(id).checked = false;
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }

                    if (this.state.Sun_quantity > 0 || this.state.Mon_quantity > 0 || this.state.Tue_quantity > 0 || this.state.Wed_quantity > 0
                        || this.state.Thu_quantity > 0 || this.state.Fri_quantity > 0 || this.state.Sat_quantity > 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                    this.getweeklysubscriptionvalues();
                }
                if (name === "biweekly_quantity") {
                    if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                }
                if (name === "monthly_quantity") {
                    if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                        this.setState({
                            addtosubscribebtn: true
                        });
                    }
                    else {
                        this.setState({
                            addtosubscribebtn: false
                        });
                    }
                }
            });
        }
        if (type === "minus") {
            let minusvalue = this.state[name];
            if (minusvalue !== 0) {
                this.setState({
                    [name]: minusvalue - 1
                }, () => {
                    if (name !== "biweekly_quantity" && name !== "monthly_quantity") {
                        if (this.state[name] > 0) {
                            document.getElementById(id).parentElement.classList.add("subscription_day_lable_selected");
                            document.getElementById(id).checked = true;
                        }
                        else {
                            document.getElementById(id).parentElement.classList.remove("subscription_day_lable_selected");
                            document.getElementById(id).checked = false;
                        }

                        if (this.state.Sun_quantity > 0 || this.state.Mon_quantity > 0 || this.state.Tue_quantity > 0 || this.state.Wed_quantity > 0
                            || this.state.Thu_quantity > 0 || this.state.Fri_quantity > 0 || this.state.Sat_quantity > 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                        this.getweeklysubscriptionvalues();
                    }
                    if (name === "biweekly_quantity") {
                        if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                    }
                    if (name === "monthly_quantity") {
                        if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                            this.setState({
                                addtosubscribebtn: true
                            });
                        }
                        else {
                            this.setState({
                                addtosubscribebtn: false
                            });
                        }
                    }
                });
            }
        }
    };



    getweeklysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.classList.remove("subscription_day_lable_selected")
            }
        }

        var quantity_values = document.getElementsByClassName('subscription_weekly_quantity_value');
        const weekly_quantity = [];

        for (let j = 0; j < quantity_values.length; j++) {
            if (quantity_values[j].value > 0) {
                // console.log(quantity_values[j].value);
                weekly_quantity.push(quantity_values[j].value);
            }
        }

        this.setState({
            weekly_selected_date: value, weekly_selected_quntity: weekly_quantity
        }, () => {
            // console.log(this.state.biweekly_selected_date.length);
            if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length > 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });

    }

    getbiweeklysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable-biweekly');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.firstChild.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            }
        }

        this.setState({
            biweekly_selected_date: value
        }, () => {
            if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity !== 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });
    }

    getmonthlysubscriptionvalues(e) {

        const value = [];
        var chks = document.getElementsByClassName('visually-hidden-lable-monthly');
        // var results = [];
        for (var i = 0; i < chks.length; i++) {
            if (chks[i].checked) {
                chks[i].parentElement.firstChild.classList.add("subscription_day_lable_selected")
                value.push(chks[i].value);
            }
            else {
                chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            }
        }

        this.setState({
            monthly_selected_date: value
        }, () => {
            if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity !== 0) {
                this.setState({
                    addtosubscribebtn: true
                });
            }
            else {
                this.setState({
                    addtosubscribebtn: false
                });
            }
        });
    }

    clearselectedsubscription = (value) => {
        // alert(value);
        var chks = document.getElementsByClassName('' + value + '');
        // console.log(chks);
        for (var i = 0; i < chks.length; i++) {
            chks[i].checked = false;
            chks[i].parentElement.firstChild.classList.remove("subscription_day_lable_selected")
            // console.log(chks[i].checked)
        }
        this.setState({
            showsubscriptionerrormessage: false,
            subscription_errormsg: ""
        });
        if (value === "visually-hidden-lable-biweekly") {
            this.getbiweeklysubscriptionvalues();
        }
        else if (value === "visually-hidden-lable-monthly") {
            this.getmonthlysubscriptionvalues();
        }

    }


    submit_subscription = () => {
        let small_spinner = 'ajax-overlay';
        this.setState({ small_spinner });
        this.setState({
            showsubscriptionerrormessage: false,
            subscription_errormsg: ""
        });

        if (this.state.weekly_selected_date.length === 0 && this.state.weekly_selected_quntity.length !== 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabone();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.biweekly_selected_date.length === 0 && this.state.biweekly_quantity !== 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabtwo();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.monthly_selected_date.length === 0 && this.state.monthly_quantity !== 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Date or Clear the Quantity to proceed next"
            }, () => {
                this.showtabthree();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else if (this.state.weekly_selected_date.length > 0 && this.state.weekly_selected_quntity.length === 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabone();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.biweekly_selected_date.length > 0 && this.state.biweekly_quantity === 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabtwo();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }

        else if (this.state.monthly_selected_date.length > 0 && this.state.monthly_quantity === 0) {
            this.setState({
                showsubscriptionerrormessage: true,
                subscription_errormsg: "Select the Quantity or Clear the selected date to proceed next"
            }, () => {
                this.showtabthree();
            });
            let small_spinner = 'ajax-overlay hide-content';
            this.setState({ small_spinner });
        }
        else {
            const jsonValue = {
                weekly_selected_date: this.state.weekly_selected_date,
                weekly_quantity: this.state.weekly_selected_quntity,
                biweekly_selected_date: this.state.biweekly_selected_date,
                biweekly_quantity: this.state.biweekly_quantity,
                monthly_selected_date: this.state.monthly_selected_date,
                monthly_quantity: this.state.monthly_quantity,
                subscription_prod_id: this.state.prod_unit,
                subscription_prod_code: this.state.subscription_prod_code,
                user_unique_id: localStorage.getItem('user_auth_key'),
            };
            axiosInstance
                .post(`insersubscriptionproduct`, jsonValue)
                .then((res) => {

                    if (res.data.status === "success" && res.data.statusCode === "200") {
                        NotificationManager.success('Product Subscribed Sucessfully', 'Success', 200);
                        this.closesubscriptionmodel();
                        this.addtobasketrefresh();
                    }
                    else if (res.data.status === "fail" && res.data.statusCode === "201") {
                        let small_spinner = 'ajax-overlay hide-content';
                        this.setState({ small_spinner });
                        NotificationManager.error('Something went wrong', 'Error', 500);
                    }
                })
                .catch((err) => console.log("Login: ", err));
        }
    }
    

    // opensubscriptioncollaps =()=>
    // {
    //     setTimeout(function()
    //     { 
    //         var tab3 = document.getElementsByClassName('Collapsible')[0];
    //         if (tab3 !== null && tab3 !== undefined) {
    //             tab3.classList.add("Subscription_collaps");
    //         } 
    //     }, 10);   
    //     setTimeout(function () {
    //         var tab1 = document.getElementById('subscription_tab_one');
    //         if (tab1 !== null && tab1 !== undefined) {
    //             tab1.classList.add("subscription_tab_active");
    //         }
    //         else {
    //             this.closesubscriptionmodel();
    //             // this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces);
    //         }
    //         var tab2 = document.getElementById('subscription_tab_two_content');
    //         if (tab2 !== null && tab2 !== undefined) {
    //             tab2.classList.add("d-none");
    //         }
    //         else {
    //             this.closesubscriptionmodel();
    //             // this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces);
    //         }
    //         var tab3 = document.getElementById('subscription_tab_three_content');
    //         if (tab3 !== null && tab3 !== undefined) {
    //             tab3.classList.add("d-none");
    //         }
    //         else {
    //             this.closesubscriptionmodel();
    //             // this.opensubscriptionmodel(prod_code, pId, produ_imgurl, produ_category, produ_name, produ_brand, produ_umo, no_of_prod_pisces);
    //         }

    //     }, 100);
    // }

    // closesubscriptioncollaps =()=>
    // {
    //     alert('ko');
    // }



    render() {


        const prod_dtl_nutrition_info = this.state.prod_dtl_nutrition_info;
        const { modalIsOpen } = this.state;
        const singleprodname = this.state.singleprodname;
        const singleprodimage = this.state.singleprodimage;
        const singleproddescription = this.state.singleproddescription;
        const singleprodurl = this.state.singleprodurl;
        const singleprodprice = this.state.singleprodprice;

        let ProductSchemaData = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": singleprodname,
            "image": singleprodimage,
            "description": singleproddescription,
            "brand": "Yalla Basket",
            "logo": "http://yallabasket.com:3002/assets/img/logo.png",
            "offers": {
                "@type": "Offer",
                "url": singleprodurl,
                "priceCurrency": "AED",
                "price": singleprodprice
            }
        }

        


        return (
            <main className="main">
                <Helmet>
                        <title>{this.state.seotitle}</title>
                        <meta name="description" content={this.state.seodescription} />
                        <JsonLd data={ProductSchemaData} />
                </Helmet>

                <NotificationContainer />
                <div className={this.state.small_spinner}>
                    <i className="porto-loading-icon"></i>
                </div>
                <div className={this.state.spinner}>
                    <div className="bounce-loader">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div className="container">
                        {this.state.productDetails.map(values => (
                            <ol className="breadcrumb" key={values.prod_id}>
                                <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i> Home</Link></li>
                                <li className="breadcrumb-item"> <Link to={{ pathname: '/' + values.mc_url_code + '/' + values.sd_url_code + '/' + values.md_url_code + '/' + values.mc_url_param }}>
                                    <span>{values.mcName}</span></Link></li>
                                <li className="breadcrumb-item"><Link to={{ pathname: '/' + values.sc_url_code + '/' + values.mc_url_code + '/' + values.sd_url_code + '/' + values.md_url_code + '/' + values.sc_url_param }}>
                                    <span>{values.scName}</span></Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{values.prod_name}</li>
                            </ol>
                        ))}
                    </div>
                </nav>

                <div className="container ">
                    <div className="row row-sm">
                        <div className="col-lg-12 col-xl-10">
                            {this.state.productDetails.map(values => (
                                <div key={values.prod_id}>
                                    <div className="product-single-container product-single-default">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-8 product-single-gallery">
                                                {/* <div className="product-slider" > */}
                                                <ProdCarousel showStatus={false} showArrows={false} autoPlay={true} infiniteLoop={true}>
                                                    {this.state.product_imagesValues.map(valuesdd => (
                                                        <div >
                                                            <InnerImageZoom src={valuesdd.product_image_url} zoomSrc={valuesdd.product_image_url} alt="" />
                                                            {/* <img src={values.produ_imgurl} /> */}
                                                        </div>
                                                    ))}
                                                </ProdCarousel>
                                                {/* </div> */}


                                            </div>

                                            <div className="col-lg-7 col-md-6">
                                                <div className="product-single-details">
                                                    <h1 className="product-title">{values.prod_name}</h1>
                                                    <p className="mb-1">Category: {values.scName} 
                                                    {values.subacribed_prod === true || values.subacribed_prod === "true" ? (
                                                            <span className="single-product-label-subscribed">
                                                                Subscribed
                                                        </span>
                                                    ) : (
                                                            <></>
                                                        )}
                                                    </p>
                                                    <p className="mb-1">UOM: {values.prod_quantity} {values.unit_id} </p>
                                                    <p className="mb-1">Brand : {values.brand_name}</p>

                                                    {values.offer_flag === '1' ? (
                                                        <>
                                                            <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                                {values.market_price !== "0.00" ? (
                                                                    <>( AED {values.original_amt} )</>
                                                                ) : (
                                                                        <> </>
                                                                    )}
                                                            </s> <br />
                                                                <span className="product-save-discount">You Save :&nbsp; {values.offer_value}   {values.offer_type === 'P' ? (
                                                                    <> % </>
                                                                ) : (
                                                                        <> AED </>
                                                                    )} OFF</span>
                                                            </p>
                                                        </>
                                                    ) : (
                                                            <>
                                                                <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                                    {values.market_price !== "0.00" ? (
                                                                        <>( Avg. Market Price AED {values.market_price} )</>
                                                                    ) : (
                                                                            <> </>
                                                                        )}
                                                                </s> <br />
                                                                </p>
                                                            </>
                                                        )}

                                                        {values.outof_stock_flag === '1' ? (
                                                            <>
                                                               {localStorage.getItem('user_log_status') === "true" ? (
                                                                    <>
                                                                        {values.subacribed_prod === "true" ? (
                                                                            <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6 mt-3 mb-3  p-0">
                                                                                <Link to="/Subscription"><span className="product_details_subscription_view" >  View Subscription </span></Link>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6 mt-3 mb-3  p-0">
                                                                                <span className="product_details_subscription_view"
                                                                                onClick={() => this.opensubscriptionmodel(values.prod_code, values.prod_id, values.produ_imgurl, values.scName,
                                                                                    values.prod_name, values.brand_name, values.prod_quantity + values.unit_id, values.no_of_prod_pisces, values.subscribe_offer_flag, values.subscribe_offer_type, values.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6 mt-3 mb-3  p-0">
                                                                        <span className="product_details_subscription_view" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}

                                                    <div className="product-action product-all-icons mt-2">
                                                        {/* <div className="product-quantity">
                                                            <input  className="custom-quantity" data-min="1" data-max="0" type="text" name="quantity" value={ this.state.clicks_pd } readOnly={true} /><div className="quantity-selectors-container">
                                                            <div className="quantity-selectors">
                                                                <button type="button" onClick={this.IncrementItem_pd} aria-label="Add one" data-direction="1"><span>&#43;</span></button>
                                                                <button type="button"  onClick={this.DecreaseItem_pd} aria-label="Subtract one" data-direction="-1"><span>&#8722;</span></button>
                                                            </div>
                                                            </div>
                                                        </div> */}

                                                        {values.outof_stock_flag === '1' ? (
                                                            <>
                                                                {values.cart_count > 0 ? (
                                                                    <>
                                                                        <div className="col-xl-3 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c">
                                                                            <div className="input-group mob-p-l">
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.basketDecreaseItem(values.prod_id, values.prod_code, values.selling_price, values.prod_quantity, values.cart_id)} aria-label="Add one" data-direction="1">
                                                                                        <span className="fa fa-minus"></span>
                                                                                    </button>
                                                                                </span>
                                                                                <input type="text" id={values.prod_code} className="form-control product-input-number product-input-number-custsty" name="quantity" value={values.cart_count} readOnly={true} />
                                                                                <span className="input-group-btn">
                                                                                    <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.basketIncrementItem(values.prod_id, values.prod_code, values.selling_price, values.prod_quantity)} aria-label="Subtract one" data-direction="-1">
                                                                                        <span className="fa fa-plus"></span>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <Link to="/Cart"><span className="paction add-cart" title="View Cart">
                                                                            View Basket
                                                                </span></Link>
                                                                    </>
                                                                ) :
                                                                    (
                                                                        <>
                                                                            <span className="paction add-cart add-cart-custsty" onClick={() => this.productaddtobasket(values.prod_id, values.prod_code, values.selling_price, values.prod_quantity)} title="Add to Cart">
                                                                                Add to Basket
                                                                            </span>
                                                                        </>
                                                                        // <div className="col-lg-12 custom-s-pc-res col-md-12 col-12 custom-cart-align-c">
                                                                        //     <button onClick={ () => this.productaddtobasket(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity) } className="cart-btn">Add to Basket +</button>
                                                                        // </div>
                                                                    )}
                                                            </>
                                                        ) : (
                                                                <div className="col-lg-12 custom-s-pc-res col-md-12 col-12">
                                                                    {/* <button onClick={ () => this.productaddtobasket(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity) } className="cart-btn">Add to Basket +</button> */}
                                                                    <h4 className="outofstockflagcolor">Currently Out Of Stock</h4>

                                                                </div>
                                                            )}


                                                    </div>

                                                    {this.state.show_pd === true ? (
                                                        <div className="autocalculate">
                                                            <p>Total UOM:&nbsp;{this.state.total_weight_pd} {values.unit_id}</p>
                                                            <p>Total Price :&nbsp;AED {this.state.total_price_pd}</p>
                                                            {/* <h3> Total UOM: {this.state.total_weight}</h3> */}<br />
                                                            {/* <h3> Total Price : {this.state.total_price}</h3> */}
                                                        </div>
                                                    ) : (
                                                            <></>
                                                        )}

                                                    {/* <div className="product-desc">
                                                    <p>{values.prod_info}</p>
                                                </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product-single-tabs">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="product-tab-info" data-toggle="tab" href="#product-info-content" role="tab" aria-controls="product-info-content" aria-selected="true">Product Info</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="storage-uses-info" data-toggle="tab" href="#storage-uses-content" role="tab" aria-controls="storage-uses-content" aria-selected="false">Storage & Uses</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="product-info-content" role="tabpanel" aria-labelledby="product-tab-info">
                                                <div className="product-info-content">
                                                    <p>{values.prod_info}</p>
                                                    {prod_dtl_nutrition_info !== '' && values.diet_type_id !== '' ? (
                                                        <>
                                                            <h4 className="mb-1">Nutrition Info</h4>
                                                            <div dangerouslySetInnerHTML={{ __html: prod_dtl_nutrition_info }} />
                                                            <p className="note custom-diet"> <b>Diet Type :</b>  {values.diet_type_id} </p>
                                                        </>
                                                    ) : prod_dtl_nutrition_info === '' && values.diet_type_id !== '' ? (
                                                        <p className="note custom-diet"> <b>Diet Type :</b> {values.diet_type_id} </p>
                                                    ) : prod_dtl_nutrition_info !== '' && values.diet_type_id === '' ? (
                                                        <>
                                                            <h4 className="mb-1">Nutrition Info</h4>
                                                            <div dangerouslySetInnerHTML={{ __html: prod_dtl_nutrition_info }} />
                                                        </>
                                                    ) : (
                                                                    null
                                                                )}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="storage-uses-content" role="tabpanel" aria-labelledby="storage-uses-info">
                                                <div className="storage-uses-content">
                                                    <p className="note">{values.prod_storageuses}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* <div className="sidebar-overlay"></div>
                        <div className="sidebar-toggle"><i className="icon-right-open"></i></div> */}
                    </div>
                </div>

                <section className="bg-grey pt-3 pb-3">
                    <div className="container">
                        <section className="mt-4 mb-4">
                            <div className="section-title mb-2">
                                <h2>Customers Also Viewed</h2>
                            </div>
                            {this.state.relatedproductValues.length && (
                                <OwlCarousel {...options}
                                    className="product-related owl-carousel owl-theme"
                                // items={5}
                                // loop={true}
                                // margin={0}
                                // dots={false}
                                // nav={false}
                                >

{
                                            this.state.relatedproductValues.map(value => {
                                                return (
                                                    <div key={value.prod_id} className="cus-div-lay">
                                                        <a className="cus-div-a" href="#">
                                                            <div className="cus-div-sty">
                                                                <div className="cus-head-div">
                                                                    <div className="cus-img-div">
                                                                        <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                                            {value.outof_stock_flag === '1' ? (
                                                                                <img className="cus-product-img" src={value.produ_imgurl} alt={value.prod_name} />
                                                                            ) : (
                                                                                <img className="cus-product-img outofstockflagon" src={value.produ_imgurl} alt={value.prod_name} />
                                                                            )}
                                                                        </Link>
                                                                    </div>

                                                                    {value.subacribed_prod === true || value.subacribed_prod === "true" ? (
                                                                        <div class="cus-subscribed-sty cus-subscribed-sty-two">
                                                                            <div class="cus-subscribed-txt">Subscribed</div>
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )}

                                                                    <div className="cus-title-div">
                                                                        <div className="cus-title-main">
                                                                            <Link to={{ pathname: '/' + value.md_url_code + '/' + value.sd_url_code + '/' + value.mc_url_code + '/' + value.sc_url_code + '/' + value.prod_url_param + '/' + value.prod_url_code + '' }}>
                                                                                {value.prod_name}
                                                                            </Link>
                                                                        </div>
                                                                        <div className="cus-title-secondary">{value.scName}</div>
                                                                    </div>
                                                                    <div className="cus-price">
                                                                        <span> UOM : {value.prod_quantity} {value.unit_id}</span>
                                                                        <div>
                                                                            {(value.offer_flag === "1" && value.redeemed_offers_flag === 1) || (value.offer_flag === "0" && value.redeemed_offers_flag === 1) ? (
                                                                                <>
                                                                                    <span className="old-price">Rs. {value.original_amt}</span>
                                                                                    <span className="product-price">Rs. {value.selling_price}</span>
                                                                                </>
                                                                            ) : (value.offer_flag === "1" && value.redeemed_offers_flag === 0) ? (
                                                                                <>
                                                                                    <span className="old-price">Rs. {value.original_amt}</span>
                                                                                    <span className="product-price">Rs. {value.selling_price}</span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {value.market_price !== "0.00" ? (
                                                                                        <span className="old-price">Rs. {value.market_price}</span>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                    <span className="product-price">Rs. {value.selling_price}</span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    {value.outof_stock_flag === '1' ? (
                                                                        <>
                                                                            {value.cart_count > 0 ? (
                                                                                <>
                                                                                
                                                                                    <div className="buttons_slots mt-1">
                                                                                        <div className="input-group mob-p-l">
                                                                                            <span className="input-group-btn">
                                                                                                <button type="button" className="btn btn-number product-custom-qty-m" onClick={() => this.basketDecreaseItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity, value.cart_id)} aria-label="Add one" data-direction="1">
                                                                                                    <span className="fa fa-minus"></span>
                                                                                                </button>
                                                                                            </span>
                                                                                            <input type="text" id={value.prod_code} className="form-control product-input-number" name="quantity" value={value.cart_count} readOnly={true} />
                                                                                            <span className="input-group-btn">
                                                                                                <button type="button" className="btn btn-number product-custom-qty-a" onClick={() => this.basketIncrementItem(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} aria-label="Subtract one" data-direction="-1">
                                                                                                    <span className="fa fa-plus"></span>
                                                                                                </button>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="cus-subscribe">
                                                                                        {localStorage.getItem('user_log_status') === "true" ? (
                                                                                            <>
                                                                                                {value.subacribed_prod === "true" ? (
                                                                                                    <Link to="/Subscription"> <span className="product-subscribe" > View Subscription  </span></Link>
                                                                                                ) : (
                                                                                                    <span className="product-subscribe"
                                                                                                        onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                            value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <span className="product-subscribe" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            ) :
                                                                                (
                                                                                    <>

                                                                                        <div className="buttons_slots mt-1">
                                                                                            <button onClick={() => this.productaddtobasket(value.prod_id, value.prod_code, value.selling_price, value.prod_quantity)} className="cart-btn">Add +</button>
                                                                                        </div>
                                                                                        <div className="cus-subscribe">
                                                                                            {localStorage.getItem('user_log_status') === "true" ? (
                                                                                                <>
                                                                                                    {value.subacribed_prod === "true" ? (
                                                                                                        <Link to="/Subscription"> <span className="product-subscribe" > View Subscription  </span></Link>
                                                                                                    ) : (
                                                                                                        <span className="product-subscribe"
                                                                                                            onClick={() => this.opensubscriptionmodel(value.prod_code, value.prod_id, value.produ_imgurl, value.scName,
                                                                                                                value.prod_name, value.brand_name, value.prod_quantity + value.unit_id, value.no_of_prod_pisces, value.subscribe_offer_flag, value.subscribe_offer_type, value.subscribe_offer_value)} >  Subscribe & Save  </span>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <span className="product-subscribe" data-toggle="modal" data-target="#login-form">  Subscribe & Save   </span>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                        </>
                                                                    ) : (
                                                                        <div className="buttons_slots mt-1">
                                                                            <span className="outofstockflagcolor">Currently Out Of Stock</span>
                                                                        </div>

                                                                    )}
                                                                        
                                                                    { (value.offer_flag === '1' && value.redeemed_offers_flag === 1) || (value.offer_flag === '0' && value.redeemed_offers_flag === 1) ? (
                                                                        <div className="cus-dis-div">
                                                                            {value.redeemed_offers_type === 'A' ? (
                                                                                <span className="cus-dis-span-two">{value.redeemed_offers_value} off | Use STEALDEAL</span>
                                                                            ) : (
                                                                                <span className="cus-dis-span-two">{value.redeemed_offers_value}% off | Use STEALDEAL</span>
                                                                            )}
                                                                        </div>
                                                                    ) : (value.offer_flag === '1' && value.redeemed_offers_flag === 0) ? (
                                                                        <div className="cus-dis-div">
                                                                            {value.redeemed_offers_type === 'A' ? (
                                                                                <span className="cus-dis-span-two">{value.offer_value} off | Use STEALDEAL</span>
                                                                            ) : (
                                                                                <span className="cus-dis-span-two">{value.offer_value}% off | Use STEALDEAL</span>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                            <>
                                                                            </>
                                                                        )}
                                                                </div>
                                                                <div className="cus-div-btn-div">
                                                                    <span role="button" aria-label="Open" className="cus-hov-btn cus-hov-sty" onClick={() => this.onOpenModal(value.prod_id)} data-toggle="modal" data-target="#addCartModal">Quick View</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }
                                    
                                </OwlCarousel>
                            )}
                        </section>
                    </div>
                </section>

                <div>

                    <ModalGateway>
                        {modalIsOpen ? (
                            <Modall onClose={this.toggleModal}>
                                <Carousel views={this.state.images_source} />
                            </Modall>
                        ) : null}
                    </ModalGateway>

                    {/* <button >Open modal</button> */}
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="container cust_stype_modal">
                            <div className="row row-sm">
                                <div className="col-lg-12 col-xl-12">
                                    {this.state.productDetails_popup.map(values => (
                                        <div key={values.prod_id}>
                                            <div className="product-single-container product-single-default">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-8 product-single-gallery">
                                                        <div className="product-slider owl-carousel owl-theme" data-toggle="owl" data-owl-options="{
                                                    'items': 1,
                                                    'loop': false,
                                                    'autoplay': false,
                                                    'autoplayHoverPause': true
                                                }">
                                                            <div className="product-slide">
                                                                <div className="product-default">
                                                                    <figure>
                                                                        <a href={values.produ_imgurl}>
                                                                            <img src={values.produ_imgurl} alt="" />
                                                                        </a>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="product-single-details">
                                                            <h1 className="product-title">{values.prod_name}</h1>
                                                            <p className="mb-1">Category: {values.scName} </p>
                                                            <p className="mb-1">UOM: {values.prod_quantity} {values.unit_id} </p>
                                                            <p className="mb-1">Brand : {values.brand_name}</p>
                                                            {values.offer_flag === '1' ? (
                                                                <>
                                                                    <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                                        {values.market_price !== "0.00" ? (
                                                                            <>( AED {values.original_amt} )</>
                                                                        ) : (
                                                                                <> </>
                                                                            )}
                                                                    </s> <br />
                                                                        <span className="product-save-discount">You Save :&nbsp; {values.offer_value}   {values.offer_type === 'P' ? (
                                                                            <> % </>
                                                                        ) : (
                                                                                <> AED </>
                                                                            )} OFF</span>
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                    <>
                                                                        <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                                            {values.market_price !== "0.00" ? (
                                                                                <>( Avg. Market Price AED {values.market_price} )</>
                                                                            ) : (
                                                                                    <> </>
                                                                                )}
                                                                        </s> <br />
                                                                        </p>
                                                                    </>
                                                                )}


                                                            {/* <p>Our Price :&nbsp;<span className="product-price"> AED {values.selling_price}</span> &ensp;<s>
                                                            {values.market_price!=0 ? (
                                                                <>( Avg. Market Price AED {values.market_price} )</>
                                                            ):(
                                                                <> </>
                                                            )}  
                                                        </s> <br />
                                                    </p> */}

                                                            <div className="product-action product-all-icons mt-2">
                                                                {values.outof_stock_flag === '1' ? (
                                                                    <>
                                                                        <div className="product-quantity">
                                                                            <input onChange={this.onChangeHandler} className="custom-quantity" data-min="1" data-max="0" type="text" name="quantity" value={this.state.clicks} readOnly={true} /><div className="quantity-selectors-container">
                                                                                <div className="quantity-selectors">
                                                                                    <button type="button" onClick={this.IncrementItem} aria-label="Add one" data-direction="1"><span>&#43;</span></button>
                                                                                    <button type="button" onClick={this.DecreaseItem} aria-label="Subtract one" data-direction="-1"><span>&#8722;</span></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <span className="paction add-cart" onClick={this.movetobasket} title="Add to Cart">
                                                                            Add to Basket
                                                                </span>
                                                                    </>
                                                                ) : (
                                                                        <div className="col-lg-12 custom-s-pc-res col-md-12 col-12">
                                                                            {/* <button onClick={ () => this.productaddtobasket(values.prod_id,values.prod_code,values.selling_price,values.prod_quantity) } className="cart-btn">Add to Basket +</button> */}
                                                                            <h4 className="outofstockflagcolor">Currently Out Of Stock</h4>

                                                                        </div>
                                                                    )}

                                                            </div>

                                                            {/* <div className="product-action product-all-icons mt-2">
                                                        <div className="product-quantity">
                                                            <input  className="custom-quantity" data-min="1" data-max="0" type="text" name="quantity" value={ this.state.clicks } readOnly={true} /><div className="quantity-selectors-container">
                                                            <div className="quantity-selectors">
                                                                <button type="button" onClick={this.IncrementItem} aria-label="Add one" data-direction="1"><span>&#43;</span></button>
                                                                <button type="button"  onClick={this.DecreaseItem} aria-label="Subtract one" data-direction="-1"><span>&#8722;</span></button>
                                                            </div>
                                                            </div>
                                                        </div>

                                                        <span className="paction add-cart" onClick={this.movetobasket} title="Add to Cart">
                                                            Add to Basket
                                                        </span>
                                                    </div> */}



                                                            {this.state.show === true ? (
                                                                <div className="autocalculate">
                                                                    <p>Total UOM:&nbsp;{this.state.total_weight} Kg</p>
                                                                    <p>Total Price :&nbsp;AED {this.state.total_price}</p>
                                                                    {/* <h3> Total UOM: {this.state.total_weight}</h3> */}<br />
                                                                    {/* <h3> Total Price : {this.state.total_price}</h3> */}
                                                                </div>
                                                            ) : (
                                                                    <></>
                                                                )}

                                                            <div className="product-desc">
                                                                <p>{values.prod_info}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    ))}
                                </div>

                                {/* <div className="sidebar-overlay"></div>
                            <div className="sidebar-toggle"><i className="icon-right-open"></i></div> */}
                            </div>
                        </div>
                    </Modal>
                    <Popup
                        open={this.state.subscriptionopen}
                        modal
                        nested
                        closeOnDocumentClick={false}
                    >
                        {close => (
                            <div className="subsription_modal">
                                <button className="subsription_close" onClick={() => this.closesubscriptionmodel()}>
                                    &times;
                                    </button>
                                <div className="subsription_header"> Subscribe
                                    </div>
                                <div className="subsription_content">
                                    {' '}
                                    <div className="ml-3 subsription_product_details_display">
                                        <div className="subsription_product_img">
                                            <img src={this.state.subscription_produ_imgurl} alt="" />
                                        </div>
                                        <div className="subsription_product_info">
                                            <span className="font-size-14"><b>{this.state.subscription_produ_name}</b></span>
                                            <span> UOM  : {this.state.subscription_produ_umo} </span>
                                            <span className="product-save-discount"> {this.state.subscribe_offer_text} </span>
                                        </div>
                                    </div>

                                    <div className="subscription_header_tab">
                                        <div className="subscription_tab" id="subscription_tab_one" onClick={() => this.showtabone()}>
                                            <p className="m-0">Weekly</p>
                                        </div>
                                        <div className="subscription_tab" id="subscription_tab_two" onClick={() => this.showtabtwo()}>
                                            <p className="m-0">Bi-Weekly</p>
                                        </div>
                                        <div className="subscription_tab" id="subscription_tab_three" onClick={() => this.showtabthree()}>
                                            <p className="m-0">Monthly</p>
                                        </div>
                                    </div>

                                    <div id="subscription_tab_one_content">
                                        <div className="display_weekly_days mb-1">
                                            <div className="subscription_name_lable_div subscription_name_lable_popup mb-1">
                                                <span className="mr-2 subscription_name_lable"> Which Days ? </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup mb-1">
                                                {
                                                    this.state.subscription_weekly.map(values => {
                                                        return (
                                                            <span key={values.subscription_weekly_id} className="subscription_weekly_days subscription_weekly_days_popup">
                                                                <div className="subscription_day_lable_popup">
                                                                    <label for={values.subscription_weekly_id + "toggle"}>{values.week_name}</label>
                                                                    <input type="checkbox" name="subscription_weekly" id={values.subscription_weekly_id + "toggle"} value={values.subscription_weekly_id} class="visually-hidden-lable" />
                                                                </div>
                                                                <div className="subscription_weekly_add_minus" onClick={() => this.subscription_quantity(values.week_name + "_quantity", "1", "add", values.subscription_weekly_id + "toggle")}>
                                                                    <span className="fa fa-plus"></span>
                                                                </div>
                                                                <div className="subscription_weekly_quantity">
                                                                    <input readOnly className="subscription_weekly_quantity_value" type="text" name={values.week_name + "_quantity"} id={values.week_name + "_quantity"} value={this.state[values.week_name + "_quantity"]} />
                                                                </div>
                                                                <div className="subscription_weekly_add_minus" onClick={() => this.subscription_quantity(values.week_name + "_quantity", "1", "minus", values.subscription_weekly_id + "toggle")}>
                                                                    <span className="fa fa-minus"></span>
                                                                </div>

                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/* <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                    <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                                </div>
                                                <div className="subscription_content_div subscription_content_popup">
                                                    <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                        <div className="input-group ">
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("weekly_quantity", "1", "minus")} aria-label="Add one" data-direction="1">
                                                                    <span className="fa fa-minus"></span>
                                                                </button>
                                                            </span>
                                                            <input type="text" className="form-control product-input-number product-input-number-custsty" name="weekly_quantity" value={this.state.weekly_quantity} readOnly={true} />
                                                            <span className="input-group-btn">
                                                                <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("weekly_quantity", "1", "add")} aria-label="Subtract one" data-direction="-1">
                                                                    <span className="fa fa-plus"></span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>
                                    </div>

                                    <div id="subscription_tab_two_content">
                                        <div className="display_weekly_days mb-1">
                                            <div className="subscription_name_lable_div subscription_name_lable_popup mb-2">
                                                <span className="subscription_name_lable"> Which Dates ? </span>
                                                <span className="subscription_name_lable cust_text_clear float-right text-right" onClick={() => this.clearselectedsubscription("visually-hidden-lable-biweekly")}> Clear selected </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup subscription_biweekly_content_popup mb-1">
                                                {
                                                    this.state.subscription_biweekly.map(values => {
                                                        return (
                                                            // <span key={values.subscription_biweekly_id} className="display_biweekly_popup_content"> {values.biweekly_dates} </span>
                                                            <span key={values.subscription_biweekly_id} className="subscription_biweekly_days_popup">
                                                                <div key={values.subscription_biweekly_id}>
                                                                    <label className="display_biweekly_popup_content" for={values.subscription_biweekly_id + "toggle_biweekly"}>{values.biweekly_dates}</label>
                                                                    <input type="radio" name="subscription_biweekly" id={values.subscription_biweekly_id + "toggle_biweekly"} value={values.subscription_biweekly_id} class="visually-hidden-lable-biweekly" onClick={this.getbiweeklysubscriptionvalues} />
                                                                </div>
                                                            </span>

                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup">
                                                <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                    <div className="input-group ">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("biweekly_quantity", "1", "minus", "dummy")} aria-label="Add one" data-direction="1">
                                                                <span className="fa fa-minus"></span>
                                                            </button>
                                                        </span>
                                                        <input type="text" className="form-control product-input-number product-input-number-custsty" name="biweekly_quantity" value={this.state.biweekly_quantity} readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("biweekly_quantity", "1", "add", "dummy")} aria-label="Subtract one" data-direction="-1">
                                                                <span className="fa fa-plus"></span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="subscription_tab_three_content">
                                        <div className="display_weekly_days mb-1">
                                            <div className="subscription_name_lable_div subscription_name_lable_popup mb-2">
                                                <span className="subscription_name_lable"> Which Date ? </span>
                                                <span className="subscription_name_lable cust_text_clear float-right text-right" onClick={() => this.clearselectedsubscription("visually-hidden-lable-monthly")}> Clear selected </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup subscription_biweekly_content_popup mb-1">
                                                {
                                                    this.state.subscription_monthly.map(values => {
                                                        return (
                                                            // <span key={values.subscription_monthly_id} className="display_monthly_popup_content"> {values.monthly_date}  </span>
                                                            <span key={values.subscription_monthly_id} className="subscription_monthly_days_popup">
                                                                <div >
                                                                    <label className="display_monthly_popup_content" for={values.subscription_monthly_id + "toggle_monthly"}>{values.monthly_date}</label>
                                                                    <input type="radio" name="subscription_biweekly" id={values.subscription_monthly_id + "toggle_monthly"} value={values.subscription_monthly_id} class="visually-hidden-lable-monthly" onClick={this.getmonthlysubscriptionvalues} />
                                                                </div>
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="subscription_name_lable_div subscription_name_lable_popup">
                                                <span className="mt-1 mr-2 subscription_name_lable"> How many units ? </span>
                                            </div>
                                            <div className="subscription_content_div subscription_content_popup">
                                                <div className="col-xl-4 col-sm-6 col-lg-4 col-md-6 col-6   custom-cart-align-c custom-padd-left-c">
                                                    <div className="input-group ">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-number product-custom-qty-m product-custom-qty-m-custsty" onClick={() => this.subscription_quantity("monthly_quantity", "1", "minus", "dummy")} aria-label="Add one" data-direction="1">
                                                                <span className="fa fa-minus"></span>
                                                            </button>
                                                        </span>
                                                        <input type="text" className="form-control product-input-number product-input-number-custsty" name="monthly_quantity" value={this.state.monthly_quantity} readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-number product-custom-qty-a product-custom-qty-a-custsty" onClick={() => this.subscription_quantity("monthly_quantity", "1", "add", "dummy")} aria-label="Subtract one" data-direction="-1">
                                                                <span className="fa fa-plus"></span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.showsubscriptionerrormessage === true ? (
                                        <p className="error ml-4"> {this.state.subscription_errormsg} </p>
                                    ) : (
                                            <> </>
                                        )}
                                    <input type="hidden" value={this.subscription_prod_id} name="subscription_prod_id" />
                                    <input type="hidden" value={this.subscription_prod_code} name="subscription_prod_code" />

                                    {this.state.addtosubscribebtn === true ? (
                                        <button className="paction add-subscription float-left add-cart add-cart-custsty" title="Add to subscription"
                                            onClick={() => this.submit_subscription()}> Add to subscription </button>
                                    ) : (
                                            <button className="paction add-subscription float-left add-subscription-disabled add-cart add-cart-custsty" title="Add to subscription"
                                                disabled="true" > Add to subscription </button>
                                        )}
                                        <Link to="/Subscription">
                                    <span className="float-right view_subscriotion_popup" title="View subscription"
                                    >  View Subscription  </span></Link>
                                </div>

                            </div>
                        )}
                    </Popup>

                </div>

            </main>
        )
    }
}

export default ProductDescription