import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Checkout.css';
import { axiosInstance } from '../../Services/PostData';
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

class placedOrder extends Component{

    constructor(){
		super();
		this.state = {
            spinner : 'loading-overlay',
            small_spinner : 'ajax-overlay hide-content',
            placedorderDetails: "",
            OrderNo: ""
        };
		this.getPlacedOrderDetails = this.getPlacedOrderDetails.bind(this);
    }

    componentDidMount() {
        let unique_id = this.generateId(20);
        let user_auth_key = localStorage.getItem('user_auth_key');

        if (user_auth_key === undefined || user_auth_key === null || user_auth_key === '' || 
            user_auth_key === "undefined" || user_auth_key === "null") 
        {
            localStorage.setItem('user_auth_key', unique_id);
            // cookies.set('user_auth_key', unique_id, {
            //     expires: nextYear,
            // });
        }
        this.getPlacedOrderDetails();
    }
    dec2hex(dec) {
        return dec < 10
            ? '0' + String(dec)
            : dec.toString(16)
    }
    // generateId :: Integer -> String
    generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }
    
    async getPlacedOrderDetails() {
        let user_auth_key = localStorage.getItem('user_auth_key');
        const jsonValue = { user_unique_id: user_auth_key };
        await axiosInstance.post(`getPlacedOrderDetails`, jsonValue)
            .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let placedorderResponse = res.data.placed_order_details;
                    let orderno = res.data.placed_order_details[0].orderno;
                    let PlacedOrderDetails = placedorderResponse.map(placedorder_response => { return placedorder_response });
                    this.setState({ placedorderDetails: PlacedOrderDetails, OrderNo: orderno });
                    // let spinner = 'loading-overlay hide-content';
                }
            })
            .catch(err => console.log('Error: ', err));
    }

    render() {
        return (
            <main className="main home">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6">
                        <div className="placed-order-style">
                            <img className="placed-order-img" src="/assets/img/Basket-Order_1_Ani_3_1.gif" alt="" />
                            <h2 className="placed-order-text"> </h2>
                            <h3>Order Number : {this.state.OrderNo}</h3>
                            <h4>Thanks for placing your order with Yalla Basket. We are excited to serve you.</h4>
                            <div className="displayname_justify">
                                <p>
                                    <img src="/assets/img/black-circle.png" alt="place order" className="order_list_cuss_stylee" />  
                                    You are welcome to inspect the product quality & freshness and accept it only if it meets your expectations. We do not charge you before delivering your order. 
                                </p>
                                <p>
                                    <img src="/assets/img/black-circle.png" alt="place order" className="order_list_cuss_stylee" />  
                                    With Yalla Basket, now you can pay for your order during the time of delivery through cash or card.
                                </p>
                            </div>
                            <p>We will notify the progress of your order shortly.</p>

                            {/* <div>
                                <ul >
                                    <li>You are welcome to inspect the product quality & freshness and accept it only if it meets your expectations. We do not charge you before delivering your order. </li>
                                    <li>With Yalla Basket, now you can pay for your order during the time of delivery through cash or card.</li>
                                </ul>
                            </div> */}


                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <Link to="/"><p className="text-right"><button className="placed-order-btn mb-3">Continue Shopping</button></p></Link>
                                </div>
                                <div className="col-md-6 col-6">
                                    <Link to="/Orders"><p className="text-left"><button className="placed-order-btn mb-3">Order History</button></p></Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </main>
        )
    }

}

export default placedOrder
